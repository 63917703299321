import React, { Component } from "react";
//import uniqueId from 'lodash/uniqueId';
//import { Link } from 'react-router'
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { hashHistory } from 'react-router';
import axios from "axios";
import {  browserHistory} from 'react-router'
import  { Redirect } from 'react-router-dom'
import ParticipentSignup from '../auth/participateSignUp'

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING
} from "../../actions/types";

//import $ from 'jquery';

class ondemand extends Component {
            constructor() {
            super();
           // let email;
            this.state = {
                data:"",
                showError:false,
                messageFromServer:'',
                type:"1",
                errors: {},
				session_found : false,
				od_status : 1,
				od_session_info : [],
				odError: false,
				msg: ''
              };
        
            }

        componentDidMount() {

          this.verifySessionId();
        }

        componentWillMount(){
          
        }

          
        verifySessionId = e => {
   
         let path=window.location.pathname;

         let path_arr = path.split("/");

         const userData = {
          sessId: path_arr[2]
        };
		console.log('---------------------',userData);
		 axios
         .post(process.env.REACT_APP_NAME+"/api/v1/session/checkOnDemand",userData)                
         .then(res => {
			 console.log(res.data.responseData);
			 if ( res.data.responseData.length > 0 )
				{
					this.setState({
						session_found : true,
						od_session_info : res.data.responseData
					})
				}
			else 
				{
					this.setState({
						session_found : false,
						od_session_info : []
					})
				}				
		 }).catch(err =>
			this.setState({
				odError: true,
				msg:err.response.data.errorData
			}) 
		);
		/*
         axios
         .post(process.env.REACT_APP_NAME+"/api/v1/session/verifyuser",userData)                
         .then(res => {
          
    console.log(res.data.responseData.sessionDta.id,'---------verifyuser123333--------------',res.data.responseData.sessionDta)
         // console.log('Lalit');
          if(res.data.responseMessage == 'success')
          {
            localStorage.setItem("sessionId", res.data.responseData.sessionDta.id);
            localStorage.setItem("scheduleDate",res.data.responseData.sessionDta.scheduleDate);
            localStorage.setItem("duration",res.data.responseData.sessionDta.duration);
            localStorage.setItem("sessionEndDate",res.data.responseData.sessionDta.sessionEndDate);
            localStorage.setItem("allowSS",res.data.responseData.sessionDta.allowSS);
            localStorage.setItem("parentRecrId",res.data.responseData.sessionDta.parentRecrId);
            //localStorage.setItem("recurringDetails",JSON.stringify(res.data.responseData.sessionDta.recurringDetails));
			
			if ( window.location.search.slice(1) )
			{
				var dataCode = atob(window.location.search.slice(1));
				if ( dataCode )
				{
					dataCode = JSON.parse(dataCode);
					if ( dataCode && dataCode['email'] )
						{
							localStorage.setItem("channelEmail", dataCode['email']);
							if ( dataCode['backToChannel'] ) { localStorage.setItem("backToChannel", dataCode['backToChannel']); }
						}
				}
				
			}
			
			
            browserHistory.push("/participantSignup");
          }

        })
      */
                        
          };

goToVirdio=e=>{
	browserHistory.push("/");
}
        render() {

        return (

            <div>
				{this.state.session_found &&
					<div>
						<ParticipentSignup state={this.state}></ParticipentSignup>              
					</div>
				}
				{this.state.odError &&
					<div className="errorOd_popup" >
						<div className="error_pop_holder" >
							<div className="error_h1" >{this.state.msg}</div>
							<div className="join_button text-uppercase go_to_btn" onClick={this.goToVirdio} >Go To Virdio</div>
						</div>
					</div>
				}				
          </div>

         );

         }

}

const container = {
    "padding": "0px 15px",
    "max-width": "1140px"
  
  };

  export default ondemand;

