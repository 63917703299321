import React, { Component } from "react";
import { Link } from 'react-router';
import $ from 'jquery';
import { browserHistory} from 'react-router'
import SimpleReactValidator from 'simple-react-validator';
class SessionFields extends Component {
  
  constructor(props) {
    super(props);
	this.validator = new SimpleReactValidator();
	this.state = {
		pickedStationId: '',
	}
}

	selectStationBeforeClass=(e)=>{
		this.setState({pickedStationId: e.target.value});
		
		if ( e.target.value !== "" )
			{
				var pickedId = e.target.value;
				var pickedMinutes = this.props.state.sessionFmTimes;
				var chosenMinute = -1;
				var fmStationName = "";
				for ( var i = 0; i < this.props.state.fmStations.length; i++ )
					{
						if ( pickedId == this.props.state.fmStations[i].id )
							{
								fmStationName = this.props.state.fmStations[i].name;
							}
					}
				
				var objectToAdd = {time: -1, fmStationId: pickedId, fmStationName: fmStationName};
				
				if ( pickedMinutes.indexOf(chosenMinute) == -1 )
					{
						this.props.addStations(objectToAdd);
					}
				else
					{
						this.props.editStation(objectToAdd, 0);
					}
			}
		else
			{
				if ( this.props.state.sessionFmTimes.length > 0 && this.props.state.sessionFmTimes[0] == -1 ) { this.props.removeStation(0); }
			}
	}
	
	updatePickedState=(id)=>{
		this.setState({pickedStationId: id});
	}

  render() {
    return (
		<div>
			<div className="">
				<div className="">
				  <div className="">
					<div className="">
					  <div className="width50fields first_fields">
						<div className="form-group">
						  <div className="invalid-show"></div>
						  <span className=""></span>
						  <label className="label">Class Name ({this.props.state.nameNumLeft})<sup className="start_color">*</sup></label>
						  <input type="text" className={(this.props.state.errorClass_SessionDetail ?"input-field red-alert":"input-field")}
						   ref={s => (this._session_details = s)} id = "session_details" 
						   value= {this.props.state.session_details}
						   onChange = {(e)=>this.props.updateStateFromChild({[e.target.id] : e.target.value,description:this.props.state.descriptionStatus.length>0?this.props.state.description:e.target.value,errorClass_SessionDetail:false, errorClass_Description:false})} placeholder="Class Name" maxLength="30"/>
						  {this.validator.message('session_details', this.props.state.session_details, 'required|min:1|max:30')}
						</div>
						<div className="form-group">
						  <span className="cover-border cover-border_create"></span>
						  <label className="label ch_top19">Description (Max 250 characters)<sup className="start_color">*</sup></label>
						  <textarea type="text" id="description" ref={d => this.props.updateDescField(d)} 
						  value = {this.props.state.description} 
						   onChange = {(e)=>this.props.updateStateFromChild({[e.target.id] : e.target.value,descriptionStatus:this.props.state.description,errorClass_Description:false})} 
						  className={(this.props.state.errorClass_Description ?"input-field fields_back none_resize red-alert":"input-field fields_back none_resize")} maxLength="250"></textarea>
						  {this.validator.message('description', this.props.state.description, 'required|min:1|max:250')}
						</div>									 
						<div className={('form-group'+(this.props.state.isMarketplaceChannel ? ' d-none' : ''))}>													
						  <span className="cover-border cover-border_create"></span>
						  <label className="label">Level<sup className="start_color">*</sup></label>														
						  <select
							className="input-field fields_back"
							id="exampleFormControlSelect1"
							style={{color:(this.props.state.exampleFormControlSelect1?"white" : '')}}
							value = {this.props.state.exampleFormControlSelect1}
							onChange = {this.props.sessionInfo}>
							<option>Beginner</option>
							<option>Intermediate</option>
							<option>Advanced</option>
							<option>Multi-Level</option>
						  </select>
					  </div>
					  </div>
					  <div className="width50fields second_fields">
						{ ((this.props.state.showIfTemplate == 0 && !this.props.state.isMarketplaceChannel) || (this.props.state.showIfTemplate == 0 && this.props.state.isMarketplaceChannel && this.props.state.userPermissions.canChangeTime)) &&					  
							<div className="form-group" >
								<div onClick={e=>{$("#calenderModel").attr({'style':'display:block'});}}>
								  <span className=""></span>
								  <label className="label">When?<sup className="start_color">*</sup></label>
								  <input type="text" id = "when" value = {this.props.state.whenSessionTime} onChange = {this.props.sessionInfo} className={(this.props.state.errorClass_Datevalidatior ? "input-field red-alert":"input-field")} placeholder="Pick a date and time" ref={wh => (this._when = wh)} readOnly/>
								  {this.validator.message('when', this.props.state.when, 'required')}
								  <div className="when-icon" ></div>
								</div>
							  <img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer manage_bulb calendar_bulb" onClick={e=>{this.props.getFaq(24)}}/>
							</div>
						}
						 <div className="row rownomargins">
						   {!this.props.state.isMarketplaceChannel && 
							   <div className="col-md-6 mdnopadding mdpaddingright">
									<div className="form-group"><span className="cover-border cover-border_create"></span>
									  <label className="label">Hours<sup className="start_color">*</sup></label>
									  <select className={"input-field fields_back " + (this.props.state.errorClass_Duration ? 'red-alert' : '') } style={{color:(this.props.state.sessionHour?"white":'')}} id="sessionHour" value={this.props.state.sessionHour} onChange={this.props.sessionInfo}>
										{this.props.forWineHour()}
									  </select>
									</div>
								</div>
						   }
						  { ((!this.props.state.isMarketplaceChannel) || (this.props.state.pickedReplayId == 0 && this.props.state.isMarketplaceChannel && this.props.state.userPermissions.canChangeDuration)) &&
							  <div className="col-md-6 mdnopadding mdpaddingright">
								<div className="form-group"><span className="cover-border cover-border_create"></span>
								  <label className="label">Minutes<sup className="start_color">*</sup></label>
									<select className={"input-field fields_back " +(this.props.state.errorClass_Duration ? 'red-alert' : '')} style={{color:(this.props.state.sessionMinute?"white":'')}} id="sessionMinute" value={this.props.state.sessionMinute} onChange={this.props.sessionInfo}>
									{this.props.forWineMinute()}
									</select>
								  </div>
							  </div>
						  }
						  { this.props.state.isMarketplaceChannel &&
							   <div className="col-md-6 mdnopadding">
								<div className="form-group"><span className="cover-border cover-border_create"></span>
								  <label className="label">Music Genre<sup className="start_color"></sup></label>
									<select className="input-field fields_back" id="chosenMusic" value={this.props.state.chosenMusic} onChange={this.props.sessionInfo}>
										<option value=""></option>
										{ this.props.state.virdioMusic.map((row, i)=>
											<option value={row.name} key={i}>{row.name}</option>
										)}
									</select>
								  </div>
							  </div>
						  }
						</div>
						{ this.props.state.isMarketplaceChannel &&
							<div className="form-group">
								<span className="cover-border cover-border_create"></span>
								<label className="label">Music Before Class<sup className="start_color"></sup></label>
								<select className="input-field fields_back" id="pickedStationId" value={this.state.pickedStationId} onChange={this.selectStationBeforeClass}>
									<option value=""></option>
									{ this.props.state.fmStations.map((row,i) =>
										<option key={i} value={row.id} className={( row.name == 'Stop Playing' ? "d-none":"")} >{row.name}</option>
									)}
								</select>
							</div>
						}
						{ this.props.state.showIfTemplate == 0 &&
							<div className="form-group d-none">
							  <span className=""></span>
							  <label className="label">Minimum Members<sup className="start_color">*</sup></label>
							  <div className="">
								<input
								  type="text"
								  className={(this.props.state.errorClass_MinimumParticipant  ?"input-field red-alert":"input-field")}
								  id = "minimumParticipants"
								  value = {this.props.state.minimumParticipants}
								  onChange = {(e)=>this.props.updateStateFromChild({[e.target.id] : e.target.value,errorClass_MinimumParticipant:false,errormsg:''})}
								  placeholder="min 1"
								  ref={min => (this._minimumParticipants = min)}
								  />
								{this.validator.message('minimumParticipants', this.props.state.minimumParticipants, 'required|integer')}
								<span className="signedup_2"></span>
							  </div>
						</div>
						}
						{ this.props.state.showIfTemplate == 0 && this.props.state.isMarketplaceChannel === false &&
							<div className="form-group">
							  <span className=""></span>
							  <label className="label">Maximum Members<sup className="start_color">*</sup></label>
							  <input type="text" id = "maximumParticipants"
							   value = {this.props.state.maximumParticipants} 
							   onChange = {(e)=>this.props.updateStateFromChild({[e.target.id] : e.target.value,errorClass_MaximumParticipant:false,errormsg:''})}
							   className={(this.props.state.errorClass_MaximumParticipant  ?"input-field red-alert":"input-field")} placeholder="max 50" ref={max => (this._maximumParticipants = max)}/>
							  {this.validator.message('maximumParticipants', this.props.state.maximumParticipants, 'required|integer')}
							  <span className="signedup_2"></span>
							</div>
						}
					  </div>
					  <div className="width50fields third_fields">
						 <div className="form-group sessionselectimage">													
							<span className="cover-border cover-border_create"></span>
							<label className="label">Upload Image</label>														
							<div className="custom-file-session">
								<img src={!this.props.state.session_image ? "/images/browse-image.png ": this.props.state.session_image} className="browse_image p10" alt="" />
								<input type="file" className="custom-file-input cursor" id="customFile_imgs" name="file" onChange={ (e) => { this.props.changeSessionImage(e) }} />
							</div>
						</div>
						
						{ this.props.state.isMarketplaceChannel &&
							<div>
								<div>
									 {this.props.state.channelData.length>1?
										<div>
											<div className="form-group new_rel_pos marbottom28">													
											<span className="cover-border cover-border_create"></span>
											<label className="label">Category</label>	
											<div className="input-field select_arrow_div text_wrap_demand set_new_padd fields_back" onClick={ e =>{this.props.openNewIntrest(e)}}>{this.props.state.newInstrestSelected.length === 0 ?  'Channel Interest' : this.props.state.selectedinterestsNames}</div>
											{this.props.state.expandNewIntrest &&
												<div className="intrest_select">
													{ this.state.channelInterestSelectionNew.sort(function(a, b){ if(a.title < b.title) { return -1; } if(a.title > b.title) { return 1; } return 0; }).map((row,i)=>											

														<div className="newIntrestName" key = {i}  onClick={ e=>{this.props.newSelectIntrest('upcoming', row.id, row.title, e)} } >{row.title}
															<div className="checkBox new_checkBox_size"  className={"checkBox new_checkBox_size " + (this.props.state.newInstrestSelected.indexOf(row.id) > -1 ? 'active' : '' )} ></div>
														</div>
											
													)}
												</div>
											}
											</div>
										</div>
										:
										<div>
											<div className="form-group new_rel_pos marbottom28">													
											<span className="cover-border cover-border_create"></span>
											<label className="label">Category</label>	
											<div className="input-field select_arrow_div text_wrap_demand set_new_padd fields_back" onClick={ e =>{this.props.openNewIntrest(e)}}>{this.props.state.newInstrestSelected.length === 0 ?  'Channel Interest' : this.props.state.selectedinterestsNames}</div>
											{this.props.state.expandNewIntrest &&
												<div className="intrest_select">
													{ this.props.state.channelInterestSelection.sort(function(a, b){ if(a.title < b.title) { return -1; } if(a.title > b.title) { return 1; } return 0; }).map((row,i)=>											

														<div className="newIntrestName" key = {i}  onClick={ e=>{this.props.newSelectIntrest('upcoming', row.id, row.title, e)} } >{row.title}
															<div className="checkBox new_checkBox_size"  className={"checkBox new_checkBox_size " + (this.props.state.newInstrestSelected.indexOf(row.id) > -1 ? 'active' : '' )} ></div>
														</div>
											
													)}
												</div>
											}
											</div>
										</div>
									}
								</div>
								<div>
									<div>
										<div className="form-group new_rel_pos">
											<span className="cover-border cover-border_create"></span>
											<label className="label">Sub-category</label>	
											<div className="input-field select_arrow_div text_wrap_demand set_new_padd fields_back" onClick={ e =>{this.props.openLiveIntrestSubcategories(e)}}>{this.props.state.liveInterestSubcategories.length === 0 ?  'Sub-Category' : this.props.state.liveSelectedInterestsSubcategoriesNames}</div>
											{this.props.state.expandLiveIntrestSubcategories &&
												<div className="intrest_select">
													{ this.props.state.channelLiveSubcategoriesSelection.sort(function(a, b){ if(a.title < b.title) { return -1; } if(a.title > b.title) { return 1; } return 0; }).map((row,i)=>											
														<div className="newIntrestName" key = {i}  onClick={ e=>{this.props.liveSelectIntrestSubcategory(row.id, row.title, e)} } >{row.title}
															<div className="checkBox new_checkBox_size"  className={"checkBox new_checkBox_size " + (this.props.state.liveInterestSubcategories.indexOf(row.id) > -1 ? 'active' : '' )} ></div>
														</div>
											
													)}
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						}
					</div>
					
					
					
					
					
				  </div>
				  </div>
				</div>
			  </div>
		 </div>
    );
  }
}
export default SessionFields;

