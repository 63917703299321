import React, { Component } from "react";
import classnames from "classnames";
import { Link } from "react-router";

class MemberVerify extends Component {
  
  constructor(props) {
	super(props);
	
	this.state={
		appUrl: process.env.REACT_APP_URL+'login',
		
    }
	
}
 
componentDidMount(){
	
}

goBack=()=>{
	
	if ( this.props.state.isWebLogin )
		{
			this.props.updateStateFromChild({showOtp: false})
		}
	else 
		{
			this.props.goToNextStep(1)
		}		
	
}

openChangePop=()=>{
	this.props.updateStateFromChild({showEmailPop: true});
}

closeChangePop=()=>{
	this.props.updateStateFromChild({showEmailPop: false});
}

  render() {
	 return (
		<div className="memberRegisterHolder more_width_welcome">
			<div className="verifyH1">Verfiy your account</div>
			<div className="verifyH2">We've sent an email with a code to <span className="verifySpan">[{this.props.state.registeredEmail}]</span> to verify your Virdio account. </div>
			<div className="verifyCodeH1">Security Code</div>
			<div className="relative_holder verifyPadding">
				<input type="text" tabIndex="1" className={classnames({'gray_inputs': true})} 
				id="verifyCode"
				value={this.props.state.verifyCode} 
				onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value})} 
				onFocus={() => this.props.updateStateFromChild({focusedElement: 'verifyCode'})} 
				onBlur={() => this.props.updateStateFromChild({focusedElement: ''})}
				/>
				{ this.props.state.verifyCode !== '' &&
					<div className="empty_input verifyInput" onClick={()=>this.props.updateStateFromChild({verifyCode: ""})}></div>
				}
			</div>
			<div className="verifyTextMsg">Didn’t receive the code?</div>
			<div className="resCodeBtn" onClick={(e)=>this.props.resendCode()} >Resend Code</div>
			<div className="verifyTextMsg">Wrong email?</div>
			<div className="resCodeBtn" onClick={(e)=>this.openChangePop()} >Change email address</div>
			<div className="noteHolder">
				<div className="noteText">Please make sure to check your tabs Updates, Promotions, Forums, and Spam Folder</div>
			</div>
			<div className="verifyNewButton" onClick={(e)=>this.props.verifyAccount()} >Continue</div>
			
			{this.props.state.showEmailPop && 
			<div className="changeEmailPop">
				<div className="innerPopupPart">
					<div className="close_pops" onClick={(e)=>this.closeChangePop()} ></div>
					<div className="innerPopH1">Change Email Address</div>
					<div className="innerPopH2">Enter email below. We’ll send you an email with a code to this new address.</div>
					<div className="verifyCodeH1">Email address</div>
					<div className="relative_holder verifyPadding">
						<input type="text" tabIndex="1" className={classnames({'gray_inputs': true})} 
						id="verifyEmail"
						placeholder="Enter Email Address"
						value={this.props.state.verifyEmail} 
						onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value})} 
						onFocus={() => this.props.updateStateFromChild({focusedElement: 'verifyEmail'})} 
						onBlur={() => this.props.updateStateFromChild({focusedElement: ''})}
						/>
						{ this.props.state.verifyEmail !== '' &&
							<div className="empty_input verifyInput" onClick={()=>this.props.updateStateFromChild({verifyEmail: ""})}></div>
						}
					</div>
					<div className="getStartedBtn" onClick={(e)=>this.props.resendCode(true)}>Get Started</div>
				</div> 
			</div>
			}
			{/*
			<div className="exit_content extra_padding_bottom">
				<div>
					<div className="inline_event" onClick={(e)=>this.goBack(1)}>
						<div className="arrow_exit"></div>
						<div className="exit_screen">BACK</div>
					</div>
				</div>
				<div className="sign_title">Verify Your Account</div>
				<div className="sign_title reg_message">We've sent a code to {this.props.state.registeredEmail} to verify your Virdio account.</div>
			</div>
			<div className="info_row_holder">
				<div className="one_form_container verify_aligns">
					<div className="form_title">Code</div>
					<div className="relative_holder">
						<input type="text" tabIndex="1" className={classnames({'gray_inputs': true})} 
						id="verifyCode"
						value={this.props.state.verifyCode} 
						onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value})} 
						onFocus={() => this.props.updateStateFromChild({focusedElement: 'verifyCode'})} 
						onBlur={() => this.props.updateStateFromChild({focusedElement: ''})}
						/>
						{ this.props.state.verifyCode !== '' &&
							<div className="empty_input" onClick={()=>this.props.updateStateFromChild({verifyCode: ""})}></div>
						}
					</div>	
				</div>
				<div className="resend_left_holder">
					<div className="resend_dispaly" onClick={(e)=>this.props.resendCode()} >Resend code</div>
				</div>
			</div>		
			<div className="bottom_arrow">
				<div className={classnames({'inline_event': true, 'verify_continue_button': true, 'opacity_event': this.props.state.verifyCode == ''  })} onClick={(e)=>this.props.verifyAccount()}>
					<div className="bottom_title_small">CONTINUE</div>
					<div className="arrow_next"></div>
				</div>
			</div>
			*/}
		</div>
    );
  }
}
export default MemberVerify;
