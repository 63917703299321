import React, { Component } from "react";
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import MarketplaceHeader from '../home/MarketplaceHeader';
import MemberSubscribe from '../home/MemberSubscribe';
import MemberWelcome from '../home/MemberWelcome';
import MemberAbout from '../home/MemberAbout';
import MemberWorkoutPreferences from '../home/MemberWorkoutPreferences';
import MemberVerify from '../home/MemberVerify';
import MemberCardInfo from '../home/MemberCardInfo';
import MemberPlans from '../home/MemberPlans';
import RegisterSelect from '../home/RegisterSelect';
import moment1 from 'moment-timezone';
import $ from 'jquery';
import axios from "axios";
import { setRegisterCookie } from '../../util/cookie';
import { getRegisterCookie } from '../../util/cookie';
import { browserHistory } from 'react-router';
import { deleteRegisterCookie } from '../../util/cookie';


class MemberRegister extends Component {
constructor(props)
{
	super(props);
	
	this.state = {
		registerStep: 1,
		firstStepRegister: 0,
		showRegisterStep: 0,
		firstName: '',
		lastName: '',
		emailInput: '',
		passwordInput: '',
		code: '',
		showPassword: false,
		errorFirstName: false,
		errorLastName: false,
		errorEmail: false,
		errorPassword: false,
		focusedElement: '',
		memberGender: 'Prefer not to disclose',
		showHelpPopup: false,
		userRegisterId: 0,
		verifyCode: '',
		registeredEmail: '',
		cardNumber: '',
		errorCard: false,
		cardMonth: '',
		errorCardMM: false,
		cardYear: '',
		errorCardYear: false,
		cardCVV: '',
		cardZip: '',
		errorCardCVV: false,
		errorCardZip: false,
		cardHolderName: '',
		errorHolderName:false,
		userToken: '',
		dateFromDefSettings: '',
		showCCscreen: true,
		planSelected: 0,
		planSelectedBefore: false,
		trialEndDate: 0,
		trialEndDateText: '',
		singlePlanObj: {},
		familyPlanObj: {},
		couponId: 0,
		promoCode: '', 
		promoText: '', 
		promoDiscount: '',
		codeApplied: false,
		subPercent: 0,
		hideBack: false,
		familyPlanType: 2,
		singlePlanType: 2,
		utm_medium_value: '',
		utm_campaign_value: '',
		utm_id_value: '',
		utm_source_value: '',
		subPercentMonth: '',					
		subPercentYear: '',					
		subPercentFamilyMonth: '',					
		subPercentFamilyYear: '',
		promoDiscountMonth: '',
		promoDiscountYear: '',
		promoDiscountFamilyMonth: '',
		promoDiscountFamilyYear: '',
		trialPeriodDays: 14,
		trialPeriodDaysDate: '',
		creditCardAdded: false,
		utm_term_value: '',
		utm_content_value: '',
		calculateFullPrice: false,
		facebookLogin: '',
		memberRandomId: 0,
		cookie_id: 0,
		videoLink: '',
		videoImage: '',
		nameValue: '',
		newHeader: true,
		planTypeText: 'annual',
		codeError: false,
		defSelectedPlan: 1,
		verifyEmail: '',
		showEmailPop: false,
		iFrameLink: false,
		registerEnteryUpdated: false,
		challengeJoin: false,
		isRegister: true,
		couponApplyStatus: 0,
	}
	
	this.setRegisterCookie = setRegisterCookie.bind(this);
	this.getRegisterCookie = getRegisterCookie.bind(this);
	this.deleteRegisterCookie = deleteRegisterCookie.bind(this);

}

componentDidMount()
{
	let randomNum = Math.floor(Math.random() * 1000);
	var expireDate = new Date();
	expireDate.setDate(expireDate.getDate() + 30);
	var tokenExpireTime = expireDate.getTime();
	var randomId = Math.floor(Math.random() * 100000000);
	
	var userIdCookie = this.getRegisterCookie('register_cookie');
	if ( !userIdCookie )
		{
			this.setRegisterCookie('register_cookie', randomId, expireDate);
			userIdCookie = this.getRegisterCookie('register_cookie');
			
			this.setState({
				memberRandomId: randomId,
				cookie_id: userIdCookie,
			}, ()=>{
				axios.post(process.env.REACT_APP_NAME+"/api/v1/user/insertMemberVisit", {cookie_id: this.state.cookie_id}).then(res => {
					this.updateVisitValues('register');
				}).catch(err =>{
					console.log('-error', err);
				});
			});
		}
	
	this.setState({
		memberRandomId: randomId,
		cookie_id: userIdCookie,
	});
	
	this.checkForTrialPeriod();
	
	var link = window.location;
		
	var queryString = link.search;
	var getAllValues = queryString.split('?')
	if ( getAllValues[1] )
		{
			var allValuesInLink = getAllValues[1];
			var valuesArray = allValuesInLink.split('&');	
			for ( var i = 0; i < valuesArray.length; i++ )
				{
					let finalValue = valuesArray[i].split('=');
					
					if ( finalValue[0] == 'plan' )
						{
							this.setState({
								planSelected: finalValue[1],
								defSelectedPlan: finalValue[1],
								planSelectedBefore: true,
							})
						}
					
					if ( finalValue[0] == 'email' )
						{
							this.setState({
								emailInput: finalValue[1],
							});
						}
					
					if ( finalValue[0] == 'planPay' )
						{
							if ( finalValue[1] == 2 )
								{
									this.setState({
										familyPlanType: 1,
										singlePlanType: 1,
										planTypeText: 'monthly',
									})	
								}
							else
								{
									this.setState({
										familyPlanType: 2,
										singlePlanType: 2,
										planTypeText: 'annual',
									})
								}								
							
						}	
						
					if ( finalValue[0] == 'coupon' )
						{
							this.setState({
								code:finalValue[1],
							}, ()=>{ this.checkCoupon() });
						}						
						
					if ( finalValue[0] == 'utm_medium' )
						{
							localStorage.setItem('utm_medium', finalValue[1]);
						}
						
					if ( finalValue[0] == 'utm_campaign' )
						{
							localStorage.setItem('utm_campaign', finalValue[1]);
						}	
					
					if ( finalValue[0] == 'utm_id' )
						{
							localStorage.setItem('utm_id', finalValue[1]);
						}

					if ( finalValue[0] == 'utm_source' )
						{
							localStorage.setItem('utm_source', finalValue[1]);						
						}
					
					if ( finalValue[0] == 'utm_term' )
						{
							localStorage.setItem('utm_term', finalValue[1]);
						}
					
					if ( finalValue[0] == 'utm_content' )
						{
							localStorage.setItem('utm_content', finalValue[1]);
						}
					
					if ( finalValue[0] == 'iFrameOpen' )
						{
							this.setState({iFrameLink: true});
						}
					
					if ( finalValue[0] == 'joinChallenge' )
						{
							this.setState({challengeJoin: true});
						}
						
					if ( finalValue[0] == 'state' )
						{
							let nextStep = parseInt(finalValue[1]);
							if ( nextStep > 1 )
								{
									this.setState({iFrameLink: false});
								}
							this.goToNextStep(nextStep, true);
						}	
				}		
		}
		
	var utm_mediumValue = '';
	if ( localStorage.getItem('utm_medium') )
		{
			utm_mediumValue = localStorage.getItem('utm_medium');
		}
		
	var utm_campaignValue = '';
	if ( localStorage.getItem('utm_campaign') )
		{
			utm_campaignValue = localStorage.getItem('utm_campaign');
		}

	var utm_idValue = '';
	if ( localStorage.getItem('utm_id') )
		{
			utm_idValue = localStorage.getItem('utm_id');
		}

	var utm_sourceValue = '';
	if ( localStorage.getItem('utm_source') )
		{
			utm_sourceValue = localStorage.getItem('utm_source');
		}		
	
	var utm_termValue = '';
	if ( localStorage.getItem('utm_term') )
		{
			utm_termValue = localStorage.getItem('utm_term');
		}
	
	var utm_contentValue = '';
	if ( localStorage.getItem('utm_content') )
		{
			utm_contentValue = localStorage.getItem('utm_content');
		}
	
	this.setState({
		utm_medium_value: utm_mediumValue,
		utm_campaign_value: utm_campaignValue,
		utm_id_value: utm_idValue,
		utm_source_value: utm_sourceValue,
		utm_term_value: utm_termValue,
		utm_content_value: utm_contentValue,
	});
}

updateVisitValues=(mod, userType)=>{
	
	var userIdValue = false;
	if ( userType )
		{
			userIdValue = userType;
		}
	
	var visitData = {cookie_id: this.state.cookie_id, field: mod, userId: userIdValue};
	
	if ( mod === 'email_entry' )
		{
			var emailValue = userType;
			visitData = {cookie_id: this.state.cookie_id, field: mod, emailEntry: emailValue};
		}
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updateMemberVisitValues", visitData).then(res => {
		
	}).catch(err =>{
			console.log('-error', err);
		});
}

updateStateFromChild = (newState, mod) => {
	
	if (newState['passwordInput'])
		{
			let checking = this.checkPassword(newState['passwordInput']);
		}
	else 
		{
			if ( this.state.passwordInput )
				{
					let checking = this.checkPassword(this.state.passwordInput);
				}
			
		}	
		
	if ( ( newState['firstName'] || newState['lastName'] || newState['emailInput'] || newState['passwordInput'] ) && !this.state.registerEnteryUpdated )
		{
			this.setState({registerEnteryUpdated: true}, ()=>{
				this.updateVisitValues('register_entry');
			})
			
		}
	if ( newState['emailInput'] )
		{
			let confirmIfEmailValid = this.emailValidation('entry', newState['emailInput']);
			
			if ( confirmIfEmailValid )
				{
					this.updateVisitValues('email_entry', newState['emailInput']);
				}
		}
		
	this.setState(newState);
}

checkForDate=()=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getRegisterDate").then(res => {
		
		var newDateDB = res.data.responseData.date;
		var newFullPriceDate = res.data.responseData.fullPricesDate;
		if ( newDateDB )
			{
				newDateDB = new Date(newDateDB);
			}

		let currentDate = new Date();
		let showCreditCardScreen = true;
		let currentDateNew = new Date();
		let currentDateInSec = currentDateNew.getTime();


		let currentTime = moment1(new Date()).hours();

		let days = this.state.trialPeriodDays;
		
		let trialDayPassed = false;
		if ( parseInt(currentTime) < 14 )
			{
				days = days - 1;
			}
		
		
		let trialDaysSec = days * 86400;
		trialDaysSec = trialDaysSec * 1000;	
		
		let varSec = parseInt(currentDateInSec) + parseInt(trialDaysSec);
		
		let trialEndDate = moment1(new Date(varSec)).format('MM/DD/YYYY');
		let dateFormated = moment1(new Date(varSec)).format("MMMM Do YYYY");
		dateFormated = moment1(varSec).format("MMMM Do YYYY");
		

		if ( this.state.trialPeriodDaysDate )
			{
				dateFormated = moment1(this.state.trialPeriodDaysDate).format("MMMM Do YYYY");
			}
			
		if ( currentDate < newDateDB || !newDateDB )
			{
				//varSec = 1650751200 * 1000;
			
				showCreditCardScreen = false;
			}
				
		if ( newFullPriceDate )
			{
				newFullPriceDate = new Date(newFullPriceDate);
			}
		
		let fullPriceFormated = moment1(new Date(newFullPriceDate)).format("MM/DD/YYYY 00:00:00");
		let currentDateFormated = moment1(new Date(currentDateInSec)).format("MM/DD/YYYY HH:mm:ss"); 
		let calculateFullPriceStatus = false;
		
		if ( currentDateFormated > fullPriceFormated )
			{
				calculateFullPriceStatus = true;
			}
		
		
		this.setState({
			showCCscreen: showCreditCardScreen,
			trialEndDate: varSec,
			trialEndDateText: dateFormated,
			calculateFullPrice: calculateFullPriceStatus,
		}, ()=>{
			this.checkForPlansData();
		});	
		
		
		
	}).catch(err =>{
		console.log('-error', err);
	});
}

checkForTrialPeriod=()=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/memberTrialPeriod").then(res => {
		this.setState({
			trialPeriodDays: res.data.responseData.days,
			videoLink: res.data.responseData.videoLink,
			videoImage: res.data.responseData.videoImage,
		}, ()=>{ this.checkForDate() });
	}).catch(err =>{
		console.log('-error', err);
	});
}

checkForPlansData=()=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getMemberPlanDetails").then(res => {
		if ( res.data.responseData )
			{
				let plansData = res.data.responseData;
				for ( var i = 0; i < plansData.length; i++ )
					{
						if ( plansData[i]['planName'] == 'Single user' )
							{

								if ( this.state.calculateFullPrice )
									{
										plansData[i]['percentageMonth'] = 0;
										plansData[i]['percentageAnnual'] = 0;
									}

								this.setState({
									singlePlanObj: plansData[i],
								});
							}
						else if ( plansData[i]['planName'] == 'Family' )
							{
								if ( this.state.calculateFullPrice )
									{
										plansData[i]['percentageMonth'] = 0;
										plansData[i]['percentageAnnual'] = 0;
									}							
								this.setState({
									familyPlanObj: plansData[i],
								});
							}							
					}
			}
	}).catch(err =>{
		console.log('-error', err);
	});
}

goToNextStep=(mod, fromLink)=>{
	
	if ( mod == 2 )
		{
			this.deleteRegisterCookie('register_cookie');
		}
	
	if ( fromLink )
		{
			let savedEmail = localStorage.getItem('registeredEmail');
			let savedPassword = localStorage.getItem('registeredPassword');
			let savedId = localStorage.getItem('registeredUserId');
					
			this.setState({
				emailInput: savedEmail,
				registeredEmail: savedEmail,
				passwordInput: savedPassword,
				userRegisterId: parseInt(savedId),
			}, ()=>{
				
				if ( parseInt(mod) > 3 )
					{
						this.goToLogin();
					}
				else 
					{
						this.setState({
							registerStep: mod
						});
					}
					
			});
		}
	else 
		{
			if ( parseInt(mod) > 3 )
				{
					this.goToLogin();
				}
			else 
				{
					this.setState({
						registerStep: mod
					});
				}
		}
			
	
}

registerNewMember=(facebookStatus)=>{
	
	let currentTime = moment1(new Date()).hours();
	let checking = this.checkPassword(this.state.passwordInput);
	let checkingEmail = this.emailValidation();
	
	if ( !facebookStatus && ( this.state.emailInput == '' || this.state.passwordInput == '' || this.state.nameValue == '' ) )
		{
			if ( this.state.nameValue.length == 0  )
				{
					this.setState({
						nameError: true,
					});
				}
				
			if ( !facebookStatus && checkingEmail == false )
				{
					this.setState({
						errorEmail: true,
					});
				}
				
			if ( !facebookStatus && checking == false )
				{
					this.setState({
						errorPassword: true,
						focusedElement: 'password',
					},()=>{
						this.props.showMainErrorPop("Please check your password as it does not meet the required criteria.");
					});
					
				}	
		
			return false;
		}

	let trialDayPassed = false;
	if ( parseInt(currentTime) > 13 )
		{
			trialDayPassed = true;
		}		
	
	let firstName = '';
	let lastName = '';
	
	let splitFullName = this.state.nameValue.split(' ');
	
	for ( var i = 0; i < splitFullName.length; i++ )
		{
			if ( i == 0 )
				{
					firstName = splitFullName[i];
				}
			else 
				{
					lastName += ' '+splitFullName[i];
				}				
		}
		
	const participentDetail = 
		{
			firstName:firstName.trim(),
			lastName:lastName.trim(),
			email:this.state.emailInput.trim(),
			password:this.state.passwordInput.trim(),
			address1:"sector3",
			address2:"noida",
			city:"noida",
			state:"UP",
			zip:"123456",
			image:"",
			type:2,
			phone:"",
			sessionId:[],
			timezone:moment1().tz(moment1.tz.guess()).format('z'),
			isMarketplaceUser: 1,
			referralCode: this.state.codeApplied ? this.state.code.toLowerCase() : '',
			utm_medium: this.state.utm_medium_value,
			utm_campaign: this.state.utm_campaign_value,
			utm_id: this.state.utm_id_value,
			utm_source: this.state.utm_source_value,
			utm_term: this.state.utm_term_value,
			utm_content: this.state.utm_content_value,
			trialDayPassed: trialDayPassed,
			facebookLogin: facebookStatus ? this.state.facebookLogin : "",
			newMemberRegister: true,
			challengeJoin: this.state.challengeJoin
		}
		
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/register", participentDetail)
	.then(res => {
		$('.main_loader').hide();
		if(res.data.responseMessage == "success")
			{
				this.setState({
					registeredEmail: participentDetail.email,
					userRegisterId: res.data.responseData.userId,
				}, ()=>{
					localStorage.setItem("registeredEmail", this.state.emailInput);
					localStorage.setItem("registeredPassword", this.state.passwordInput);
					localStorage.setItem("registeredUserId", this.state.userRegisterId);
					
					let stateNum = 0;
					if ( res.data.responseData.checkForOtp )
						{
							stateNum = 2;
						}
					else 
						{
							if ( this.state.planSelected == 0 )
								{
									stateNum = 3;
								}
							else 
								{
									this.selectPlan(this.state.planSelected);
									if ( this.state.showCCscreen )
										{
											stateNum = 4;
										}
									else
										{
											stateNum = 5;
										}
								}
						}				
						
					let linkParams = '';
					var link = window.location;
					if ( link.search )
						{
							linkParams = link.search + '&state='+stateNum;
							
						}
					else 
						{
							linkParams = '?state='+stateNum;
						}
						
					window.top.location = '/memberRegister'+linkParams;	
					this.updateVisitValues('userId', this.state.userRegisterId);
				})
			}
		
	}).catch(err =>{
		$('.main_loader').hide();
		if ( err.response.data.errorData  == 'User already exists' )
			{
				this.props.showMainErrorPop("This email is already taken, try login instead.");
			}
		else if ( err.response.data.errorData  == 'User not verified' )
			{
				this.props.showMainErrorPop("This email is already taken, but not verified yet, try log in instead.");
			}
		else 
			{
				this.props.showMainErrorPop('We are getting trouble in reaching to our server/s. Please refresh the page and try again!');
			}			
		console.log('----------there is problem------------',err);
	});
		
}

checkPassword=(pass)=>{
	let checkAll = false;
	var regexcheck = /[!@#$%_+:;'"~`.,?|\^&*(){}[\]<>?/|\-]/;
	var contains_number = /\d+/;
	var Regex = /^[^a-zA-Z]*$/;
	
	this.setState({
		contain8Ch: false,
		specialSymbol: false,
		numberContain: false,
		letterContain: false
	});
	
	if ( !Regex.test(pass) ) 
		{ 
			this.setState({
				letterContain: true
			});
		}
	
	if ( pass.length >= 8 )
		{
			this.setState({
				contain8Ch: true
			});
		}
	
	if ( regexcheck.test(pass) )
		{
			this.setState({
				specialSymbol: true
			});
		}
		
	if ( contains_number.test(pass) )
		{
			this.setState({
				numberContain: true
			});
		}
		
	if ( pass != '' && pass.length >= 8 && regexcheck.test(pass) && contains_number.test(pass) && !Regex.test(pass) )
		{
			checkAll = true;
		}
		
	return checkAll;
}


emailValidation(mod, val){
	const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
	
	let mainValue = this.state.emailInput;
	
	if ( mod && mod !== 'entry' )
		{
			mainValue = this.state.verifyEmail;
		}
	if ( mod === 'entry' )
		{
			mainValue = val;
		}
	
	if(!mainValue || regex.test(mainValue) === false)
		{
            return false;
        }
        return true;
    }
	
verifyAccount=()=>{
	let otpDetail={ 
			email : this.state.registeredEmail,
			code:this.state.verifyCode
		}
		
	if ( this.state.verifyCode.length == 0 )
		{
			this.props.showMainErrorPop('Code can not be empty!');
			return false;
		}	
			
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/verify-otp", otpDetail) .then(res => {
		if( res.data.responseMessage === "success" ) 
			{
				this.setState({
					userToken:res.data.responseData.token,
				});
				this.checkForFamilyMembership();			
			}
	 }).catch(err =>{
		  if( err.response.data.responseCode===417 )
			{
				$('.main_loader').hide();
				if( !err.response.data.errorData )
					{
						
					}
				else
					{
						this.props.showMainErrorPop(err.response.data.errorData);
					}
			}
		else
			{
				$('.main_loader').hide();
				this.props.showMainErrorPop('We are getting trouble in reaching to our server/s. Please refresh the page and try again!');	
			}				
	 });		
	
}

resendCode=(mod)=>{
	$('.main_loader').hide();
	
	let resendOtpEmail = this.state.registeredEmail;
	let isChangeEmail = false;
	if ( mod )
		{
			isChangeEmail = true;
			if ( this.state.verifyEmail.length == 0 )
				{
					this.props.showMainErrorPop("Email address can't be empty. ");
					return false;
				}
			else 
				{
					let checkingEmail = this.emailValidation(true);
					if ( checkingEmail == false )
						{
							this.props.showMainErrorPop("Please enter valid email address.");
							return false
						}
				}
		}
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/resendOtp", {"email" : resendOtpEmail, "newEmailStatus": isChangeEmail, "newEmailValue": this.state.verifyEmail}).then(res => {
		if( res.data.responseMessage === "success" )
			{
				if ( isChangeEmail )
					{
						let newEmail = this.state.verifyEmail;
						this.setState({
							registeredEmail: newEmail,
							emailInput: newEmail,
						}, ()=>{
							localStorage.setItem("registeredEmail", this.state.emailInput);
						})
					}
				this.setState({
					showEmailPop: false,
				});
				$('.main_loader').hide();
				$("#resend_verification").attr({'style':'display:block'});
			}
	}).catch(err =>{
		$('.main_loader').hide();
		console.log('-err---', err);
		if ( err.response.data.errorData  == 'This email is already taken, please set another email address.' )
			{
				this.props.showMainErrorPop(err.response.data.errorData);
			}
		else 
			{
				this.props.showMainErrorPop("We are getting trouble in reaching to our server/s. Please refresh the page and try again!");
			}			
		
  });
  
}	

addCreditCard=()=>{
	
	if ( this.state.creditCardAdded )
		{
			this.setState({
				registerStep: 5,
			});
			return false;
		}
	
	
	let expDate = this.state.cardYear + '-' + this.state.cardMonth +'-'+'30';
	let currentDate = moment1(new Date()).format('YYYY-MM-DD');
	
	if ( this.state.cardHolderName == '' || this.state.cardZip == '' || this.state.cardCVV == '' || this.state.cardYear == ''  || this.state.cardMonth == '' )
		{
			return false;
		}
		
	if ( this.state.cardNumber.length > 17 )
		{
			this.setState({
				errorCardNum: true,
			})
			return false;
		}	
		
	if ( parseInt(this.state.cardMonth) > 12 )
		{
			this.setState({
				errorCardMM: true,
			})
			return false;
		}		
	
	if ( this.state.cardYear.length != 4 )
		{
			this.setState({
				errorCardYear: true,
			})
			return false;
		}
		
	if ( currentDate > expDate )
		{
			this.setState({
				errorCardYear: true,
				errorCardMM: true,
			}, ()=>{
				this.props.showMainErrorPop("Expiry Date must be in future.");
			});
			return false;
		}	
		
	if ( this.state.cardZip.length != 5 )
		{
			this.setState({
				errorCardZip: true,
			})
			return false;
		}
	
	if ( this.state.cardHolderName.length == 0 )
		{
			this.setState({
				errorHolderName: true,
			})
			return false;
		}		
		
	const cr_card = {
						'userId': this.state.userRegisterId,
						'type': this.getCardType(this.state.cardNumber),
						'nameOnCredicCard': this.state.cardHolderName,
						'expires': this.state.cardMonth+'/'+this.state.cardYear,
						'securityCode': this.state.cardCVV,
						'zipCode': this.state.cardZip,
						'saveToFile': true,
						card_number: this.state.cardNumber,
						expire_month: this.state.cardMonth,
						expire_year: this.state.cardYear.slice(-2),
						registerAsDef: true,
					};				
	$('.main_loader').attr({'style':'display:flex'});
	  axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/addcard", cr_card, {headers: {'Authorization': this.state.userToken}}).then(res => {
		if(res.data.responseMessage === "success")
			{	
				this.setState({
					registerStep: 5,
					creditCardAdded: true,
				});
			}
		$('.main_loader').hide();	
	}).catch(err =>{
		$('.main_loader').hide();
		this.props.showMainErrorPop('Card was not accepted.');
	});				
}


getCardType=(number)=>{
	
	// visa
	var re = new RegExp("^4");
	if (number.match(re) != null)
		return "Visa";

	// Mastercard
	re = new RegExp("^5[1-5]");
	if (number.match(re) != null)
		return "Mastercard";

	// AMEX
	re = new RegExp("^3[47]");
	if (number.match(re) != null)
		return "AMEX";

	// Discover
	re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
	if (number.match(re) != null)
		return "Discover";

	// Diners
	re = new RegExp("^36");
	if (number.match(re) != null)
		return "Diners";

	// Diners - Carte Blanche
	re = new RegExp("^30[0-5]");
	if (number.match(re) != null)
		return "Diners - Carte Blanche";

	// JCB
	re = new RegExp("^35(2[89]|[3-8][0-9])");
	if (number.match(re) != null)
		return "JCB";

	// Visa Electron
	re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
	if (number.match(re) != null)
		return "Visa Electron";

	return "";
}

selectPlan=(mod)=>{
	
	let selectedPlan = mod;
	let nextStepNum = 0;
	
	if ( this.state.showCCscreen )
		{
			nextStepNum = 4;
		}
	else 
		{
			nextStepNum = 5;
		}	
	
	
	
	this.setState({
		planSelected: mod,
	}, ()=>{
		
		
		let plansData = {
		'memberId' : this.state.userRegisterId,
		'selectedPlan' : this.state.planSelected,
		'trialEndDate' : this.state.trialEndDate,
		'planDetails' : this.state.planSelected == 1 ? this.state.singlePlanObj : this.state.familyPlanObj,
		'couponId' : this.state.couponId,
		'planTypeSub' : 0,
		};
		
		if ( this.state.planSelected == 1 )
			{
				plansData.planTypeSub = this.state.singlePlanType;
			}
		else 
			{
				plansData.planTypeSub = this.state.familyPlanType;
			}			
			
		axios.post(process.env.REACT_APP_NAME+"/api/v1/user/addMemberPlan", plansData, {headers: {'Authorization': this.state.userToken}}).then(res => {
		}).catch(err =>{
			console.log('-------err', err);
		});
		
		this.goToLogin();
		/*		
		this.setState({
			registerStep: nextStepNum,
		})
		*/	
	});		
}

goToLogin=()=>{
	$('.main_loader').attr({'style':'display:flex'});
	localStorage.setItem("registeredEmail", this.state.emailInput);
	localStorage.setItem("registeredPassword", this.state.passwordInput);
	browserHistory.push('/login#MemberSignUp');
}

checkCoupon=()=>{
	if ( this.state.code )
		{
			$('.main_loader').attr({'style':'display:flex'});
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getMemberCoupon", {codep: this.state.code.toLowerCase()}).then(res => {
				 
				var code = res.data.responseData;
				if ( !code )
					{
						this.setState({
							promoText: 'Code Not found.',
							codeApplied: false,
							codeError: true,
							couponId: 0,
							}, ()=>{ this.props.showMainErrorPop("Oops, this is not a valid promotional/referral code. \nPlease check your code and try again.") });
					}
				else
					{
						
						let coupon_apply_status = code.coupon_apply_status;
						
						let planType = 1;
	
						if ( this.state.planTypeText == 'annual' )
							{
								planType = 2;
							}
							
						if ( coupon_apply_status && coupon_apply_status != planType)
							{
								this.props.showMainErrorPop("Oops, this is not a valid promotional/referral code for "+this.state.planTypeText+" plan type. Please use different code.");
								this.setState({
									promoText: 'Code Not found.',
									codeApplied: false,
									couponId: 0,
									code: '',
									});
								$('.main_loader').hide();
								return false;
							}
						
						
						
						let subPerNumMonth = code.subPercent;
						let subPerNumYear = code.subPercent;
						let subPerNumFamilyMonth = code.subPercent;
						let subPerNumFamilyYear = code.subPercent;
						
						let SinglePercentageMonthValue = 0;
						let FamilyPercentageMonthValue = 0;
						
						let	SinglePercentageAnnual = 0;
						let	FamilyPercentageAnnual = 0;
						
						if ( !this.state.calculateFullPrice )
							{
								SinglePercentageMonthValue = 20;
								FamilyPercentageMonthValue = 20;
						
								SinglePercentageAnnual = 30;
								FamilyPercentageAnnual = 30;
						
								if ( this.state.singlePlanObj['percentageMonth'] )
									{
										SinglePercentageMonthValue = this.state.singlePlanObj['percentageMonth'];
									}
								
								if ( this.state.singlePlanObj['percentageAnnual'] )
									{
										SinglePercentageAnnual = this.state.singlePlanObj['percentageAnnual'];
									}
								
								if ( this.state.familyPlanObj['percentageMonth'] )
									{
										FamilyPercentageMonthValue = this.state.familyPlanObj['percentageMonth'];
									}
								
								if ( this.state.familyPlanObj['percentageAnnual'] )
									{
										FamilyPercentageAnnual = this.state.familyPlanObj['percentageAnnual'];
									}
							}
						
						console.log('single', SinglePercentageMonthValue);
						console.log('single2', SinglePercentageAnnual, this.state.calculateFullPrice);
							
						
						subPerNumMonth = subPerNumMonth + SinglePercentageMonthValue;
						subPerNumYear = subPerNumYear + SinglePercentageAnnual;
						
						
						subPerNumFamilyMonth = subPerNumFamilyMonth + FamilyPercentageMonthValue;
						subPerNumFamilyYear = subPerNumFamilyYear + FamilyPercentageAnnual;
							
						if ( subPerNumMonth > 100 )
							{
								subPerNumMonth = 100;
							}
						
						if ( subPerNumYear > 100 )
							{
								subPerNumYear = 100;
							}	
						
						if ( subPerNumFamilyMonth > 100 )
							{
								subPerNumFamilyMonth = 100;
							}
						
						if ( subPerNumFamilyYear > 100 )
							{
								subPerNumFamilyYear = 100;
							}
						
						
						var endDateCoup = '';
						var newDays = this.state.trialPeriodDays ? this.state.trialPeriodDays : 14;
						if ( code.trialType == 1 ) { newDays = code.tiralSub; }
						if ( code.trialType == 2 )
							{
								var date1 = moment1(new Date(code.tiralEndDate).getTime());
								var date2 = moment1(new Date().getTime());
								var secDiff = date1 - date2;
								secDiff = secDiff / 1000;
								
								var daysDiff = Math.ceil(secDiff / 60 / 60 / 24);
								newDays = daysDiff;
								
								endDateCoup = code.tiralEndDate;
							}
						
							
						this.setState({
							couponId: code.id,
							promoCode: code, 
							promoText: code.subText,
							subPercent: code.subPercent,					
							subPercentMonth: subPerNumMonth,					
							subPercentYear: subPerNumYear,					
							subPercentFamilyMonth: subPerNumFamilyMonth,					
							subPercentFamilyYear: subPerNumFamilyYear,					
							promoDiscount: ((100-code.subPercent)/100),
							promoDiscountMonth: ((100-subPerNumMonth)/100),
							promoDiscountYear: ((100-subPerNumYear)/100),
							promoDiscountFamilyMonth: ((100-subPerNumFamilyMonth)/100),
							promoDiscountFamilyYear: ((100-subPerNumFamilyYear)/100),
							codeApplied: true,
							trialPeriodDays: newDays,
							trialPeriodDaysDate: endDateCoup,
							couponApplyStatus: coupon_apply_status,
							}, ()=>{ this.checkForDate() });
					}
				$('.main_loader').hide();		
			}).catch(err =>{ $('.main_loader').hide(); });
		}
		
}

checkForFamilyMembership=()=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkForFamilyMembership", {email: this.state.emailInput, userId: this.state.userRegisterId}).then(res => {
		if ( res.data.responseData )
			{
				this.setState({
					planSelected: 2,
					hideBack: true,
					planSelectedBefore: true,
				}, ()=>{
					this.goToLogin();	
					//this.goToNextStep(5);					
				});
			}
		else 
			{
				if ( this.state.planSelected == 0 )
					{
						this.goToNextStep(3);
					}
				else 
					{
						this.selectPlan(this.state.planSelected);
						if ( this.state.showCCscreen )
							{
								this.goToLogin();
								//this.goToNextStep(4);
							}
						else
							{
								this.goToLogin();
								//this.goToNextStep(5);
							}
					}	
			}
	$('.main_loader').hide(); 			
	}).catch(err =>{		
			if ( this.state.planSelected == 0 )
				{
					this.goToNextStep(3);
				}
			else 
				{
					this.selectPlan(this.state.planSelected);
					if ( this.state.showCCscreen )
						{
							
							this.goToLogin();
							//this.goToNextStep(4);
						}
					else
						{
							this.goToLogin();
							//this.goToNextStep(5);
						}
				}	
		$('.main_loader').hide(); 
	});
}

loginFacebook=()=>{
	
	/* if ( this.state.showRegisterStep )
		{
			return false;
		} */
		
	if ( window.FB )
		{
			var mainThis = this;
			window.FB.login(function(response)
				{
					if ( response && response.authResponse && response.authResponse.accessToken )
						{
							mainThis.setState({facebookLogin: response.authResponse.accessToken}, ()=>{
								mainThis.registerNewMember(true);
							});	
						}
					
				}, {scope: 'public_profile,email'});
			
		}
}

setPlanType=(mod)=>{
	let planType = 1;
	
	if ( mod == 'annual' )
		{
			planType = 2;
		}
		
	if ( this.state.couponApplyStatus && this.state.couponApplyStatus != planType)
		{
			this.removeCoupon();
		}	
		
	this.setState({
		planTypeText: mod,
		familyPlanType: planType,
		singlePlanType: planType,							
	})

}

removeCoupon=()=>{
	
	this.setState({
		couponId: 0,
		promoCode: '', 
		promoText: '',
		subPercent: 0,					
		subPercentMonth: 0,					
		subPercentYear: 0,					
		subPercentFamilyMonth: 0,					
		subPercentFamilyYear: 0,					
		promoDiscount: 0,
		promoDiscountMonth: 0,
		promoDiscountYear: 0,
		promoDiscountFamilyMonth: 0,
		promoDiscountFamilyYear: 0,
		codeApplied: false,
		code: '',
		couponApplyStatus: 0,
		}, ()=>{
			this.checkForPlansData();
		});
}

setPlanSelected=(mod)=>{
	this.setState({
		defSelectedPlan: mod,
	})
}

chooseRegisterStep=()=>{
	
	if ( this.state.showRegisterStep )
		{
			this.setState({
				showRegisterStep: 0,
			})
		}
	else 
		{
			this.setState({
				showRegisterStep: 1,
			})
		}		
	
}

render() {
	
    return (
		<div className={"memberRegisterContainer " + (this.state.iFrameLink ? "noMarginContainer" : "" ) } >
			{!this.state.iFrameLink &&
				<MarketplaceHeader
					centerHeader={this.state.newHeader}
				></MarketplaceHeader>
			}
			<div className="registerMainContainer" >
				{this.state.registerStep == 1 &&
					<RegisterSelect
					state={this.state}
					loginFacebook={this.loginFacebook}
					chooseRegisterStep={this.chooseRegisterStep}
					updateStateFromChild={this.updateStateFromChild}
					goToNextStep={this.goToNextStep}
					registerNewMember={this.registerNewMember}
					></RegisterSelect>
				}
				{/*this.state.registerStep == 1 && this.state.firstStepRegister == 2 &&
					<MemberSubscribe
					state={this.state}
					updateStateFromChild={this.updateStateFromChild}
					goToNextStep={this.goToNextStep}
					registerNewMember={this.registerNewMember}
					checkCoupon={this.checkCoupon}
					loginFacebook={this.loginFacebook}
					></MemberSubscribe>
				*/}
				{this.state.registerStep == 2 &&
					<MemberVerify
					state={this.state}
					updateStateFromChild={this.updateStateFromChild}
					goToNextStep={this.goToNextStep}
					verifyAccount={this.verifyAccount}
					resendCode={this.resendCode}
					>
					</MemberVerify>
				}
				{this.state.registerStep == 3 &&
					<MemberPlans
					state={this.state}
					updateStateFromChild={this.updateStateFromChild}
					goToNextStep={this.goToNextStep}
					selectPlan={this.selectPlan}
					checkCoupon={this.checkCoupon}
					setPlanType={this.setPlanType}
					removeCoupon={this.removeCoupon}
					setPlanSelected={this.setPlanSelected}
					>
					</MemberPlans>
				}
				{this.state.registerStep == 4 &&
					<MemberCardInfo
					state={this.state}
					updateStateFromChild={this.updateStateFromChild}
					goToNextStep={this.goToNextStep}
					addCreditCard={this.addCreditCard}
					>
					</MemberCardInfo>
				}
				{this.state.registerStep == 5 &&
					<MemberWelcome
					state={this.state}
					updateStateFromChild={this.updateStateFromChild}
					goToNextStep={this.goToNextStep}
					>
					</MemberWelcome>
				}
				{this.state.registerStep == 6 &&
					<MemberAbout
					state={this.state}
					updateStateFromChild={this.updateStateFromChild}
					goToNextStep={this.goToNextStep}
					>
					</MemberAbout>
				}
				{this.state.registerStep == 7 &&
					<MemberWorkoutPreferences
					state={this.state}
					updateStateFromChild={this.updateStateFromChild}
					goToNextStep={this.goToNextStep}
					>
					</MemberWorkoutPreferences>
				}
			</div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			<div className="modal pr-0" id="resend_verification" aria-modal="true" >
				<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 pb-4 modl_bg_drk m-2 shadow-none radius-8">
						  <p className="py-4 text-light text-center mb-0 font-20"> A verification code has been resent to your email address. Please enter it once you receive the email.</p>
						</div>
						<div className="text-center">
						  <button type="button" className="custom_btn1 mt-3"  data-dismiss="modal" onClick={e=>{$("#resend_verification").attr({'style':'display:none'});}}>ok</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(MemberRegister);