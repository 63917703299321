import React, { Component } from "react";
import { connect } from "react-redux";
import { showMainErrorPop } from '../../actions/popups';
import moment1 from 'moment-timezone';
import $ from 'jquery';
import {  browserHistory} from 'react-router';
import axios from "axios";
import MarketplaceHeader from '../home/MarketplaceHeader';
import MemberPopups from '../home/MemberPopups';
import MemberCardInfo from '../home/MemberCardInfo';
import FamilyPlanMembers from '../home/FamilyPlanMembers';
import ParticipantSignedUp from '../home/participantSignedUp';
import classnames from "classnames";
import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablet,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";
import { deleteCookie } from '../auth/Logout';
import customProtocolCheck from "custom-protocol-check";
import MemberPaymentHistory from '../home/MemberPaymentHistory';
import { joinSessionValidate } from '../../util/joinSessionValidate';
import MemberPlans from '../home/MemberPlans';

class MemberProfile extends Component {
constructor(props)
{
	super(props);
	
	this.state = {
		currentUserData: JSON.parse(localStorage.getItem('userData'))? JSON.parse(localStorage.getItem('userData')).data.responseData : '',
		isProfile: true,
		addedPayment: false,
		planSelected: 0,
		defSelectedPlan: 0,
		planId: 0,
		planType: 1, // 1 - monthly , 2 - annaul,
		chosenPopup: '',
		showPop: false,
		loseBenefitsDate: '',
		singlePlanObj: {},
		familyPlanObj: {},
		memberCreditCards: [],
		removeCardNum: 0,
		showAddCC: false,
		cardNumber: '',
		errorCard: false,
		cardMonth: '',
		errorCardMM: false,
		cardYear: '',
		errorCardYear: false,
		cardCVV: '',
		cardZip: '',
		errorCardCVV: false,
		errorCardZip: false,
		cardHolderName: '',
		errorHolderName:false,
		familyMembersNum: 1,
		showFamilyMembers: false,
		emailAddress1: JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')).data.responseData.email : '',
		emailAddress2: '',
		emailAddress3: '',
		familyPlanId: 0,
		onlyFamilyPlanMember: true,
		planDataFund: false,
		membersWithPlan: [],
		singlePlanNextMonthPrice: 0,
		familyPlanNextMonthPrice: 0,
		singleAnnualNextMonthPrice: 0,
		familyAnnualNextMonthPrice: 0,
		promoDiscount: '',
		codeApplied: false,
		subPercent: 0,
		code: '',
		couponId: 0,
		subPercentYear: '',					
		subPercentFamilyMonth: '',					
		subPercentFamilyYear: '',
		promoDiscountMonth: '',
		promoDiscountYear: '',
		promoDiscountFamilyMonth: '',
		promoDiscountFamilyYear: '',
		singlePlanType: 2,
		familyPlanType: 2,
		trialPeriod: false,
		trialDaysLeft: 0,
		trialEndDate: '',
		showLaunchPop: false, 
		loggedUserData: JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')).data.responseData : '',
		urlPath: '',
		urlPathMain: '',
		urlElectron: '',
		urlElectronMain: '',
		deepLinkButton: '',
		showPaymentHistory: false,
		failedPaymentStatus: false,
		failedPaymentDetails: [],
		switchPlan: true,
		changeNextMonth: 0,
		changeType: 0,
		planData: [],
		calculateFullPrice: false,
		newMemberHeader: true,
		isHost: false,
		isOnDemandSession: false,
		showDesktopApp: false,
		showMobileApp: false,
		showWatchApp: false,
		sessionName: '',
		isSending: false,
		nextCardChargeDate: '',
		renewDate: '',
		showAddCardForm: false,
		showSelectPlan: false,
		planTypeText: 'annual',
		showWhatIsPop: false,
		memberSelectedPlanType: 0,
		promoCodeDisplayed: 0,
		couponApplyStatus: 0,
		
		planStartEnd: false,
		planBillMonth: '',
		planBillPrice: '',
		
		planPayStatus: 0,
		planPayUpdateDate: ''
	}

	this.deleteCookie = deleteCookie.bind(this);
	this.joinSessionValidate = joinSessionValidate.bind(this);
}

componentDidMount(){
	
	if ( this.state.currentUserData )
		{
			this.getAppChecks();
			
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getRegisterDate").then(res => {
				let currentDateNew = new Date(this.state.currentUserData.createAt);
				let currentDateInSec = currentDateNew.getTime();
				var newFullPriceDate = res.data.responseData.fullPricesDate;
		
				if ( newFullPriceDate )
					{
						newFullPriceDate = new Date(newFullPriceDate);
					}
			
				let fullPriceFormated = moment1(new Date(newFullPriceDate)).format("MM/DD/YYYY 00:00:00");
				let currentDateFormated = moment1(new Date(currentDateInSec)).format("MM/DD/YYYY HH:mm:ss"); 
			
				let currentDateInSecNew = new Date(currentDateInSec).getTime()
				let fullPriceDateInSec = new Date(newFullPriceDate).getTime();
				
				let calculateFullPriceStatus = false;
				
				if ( currentDateInSecNew > fullPriceDateInSec )
					{
						calculateFullPriceStatus = true;
					}	
		
				this.setState({
					calculateFullPrice: calculateFullPriceStatus,
				}, ()=>{			
					this.checkForPlansData();
					this.checkForFailedPayments();
					this.checkMemberPlan();
					this.checkMemberCard();
				});
		
		}).catch(err =>{
			this.checkForPlansData();
			this.checkForFailedPayments();
			this.checkMemberPlan();
			this.checkMemberCard();
			console.log('-error', err);
		});
	
			var checkSessionValidate = this.joinSessionValidate(true, this.state.currentUserData.id, this.state.currentUserData.token, null);
			  this.setState({ 
					urlPath: checkSessionValidate.urlPathVar,
					urlPathMain: checkSessionValidate.urlPathVar,
					urlElectron: checkSessionValidate.urlElectronVar,
					urlElectronMain: checkSessionValidate.urlElectronVar,
					deepLinkButton: checkSessionValidate.deepLinkButton
			  });
				
		}
	else 
		{
			browserHistory.push('/login');
		}		
	
}

getAppChecks=()=>{
	
	var url = window.location.href;
	let mainthis = this;
	
	if ( ( osname === "Windows" || osname === "Mac OS" ) && !tablet )
		{
			//show watch app only for participant on desktop
			
			if ( this.state.isHost )
				{
					this.setState({showDesktopApp: true, showMobileApp: true});
				}
				
			if ( !this.state.isHost )
				{
					this.setState({showDesktopApp: true, showMobileApp: true, showWatchApp: true});
				}
				
			if ( osname === "Windows" ) { this.setState({desktopAppName: 'Windows'}); }
			if ( osname === "Mac OS" ) { this.setState({desktopAppName: 'macOS'}); }
		}
		
	if ( ios )
		{
			//same for host and participant ios devices
			
			if ( deviceType === 'mobile' )
				{
					this.setState({showDesktopApp: true, showMobileApp: true});
					
					if ( !this.state.isHost ) { this.setState({showWatchApp: true}); }
				}
			
			if ( deviceType === 'tablet' || tablet )
				{
					this.setState({showDesktopApp: true, showMobileApp: true});
					
					if ( !this.state.isHost ) { this.setState({showWatchApp: true}); }
				}
				
			this.setState({desktopAppName: 'Native', mobileHref: this.state.urlElectron});
		}
		
	if ( android )
		{
			//same for host and participant android devices
			
			if ( deviceType === 'mobile' )
				{
					this.setState({showDesktopApp: true, showMobileApp: true});
					
					if ( !this.state.isHost ) { this.setState({showWatchApp: true}); }
				}
			
			if ( deviceType === 'tablet' || tablet )
				{
					this.setState({showDesktopApp: true, showMobileApp: true});
					
					if ( !this.state.isHost ) { this.setState({showWatchApp: true}); }
				}
				
			this.setState({desktopAppName: 'Native', mobileHref: this.state.urlElectron});
		}
		
	if ( osname !== "Windows" && osname !== "Mac OS" && !ios && !android ) 
		{ 
			this.setState({isOtherDevice: true});

			if ( !this.state.isHost )
				{
					this.setState({showDesktopApp: true, showMobileApp: true, showWatchApp: true});
				}
			else
				{
					this.setState({showDesktopApp: true, showMobileApp: true});
				}
				
			this.setState({desktopAppName: 'Native', otherDeviceURL: mainthis.state.urlPath});
		}
		
	if ( !this.state.isHost )
		{
			if ( !this.state.isOnDemandSession ) 
				{
					var partTextLive = this.state.loggedUserData.firstName + ', you have successfully signed up for the class. Be on top of your game download Virdio before your next class '+this.state.sessionName+' at '+this.getSessionDate(this.state.scheduleDate)+' @ '+this.getSessionTime(this.state.scheduleDate)+' '+moment1().tz(moment1.tz.guess()).format('z')+'.';
					
					if ( url.indexOf('participant-dashboard') > -1 || url.indexOf('login') > -1 )
						{
							partTextLive = this.state.loggedUserData.firstName + ', you are all set! Use Virdio native apps to up your workout!';
						}
						
					if ( ( url.indexOf('session') === -1 || url.indexOf('ondemand') === -1 ) && this.state.logedUserAlreadyParticate  )
						{
							partTextLive = this.state.loggedUserData.firstName + ', you have already signed up for the class. Be on top of your game download Virdio before your next class '+this.state.sessionName+' at '+this.getSessionDate(this.state.scheduleDate)+' @ '+this.getSessionTime(this.state.scheduleDate)+' '+moment1().tz(moment1.tz.guess()).format('z')+'.';
						}
					
					if ( url.indexOf('participant-dashboard') === -1 && url.indexOf('login') === -1 && url.indexOf('session') === -1 && url.indexOf('ondemand') === -1 )
						{
							partTextLive = this.state.loggedUserData.firstName + ', you are all set! Be on top of your game download Virdio before your next class '+this.state.sessionName+' at '+this.getSessionDate(this.state.scheduleDate)+' @ '+this.getSessionTime(this.state.scheduleDate)+' '+moment1().tz(moment1.tz.guess()).format('z')+'.';
						}
					
					this.setState({participantLivePopText: partTextLive});
				}
			else
				{
					var partTextDemand = this.state.loggedUserData.firstName + ', up your workout by using Virdio Apps!';
					
					this.setState({participantDemandPopText: partTextDemand});
				}
		}
	else
		{
			if ( !this.state.isOnDemandSession ) 
				{
					var hostTextLive = this.state.loggedUserData.firstName + ', you are hosting this class. Be on top of your game download Virdio before your next class '+this.state.sessionName+' at '+this.getSessionDate(this.state.scheduleDate)+' @ '+this.getSessionTime(this.state.scheduleDate)+' '+moment1().tz(moment1.tz.guess()).format('z')+'.';
				
					this.setState({hostLivePopText: hostTextLive});
				}
			else
				{
					var partTextHost = this.state.loggedUserData.firstName + ', you are hosting '+this.state.sessionName+' On Demand class.';
					
					this.setState({hostDemandPopText: partTextHost});
				}
		}
		
	//get user loggs from database
	var postData = {userId: this.state.loggedUserData.id};
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getUserLoggs", postData).then(res => {
		this.setState({loggedNative: res.data.responseData.loggedNative, loggedRemote: res.data.responseData.loggedRemote, loggedWatch: res.data.responseData.loggedWatch});
		
		if ( !this.state.isHost )
			{
				if ( res.data.responseData.loggedNative === 1 && res.data.responseData.loggedRemote === 1 && res.data.responseData.loggedWatch === 1 )
					{
						this.setState({hasAllApps: true});
					}
			}
		else
			{
				if ( res.data.responseData.loggedNative === 1 && res.data.responseData.loggedRemote === 1 )
					{
						this.setState({hasAllApps: true});
					}
			}
			
		var userData = JSON.parse(localStorage.getItem('userData'));
		userData.data.responseData.loggedNative = res.data.responseData.loggedNative;
		userData.data.responseData.loggedRemote = res.data.responseData.loggedRemote;
		userData.data.responseData.loggedWatch = res.data.responseData.loggedWatch;
		localStorage.setItem('userData', JSON.stringify(userData));
		
	}).catch(err =>{ });
}

checkForFailedPayments=()=>{
	const participentDetail = {
		'memberId': this.state.currentUserData.id,
	};
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkForMemberFailedPayments", participentDetail).then(res => {
		
		if ( res.data.responseData && res.data.responseData['id'] )
			{
				this.setState({
					failedPaymentStatus: true,
					failedPaymentDetails: res.data.responseData,
				}, ()=>{
						/*
						this.setState({
							showPop: true,
							chosenPopup: 'failedPayment',
						}); */
				});
			}
		else 
			{
				this.setState({
					failedPaymentStatus: false,
					failedPaymentDetails: [],
				})
			}			
	}).catch(err =>{
		console.log('-error', err);
	}); 
}

checkMemberPlan =()=>{
	const participentDetail = {
		'memberId': this.state.currentUserData.id,
	};
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getMemberPlan", participentDetail).then(res => {
		if ( res.data.responseData )
			{
				if ( res.data.responseData.planInfo )
					{
						var resData = res.data.responseData.planInfo;
						this.setState({
										planStartEnd: resData.fullMonthPay ? false : {'startDay': resData.period[0], 'endDay': resData.period[1]},
										planBillMonth: resData.fullMonthName,
										planBillPrice: resData.calcPrice
						});
					}
				
				if ( res.data.responseData.planPayInfo )
					{
						var resData2 = res.data.responseData.planPayInfo;
						this.setState({
										planPayStatus: resData2.status,
										planPayUpdateDate: moment1(new Date(resData2.updatedDate)).format('DD/MM/YYYY')
						});
					}
				
				
				
				var date = new Date();
				var nextMonthDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
				var formatDate = moment1(new Date(nextMonthDate)).format('DD/MM/YYYY');
				let memberPlan = res.data.responseData.planPricing;
				let memberPlanType = res.data.responseData.planType;
				var nextCardChargeDate = moment1(new Date(nextMonthDate)).format('DD/MM/YYYY');
				var renewDate = moment1(new Date(nextMonthDate)).format('MMMM Do YYYY');
				
				var trialEndDate = moment1(new Date(res.data.responseData.trialEndDate)).format('DD/MM/YYYY');
				var todayDate = moment1(new Date()).format('DD/MM/YYYY');
				
				var trialEndDateInSec = new Date(res.data.responseData.trialEndDate).getTime();
				var todayEndDateInSec = new Date().getTime();
				
				if ( memberPlanType == 2 )
					{
						nextCardChargeDate = moment1(new Date(res.data.responseData.nextAnnualPaymentDay)).format('DD/MM/YYYY');
						renewDate = moment1(new Date(res.data.responseData.nextAnnualPaymentDay)).format('MMMM Do YYYY');
					}
				
				if ( trialEndDateInSec > todayEndDateInSec )
					{
						var date1 = moment1(new Date(res.data.responseData.trialEndDate).getTime());
						var trialEndDateFormated = moment1(new Date(res.data.responseData.trialEndDate)).format('MM/DD/YY');
						nextCardChargeDate = moment1(new Date(res.data.responseData.trialEndDate)).format('MM/DD/YY');
						renewDate = moment1(new Date(res.data.responseData.trialEndDate)).format('MMMM Do YYYY');
						var date2 = moment1(new Date().getTime());
						var secDiff = date1 - date2;
						secDiff = secDiff / 1000;
						
						var daysDiff = Math.ceil(secDiff / 60 / 60 / 24);
						
						let memberTrialDuration = parseInt(res.data.responseData.trialDuration);
						
						if ( daysDiff > memberTrialDuration )
							{
								daysDiff = memberTrialDuration;
							} 
						this.setState({
							trialPeriod: true,
							trialDaysLeft: daysDiff,
							trialEndDate: trialEndDateFormated,
						})
					}
			
				this.setState({
					planData: res.data.responseData,
					planId: res.data.responseData.id,
					planType: res.data.responseData.planType,
					memberSelectedPlanType: res.data.responseData.planType,
					changeNextMonth: res.data.responseData.cancAtEndOfTheMonth,
					changeType: res.data.responseData.cancType,
					nextCardChargeDate: nextCardChargeDate,
					renewDate: renewDate,
				}, ()=>{
					this.checkForPlansData();
					if ( memberPlan == 2 )
						{
							this.setState({
								familyPlanType: this.state.planType,
							})
						}
					else 
						{
							this.setState({
								singlePlanType: this.state.planType,
							})
						}	
				});
				
				if ( memberPlan == 2 )
					{
						this.checkFamilyPlanMembers(res.data.responseData.id);
						this.setState({
							familyPlanId: res.data.responseData.id
						});
					}
					
				if ( res.data.responseData.couponId )
					{
						this.checkCouponValues(res.data.responseData.couponId);
					}	
				else 
					{
						this.setState({
							couponId: 0,
							code: '',
							codeApplied: false,
						});
					}					
				this.setState({
					planSelected: memberPlan,
					defSelectedPlan: memberPlan,
					loseBenefitsDate: formatDate,
					onlyFamilyPlanMember: false,
					planDataFund: true,
				});
			}
		else 
			{
				this.setState({
					planSelected: 0,
					defSelectedPlan: 0,
					planId: 0,
					trialPeriod: false,
				}, ()=>{
					this.checkForFamilyMembership();
				});
			}			
	}).catch(err =>{
		console.log('-error', err);
	}); 
}

checkForFamilyMembership=()=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkForFamilyMembership", {email: this.state.currentUserData.email, userId: this.state.currentUserData.id}).then(res => {
		if ( res.data.responseData )
			{
				this.setState({
					onlyFamilyPlanMember: true,
					planDataFund: true,
				}, ()=>{
					this.checkFamilyPlanMembers(res.data.responseData.planId);
				});
			}
		else 
			{
				this.setState({
					onlyFamilyPlanMember: false,
					planDataFund: true,
				});
			}			
	}).catch(err =>{
		console.log('---err-', err);
	});
};

checkForPlansData=()=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getMemberPlanDetails").then(res => {
		if ( res.data.responseData )
			{
				let plansData = res.data.responseData;
				for ( var i = 0; i < plansData.length; i++ )
					{
						
						if ( plansData[i]['planName'] == 'Single user' )
							{

								if ( this.state.singlePlanType == 1)
									{
										
										var planPrice = plansData[i]['monthlyCalcPrice'];
										var annualSingle = plansData[i]['annualCalcPrice'];
										
										if ( this.state.calculateFullPrice )
											{
												planPrice = plansData[i]['monthlyFullPrice'];
												annualSingle = plansData[i]['annualFullPrice'];
												
												plansData[i]['percentageMonth'] = 0;
												plansData[i]['percentageAnnual'] = 0;
											}
										
										if ( this.state.codeApplied )
											{											
												planPrice = plansData[i]['monthlyFullPrice'] * this.state.promoDiscountMonth;
												annualSingle = plansData[i]['annualFullPrice'] * this.state.promoDiscountYear;
											}
										this.setState({
												singlePlanObj: plansData[i],
												singlePlanNextMonthPrice: planPrice.toFixed(2),
												singleAnnualNextMonthPrice: annualSingle.toFixed(2),
											});
									}
								else 
									{
										
										var planPrice = plansData[i]['monthlyCalcPrice'];
										var annualSingle = plansData[i]['annualCalcPrice'];
										
										if ( this.state.calculateFullPrice )
											{
												planPrice = plansData[i]['monthlyFullPrice'];
												annualSingle = plansData[i]['annualFullPrice'];
												
												plansData[i]['percentageMonth'] = 0;
												plansData[i]['percentageAnnual'] = 0;
											}
										
										
										if ( this.state.codeApplied )
											{
												planPrice = plansData[i]['monthlyFullPrice'] * this.state.promoDiscountMonth;
												annualSingle = plansData[i]['annualFullPrice'] * this.state.promoDiscountYear;							
											}

										this.setState({
												singlePlanObj: plansData[i],
												singlePlanNextMonthPrice: planPrice.toFixed(2),
												singleAnnualNextMonthPrice: annualSingle.toFixed(2),
											});
									}									
								
							}
						else if ( plansData[i]['planName'] == 'Family' )
							{
								if ( this.state.familyPlanType == 1)
									{
										var planPrice = plansData[i]['monthlyCalcPrice'];
										var annualFamily = plansData[i]['annualCalcPrice'];
										
										if ( this.state.calculateFullPrice )
											{
												planPrice = plansData[i]['monthlyFullPrice'];
												annualFamily = plansData[i]['annualFullPrice']
												
												plansData[i]['percentageMonth'] = 0;
												plansData[i]['percentageAnnual'] = 0;
											}
										
										if ( this.state.codeApplied )
											{
												planPrice = plansData[i]['monthlyFullPrice'] * this.state.promoDiscountFamilyMonth;
												annualFamily = plansData[i]['annualFullPrice'] * this.state.promoDiscountFamilyYear;
											}
										this.setState({
											familyPlanObj: plansData[i],
											familyPlanNextMonthPrice: planPrice.toFixed(2),
											familyAnnualNextMonthPrice: annualFamily.toFixed(2),
										});
									}
								else 
									{
										var annualFamily = plansData[i]['annualCalcPrice'];
										var planPrice = plansData[i]['monthlyCalcPrice'];
										
										
										if ( this.state.calculateFullPrice )
											{
												planPrice = plansData[i]['monthlyFullPrice'];
												annualFamily = plansData[i]['annualFullPrice'];
												
												plansData[i]['percentageMonth'] = 0;
												plansData[i]['percentageAnnual'] = 0;
											}
										
										if ( this.state.codeApplied )
											{
												planPrice = plansData[i]['monthlyFullPrice'] * this.state.promoDiscountFamilyMonth;
												annualFamily = plansData[i]['annualFullPrice'] * this.state.promoDiscountFamilyYear;
											}

										this.setState({
											familyPlanObj: plansData[i],
											familyPlanNextMonthPrice: planPrice.toFixed(2),
											familyAnnualNextMonthPrice: annualFamily.toFixed(2),
										});
									}
							}							
					}
			}
	}).catch(err =>{
		console.log('-error', err);
	});
}

checkFamilyPlanMembers=(mod)=>{
	const planDetails = {
		'planId': mod,
	};
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getFamilyPlanMembers", planDetails).then(res => {
		if ( res.data.responseData ) 
			{
				let emailValue1 = '';
				let emailValue2 = '';
				let emailValue3 = '';
				
				if ( res.data.responseData[0] )
					{
						
						if ( res.data.responseData[0]['email'] )
							{
								emailValue1 = res.data.responseData[0]['email'];
							}
						else if ( res.data.responseData[0]['memberEmail'] )
							{
								emailValue1 = res.data.responseData[0]['memberEmail'];
							}							
					}
					
				if ( res.data.responseData[1] )
					{
						if ( res.data.responseData[1]['email'] )
							{
								emailValue2 = res.data.responseData[1]['email'];
							}
						else if ( res.data.responseData[1]['memberEmail'] )
							{
								emailValue2 = res.data.responseData[1]['memberEmail'];
							}							
					}
					
				if ( res.data.responseData[2] )
					{
						if ( res.data.responseData[2]['email'] )
							{
								emailValue3 = res.data.responseData[2]['email'];
							}
						else if ( res.data.responseData[2]['memberEmail'] )
							{
								emailValue3 = res.data.responseData[2]['memberEmail'];
							}							
					}
				
				let newNum = res.data.responseData.length;
				if ( !newNum )
					{	
						newNum = 1;
					}
			
				
				this.setState({
					familyMembersNum: newNum,
					allFamilyPlanMembers: res.data.responseData,
					emailAddress2: emailValue2,
					emailAddress3: emailValue3,
				})
			}
		else 
			{
				this.setState({
					familyMembersNum: 1,
					allFamilyPlanMembers: [],
					emailAddress1: JSON.parse(localStorage.getItem('userData')).data.responseData.email,
					emailAddress2: '',
					emailAddress3: ''
				})
			}			
	}).catch(err =>{
		this.setState({
					familyMembersNum: 1,
					allFamilyPlanMembers: [],
					emailAddress1: JSON.parse(localStorage.getItem('userData')).data.responseData.email,
					emailAddress2: '',
					emailAddress3: ''
				})
		console.log('-error');
	}); 
}

checkCouponValues=(mod)=>{
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkMemberCoupon", {'couponId' : mod} ).then(res => {
		if ( res.data.responseData )
			{
				var code = res.data.responseData;
				let promoDiscount = ((100-code.subPercent)/100);
				let subPerNumMonth = code.subPercent;
				let subPerNumYear = code.subPercent;
				let subPerNumFamilyMonth = code.subPercent;
				let subPerNumFamilyYear = code.subPercent;
				
				
				subPerNumMonth = subPerNumMonth + this.state.singlePlanObj['percentageMonth'];
				subPerNumYear = subPerNumYear + this.state.singlePlanObj['percentageAnnual'];
				
				subPerNumFamilyMonth = subPerNumFamilyMonth + this.state.familyPlanObj['percentageMonth'];
				subPerNumFamilyYear = subPerNumFamilyYear + this.state.familyPlanObj['percentageAnnual'];
				
				if ( subPerNumMonth > 100 )
					{
						subPerNumMonth = 100;
					}
				
				if ( subPerNumYear > 100 )
					{
						subPerNumYear = 100;
					}	
				
				if ( subPerNumFamilyMonth > 100 )
					{
						subPerNumFamilyMonth = 100;
					}
				
				if ( subPerNumFamilyYear > 100 )
					{
						subPerNumFamilyYear = 100;
					}	
				
				let displayPromoNum = subPerNumFamilyMonth;
				if ( this.state.planType != 1 )
					{
						displayPromoNum = subPerNumFamilyYear;
					}
					
				this.setState({
						couponId: code.id,
						subPercent: code.subPercent,
						promoDiscount: promoDiscount,
						code: code.subCode,
						codeApplied: true,					
						subPercentMonth: subPerNumMonth,					
						subPercentYear: subPerNumYear,					
						subPercentFamilyMonth: subPerNumFamilyMonth,					
						subPercentFamilyYear: subPerNumFamilyYear,
						promoCodeDisplayed: displayPromoNum,
						promoDiscountMonth: ((100-subPerNumMonth)/100),
						promoDiscountYear: ((100-subPerNumYear)/100),
						promoDiscountFamilyMonth: ((100-subPerNumFamilyMonth)/100),
						promoDiscountFamilyYear: ((100-subPerNumFamilyYear)/100),
					}, ()=>{ 
						this.checkForPlansData();
					});
			}
	}).catch(err =>{
		console.log('-----err', err);
	});
}

changeMemberEmail=(e)=>{
	this.setState({ [e.target.id]: e.target.value });
}

clearEmailInput=(value, opened)=>{
	this.setState({ [value]: '' });
}

checkMemberCard=()=>{
	let token = this.state.currentUserData.token;
	axios.defaults.headers.common['Authorization'] = token;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkUserCard",{"id": JSON.parse(localStorage.getItem('userData')).data.responseData.id}).then(res => {
		if ( res.data.responseData )
			{
				if ( res.data.responseData['all'].length > 0 )
					{
						this.setState({
								memberCreditCards: res.data.responseData['all'],
							}, ()=>{
								if ( this.state.memberCreditCards.length > 0 )
									{
										this.setState({
											addedPayment: true,
										})
									}						
							});
					}
				else 
					{
						this.setState({
								memberCreditCards: [],
								addedPayment: false,
							});
					}
			}
		else
			{
				this.setState({
					memberCreditCards: [],
					addedPayment: false,
				});
			}	
		
	}).catch(err =>{ });
}

updateStateFromChild=(state)=>{
	this.setState(state);
}

selectPlan=(mod)=>{
	if ( this.state.planSelected == mod )
		{
			return false;
		}
		
	if ( !this.state.planSelected )
		{
			let chosenPop = 'selectSinglePlan';
			if ( mod == 2 )
				{
					chosenPop = 'selectFamilyPlan'
				}
			
			this.setState({
				showPop: true,
				chosenPopup: chosenPop,
				planSelected: mod,
				defSelectedPlan: mod,
			});
		}		
	else 
		{
			let chosenPop = 'switchToSinglePlan';
			if ( mod == 2 )
				{
					chosenPop = 'switchToFamilyPlan';
				}
			this.setState({
				showPop: true,
				chosenPopup: chosenPop,
				planSelected: mod,
				defSelectedPlan: mod,
			});
		}		
	
	this.setState({
		showSelectPlan: false,
	})
	
}

openCancelPlan=(ev)=>{
	ev.preventDefault();
	ev.stopPropagation();
	
	let msg = 'cancelMembership';
	
	if ( this.state.trialPeriod )
		{
			msg = 'cancelTrial'
		}
	
	this.setState({
		chosenPopup: msg,
		showPop: true,
	});
}

addMemberPlan=()=>{
	
	let plansData = {
		'memberId' : this.state.currentUserData.id,
		'selectedPlan' : this.state.planSelected,
		'planDetails' : this.state.planSelected == 1 ? this.state.singlePlanObj : this.state.familyPlanObj,
		'couponId' : this.state.couponId,
		'switchPlan' : this.state.switchPlan,
		'isTrial' : this.state.trialPeriod,
		'failedPaymentStatus' : this.state.failedPaymentStatus,
		};
		
		if ( this.state.planSelected == 1 )
			{
				plansData.planTypeSub = this.state.singlePlanType;
			}
		else 
			{
				plansData.planTypeSub = this.state.familyPlanType;
			}
			
	$('.main_loader').attr({'style':'display:flex'});	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/addMemberPlan", plansData, {headers: {'Authorization': this.state.currentUserData.token}}).then(res => {
			this.setState({
				chosenPopup: '',
				showPop: false,
				showSelectPlan: false,
			}, ()=>{ 
				this.checkMemberPlan();
				this.checkForFailedPayments();
				});
			$('.main_loader').hide();	
		}).catch(err =>{
			$('.main_loader').hide();
			this.checkMemberPlan();
			
			if ( err.response.data.errorData == "You can't change annual plan, plan can be switched at next renewal" )
				{
					this.props.showMainErrorPop(err.response.data.errorData);
				}
			else if ( err.response.data.errorData == 'Failed payment' )
				{
					this.props.showMainErrorPop('Your plan changed successfully, unfortunately we could not charge your new plan. Please update your payment methods.');
					this.setState({
							chosenPopup: '',
							showPop: false,
							showSelectPlan: false,
						}, ()=>{ 
							this.checkMemberPlan();
							this.checkForFailedPayments();
							});
				}				
			this.setState({
				showSelectPlan: false,
			});
			console.log('-------err', err);
		});		
}

cancelMembership=()=>
{
	let plansData = {
		'memberId' : this.state.currentUserData.id,
		'isTrial' : this.state.trialPeriod ? true : false,
	}
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/cancelMembership", plansData, {headers: {'Authorization': this.state.currentUserData.token}}).then(res => {
			this.setState({
				chosenPopup: 'membershipCanceled',
				showPop: true,
				planType: 0
			}, ()=>{ this.checkMemberPlan() });
			$('.main_loader').hide();	
		}).catch(err =>{
			$('.main_loader').hide();	
			console.log('-------err', err);
		});
}

openRemoveCard=(mod)=>{
	this.setState({
		removeCardNum: mod.card_number,
		chosenPopup: 'removeCard',
		removeCardId: mod.id,
		showPop: true,
	});
}

makeDefaultCard=(mod)=>{
	let token = this.state.currentUserData.token;
	if ( token ) { axios.defaults.headers.common['Authorization'] = token; }
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/selectDefaultCard",{"id": mod.id})
	.then(res => {
		this.checkMemberCard();
		$('.main_loader').hide();	
	}).catch(err =>{
		$('.main_loader').hide();	
		console.log('-------err', err);
	});
}

deleteCreditCard=()=>{
	let token = this.state.currentUserData.token;
	if ( token ) { axios.defaults.headers.common['Authorization'] = token; }
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/deleteUserCard",{"id": this.state.removeCardId})
	.then(res => {
		this.checkMemberCard();
		this.setState({
			chosenPopup: '',
			removeCardNum: '',
			removeCardId: '',
			showPop: false,
		});
		$('.main_loader').hide();	
	}).catch(err =>{
		$('.main_loader').hide();	
		console.log('-------err', err);
	});
	
}

openAddCard=()=>{
	this.setState({
		showAddCC: true,
	})
}

showAddFirstCardForm=()=>{
	
	if ( this.state.showAddCardForm )
		{
			this.setState({
				showAddCardForm: false,
			});
		}
	else 
		{
			this.setState({
				showAddCardForm: true,
			});
		}
}

closeAddCC=()=>{
	this.setState({
		showAddCC: false,
		cardNumber: '',
		errorCard: false,
		cardMonth: '',
		errorCardMM: false,
		cardYear: '',
		errorCardYear: false,
		cardCVV: '',
		cardZip: '',
		errorCardCVV: false,
		errorCardZip: false,
		cardHolderName: '',
		errorHolderName:false,
		errorCardNum: false,
	})
}

addCreditCard=()=>{
	let expDate = this.state.cardYear + '-' + this.state.cardMonth +'-'+'30';
	let currentDate = moment1(new Date()).format('YYYY-MM-DD');
	
	if ( this.state.cardNumber == '' || this.state.cardHolderName == '' || this.state.cardZip == '' || this.state.cardCVV == '' || this.state.cardYear == ''  || this.state.cardMonth == '' )
		{
			if ( this.state.cardHolderName == '' )
				{
					this.setState({
						errorHolderName: true,
					})
				}
				
			if ( this.state.cardZip == '' )
				{
					this.setState({
						errorCardZip: true,
					})
				}
			
			if ( this.state.cardCVV == '' )
				{
					this.setState({
						errorCardCVV: true,
					})
				}	
				
			if ( this.state.cardYear == '' )
				{
					this.setState({
						errorCardYear: true,
					})
				}	
			
			if ( this.state.cardMonth == '' )
				{
					this.setState({
						errorCardMM: true,
					})
				}
				
			if ( this.state.cardNumber.length == 0 )
				{
					this.setState({
						errorCardNum: true,
					})
				}	
				
			return false;
		}
	
	if ( this.state.cardNumber.length > 17 )
		{
			this.setState({
				errorCardNum: true,
			})
			return false;
		}
	
	if ( parseInt(this.state.cardMonth) > 12 )
		{
			this.setState({
				errorCardMM: true,
			})
			return false;
		}		
	
	if ( this.state.cardYear.length != 4 )
		{
			this.setState({
				errorCardYear: true,
			})
			return false;
		}
		
	if ( currentDate > expDate )
		{
			this.setState({
				errorCardYear: true,
				errorCardMM: true,
			}, ()=>{ 
			
				this.props.showMainErrorPop("Expiry Date must be in future.");
			});
			return false;
		}	
		
	if ( this.state.cardZip.length != 5 )
		{
			this.setState({
				errorCardZip: true,
			})
			return false;
		}
	
	if ( this.state.cardHolderName.length == 0 )
		{
			this.setState({
				errorHolderName: true,
			})
			return false;
		}	
	
	const cr_card = {
						'userId': this.state.currentUserData.id,
						'type': this.getCardType(this.state.cardNumber),
						'nameOnCredicCard': this.state.cardHolderName,
						'expires': this.state.cardMonth+'/'+this.state.cardYear,
						'securityCode': this.state.cardCVV,
						'zipCode': this.state.cardZip,
						'saveToFile': true,
						card_number: this.state.cardNumber,
						expire_month: this.state.cardMonth,
						expire_year: this.state.cardYear.slice(-2)
					};				
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/addcard", cr_card, {headers: {'Authorization': this.state.currentUserData.token}}).then(res => {
		this.checkMemberCard();
		if ( res.data.responseData.creditCardId )
			{
				this.setCardAsDefault(res.data.responseData.creditCardId);
			}
		
		this.setState({
			showAddCC: false,
			cardNumber: '',
			errorCard: false,
			cardMonth: '',
			errorCardMM: false,
			cardYear: '',
			errorCardYear: false,
			cardCVV: '',
			cardZip: '',
			errorCardCVV: false,
			errorCardZip: false,
			cardHolderName: '',
			errorHolderName:false,
		});
		$('.main_loader').hide();	
	}).catch(err =>{
		$('.main_loader').hide();
		this.props.showMainErrorPop('Card was not accepted.');
	});
}

getCardType=(number)=>{
	
	// visa
	var re = new RegExp("^4");
	if (number.match(re) != null)
		return "Visa";

	// Mastercard
	re = new RegExp("^5[1-5]");
	if (number.match(re) != null)
		return "Mastercard";

	// AMEX
	re = new RegExp("^3[47]");
	if (number.match(re) != null)
		return "AMEX";

	// Discover
	re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
	if (number.match(re) != null)
		return "Discover";

	// Diners
	re = new RegExp("^36");
	if (number.match(re) != null)
		return "Diners";

	// Diners - Carte Blanche
	re = new RegExp("^30[0-5]");
	if (number.match(re) != null)
		return "Diners - Carte Blanche";

	// JCB
	re = new RegExp("^35(2[89]|[3-8][0-9])");
	if (number.match(re) != null)
		return "JCB";

	// Visa Electron
	re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
	if (number.match(re) != null)
		return "Visa Electron";

	return "";
}

openEditMembers=()=>{
	if ( this.state.familyPlanId != 0 )
		{
			this.checkFamilyPlanMembers(this.state.familyPlanId)
		}
	this.setState({
		showFamilyMembers: true,
	})
}

setCardAsDefault=(mod)=>{
	//console.log('--here-----mod', mod);
}

closeEditMembers=()=>{
	this.setState({
		showFamilyMembers: false,
	}, () =>{
		if ( this.state.emailAddress1 == '' && this.state.emailAddress2 == '' && this.state.emailAddress3 == '' )
			{
				this.setState({
						chosenPopup: 'closeAddFamilyMembers',
						showPop: true,
					});
			}
	})
}

validateEmail=(email)=>{
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

saveEditMembers=()=>{
	if ( (this.state.emailAddress1 != '' && !this.validateEmail(this.state.emailAddress1)) || (this.state.emailAddress2 != '' && !this.validateEmail(this.state.emailAddress2)) || (this.state.emailAddress3 != '' && !this.validateEmail(this.state.emailAddress3)))
		{
			this.props.showMainErrorPop('Please enter the valid email/s');
			return false;
		}
		
	if ( ( ( this.state.emailAddress1 == this.state.emailAddress2 ) && ( this.state.emailAddress1 != '' && this.state.emailAddress1 != '')  ) || ( ( this.state.emailAddress1 == this.state.emailAddress3 ) && ( this.state.emailAddress1 != '' && this.state.emailAddress3 != '')  ) || ( ( this.state.emailAddress2 == this.state.emailAddress3 ) && ( this.state.emailAddress2 != '' && this.state.emailAddress3 != '')  )  )
		{
			this.props.showMainErrorPop('Entered emails cannot be the same');
			return false;
		}
	
	const membersDetails = {
		'planId': this.state.familyPlanId,
		'memberEmails': [this.state.emailAddress1, this.state.emailAddress2, this.state.emailAddress3]
	};
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/editFamilyPlanMembers", membersDetails).then(res => {
		console.log(res.data.responseData, 'responseData111');
		if ( res.data.responseData == 'emailError')
			{
				$('.main_loader').hide();
				this.props.showMainErrorPop('Entered Member does not exist');
			}
		else if ( res.data.responseData.message == 'member have plan')
			{
				this.setState({
					membersWithPlan: res.data.responseData.membersWithPlan,
					showFamilyMembers: false,
				}, ()=>{
					this.setState({
						chosenPopup: 'membersHavePlan',
						showPop: true,
					});
					this.checkFamilyPlanMembers(this.state.familyPlanId);
					$('.main_loader').hide();
				});
				
			}			
		else
			{
				this.setState({
					showFamilyMembers: false,
				},()=>{$('.main_loader').hide(); this.checkFamilyPlanMembers(this.state.familyPlanId)})
			}
	}).catch(err =>{
		$('.main_loader').hide();
		console.log('-error');
	}); 
}

checkCoupon=()=>{
	if ( this.state.code )
		{
			$('.main_loader').attr({'style':'display:flex'});
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getMemberCoupon", {codep: this.state.code.toLowerCase()}).then(res => {
				var code = res.data.responseData;
				if ( !code )
					{
						this.setState({
							promoText: 'Code Not found.',
							codeApplied: false,
							couponId: 0,
							}, ()=>{ this.props.showMainErrorPop("Oops, this is not a valid promotional/referral code. \nPlease check your code and try again.") });
					}
				else
					{
						var code = res.data.responseData;
						
						let coupon_apply_status = code.coupon_apply_status;
						
						if ( coupon_apply_status && coupon_apply_status != this.state.planType && this.state.planType != 0 )
							{
								let planTypeName = 'monthly';

								if ( coupon_apply_status == 2 )
									{
										planTypeName = 'annual';
									}
								
								this.setState({
									codeApplied: false,
									couponId: 0,
									code: '',
								});
								
								this.props.showMainErrorPop("Oops, the promotion code you applied is only valid for "+planTypeName+" plan. \nPlease switch to "+planTypeName+" plan or use a different promotion code.");
								$('.main_loader').hide();
								return false;
							}
						
						
						let promoDiscount = ((100-code.subPercent)/100);
						let subPerNumMonth = code.subPercent;
						let subPerNumYear = code.subPercent;
						let subPerNumFamilyMonth = code.subPercent;
						let subPerNumFamilyYear = code.subPercent;
						subPerNumMonth = subPerNumMonth + this.state.singlePlanObj['percentageMonth'];
						subPerNumYear = subPerNumYear + this.state.singlePlanObj['percentageAnnual'];
						
						subPerNumFamilyMonth = subPerNumFamilyMonth + this.state.familyPlanObj['percentageMonth'];
						subPerNumFamilyYear = subPerNumFamilyYear + this.state.familyPlanObj['percentageAnnual'];	
	
						if ( subPerNumMonth > 100 )
							{
								subPerNumMonth = 100;
							}
						
						if ( subPerNumYear > 100 )
							{
								subPerNumYear = 100;
							}	
						
						if ( subPerNumFamilyMonth > 100 )
							{
								subPerNumFamilyMonth = 100;
							}
						
						if ( subPerNumFamilyYear > 100 )
							{
								subPerNumFamilyYear = 100;
							}	
						
						this.setState({
								couponId: code.id,
								promoText: code.subText,
								subPercent: code.subPercent,
								promoDiscount: promoDiscount,
								subPercentMonth: subPerNumMonth,					
								subPercentYear: subPerNumYear,					
								subPercentFamilyMonth: subPerNumFamilyMonth,					
								subPercentFamilyYear: subPerNumFamilyYear,
								promoDiscountMonth: ((100-subPerNumMonth)/100),
								promoDiscountYear: ((100-subPerNumYear)/100),
								promoDiscountFamilyMonth: ((100-subPerNumFamilyMonth)/100),
								promoDiscountFamilyYear: ((100-subPerNumFamilyYear)/100),
								codeApplied: true,
								couponApplyStatus : coupon_apply_status
							}, ()=>{ 
								if ( this.state.planId == 0 )
									{
										this.checkForPlansData();
	
									}
								else 
									{
										this.setCouponInPlan();
									}									
								
							});
					}
				$('.main_loader').hide();
			}).catch(err =>{ $('.main_loader').hide(); });
		}
}

removeCoupon=()=>{
	this.setState({
		couponId: 0,
		code: '',
		promoText: '',
		subPercent: 0,					
		subPercentMonth: 0,					
		subPercentYear: 0,					
		subPercentFamilyMonth: 0,					
		subPercentFamilyYear: 0,					
		promoDiscount: 0,
		promoDiscountMonth: 0,
		promoDiscountYear: 0,
		promoDiscountFamilyMonth: 0,
		promoDiscountFamilyYear: 0,
		codeApplied: false,
		couponApplyStatus: 0
	}, ()=>{
		this.setCouponInPlan();
	});
}

setCouponInPlan=()=>{
	
	if ( this.state.planId == 0 )
		{
			return false;
		}
	let planDetails = {
		'planId': this.state.planId,
		'couponId': this.state.couponId,
		'codeText': this.state.code,
	};
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updatePlanCoupon", planDetails).then(res => {
		this.checkMemberPlan();
	}).catch(err =>{ console.log('err--', err); });
}

setFamilyPlanType=(ev, mod, fromPop)=>{
	ev.preventDefault();
	ev.stopPropagation();
	
	this.setState({
		familyPlanType: mod,
	}, ()=>{ 
		if ( this.state.planId && !fromPop )
			{
				this.updatePlanType(this.state.familyPlanType) 
			}
	
	});
}

setSinglePlanType=(ev, mod, type)=>{
	ev.preventDefault();
	ev.stopPropagation();
	
	this.setState({
		singlePlanType: mod,
	}, ()=>{ 
		if ( this.state.planId && !type)
			{
				this.updatePlanType( this.state.singlePlanType ) 
			}
		
		});
}

updatePlanType=(mod)=>{
	let planDetails = {
		'planId': this.state.planId,
		'planType': mod,
	};
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updatePlanType", planDetails).then(res => {
		this.checkMemberPlan();
	}).catch(err =>{ console.log('err--', err); });
}

stopClick=(ev)=>{
	ev.preventDefault();
	ev.stopPropagation();
	
	return false;
}

logOutMember=()=>{
	localStorage.removeItem('userData'); 
	this.deleteCookie(process.env.REACT_APP_COOKIE_ID); 
	this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); 
	window['user_loggedIn'] = 0;
	browserHistory.push('/login');
}


openNativeUrl = (nurl) => {
	
	var globalSettings = JSON.parse(localStorage.getItem('userData')).data.responseData.settings;
	
	this.setState({appDownload: ( osname == "Windows" ? globalSettings.windowsAppLink : globalSettings.macAppLink )});
	
	$('.main_loader').attr({'style':'display:flex'});
	customProtocolCheck(nurl,
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative').modal('hide');
							$("#nativedownload").show();
						  },
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative').modal('hide');
						  }, 15000);
}

openPaymentHistory=()=>{
	this.setState({
		showPaymentHistory: true,
	});
}

openAddDefCreditCard=()=>{
	this.setState({
		showAddCC: true,
	})
}

retryPayment=()=>{
	let planDetails = {
		'plan': this.state.failedPaymentDetails,
		'couponDiscount': this.state.subPercent,
		'couponId': this.state.couponId,
	};
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/billing/retryFailedPayment", planDetails).then(res => {
		if ( res.data.responseData == 'success' )
			{
				this.props.showMainErrorPop('Your payment was successful. Enjoy using Virdio.');
				this.checkForFailedPayments();
			}
		else 
			{
				this.props.showMainErrorPop('Payment failed');
			}
		$('.main_loader').hide();			
	}).catch(err =>{ 
	$('.main_loader').hide();
	console.log('err--', err); });

}

getSessionDate=(date)=>{
	let sessionDate = '';
	let dateVar = new Date(date).getTime();
	if ( dateVar && dateVar !== 'Invalid Date' )
		{
			let day = new Date(dateVar).getDay();
			let dateNum = new Date(dateVar).getDate();
			let month = new Date(dateVar).getMonth();
			let weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
			let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			sessionDate = weekday[day]+ ', ' + months[month] + ' ' + dateNum;
		}
		
	return sessionDate;
}

getSessionTime=(date)=>{
	let ab = new Date(date);
	if ( ab && ab !== 'Invalid Date' )
		{
			let hour = new Date(date).getHours();
			let minute = new Date(date).getMinutes();
			let sessionTime = hour+':'+minute;
			
			if ( minute < 10 )
				{
				  minute = '0'+minute
				}

			if ( hour < 12 )
				{
					if ( hour < 10 )
						{	
							sessionTime = '0'+hour+':'+minute+" " +'AM';
						}
					else
						{
							sessionTime = hour+':'+minute+" " +'AM';
						}
					
					return sessionTime;
				}
			
			if ( hour > 12 )
				{
					if ( ( hour - 12 ) < 10 )
						{
							sessionTime = '0'+( hour - 12 )+':'+minute+" " +'PM';
						}
					else
						{
							sessionTime = ( hour - 12 )+':'+minute+" " +'PM';
						}	
					return sessionTime ;
				}
			
			if ( hour == 12 && minute >= 0 )
				{
					sessionTime = hour+':'+minute+" " +'PM';	
					return sessionTime;
				}
		}
	else
		{
			return '-';
		}
}

sendInstruction=(type, isDesktop, additionalPop)=>{
	if ( !this.state.isSending )
		{
			if ( type === 'Mobile' && !this.state.additionalMobilePopup && !additionalPop )
				{
					this.setState({additionalMobilePopup: true});
					return false;
				}
			
			
			var urlApp = '';
			if ( (type === 'Windows' || type === 'Mac OS' || type === 'iOS' || type === 'Android') && !tablet )
				{
					if ( type === 'Windows'  ) { urlApp = this.state.loggedUserData.settings.windowsAppLink; type = 'Windows'; }
					if ( type === 'Android' ) { type = 'Windows'; }
					if ( type === 'Mac OS' ) { urlApp = this.state.loggedUserData.settings.macAppLink; type = 'Mac OS'; }
					if ( type === 'iOS' ) { type = 'Mac OS'; }
					
					if ( type === 'Windows' || type === 'Mac OS' ) { this.setState({loggedNative: 1}); } 
				}
				
			if ( type === 'Remote Control' || type === 'Smartwatch' || type === 'Mobile' )
				{
					if ( android ) { urlApp = 'https://play.google.com/store/apps/details?id=mvvm.f4wzy.com.virdioApp'; }
					if ( ios ) { urlApp = 'https://apps.apple.com/us/app/virdio/id1489704718'; }
				}
				
			if ( isDesktop && this.state.isOtherDevice ) { type = 'Native'; }
			if ( isDesktop && type === 'iOS' ) { type = 'Mac OS'; }
			if ( isDesktop && type === 'Android') { type = 'Windows'; }
			
			if ( urlApp !== '' ) { window.open(urlApp) };
			
			console.log(urlApp);
			
			this.setState({isSending: true, appType: type}, () => {
				var postData = {type: type, email: this.state.loggedUserData.email, isDesktop: isDesktop, isOtherDevice: this.state.isOtherDevice};
				axios.post(process.env.REACT_APP_NAME+"/api/v1/user/sendAppsEmail", postData).then(res => {
					this.setState({emailSent: true});
					var mainthis = this;
					setTimeout(function(){ mainthis.setState({isSending: false}); }, 2000);
				}).catch(err =>{ });
			});
		}
}

openPlanSelect=()=>{
	this.setState({
		showSelectPlan: true,
	}, ()=>{
		
		let mod = 'monthly'
		if ( this.state.planType == 2 )
			{
				mod = 'annual';
			}
		this.setPlanType(mod);
	});
}

setPlanType=(mod)=>{
	let planType = 1;
	
	if ( mod == 'annual' )
		{
			planType = 2;
		}
	
	if ( this.state.couponApplyStatus && this.state.couponApplyStatus != planType)
		{
			this.removeCoupon();
		}	
		
	this.setState({
		planTypeText: mod,
		familyPlanType: planType,
		singlePlanType: planType,							
	})

}

setPlanSelected=(mod)=>{
	this.setState({
		planSelected: mod,
	}, ()=>{
		this.addMemberPlan();
	})
}

closePopTry=()=>{
	this.setState({
		showSelectPlan: false,
	});
}

showWhatIsPop=(type)=>{
	
	if ( type )
		{
			if ( this.state.showWhatIsPop )
				{
					this.setState({
						showWhatIsPop: false,
					})
				}
			else 
				{
					this.setState({
						showWhatIsPop: true,
					})
				}				
		}
	else 
		{
			this.setState({
				showWhatIsPop: true,
			})
		}		
	
}

hideWhatIsPop=()=>{
	this.setState({
		showWhatIsPop: false,
	})
}

render() {
	
    return (
		<div className="memberRegisterContainer memberProfilePage onlyMemberProfilePage">
			{!this.state.showLaunchPop &&
				<MarketplaceHeader
				state={this.state}
				updateStateFromChild={this.updateStateFromChild}
				logOutMember={this.logOutMember}
				></MarketplaceHeader>
			}
			<div className="registerMainContainer" >
				<div className="memberRegisterHolder paddt45 profileHolder">
					<div className="manageH1">Manage Membership</div>
					{this.state.trialPeriod &&
						<div className="manageH2">You have <span className="manageSpanBold"> {this.state.trialDaysLeft} days left </span>in your free trial. It ends on {this.state.trialEndDate}. Renew it by adding a payment method.</div>
					}
					<div className="manageLine"></div>
					<div>
						<div className="manageHalfHolder">
							<div className="manageH1 mediaHalfHolder">Your Plan</div>
							<div className="mediaHalfHolder">
								{this.state.planSelected == 1 &&
									<div className="managePlanDetailsHalf">Single User</div>
								}
								{this.state.planSelected == 2 &&
									<div className="managePlanDetailsHalf">Family</div>
								}
								{this.state.planSelected == 0 && !this.state.onlyFamilyPlanMember && this.state.planDataFund &&
									<div className="managePlanDetailsHalf">No plan selected</div>
								}
								{this.state.planSelected == 0 && this.state.onlyFamilyPlanMember && this.state.planDataFund &&
									<div className="managePlanDetailsHalf">You are part of family plan</div>
								}
								
								{this.state.planSelected == 1 && this.state.planType == 1 &&
									<div className="managePlanDetailsHalf planDeatilsName">${this.state.singlePlanNextMonthPrice} per month</div>
								}
								{this.state.planSelected == 1 && this.state.planType == 2 &&
									<div className="managePlanDetailsHalf planDeatilsName">${this.state.singleAnnualNextMonthPrice} per year</div>
								}
								{this.state.planSelected == 2 && this.state.planType == 1 &&
									<div className="managePlanDetailsHalf planDeatilsName">${this.state.familyPlanNextMonthPrice} per month</div>
								}
								{this.state.planSelected == 2 && this.state.planType == 2 &&
									<div className="managePlanDetailsHalf planDeatilsName">${this.state.familyAnnualNextMonthPrice} per year</div>
								}
								{this.state.planSelected != 0 &&
									<div className="managePlanDetailsHalf planDeatilsName">
											{!this.state.changeType ?
												<span>Your Plan renews on {this.state.renewDate}</span>
												:
												<span>Your plan will be canceled after plan expire</span>
											}
									</div>
									
									
									
								}
								{this.state.planSelected == 2 &&
									<div className="familyPlanStatus">
										<div className="innerFamilyPlanStatus">
											<div className="familyPlanMembersText">{this.state.familyMembersNum}/3 members assigned</div>
										</div>
										<div className="innerFamilyPlanStatus rightSideStatus">
											<div className="familyPlanMembersText" onClick={(e)=>this.openEditMembers()} >Edit members</div>
										</div>
									</div>
								}
								
							</div>
						</div>
						<div className="manageHalfHolder manageRightSide">
							<div className="planSelectBtnHolder">
								{this.state.planId ?
									<div className="newSelectBtn mediaHalfHolder" onClick={(e)=>this.openPlanSelect(e)} >Switch Plan</div>
									:
									<div className="newSelectBtn mediaHalfHolder" onClick={(e)=>this.openPlanSelect(e)}>Select a Plan</div>
								}
							</div>
							<div className="planSelectBtnHolder rightSidePlanSelect">
								{this.state.planId != 0 && !this.state.changeType &&
									<div className="manageSwitch cancNewButton" onClick={(e)=>this.openCancelPlan(e)} >Cancel Membership</div>
								}
							</div>
						</div>
					</div>
					<div className="manageLine"></div>
					<div>
						<div className="manageHalfHolder">
							<div className="manageH1">Payment Method</div>
						</div>
						<div className="manageHalfHolder manageRightSide">
							{this.state.addedPayment ?
								<div className="manageSwitch" onClick={(e)=>this.openPaymentHistory()} >View Payment History</div>
								:
								<div className="manageSwitch manageBtnColor" onClick={(e)=>this.showAddFirstCardForm()} >Add Payment Method</div>
							}
						</div>

							<div>
								{ !this.state.addedPayment && <div className="noteHolder"><div className="noteText">Payment method required after trial ends</div></div> }
									<div>									
										{!this.state.addedPayment ?
												<div>	
													{this.state.showAddCardForm &&
													<div>
														<div className="addCardFormHolder">
															<div className="managePlanDetailsHalf">Credit Card</div>
															<div className="info_row_holder">
																<div className="one_form_container">
																	<div className="relative_holder">
																		<input type="text" tabIndex="6" className={classnames({'gray_inputs': true, 'error_input': this.state.errorHolderName})} 
																		id="cardHolderName" 
																		placeholder="Name on card"
																		value={this.state.cardHolderName} 
																		onChange={(e)=>this.updateStateFromChild({[e.target.id]:e.target.value, errorHolderName: false})} 
																		onFocus={() => this.updateStateFromChild({focusedElement: 'cardHolderName'})} 
																		onBlur={() => this.updateStateFromChild({focusedElement: ''})} />
																		{ this.state.cardHolderName !== '' &&
																			<div className="empty_input" onClick={()=>this.updateStateFromChild({cardHolderName: ""})}></div>
																		}
																	</div>
																</div>
															</div>
															<div className="info_row_holder">
																<div className="one_form_container">
																	<div className="relative_holder">
																		<input type="tel" tabIndex="1" className={classnames({'gray_inputs': true, 'error_input': this.state.errorCardNum})} 
																		id="cardNumber" maxLength="16"
																		placeholder="Card Number"
																		value={this.state.cardNumber} 
																		onChange={(e)=>this.updateStateFromChild({[e.target.id]:e.target.value, errorCardNum: false})} 
																		onFocus={() => this.updateStateFromChild({focusedElement: 'cardNumber'})} 
																		onBlur={() => this.updateStateFromChild({focusedElement: ''})} />
																		{ this.state.cardNumber !== '' &&
																			<div className="empty_input" onClick={()=>this.updateStateFromChild({cardNumber: ""})}></div>
																		}
																	</div>
																</div>
															</div>
															<div className="holderWithDatesCard">
																<div className="inputDateHolderNew first25">
																	<div className="inline_relative">
																		<input type="tel" className={classnames({'fitness_input dates_width': true, 'error_input': this.state.errorCardMM})}
																		maxLength="2" id="cardMonth" maxLength="2" 
																		tabIndex="2"
																		placeholder="MM"
																		value={this.state.cardMonth} 
																		onChange={(e)=>this.updateStateFromChild({[e.target.id]:e.target.value, errorCardMM: false})} 
																		onFocus={() => this.updateStateFromChild({focusedElement: 'cardMonth'})} 
																		onBlur={() => this.updateStateFromChild({focusedElement: ''})} />
																		{ this.state.cardMonth !== '' &&
																			<div className="empty_input" onClick={()=>this.updateStateFromChild({cardMonth: ""})}></div>
																		}
																	</div>
																</div>
																<div className="inputDateHolderNew first25">
																	<div className="inline_relative">
																		<input type="tel" className={classnames({'fitness_input dates_width': true, 'error_input': this.state.errorCardYear})}
																		id="cardYear" maxLength="4" 
																		tabIndex="3"
																		placeholder="YYYY"
																		value={this.state.cardYear} 
																		onChange={(e)=>this.updateStateFromChild({[e.target.id]:e.target.value, errorCardYear: false})} 
																		onFocus={() => this.updateStateFromChild({focusedElement: 'cardYear'})} 
																		onBlur={() => this.updateStateFromChild({focusedElement: ''})} />
																		{ this.state.cardYear !== '' &&
																			<div className="empty_input" onClick={()=>this.updateStateFromChild({cardYear: ""})}></div>
																		}
																	</div>
																</div>
																<div className="inputDateHolderNew last50">
																	<div className="inline_relative">
																		<input type="text" tabIndex="4" className={classnames({'gray_inputs': true, 'error_input': this.state.errorCardCVV})} 
																		id="cardCVV" 
																		placeholder="Security Code"
																		value={this.state.cardCVV} 
																		onChange={(e)=>this.updateStateFromChild({[e.target.id]:e.target.value, errorCardCVV: false})} 
																		onFocus={() => this.updateStateFromChild({focusedElement: 'cardCVV'})} 
																		onBlur={() => this.updateStateFromChild({focusedElement: ''})} />
																		{ this.state.cardCVV !== '' &&
																			<div className="empty_input" onClick={()=>this.updateStateFromChild({cardCVV: ""})}></div>
																		}
																	</div>
																</div>
															</div>
															<div className="cardWhatText" onMouseOut={(e)=> {this.hideWhatIsPop()}} onMouseOver={(e)=> {this.showWhatIsPop(false)}} onClick={(e)=>this.showWhatIsPop(true)}  >What’s this?
																{this.state.showWhatIsPop && 
																	<div className="whatIsPopHolder">
																		<div className="arrowPointerPopHolder"></div>
																		The CVV/CVC code (Card Verification Value/Code) is located on the back of your credit/debit card on the right side of the white signature strip; it is always the last 3 digits in case of VISA and MasterCard.
																	</div>
																}
															</div>
															<div className="info_row_holder">
																<div className="one_form_container">
																	<div className="relative_holder">
																		<input type="tel" tabIndex="5" className={classnames({'gray_inputs': true, 'error_input': this.state.errorCardZip})} 
																			id="cardZip" maxLength="5"
																			value={this.state.cardZip}
																			placeholder="Zip Code"
																			onChange={(e)=>this.updateStateFromChild({[e.target.id]:e.target.value, errorCardZip: false})} 
																			onFocus={() => this.updateStateFromChild({focusedElement: 'cardZip'})} 
																			onBlur={() => this.updateStateFromChild({focusedElement: ''})} />
																			{ this.state.cardZip !== '' &&
																				<div className="empty_input" onClick={()=>this.updateStateFromChild({cardZip: ""})}></div>
																			}
																	</div>
																</div>
															</div>
															<div className="btnSaveAlign">
																<div className="active_booked_bnt_new" onClick={() => this.addCreditCard()}>Save</div>
															</div>
														</div>
														<div className="planBillingDetailsHolder">
															<div className="planBillingInnerHolder">
																<div className="planSummH1">Plan Summary</div>
																<div className="summLine"></div>
																<div className="summ_one_value">	
																	<div className="leftSideSmm">Billing cycle</div>
																	<div className="rightSideSmm">
																		{this.state.planType == 1 ?
																			<div className="planNameSumm">Monthly</div>
																			:
																			<div className="planNameSumm">Annual</div>
																		}
																	</div>
																</div>
																<div className="summ_one_value">
																	{this.state.planSelected == 1 ?
																		<div className="leftSideSmm normalFontWeight">Single User {this.state.codeApplied && <span>({this.state.promoCodeDisplayed}% off)</span>}</div> 
																		:
																		<div className="leftSideSmm normalFontWeight">Family {this.state.codeApplied && <span>({this.state.promoCodeDisplayed}% off)</span>}</div> 
																	}
																	
																	{this.state.planSelected == 1 && this.state.planType == 1 &&
																		<div className="rightSideSmm">
																			{ !this.state.codeApplied ?
																				<span>${this.state.singlePlanObj['monthlyFullPrice']}</span>
																				:
																				<div>
																					<span className="">${this.state.singlePlanNextMonthPrice}</span>
																					<span className="planPriceCrossed">${this.state.singlePlanObj['monthlyFullPrice']}</span>
																				</div>
																				
																			}
																		</div>
																	}
																	{this.state.planSelected == 1 && this.state.planType == 2 &&
																		<div className="rightSideSmm">
																			{ !this.state.codeApplied ?
																				<span>${this.state.singlePlanObj['annualFullPrice']}</span>
																				:
																				<div>
																					<span>${this.state.singleAnnualNextMonthPrice}</span>
																					<span className="planPriceCrossed">${this.state.singlePlanObj['annualFullPrice']}</span>
																				</div>
																				
																			}
																		</div>
																	}
																	{this.state.planSelected == 2 && this.state.planType == 1 &&
																		<div className="rightSideSmm">
																			{ !this.state.codeApplied ?
																				<span> ${this.state.familyPlanObj['monthlyFullPrice']} </span> 
																				:
																				<div>
																					<span>${this.state.familyPlanNextMonthPrice}</span>
																					<span className="planPriceCrossed"> ${this.state.familyPlanObj['monthlyFullPrice']} </span> 
																				</div>
																				
																			}
																		</div>
																	}
																	{this.state.planSelected == 2 && this.state.planType == 2 &&
																		<div className="rightSideSmm">
																			{ !this.state.codeApplied ?
																				<span>${this.state.familyPlanObj['annualFullPrice']}</span>
																				:
																				<div>
																					<span>${this.state.familyAnnualNextMonthPrice}</span>
																					<span className="planPriceCrossed">${this.state.familyPlanObj['annualFullPrice']}</span>
																				</div>
																				
																			}
																		</div>
																	}
																</div>
																
																<div className="summ_one_value">
																	<div className="leftSideSmm normalFontWeight">Tax (0%)</div>
																	<div className="rightSideSmm">$0.00</div>
																</div>
																<div className="summLine"></div>
																<div className="summ_one_value summ_one_valueprice">
																	{this.state.planType == 1 ?
																		 this.state.planStartEnd ?
																			<div className="leftSideSmm">Total for {this.state.planBillMonth} <span>({this.state.planStartEnd['startDay']} to {this.state.planStartEnd['endDay']})</span></div>
																			:
																			<div className="leftSideSmm">Total for {this.state.planBillMonth}</div>
																		:
																		<div className="leftSideSmm">Total per year</div>
																	}
																	
																	<div className="rightSideSmm summTotalText">${this.state.planBillPrice}</div>
																</div>
																
																{ this.state.planPayStatus == 0 || this.state.planPayStatus == 1 && <div className="profileChargeText">We’ll charge your card on <span>{this.state.nextCardChargeDate}</span></div> }
																{ this.state.planPayStatus == 2 && <div className="profileChargeText">Paid on <span>{this.state.planPayUpdateDate}</span></div> }
																{ this.state.planPayStatus == 3 && <div class="noteHolder"><div class="noteText">Payment FAILED on <span>{this.state.planPayUpdateDate}</span></div></div> }
																
																<div className="summ_one_value leftSideSmm" >Have a promo code?</div>
																<div className="summ_one_value">
																	<div className="relative_holder" className={classnames({'relative_holder': true, 'showButton': true })}  >
																		<input type="text" className={classnames({'gray_inputs': true, 'foundCouponBorder': this.state.codeApplied})} 
																		id="code" 
																		value={this.state.code}  
																		onChange={(e)=>this.setState({[e.target.id]:e.target.value})} 
																		onFocus={() => this.setState({focusedElement: 'code'})} 
																		onBlur={() => this.setState({focusedElement: ''})} />
																		{/* this.state.code !== '' &&
																			<div className="empty_input" onClick={()=>this.setState({code: ""})}></div>
																		*/} 
																	</div>
																	
																	{this.state.codeApplied ? 
																		<div className="applyBtnHolder">
																			<div className={classnames({'inline_event': true})} onClick={(e)=>this.removeCoupon()}>
																				<div className="bottom_title_small newTitleSumm">Remove</div>
																			</div>
																		</div>
																		:
																		<div className="applyBtnHolder">
																			<div className={classnames({'inline_event': true})} onClick={(e)=>this.checkCoupon()}>
																				<div className="bottom_title_small newTitleSumm">APPLY</div>
																			</div> 
																		</div>
																		
																	}
																</div>
																<div className="summ_one_value">
																	{ !!this.state.failedPaymentStatus && <div className="manageSwitch manageBtnColor" onClick={(e)=>this.retryPayment()}>Renew Membership</div> }
																</div>
															</div>														
													</div>
												</div>
												}												
											</div>	
											:
											<div>
												<div>
													<div className="addCardFormHolder">
														<div className="managePlanDetailsHalf">Credit Card</div>
														<div>
															{this.state.memberCreditCards.map((row,i)=> 
																<div className="paymentMethodHolder" key={i}>
																	<div className="detailsHolderCard">	
																		<div className="cardHalfHolder">	
																			<div className={classnames("innerPaymentDetails " + ( row.type == 'Visa' || row.type == 'Visa Electron' ? "visaCardType" : "" ) + ( row.type == 'Mastercard' ? "mastercardType" : "" ) + ( row.type == 'AMEX' ? "AmexCardType" : "" ) + ( row.type == 'Discover' ? "DiscoverCardType" : "" ) + ( row.type == 'Diners' || row.type == 'Diners - Carte Blanche' ? "DinersCardType" : "" ) )} > 
																				<span>{row.type}</span> **** <span> {row.card_number}</span> 
																			</div>
																			<div className="editPaymentMethod">
																				<span>Expires {row.expires}</span> 
																			</div>
																		</div>
																		<div className="cardHalfHolder rightSideCard">
																			{ !row.is_default && <div className="manageCancBtn" onClick={(e)=>this.makeDefaultCard(row)}>Make Default</div> }
																			{ !!row.is_default && <div className="editPaymentMethod"><span>Default Card&nbsp;&nbsp;&nbsp;</span></div> }
																			
																			<div className="manageCancBtn" onClick={(e)=>this.openRemoveCard(row)}>Remove</div>
																		</div>
																	</div>
																</div>
															)}
														</div>
														<div className="manageSwitch manageBtnColor" onClick={(e)=>this.openAddCard()} >Add Payment Method</div>
													</div>
													<div className="planBillingDetailsHolder">
														<div className="planBillingInnerHolder">
															<div className="planSummH1">Plan Summary</div>
															<div className="summLine"></div>
															<div className="summ_one_value">	
																<div className="leftSideSmm">Billing cycle</div>
																<div className="rightSideSmm">
																	{this.state.planType == 1 ?
																		<div className="planNameSumm">Monthly</div>
																		:
																		<div className="planNameSumm">Annual</div>
																	}
																</div>
															</div>
															<div className="summ_one_value">
																{this.state.planSelected == 1 ?
																	<div className="leftSideSmm normalFontWeight">Single User {this.state.codeApplied && <span>({this.state.promoCodeDisplayed}% off)</span>}</div> 
																	:
																	<div className="leftSideSmm normalFontWeight">Family {this.state.codeApplied && <span>({this.state.promoCodeDisplayed}% off)</span>}</div> 
																}
																{this.state.planSelected == 1 && this.state.planType == 1 &&
																	<div className="rightSideSmm">
																		{ !this.state.codeApplied ?
																			<span>${this.state.singlePlanObj['monthlyFullPrice']}</span>
																			:
																			<div>
																				<span className="">${this.state.singlePlanNextMonthPrice}</span>
																				<span className="planPriceCrossed">${this.state.singlePlanObj['monthlyFullPrice']}</span>
																			</div>
																			
																		}
																	</div>
																}
																{this.state.planSelected == 1 && this.state.planType == 2 &&
																	<div className="rightSideSmm">
																		{ !this.state.codeApplied ?
																			<span>${this.state.singlePlanObj['annualFullPrice']}</span>
																			:
																			<div>
																				<span>${this.state.singleAnnualNextMonthPrice}</span>
																				<span className="planPriceCrossed">${this.state.singlePlanObj['annualFullPrice']}</span>
																			</div>
																			
																		}
																	</div>
																}
																{this.state.planSelected == 2 && this.state.planType == 1 &&
																	<div className="rightSideSmm">
																		{ !this.state.codeApplied ?
																			<span> ${this.state.familyPlanObj['monthlyFullPrice']} </span> 
																			:
																			<div>
																				<span>${this.state.familyPlanNextMonthPrice}</span>
																				<span className="planPriceCrossed"> ${this.state.familyPlanObj['monthlyFullPrice']} </span> 
																			</div>
																			
																		}
																	</div>
																}
																{this.state.planSelected == 2 && this.state.planType == 2 &&
																	<div className="rightSideSmm">
																		{ !this.state.codeApplied ?
																			<span>${this.state.familyPlanObj['annualFullPrice']}</span>
																			:
																			<div>
																				<span>${this.state.familyAnnualNextMonthPrice}</span>
																				<span className="planPriceCrossed">${this.state.familyPlanObj['annualFullPrice']}</span>
																			</div>
																			
																		}
																	</div>
																}
															</div>
															
															<div className="summ_one_value">
																<div className="leftSideSmm normalFontWeight">Tax (0%)</div>
																<div className="rightSideSmm">$0.00</div>
															</div>
															<div className="summLine"></div>
															<div className="summ_one_value summ_one_valueprice">
																{this.state.planType == 1 ?
																	 this.state.planStartEnd ?
																		<div className="leftSideSmm">Total for {this.state.planBillMonth} <span>({this.state.planStartEnd['startDay']} to {this.state.planStartEnd['endDay']})</span></div>
																		:
																		<div className="leftSideSmm">Total for {this.state.planBillMonth}</div>
																	:
																	<div className="leftSideSmm">Total per year</div>
																}
																
																<div className="rightSideSmm summTotalText">${this.state.planBillPrice}</div>
															</div>
															
															{ this.state.planPayStatus == 0 || this.state.planPayStatus == 1 && <div className="profileChargeText">We’ll charge your card on <span>{this.state.nextCardChargeDate}</span></div> }
															{ this.state.planPayStatus == 2 && <div className="profileChargeText">Paid on <span>{this.state.planPayUpdateDate}</span></div> }
															{ this.state.planPayStatus == 3 && <div class="noteHolder"><div class="noteText">Payment FAILED on <span>{this.state.planPayUpdateDate}</span></div></div> }
															
															<div className="summ_one_value leftSideSmm" >Have a promo code?</div>
															<div className="summ_one_value">
																<div className="relative_holder" className={classnames({'relative_holder': true, 'showButton': true })}  >
																	<input type="text" className={classnames({'gray_inputs': true, 'foundCouponBorder': this.state.codeApplied})} 
																	id="code" 
																	value={this.state.code}  
																	onChange={(e)=>this.setState({[e.target.id]:e.target.value})} 
																	onFocus={() => this.setState({focusedElement: 'code'})} 
																	onBlur={() => this.setState({focusedElement: ''})} />
																	{/* this.state.code !== '' &&
																		<div className="empty_input" onClick={()=>this.setState({code: ""})}></div>
																	*/} 
																</div>
																
																{this.state.codeApplied ? 
																	<div className="applyBtnHolder">
																		<div className={classnames({'inline_event': true})} onClick={(e)=>this.removeCoupon()}>
																			<div className="bottom_title_small newTitleSumm">Remove</div>
																		</div>
																	</div>
																	:
																	<div className="applyBtnHolder">
																		<div className={classnames({'inline_event': true})} onClick={(e)=>this.checkCoupon()}>
																			<div className="bottom_title_small newTitleSumm">APPLY</div>
																		</div> 
																	</div>
																	
																}
															</div>
															<div className="summ_one_value">
																{ !!this.state.failedPaymentStatus && <div className="manageSwitch manageBtnColor" onClick={(e)=>this.retryPayment()}>Renew Membership</div> }
															</div>
														</div>
													</div>
												</div>
											</div>	
										}
										
									</div>
							</div>
					</div>
					<div className="manageLine"></div>
					<div className="manageAppsHolder">
						<div className="manageH1">Download Virdio Apps</div>
						<div className="appPartHolder launchHolder">
							{ ( ( this.state.showDesktopApp && !this.state.isOtherDevice ) || ( this.state.showDesktopApp && this.state.isOtherDevice && this.state.sessionStartTimeHigher15Min ) ) &&																																																			
								<div className="one_part">
									<div className="inside_one_part desktop">
										<div className="one_part_title">{this.state.loggedNative === 1 && !tablet && ( osname === "Windows" || osname === "Mac OS" ) ?  'Launch the '+this.state.desktopAppName+' app' : 'Get the most out of your workout' }</div>
										<div className="one_part_subtitle">{this.state.loggedNative === 1 && !tablet && ( osname === "Windows" || osname === "Mac OS" ) ? 'Recommended for best performance and experience' : 'Download the Desktop app to experience classes with Virtual Equipment.' }</div>
										{ /* this.state.loggedNative === 0 && osname === "Mac OS" && !tablet &&
											<div className="one_part_subtitle pt10">or, for Macs with Apple silicon chip, click <a href={this.state.state.loggedUserData.settings.macAppSiliconChipLink} target="_blank"><span className="macos_span">here to download</span></a></div> */
										}
										{ /* this.state.loggedNative === 1 && osname === "Mac OS" && !tablet &&
											<div className="one_part_subtitle pt10">or, if you just switched to a Mac with Apple silicon chip,​ click <a href={this.state.state.loggedUserData.settings.macAppSiliconChipLink} target="_blank"><span className="macos_span">here to download</span></a></div> */
										}
										<div className="signup_btn" onClick={ (this.state.loggedNative === 1 && !tablet && ( osname === "Windows" || osname === "Mac OS" ) ) ? () => this.openNativeUrl(this.state.urlElectron) : () =>  this.sendInstruction(osname, true) }>{(this.state.loggedNative === 1 && !tablet && ( osname === "Windows" || osname === "Mac OS" ) ) ? 'Launch' : 'Download'}</div>
									</div>
								</div>
							}
							{ this.state.showDesktopApp && this.state.isOtherDevice && !this.state.sessionStartTimeHigher15Min &&
								<div className="one_part">
									<div className="inside_one_part desktop">
										<div className="one_part_title">Join with browser for Chromebook</div>
										<div className="one_part_subtitle">Recommended for best performance and experience</div>
										<a href={this.state.otherDeviceURL} target="_blank"><div className="signup_btn" onClick={this.state.showClose ? this.closePopup : this.triggerOpened}>Launch</div></a>
									</div>
								</div>
							}
							{ this.state.showMobileApp &&
								<div className="one_part">
									<div className="inside_one_part smartphone">
										<div className="one_part_title">{this.state.loggedRemote === 1 ? 'Use the Mobile and Remote control app' : 'Join classes on the go'}</div>
										<div className="one_part_subtitle">{this.state.loggedRemote === 1 ? 'Use your mobile phone to manage your class' : this.state.isHost ? 'Download the Mobile App to use your mobile device as a remote control to manage your Class' : 'Download the Mobile App to book and attend classes. No Virtual Equipment (coming soon)'}</div>
										<a href={this.state.mobileHref !== '' ? this.state.mobileHref : undefined} target="_blank"><div className="signup_btn" onClick={( deviceType === 'mobile' || deviceType === 'tablet' || tablet ) ? () => this.updateUserDemand() : () => this.sendInstruction(this.state.isHost ? 'Remote Control' : 'Mobile')}>{( deviceType === 'mobile' || deviceType === 'tablet' || tablet ) ? 'Launch' : 'Download'}</div></a>
									</div>
								</div>
							}
						</div>
					</div>
				</div>				
				{/*<div className="memberRegisterHolder paddt45 profileHolder">
					<div className="memberLogoutBtn" onClick={(e)=>{this.logOutMember()}}>SIGN OUT</div>
					<div className="planH1">Your Plan</div>
					{ !this.state.onlyFamilyPlanMember &&
						<div>
						{this.state.planSelected ?
							<div className="profileH2">You are currently on the 
								{this.state.planSelected == 1 ?
									<span className="profileSpanB"> Single User </span> 
									:
									<span className="profileSpanB"> Family </span> 
								}
								{this.state.trialPeriod &&
									<span className="profileSpanB" >Trial ({this.state.trialDaysLeft} days remaining)</span>
								}
								{this.state.changeNextMonth == 1 &&
									<div className="profileSpanB" >
										{this.state.changeType ?
											<span>This plan will be canceled at end of the month</span>
											:
											<span>This plan will be switched at end of the month</span>
										}
									</div> 
								}								
							</div>
							:
							<div className="profileH2">You are currently not subscribed to any plan</div>
						}
						
						{this.state.failedPaymentStatus &&
							<div className="profileH2 failedH2">Your last payment failed 
								<span className="retryButton" onClick={(e)=>this.retryPayment()} >Retry</span>
							</div>
						}
						</div>
					}
					{this.state.onlyFamilyPlanMember && 
						<div className="profileH2">You are currently on the <span className="profileSpanB"> Family </span> plan. This plan is controlled by other user.</div>
					}
					{!this.state.onlyFamilyPlanMember &&
					<div className="profilePlanHolder">
						<div className={classnames("planInnerHolder " + (this.state.planSelected == 1 ? "memberPlanChosen" : "" ))}  >					
							{this.state.planSelected == 2 &&
								<div className="opacityHideDiv" onClick={(e)=>this.stopClick(e)}></div>
							}
							{!this.state.planSelected &&
								<div className="profileSubButton" onClick={(e)=>this.selectPlan(1)} >Subscribe</div>
							}
							{this.state.planSelected == 1 &&
								<div className="profileSubButton" onClick={(e)=>this.openCancelPlan(e)}>Cancel Membership</div>
							}
							{this.state.planSelected == 2 &&
								<div className="profileSubButton" onClick={(e)=>this.selectPlan(1)} >Switch to Plan</div>
							}
							<div className="innerH1">
								<div className="selectedPartHolder" onClick={(e)=>this.selectPlan(1)}>
									<div className="selectedDivPart">
										<div className="innerSelectedDiv"></div>
									</div>
								</div>
								<div className="innerPlanName">Single User</div>
							</div>							
							<div className="planDetails_holder">
								<div className="oneDetailHolder">
									35+ live classes weekly
									<div className="dotPartAbs"></div>
								</div>
								<div className="oneDetailHolder">
									All access to on-demand classes
									<div className="dotPartAbs"></div>
								</div>
								<div className="oneDetailHolder">
									World-class instructors and access to training programs
									<div className="dotPartAbs"></div>
								</div>
							</div>
							<div className={classnames("new_plan_type_holder " + (this.state.singlePlanType == 1 ? "activePlanType" : "" ))} onClick={e=>{this.setSinglePlanType(e, 1)}} >
								<div className="planTypeInnerHolder">
									<div className="selectedPartHolder selectedPartHolder2" >
											<div className="selectedDivPart selectedDivPart2">
												<div className="innerSelectedDiv2"></div>
											</div>
										</div>
										<div className="innerPlanName innerPlanName2">Monthly:</div>
								</div>
								<div className="planTypeInnerHolder rightPriceHolder">
									<div className="calcPriceText">
											<span className="selectedPriceText">${this.state.singlePlanNextMonthPrice}</span>/mo
									</div>
								</div>
							</div>
							<div className={classnames("new_plan_type_holder " + (this.state.singlePlanType == 2 ? "activePlanType" : "" ))} onClick={e=>{this.setSinglePlanType(e, 2)}} >
								<div className="planTypeInnerHolder">
									<div className="selectedPartHolder selectedPartHolder2" >
											<div className="selectedDivPart selectedDivPart2">
												<div className="innerSelectedDiv2"></div>
											</div>
										</div>
										<div className="innerPlanName innerPlanName2">Annual:</div>
								</div>
								{!this.state.calculateFullPrice ? 
									<div className="planTypeInnerHolder rightPriceHolder">
										<div className="calcPriceText">
											{!this.state.codeApplied ?
												<span className="selectedPriceText smallerText">$16.99</span>
												:
												<span className="selectedPriceText smallerText">${(this.state.singleAnnualNextMonthPrice / 12).toFixed(2)}</span>
											}	
												/mo
										</div>
										<div className="calcPriceText">									
												<span className="selectedPriceText smallerText yr_text">(${this.state.singleAnnualNextMonthPrice})/yr</span>									
										</div>
									</div>
									:
									<div className="planTypeInnerHolder rightPriceHolder">
										<div className="calcPriceText">
											<span className="selectedPriceText smallerText">${(this.state.singleAnnualNextMonthPrice / 12).toFixed(2)}</span>/mo
										</div>
										<div className="calcPriceText">									
											<span className="selectedPriceText smallerText yr_text">(${this.state.singleAnnualNextMonthPrice})/yr</span>									
										</div>
									</div>
								}
							</div>
							{ !this.state.calculateFullPrice &&
								<div className="saveTip">Save 30% by paying annually</div>	
							}
						</div>
					</div>
					}
					{!this.state.onlyFamilyPlanMember &&
					<div className="profilePlanHolder">
						<div className={classnames("planInnerHolder " + (this.state.planSelected == 2 ? "memberPlanChosen" : "" ))}>
							{this.state.planSelected == 1 &&
								<div className="opacityHideDiv" onClick={(e)=>this.stopClick(e)}></div>
							}
							{!this.state.planSelected &&
								<div className="profileSubButton" onClick={(e)=>this.selectPlan(2)} >Subscribe</div>
							}
							{this.state.planSelected == 2 &&
								<div className="profileSubButton" onClick={(e)=>this.openCancelPlan(e)}>Cancel Membership</div>
							}
							{this.state.planSelected == 1 &&
								<div className="profileSubButton" onClick={(e)=>this.selectPlan(2)}>Switch to Plan</div>
							}
							<div className="innerH1">
								<div className="selectedPartHolder" >
									<div className="selectedDivPart" onClick={(e)=>this.selectPlan(2)} >
										<div className="innerSelectedDiv"></div>
									</div>
								</div>
								<div className="innerPlanName">Family</div>
							</div>
				
							<div className="planDetails_holder">
								<div className="oneDetailHolder">
									35+ live classes weekly
									<div className="dotPartAbs"></div>
								</div>
								<div className="oneDetailHolder">
									All access to on-demand classes
									<div className="dotPartAbs"></div>
								</div>
								<div className="oneDetailHolder">
									World-class instructors and access to training programs
									<div className="dotPartAbs"></div>
								</div>
							</div>
							<div className={classnames("new_plan_type_holder " + (this.state.familyPlanType == 1 ? "activePlanType" : "" ))} onClick={e=>{this.setFamilyPlanType(e, 1)}}>
								<div className="planTypeInnerHolder">
									<div className="selectedPartHolder selectedPartHolder2" >
											<div className="selectedDivPart selectedDivPart2">
												<div className="innerSelectedDiv2"></div>
											</div>
										</div>
										<div className="innerPlanName innerPlanName2">Monthly:</div>
								</div>
								<div className="planTypeInnerHolder rightPriceHolder">
									<div className="calcPriceText">
											<span className="selectedPriceText">${this.state.familyPlanNextMonthPrice}</span>/mo
									</div>
								</div>
							</div>
							<div className={classnames("new_plan_type_holder " + (this.state.familyPlanType == 2 ? "activePlanType" : "" ))} onClick={e=>{this.setFamilyPlanType(e, 2)}} >
								<div className="planTypeInnerHolder">
									<div className="selectedPartHolder selectedPartHolder2" >
											<div className="selectedDivPart selectedDivPart2">
												<div className="innerSelectedDiv2"></div>
											</div>
										</div>
										<div className="innerPlanName innerPlanName2">Annual:</div>
								</div>
								<div className="planTypeInnerHolder rightPriceHolder">
									<div className="calcPriceText">																		
										{!this.state.codeApplied && !this.state.calculateFullPrice ?
													<span className="selectedPriceText smallerText">$24.99</span>
													:
													<span className="selectedPriceText smallerText">${(this.state.familyAnnualNextMonthPrice / 12).toFixed(2)}</span>
										}
										/mo																	
									</div>
									<div className="calcPriceText">
										<span className="selectedPriceText smallerText yr_text">(${this.state.familyAnnualNextMonthPrice})/yr</span>									
									</div>
								</div>						
							</div>
							{ !this.state.calculateFullPrice &&
								<div className="saveTip">Save 30% by paying annually</div>	
							}
							<div className="familyPlanStatus">
								<div className="innerFamilyPlanStatus">
									<div className="familyPlanMembersText">{this.state.familyMembersNum}/3 members assigned</div>
								</div>
								<div className="innerFamilyPlanStatus rightSideStatus">
									<div className="familyPlanMembersText" onClick={(e)=>this.openEditMembers()} >Edit members</div>
								</div>
							</div>
						</div>					
					</div>
					}
					{!this.state.onlyFamilyPlanMember &&	
						<div>
							<div className="bottomPartHolders" >
								<div className="paymentMethod">
									<div className="paymentMethodText"> Payment Method </div>
									{!this.state.addedPayment && !this.state.trialPeriod &&
										<div className="notFoundPayment">Payment method required</div>
									}
									{!this.state.addedPayment && this.state.trialPeriod &&
										<div className="notFoundPayment trialPeriodNoFoundPayment">Payment method required after trial ends</div>
									}
								</div>
								
								<div className="paymentMethodHolder">
									<div className="innerPaymentDetails addImg" onClick={(e)=>this.openAddCard()} >Add payment method</div>
									<div className="editPaymentMethod historyBtn" onClick={(e)=>this.openPaymentHistory()} >View History</div>
								</div>					
								{this.state.addedPayment &&
									<div>
										{this.state.memberCreditCards.map((row,i)=> 
											<div className="paymentMethodHolder" key={i}>
												<div className="innerPaymentDetails">**** **** **** <span> {row.card_number}</span> </div>
												<div className="editPaymentMethod"  >
													<span onClick={(e)=>this.openRemoveCard(row)}> Remove </span>
												</div>
											</div>
										)}
									</div>
								}
							</div>
							<div className="bottomPartHolders bottomRightSide">
								<div className="one_form_container">
									<div className="form_title codeColorText">Have a discount/referral code?</div>
									<div className="relative_holder" className={classnames({'relative_holder': true, 'showButton': this.state.code.length > 0 })}  >
										<input type="text" className={classnames({'gray_inputs': true, 'foundCouponBorder': this.state.codeApplied})} 
										id="code" 
										value={this.state.code}  
										onChange={(e)=>this.setState({[e.target.id]:e.target.value})} 
										onFocus={() => this.setState({focusedElement: 'code'})} 
										onBlur={() => this.setState({focusedElement: ''})} />
										{ this.state.code !== '' &&
											<div className="empty_input" onClick={()=>this.setState({code: ""})}></div>
										}
									</div>
									{this.state.code.length > 0 &&
										<div className="applyBtnHolder">
											<div className={classnames({'inline_event': true})} onClick={(e)=>this.checkCoupon()}>
												<div className="bottom_title_small">APPLY</div>
											</div>
										</div>
									}
								</div>
							</div>
						</div>
					}
				</div>
				*/}
			</div>
			{this.state.showPop &&
				<MemberPopups
				state={this.state}
				updateStateFromChild={this.updateStateFromChild}
				addMemberPlan={this.addMemberPlan}
				cancelMembership={this.cancelMembership}
				checkMemberPlan={this.checkMemberPlan}
				deleteCreditCard={this.deleteCreditCard}
				openEditMembers={this.openEditMembers}
				setFamilyPlanType={this.setFamilyPlanType}
				setSinglePlanType={this.setSinglePlanType}
				openAddDefCreditCard={this.openAddDefCreditCard}
				></MemberPopups>
			}
			{this.state.showAddCC &&
				<div className="booked_pop_content">
					<MemberCardInfo
					state={this.state}
					updateStateFromChild={this.updateStateFromChild}
					closeAddCC={this.closeAddCC}
					addCreditCard={this.addCreditCard}
					></MemberCardInfo>	
				</div>	
			}
			{this.state.showFamilyMembers &&
				<div className="booked_pop_content">
					<FamilyPlanMembers
					state={this.state}
					closeEditMembers={this.closeEditMembers}
					changeMemberEmail={this.changeMemberEmail}
					saveEditMembers={this.saveEditMembers}
					clearEmailInput={this.clearEmailInput}
					></FamilyPlanMembers>
				</div>	
			}
			{this.state.showLaunchPop &&
				 <div className="full_popup">
					<ParticipantSignedUp
					state={this.state}
					updateStateFromChild={this.updateStateFromChild}
					openNativeUrl={this.openNativeUrl}
					urlElectron={this.state.urlElectron}
					urlPath={this.state.urlPath}
					></ParticipantSignedUp>
				</div>
			}
			{this.state.showPaymentHistory &&
				<div className="booked_pop_content">
					<MemberPaymentHistory
					state={this.state}
					updateStateFromChild={this.updateStateFromChild}
					></MemberPaymentHistory>	
				</div>	
			}
			{this.state.showSelectPlan &&
				<div className="booked_pop_content">
					<MemberPlans
						state={this.state}
						updateStateFromChild={this.updateStateFromChild}
						setPlanType={this.setPlanType}
						setPlanSelected={this.setPlanSelected}
					>
					</MemberPlans>
					<div className="coverAll" onClick={(e)=>this.closePopTry()}></div>
				</div>
			}
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
		</div>
	);
}

}

export default connect(
  null,
  { 
	showMainErrorPop
  }
)(MemberProfile);