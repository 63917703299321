import React, {Component} from 'react';
import { Router, Route, browserHistory } from 'react-router'

import { Provider } from "react-redux";

import Header from './components/home/Header';
import SessionCreation from './components/home/SessionCreation';
import Dashboard from "./components/home/Dashboard";
//import PDashboard from "./components/home/PDashboard";
import ParticipantDashboard from "./components/home/ParticipantDashboard";
import store from "./store";

import HostSessionCreation from './components/home/HostSessionCreation'
import verifyuser from "./components/auth/VerifyUser";
import ondemand from "./components/auth/ondemand";
import inviteUser from "./components/auth/inviteUser";
import inviteParticipant from "./components/auth/inviteParticipant";
// import inviteOnBoarding from "./components/auth/inviteOnBoarding";
import DashboardLanding from './components/home/DashboardLanding'
import hostSignUp from './components/auth/hostsignup'
import signUp from './components/auth/signup'
import participentSignup from './components/auth/participateSignUp'
import AdminDashboard from './components/home/AdminDashboard'
import NoAdmin from './components/home/NoAdmin'
import Login from './components/auth/Login'
import WebLogin from './components/auth/WebLogin'
import trainingPlans from './components/auth/trainingPlans'
import Forgotpassword from './components/auth/Forgotpassword'
import ResetPassword from './components/auth/ResetPassword'
import PrivateRoute from './components/helper/PrivateRoute'
import PrivacyPolicy from './components/home/PrivacyPolicy'
import UsePolicy from './components/home/UsePolicy'
import TermsofUse from './components/home/TermsofUse'
import ChannelCreation from './components/home/ChannelCreation'
import FileUpload from './components/home/fileUpload'
import UserPlaylists from './components/home/UserPlaylists'
import News from './components/home/News'
import SessionGuideline from './components/home/SessionGuideline'
import ComingSoon from './components/home/ComingSoon' 
import EditChannelCreation from './components/home/EditChannelCreation'
import verification from './components/home/verification'
import Form from './components/home/Form'
import Support from './components/home/Support'
import member from './components/home/member'
import SessionTemplates from './components/home/SessionTemplates'
import channelHosts from './components/home/channelHosts'
import channelInterests from './components/home/channelInterests'
import products from './components/home/products'
import Channel from './components/home/Channel'
import newsPage from './components/home/newsPage'
import newsMain from './components/home/newsMain'
import myProfile from './components/home/myProfile';
import ChannelSettings from './components/home/ChannelSettings';
import HostSettings from './components/home/HostSettings';
import ReportsDashboard from './components/home/ReportsDashboard';
import PopupError from './components/home/PopupError';
import FaqMain from './components/home/FaqMain';
import IdeaWorldVirtual from './components/home/IdeaWorldVirtual';
import ChannelSignUp from './components/home/ChannelSignUp';
import CustomerSupport from './components/home/CustomerSupport';
import Graphs from './components/home/Graphs';
import Reports from './components/home/Reports';
import Register from './components/home/Register';
import channelSubscription from './components/home/channelSubscription';
import SwagBagWelcome from './components/home/SwagBagWelcome';
import BrandAmbassador from './components/home/BrandAmbassador';
import BrandAmbassadorPTHub from './components/home/BrandAmbassadorPTHub';
import Verifylink from "./components/auth/Verifylink";
import CancelLink from "./components/auth/cancelLink";
import SessionReviews from "./components/home/SessionReviews";
import ParticipantSignedUp from './components/home/participantSignedUp';
import Access from './components/auth/Access';
import { checkCookie } from './util/cookie';
import { getCookie } from './util/cookie';
import ChannelNew from './components/home/ChannelNew';
import MemberRegister from './components/home/MemberRegister';
import MemberProfile from './components/home/MemberProfile';
import AdminReports from './components/home/AdminReports';


import uploadDocument from './components/home/uploadDocument';



var waitload = checkCookie();
var userIdCookie = getCookie(process.env.REACT_APP_COOKIE_ID);
window['user_loggedIn'] = ( localStorage.getItem('userData') && userIdCookie ) ? 1 : 0;
if ( !userIdCookie && localStorage.getItem('userData') )
	{
		localStorage.removeItem('userData');
	}

var mainS;
let channelName = window.location.host.split(".")[0];

var exclude = ['fb', 'fblive', 'fbapi', 
              'dev', 'devlive', 'devapi',
              'demo','demolive','demoapi',              
              'uat', 'uatlive', 'uatapi',
              'live','api', 'virdio',
              'rooms', 'ondemand', 'games', 'admin', 'fitness', 'wines', 'cooking',
              'homeimprovement', 'beauty', 'physicaltherapy', 'home',
			  'stag', 'staglive', 'stagapi', 'stagnew', 'localhost:3000',
			'livenew','livenewclient','livenewapi'
            ];
		
		
var isHomePage = 0;

if ( exclude.indexOf(channelName) < 0 && channelName )
	{
		if ( channelName.indexOf('-ba') > -1 && channelName.slice(-3) == '-ba' ) { mainS = BrandAmbassador; }
		else if ( channelName.indexOf('-signup') > -1 ) { mainS = ChannelSignUp; }
		else { mainS = ChannelNew; }
	}
else
	{
		mainS = Dashboard;
		isHomePage = 1;
	}

if ( window.location.search == '?closeTab=1' )
	{
		window.close();
		alert('Popup enabled successfuly. You can close this new window now and continue adding playlist in already selected one.');
	}

/*function App() {
  return (
    <div className="App">
      <Header />
    </div>
  );
}

export default App;*/


class App extends Component {
	/* constructor(props) {
		super(props);
		this.checkCookie = checkCookie.bind(this);
	} */
	
 render() {
	 if ( waitload ) { return ( <Provider store={store}></Provider>); }
	 
	 if ( isHomePage == 1 ) 
		{ 
			var splitedURL = window.location.href.split('/');
			if ( splitedURL[3] === '' )
				{
					var splitedMain = process.env.REACT_APP_PATH.split('//');
					var homepagePath = splitedMain[0] + '//home.' + splitedMain[1];
					window.location.href = homepagePath;
					return false; 
				}
		}
	
	if ( window.location && window.location.href && window.location.href.toLowerCase().indexOf('/memberregister?') > 0 && window.location.href.indexOf('las=1') > 0 )
		{
			if ( localStorage.getItem('hasOldLoginEmail') )
				{
					window.location.href = '/login';
					return false;
				}
		}
	 
   return (
   <Provider store={store}>
    <PopupError />
    <Router history = {browserHistory}>
     <div className="App">
     
      <Route exact path="/" component={mainS} />
	  <Route exact path="/myProfile" component={myProfile} />
	  <Route exact path="/ChannelSettings" component={ChannelSettings} />
	  <Route exact path="/HostSettings" component={HostSettings} />
	  <Route exact path="/ReportsDashboard" component={ReportsDashboard} />
      <Route exact path="/UserPlaylists" component={UserPlaylists} />
      <Route exact path="/FitnessSessionCreation" component={SessionCreation} />
		<Route exact path="/participant-dashboard" component={ParticipantDashboard} />
      <Route exact path="/ChannelCreation1" component={HostSessionCreation} />
      <Route path="/verify-user/:sessionId" component={verifyuser} />
      <Route path="/session/:sessionId" component={verifyuser} />
      <Route path="/class/:sessionId" component={verifyuser} />
      <Route path="/ondemand/:sessionId" component={ondemand} />
      <Route path="/invite-user/:emailId" component={inviteUser} />
      <Route path="/invite-participant/:emailId" component={inviteParticipant} />
      {/* <Route path="/invite-onBoarding/:userId" component={inviteOnBoarding} /> */}
      {/* <Route exact path="/HostSessionCreation" component={HostSessionCreation} /> */}
      <Route exact path="/DashboardLanding" component={DashboardLanding} />
      <Route exact path="/SessionTemplates" component={SessionTemplates} />
      <Route exact path="/hostSignUp" component={hostSignUp} />
      <Route exact path="/participantSignup" component={Register} />
      <Route exact path="/participantSignedUp" component={ParticipantSignedUp} />
      <Route exact path="/signUp" component={verification} />
      <Route  path="/fitnessdetail/:id" component={SessionCreation} />
      <Route  path="/sessionEditable/:id" component={SessionCreation} />
      <Route  path="/AdminDashboard" component={AdminDashboard} />
      <Route  path="/winedetail/:id" component={SessionCreation} />
      <Route  path="/noAdmin" component={NoAdmin} />
      <Route  path="/login" component={WebLogin} />
      <Route  path="/login/:resetEmail/:userId/:code" component={Login} />
      <Route  path="/trainingPlans" component={trainingPlans} />
      <Route  path="/forgotpassword" component={Forgotpassword} />
      <Route  path="/reset-password" component={ResetPassword} />
      <Route  path="/PrivacyPolicy" component={PrivacyPolicy} />
      <Route  path="/UsePolicy" component={UsePolicy} />
      <Route  path="/FileUpload" component={FileUpload} />
      <Route  path="/TermsofService" component={TermsofUse} />
      <Route exact path="/ChannelCreation" component={ChannelCreation} />
      <Route exact path="/News" component={News} />
      <Route exact path="/SessionGuideline" component={SessionGuideline} />
      <Route exact path="/ComingSoon" component={ComingSoon} />
      <Route exact path="/editChannel/:id" component={EditChannelCreation} />
      <Route  path="/accesscode" component={verification} />
      <Route  path="/newsletter" component={Form} />
      <Route  path="/virdiosupport" component={Support} />
      <Route  path="/member" component={member} />
      <Route  path="/channelHosts" component={channelHosts} />
      <Route  path="/channelInterests" component={channelInterests} />
      <Route  path="/products" component={products} />
	  <Route exact path="/newspage" component={newsPage} />
	  <Route exact path="/news/:newsName" component={newsMain} />
	  <Route exact path="/faq" component={FaqMain} />
	  <Route exact path="/ideaworldvirtual2020" component={IdeaWorldVirtual} />
	  <Route exact path="/support" component={CustomerSupport} />
	  <Route exact path="/graphs" component={Graphs} />
	  <Route path="/Reports" component={Reports} />
	  <Route path="/verify-link/:code" component={Verifylink} />
	  <Route path="/verify-link/:code/:code2" component={Verifylink} />
	  <Route path="/cancelLink/:code/:code2" component={CancelLink} />
	  <Route path="/Register" component={Register} />
	  <Route path="/channelSubscription" component={channelSubscription} />
	  <Route path="/swagbagwelcome" component={SwagBagWelcome} />
	  <Route path="/SessionReviews" component={SessionReviews} />
	  <Route path="/access" component={Access} />
	  <Route path="/MemberRegister" component={MemberRegister} />
	  <Route path="/MemberProfile" component={MemberProfile} />
	  <Route exact path="/AdminReports" component={AdminReports} />
	  
	  <Route exact path="/uploaddocument" component={uploadDocument} />
	</div>
     </Router>
	 </Provider>
   );
 }
}

export default App;
