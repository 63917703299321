import React, { Component } from "react";
import {  browserHistory} from 'react-router'
import DatePicker from "react-datepicker";
import { Link } from 'react-router';
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery';
import axios from "axios";
import moment from 'moment';
import { connect } from "react-redux";
import { setUserChannel } from "../../actions/authActions";
import SimpleReactValidator from 'simple-react-validator';
import Countdown from 'react-countdown-now';
import { CopyToClipboard } from "react-copy-to-clipboard";
import AddEditProduct from '../home/AddEditProduct';
import OnDemandSessions from '../home/OnDemandSessions';
import EditVideoCut from '../home/EditVideoCut';
import EditVideoCutImage from '../home/EditVideoCutImage';
import Calendar from 'react-calendar';
import { productFetchAttributesList } from '../../util/productFetchAttributesList';
import { productAddAttribute } from '../../util/productAddAttribute';
import { productSaveProductList } from '../../util/productSaveProductList';

import { showMainErrorPop } from '../../actions/popups';
import { updatesse } from '../../util/updatesse';
import ActivityList from '../home/activityList';

import { isChrome as chrome,isMobile as mobileTab,isMobileOnly as omobile,isTablet as tablet,isBrowser as browser,isSmartTV as smarttv,isWearable as wearable,isAndroid as android,isWinPhone as winphone,isIOS as ios,isFirefox as ff,isSafari as safari,isOpera as opera,isIE as ie,isEdge as edge,isYandex as yandex,isChromium as chromium,isMobileSafari as mobsafari,osVersion as version,osName as osname,fullBrowserVersion as fbrowserversion,browserVersion as browserVer,browserName as browserName,mobileVendor as mobvendor,mobileModel as mobmodel,engineName as engName,engineVersion as engversion,getUA as getua,deviceType as deviceType,isIOS13 as ios13,isIPhone13 as iphone13,isIPad13 as ipad13,isIPod13 as ipod13,isElectron as isElectron,deviceDetect as devicedetect} from "react-device-detect";

import MainTutorial from '../home/MainTutorial';
import MembersList from '../home/MembersList';
import classnames from "classnames";

import customProtocolCheck from "custom-protocol-check";
import { setCookie } from '../../util/cookie';
import { getCookie } from '../../util/cookie';
import { deleteCookie } from '../auth/Logout';

import { joinSessionValidate } from '../../util/joinSessionValidate';
import ParticipantDashboardUpcomingSessions from '../home/ParticipantDashboardUpcomingSessions';
import PastSessionsParticipant from '../home/PastSessionsParticipant';
import SupportContainer from '../home/SupportContainer';
import ParticipantSignedUp from '../home/participantSignedUp';
import ReviewClassesContainer from '../home/ReviewClassesContainer';

import AWS from 'aws-sdk';

import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';
import { userPermissions } from '../../util/userPermissions';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY
});


class DashboardLanding extends Component {
   constructor(props) {
	super(props);
	
	if ( !localStorage.getItem('userData') )
		{
			window['user_loggedIn'] = 0;
			var adminpath = process.env.REACT_APP_PATH+'/login';
			window.location.href = adminpath;
			return false;
		}
	
	this.state={
		startDate:new Date(),
		imageName:'',
		file:"",
		imagePreviewUrl: "/images/browse-image.png",
		daysOfMonth:[],
		upcomingSession:[],
		sessionInformation:'',
		// dash_land_prop:true,
		// emailer:'select email',
		channelPopup:0,
		participientPopup:0,
		sessionData:[],
		upcomingSessionArray:[],
		sessionData2:[],
		channelData:[],
		checkHost:'',
		customRadio2:false,
		customRadio1:true,
		customRollRadio1:true,
		customRollRadio2:false,
		toggleDropDown:false,
		mail:'',
		hostMail:[{mail:''},{mail:'ak@gmail.com'},{mail:'ak1@gmail.com'}],
		customRadio3:false,
		customRadio4:true,
		customCheck1:false,
		searchContainer:false,
		exampleFormControlSelect1:'',
		sessionType:'',
		sessionTypeRedirectTo:'/DashboardLanding',
		loginName:'',
		userAvatar:'',
		totalSession:'',
		totalSessionAll: 0,
		pastSessionData:'',
		lastestSessionData:[],
		latestUpComingSession:'',
		pastlatestSessionData:'',
		channelInterestSelection:[],
		inviteHostFirtsName:'',
		inviteHostLastName:'',
		inviteHostEmail:'',
		inviteHostChannelId:'',
		inviteHostAdminId:'',
		hostEmailData:[],
		responseMessage:'',
		sessionType:'Upcoming',
		urlLink:'',
		upComingScheduleDate:'',
		week:'Week 11',
		errorClass_NewEmail:false,
		errorClass_NewLastName:false,
		errorClass_NewFirstName:false,
		errorClass_MemberFirstName:false,
		errorClass_MemberLastName:false,
		errorClass_MemberEmail:false,
		lastUpcomingDate:'',
		dateForUpcomingSession:[],
		totalUpcomingSession:'',
		currentDay:'',
		currentMonth:'',
		CurrentUrl:'',
		isTutorialRead:'',
		loginId:'',
		sessionCurrentWeek:[],
		participantList:[],
		updateForSession:'',
		cancelSessionId:'',
		cancelReccuringId: 0,
		cancelSessionDate:'',
		memeberData:[],
		firstName:'',
		lastName:'',
		email:'',
		dateOfNextSession:'',
		currentWeekData:[],
		urlPath:'',
		urlPathMain:'',
		urlElectron:'',
		urlElectronMain:'',
		appDownload: '',
		sessionInterest:'',
		platformStatus:true,
		recurringSessionData:[],
		buttonText:'Copy Url',
		buttonText2:'Copy Url',
		intervalID:'',
		addProduct:[],
		//////////Add Product //////////////////
		productInformation:[],
		addAttribute:[],
		productCount:0,
		shoppingProductName:'',
		productStatus:false,
		subdomain: '',
		fullsubdomain: '',
		fullsubdomainbrand: '',
		fullsubdomaininvite: '',
		hasAmbassador: '',
		fullsubpath: '',
		existSubdomain: false,
		emptySubdomain: false,
		wineProduct:[],
		productAdd: true,
		productEdit: false,
		chosenTutorialIndex: 0,
		showTutorial: false,
		onDemandName: '',
		onDemandLevel: '',
		onDemandMemberCharges: 0,
		onDemandNonMemberCharges: 0,
		onDemandDescription: '',
		onDemandGoal: '',
		onDemandInterest: [],
		onDemandEquipment: '',
		autoDeleteAfter: '',
		onDemandImage: '',
		onDemandImageName: '',
		onDemandVideo: '',
		onDemandVideoName: '',
		onDemandVideoSavePath: '',
		errorSessionOnDemand: false,
		openAddDemand: false,
		uploadError: 'Something went wrong. Please check your Internet connection and try again.',
		mainDemandObject: {},
		editOnDemandVideo: {},
		openEditVideo: false,
		openEditVideoImage: false,
		demandPreview: 'square',
		isDashboard: true,
		demandToDelete: 0,
		demandToEdit: 0,
		gridDemandView: false,
		hasGridView: false,
		shortWineProduct: [],
		mainUserData: {},
		completedDefaultSession: 'yes',
		errorDemandName: false,
		errorDemandLevel: false,
		errorDemandMembersCharges: false,
		errorDemandNonMembersCharges: false,
		errorDemandImage: false,
		errorDemandDescription: false,
		errorDemandVideo: false,
		errorDemandGoal: false,
		errorDemandInterest: false,
		errorDemandEquipment: false,
		errorDemandAutoDelete: false,
		sortedByHost: false,
		sortedBySession: false,
		sortedByLevel: false,
		sortedByinterest: false,
		sortedByDuration: false,
		sortedByEquipment: false,
		page_count: 1,
		showLoadMore: false,
		shownLength: 0,
		dataLength: 0,
		hasOnDemandFiles: {'image': '', 'video': ''},
		todayDateDay: new Date().toISOString().substr(0, 10),
		upcomingSessionLength: 0,
		pastSessionLength: 0,
		ondemandSessionLength: 0,
		invsub_heading: '',
		invsub_text: '',
		mainPlans: [],
		chosenPlanId: 0,
		sessionPreview: 'square',
		sessionPublishPage: true,
		ondemandopentitle: '',
		hostsArray: [],
		hostInterestArray: [],
		filterLevel: '',
		filterHost: '',
		filterIntrest: '',
		expandSearchStatus: false,
		showloaderfromnative: false,
		showWindowsApp: true,
		buttonsColorPick: '#bd00ff',
		searchSessionName: '',
		mainFaqs: [],
		choosenSessionId : '',
		choosenSessionLink: '',
		choosenSessionSpotsLeft: 0,
		mebersInChoosenSession: [],
		pastSessionPreview: 'square',
		upcomingSessionPreview: 'square',
		expandNewIntrest: false,
		newInstrestSelected: [],
		newInstrestSelectedDemand: [],
		buttonsColorPick: '#bd00ff',
		selectedinterestsNames: '',
		selectedinterestsNamesArray: [],
		selectedinterestsNamesDemand: '',
		selectedinterestsNamesArrayDemand: [],
		channelInterestSelectionNew: [],
		searchValue: '',
		deepLinkButton: 'Web App',
		userTypeData: 0,
		isAdmin: 0,
		isAdminReports: 0,
		userFirstName: '',
		fullName: '',
		channelName: '',
		sessionPrivateMemberOnly: false,
		manualPayment: 0,
		expandChangeHost: false,
		onDemandHostId: 0,
		onDemandHostName: '',
		copyLink_state: 'session',
		defaultJoinTime: 15,
		filterEquipment: '',
		filterDuration: '',
		caseForSort: '',
		applySort: false,
		sortedDateRecent: false,
		sortedDateOlder: false,
		searchShowTime: '',
		sessionCalender: new Date(),
		searchStartTime: '',
		pickedDate: '',
		pickedStartDate: '',
		pickedEndDate: '',
		fullStartDate: '',
		fullEndDate: '',
		searchedBy: '',
		noFoundSearch: false,
		showExsistSubdomainStatus : false,
		countMaximum: false,
		onDemandToOpen: 0,
		participantToRemove: {},
		reasonEject: '',
		reasonError: false,
		sessionEject: 0,
		excludeSubdomain: ['fb', 'fblive', 'fbapi', 
							  'dev', 'devlive', 'devapi',
							  'demo','demolive','demoapi',              
							  'uat', 'uatlive', 'uatapi',
							  'live','api', 'virdio',
							  'rooms', 'ondemand', 'games', 'admin', 'fitness', 'wines', 'cooking',
							  'homeimprovement', 'beauty', 'physicaltherapy', 'home',
							  'stag', 'staglive', 'stagapi', 'stagnew',
							  'livenew','livenewclient','livenewapi'
							],
		upcomingSessionNumber: 0,
		isHostDashboard: 1,
		individualSession: {"activityArray": []},
		pastSessionsCounter: 0,
		currentShownPast: 0,
		weekFoward: 0,
		calendarStartTime: 0,
		sessionToEdit: 0,
		sessionHostEdit: 0,
		sessionHostEditName: '',
		pendingHostSeconds: 30,
		editSessionStatus: 'pending',
		isSessionRecurring: 0,
		reviewLength: 0,
		showReview: false,
		reviewHosts: [],
		skipedCalendly: false,
		channelWaitingListOn: false,
		supportPageName: 'Dashboard',
		supportPageApi: '/dashboardLanding',
		userTypeFaqs: 2,
		remId: '',
		remIndex: '',
		userSignedUp: false,
		loggedUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
		sessionStartTimeHigher15Min: false,
		sessionScheduleDate: '',
		sessionTimezone: '',
		sessionChannelName: '',
		isHost: true,
		sessionName: '',
		isOnDemandSession: false,
		ODCheckInterval: 0,
		ODCheckCorrection: 0,
		isFromCancel: false,
		onDemandInterestSubcategories: [],
		expandNewIntrestSubcategories: false,
		channelInterestSubcategoriesSelection: [],
		channelInterestSubcategories: [],
		onDemandInterestSubcategoriesNamesArray: [],
		selectedinterestsSubcategoriesNames: '',
		isChannelMarketPlace: false,
		expandLiveIntrestSubcategories: false,
		liveInterestSubcategories: [],
		channelLiveSubcategoriesSelection: [],
		liveInterestSubcategoriesNamesArray: [],
		liveSelectedInterestsSubcategoriesNames: '',
		isMarketplaceChannel: false,
		reviewCount: 0,
		isOpenReviewClasses: false,
		userPermissions: {}
	}
	this.validator = new SimpleReactValidator();
	this.memeberValidator = new SimpleReactValidator();

	this.fetchAttributesList = productFetchAttributesList.bind(this);
	this.addAttribute = productAddAttribute.bind(this);
	this.saveProductList = productSaveProductList.bind(this);

	this.updatesse = updatesse.bind(this);
	this.setCookie = setCookie.bind(this);
	this.getCookie = getCookie.bind(this);
	this.deleteCookie = deleteCookie.bind(this);
	this.joinSessionValidate = joinSessionValidate.bind(this);
	
	window['reloadDashTimer'] = {};
	
	this.getSessionDateUtil = getSessionDate.bind(this);
	this.getSessionTimeUtil = getSessionTime.bind(this);
	this.userPermissions = userPermissions.bind(this);
	
}
currentUrlMethod=(e)=>{
	//alert(process.env.REACT_APP_URL)
	// if(this.state.sessionData.length>0){
	// this.setState({
	// 	CurrentUrl:process.env.REACT_APP_URL
	// })
	// }else{
	// 	this.setState({
	// 		CurrentUrl:process.env.REACT_APP_URL
	// 	})	
	// }
	
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	 axios.defaults.headers.common['Authorization'] = token;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/host/updateDefaultSessionTime")
	.then(res => {
		console.log(res)
		this.setState({
			CurrentUrl:this.state.urlPath
		},()=>{
			if(!this.state.platformStatus){
			$("#streaming_option").attr({'style':'display:block'})
			}else{

			}
		})
	}).catch(err =>{
		console.log('----------there is problem------------',err);
		});   
}

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }

	reader.readAsDataURL(file)

	const data = new FormData() 
		data.append('file', e.target.files[0]);
		// console.log('----------------------',data);
		// this.setState({
		//     imageName:event.target.files[0]
		// },()=>{console.log('=========================',this.state.imageName)})
		const types = ['image/png', 'image/jpeg', 'image/gif']
		if (types.every(type => e.target.files[0].type !== type)) {
			// create error message and assign to container   
			//alert(' is not a supported format\n');
		//	$("#cancel_session").attr({'style':'display:block'});
	
		  }else{
		axios.post(process.env.REACT_APP_NAME+"/api/v1/upload/uploadProductImage", data)
		.then(res => {
			console.log('dsddsd===================>',res.data.responseData.file)
			this.setState({
			 imageName:res.data.responseData.file
			 },()=>console.log("====",this.state.imageName))
		}).catch(err =>{
			console.log(err);
			
		})
	}

	
  }
 tutorialRead=(e)=>{
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	axios.get(process.env.REACT_APP_NAME+"/api/v1/session/host/updateIsTutorialRead")
	.then(res => {
		if(res.data.responseData==="updated successfully"){
		$("#go_Dashboard").attr({'style':'display:none'})
		$("#pick_channel").attr({'style':'display:block'});

		}else{
			$("#go_Dashboard").attr({'style':'display:none'})
		}
	}).catch(err =>{
		console.log('----------there is problem------------',err);
		});
 }

checkHost1=(e)=>{
	console.log(e.target)
	this.setState({
		[e.target.id]:!this.state.customRadio1,
		customRadio2:!this.state.customRadio2,
		inviteHostFirtsName:'',
		inviteHostLastName:'',
		inviteHostEmail:'',
		responseMessage:''
		},()=>console.log(this.state.customRadio1))
}

updateStateFromChild=(newState, mod) => {
	this.setState(newState);
	if ( mod )
		{
			this.fetchAttributesList();
			$("#product_lst_modal").attr({'style':'display:none'});
			this.getWineProductList();
		}
}
checkHost2=(e)=>{
	console.log(e.target)
	this.setState({
		[e.target.id]:!this.state.customRadio2,
		customRadio1:!this.state.customRadio1,
		inviteHostFirtsName:this.state.hostMail[0].firstName,
		inviteHostLastName:this.state.hostMail[0].lastName,
		inviteHostEmail:this.state.hostMail[0].email,
		responseMessage:''
		},()=>console.log(this.state.customRadio2))
}

checkRoll1=(e)=>{
	console.log(e.target)
	this.setState({
		[e.target.id]:!this.state.customRollRadio1,
		customRollRadio2:!this.state.customRollRadio2
		},()=>console.log(this.state.customRollRadio1))
}
checkRoll2=(e)=>{
	console.log(e.target)
	this.setState({
		[e.target.id]:!this.state.customRollRadio2,
		customRollRadio1:!this.state.customRollRadio1
		},()=>console.log(this.state.customRollRadio2))
}

// add participient

checkHost3=(e)=>{
	console.log('2',e.target)
	this.setState({
		[e.target.id]:!this.state.customRadio3,
		customRadio4:!this.state.customRadio4
		},()=>console.log(this.state.customRadio3))
}
checkHost4=(e)=>{
	console.log('1',e.target)
	this.setState({
		[e.target.id]:!this.state.customRadio4,
		customRadio3:!this.state.customRadio3
		},()=>console.log(this.state.customRadio4))
}
copyClick = () => {
  
	let buttonText = this.state.buttonText == "Copy Url" ? "Copied" :'Copy Url'      
	this.setState({buttonText: buttonText})
  }
  
copyClick2 = () => {
	let buttonText = this.state.buttonText2 == "Copy Url" ? "Copied" :'Copy Url'
	this.setState({buttonText2: buttonText})
  }
// shouldComponentUpdate(nextProps, nextState){
// 	console.log('=============================');
// 	return true
// }




checkTokenFunction = () => {
	
	/* Check Token Expired */
	let user_data = JSON.parse(localStorage.getItem("userData"));
	if ( user_data && user_data['data'] && user_data['data']['responseData'] && user_data['data']['responseData']['tokenExpire'] )
		{
			let expireTime = (user_data['data']['responseData']['tokenExpire'] - (new Date().getTime()));
			
			console.log(expireTime);
			
			if ( expireTime > 0 )
				{
					if ( window['expiretimer'] ) { clearTimeout(window['expiretimer']); }
					window['expiretimer'] = setTimeout(function ()
						{
							localStorage.removeItem("userData");
							$('#notloggedin').show();
						}, expireTime);
				}
			else
				{
					localStorage.removeItem("userData");
					$('#notloggedin').show();
				}
		}
	/* Check Token Expired */
}




componentDidMount(){
	if ( JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace && JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace == 1 )
		{
			this.setState({isMarketplaceChannel: true});
			this.getReviewCount();
			var permissions = this.userPermissions(JSON.parse(localStorage.getItem('userData')).data.responseData.channelRole);
			this.setState({userPermissions: permissions});
		}		
	
	var startOfWeek = moment().startOf('isoweek').toDate(); 
	var endOfWeek   = moment().endOf('isoweek').toDate();
	
	let startCalendar = new Date (startOfWeek).getTime() / 1000;
	this.setState({
		calendarStartTime: startCalendar
	});	
		
	//this.checkDefaultSession();
	this.checkTokenFunction();
	
	var mainthis = this;
	this.getHosts();
	var userIdCookie = this.getCookie(process.env.REACT_APP_COOKIE_ID);
	window['user_loggedIn'] = ( localStorage.getItem('userData') && userIdCookie ) ? 1 : 0;
	
	this.setState({ 
		userAvatar: JSON.parse(localStorage.getItem('userData')).data.responseData.image, 
		loginName: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName,
		isAdminReports: JSON.parse(localStorage.getItem('userData')).data.responseData.adminReports,
		});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/insertChannelSettings",{"channel_id": JSON.parse(localStorage.getItem('userData')).data.responseData.channel})
	.then(res => {
		this.setState({manualPayment: res.data.responseData.manualPayment}, ()=>{console.log(this.state.manualPayment, 'MANUAL')});
	}).catch(err =>{ });
	
	if (localStorage['upcomingSessionPreview'] )
		{
			this.setState({
				sessionPreview : localStorage['upcomingSessionPreview'],
				upcomingSessionPreview : localStorage['upcomingSessionPreview']
			});
		}
	if ( localStorage['pastSessionPreview'] )
		{
			this.setState({
				pastSessionPreview : localStorage['pastSessionPreview']
			});
		}
	if ( localStorage['demandPreview'] )
		{
			this.setState({
				demandPreview : localStorage['demandPreview']
			});
		}		
		
	
	// $("#dash_land_block :input").attr("disabled", true);	
	//console.log(this.props);
	console.log("OS NAME-->>",osname,"VERSION==>",version,"IPAD13>>>",ipad13,"TABLET-->>",tablet,"deviceType-->>>>",deviceType,"BroserName=>",browserName);
	
	window.scrollTo(0, 0);
	//var current = new Date(2020, 1, 27)
	var current = new Date()
	var week = [];
	var first ;
	if(current.getDay()===0){
		first = current.getDate() - 7 + 1;
	}else{
		first = current.getDate() - current.getDay() + 1;
	}
	
	console.log(/\((.*)\)/.exec(new Date().toString())[1],Intl.DateTimeFormat().resolvedOptions().timeZone);
	// SINGLE SIGN Branch
	
	//console.log(current.getDate(),'first',first,current.getDay());
	current.setDate(first);
	
	
	
	for (var i = 0; i < 7; i++) {
		week.push(new Date(+current).toISOString());
		current.setDate(current.getDate()+1);
		
	}
	let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
	let getMonth1=(new Date(week[6]).getMonth());
	let getDay1=new Date(week[6]).getDate();
	this.setState({
		week:'Week of ' + monthArray[new Date(week[0]).getMonth()] +' '+ new Date(week[0]).getDate(),
		currentDay:getDay1,
		currentMonth:getMonth1
	})

	if(localStorage.getItem('userData')){
		//console.log(this.props.location)
		let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
		let loginId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
		let ak = JSON.parse(localStorage.getItem('userData'));
		
		if ( JSON.parse(localStorage.getItem('userData')) && JSON.parse(localStorage.getItem('userData')).data.responseData.settings )
			{
				if ( parseInt(JSON.parse(localStorage.getItem('userData')).data.responseData.settings.showNativeApplications) === 0 )
					{
						this.setState({showWindowsApp: false});
					}
			}
		
		let userFirstName = JSON.parse(localStorage.getItem('userData')).data.responseData.firstName;
		let userLastName = JSON.parse(localStorage.getItem('userData')).data.responseData.lastName;
		let userFullName = userFirstName + ' ' + userLastName;
		
		this.setState({
						mainUserData: JSON.parse(localStorage.getItem('userData')).data.responseData,
						userFirstName: userFirstName, 
						fullName: userFullName
					})
		//console.log(new Date ('2020-1-24 04:03:00').toUTCString(),new Date('2020-1-24 04:03:00').getUTCDate(),new Date('2020-1-24 04:03:00').getUTCFullYear(),new Date('2020-1-24 04:03:00').getUTCMonth(),new Date('2020-1-24 04:03:00').getUTCHours(),new Date('2020-1-24 04:03:00').getUTCMinutes(),new Date('2020-1-24 04:03:00').getUTCSeconds(),"-------------",new Date('2020-1-25 04:00:00').toUTCString())
		
		let startOfWeekIso = moment().startOf('isoWeek').toDate();
		let endOfWeekIso = moment().endOf('isoWeek').toDate();
		
		const userData ={
			email : ak.data.responseData.email,
			type:ak.data.responseData.type,
			id:ak.data.responseData.id,
			filterLevel: this.state.filterLevel,
			filterHost: this.state.filterHost,
			filterIntrest: this.state.filterIntrest,
			filterEquipment: this.state.filterEquipment,
			filterDuration: this.state.filterDuration,
			startDate: this.state.fullStartDate,
			endDate: this.state.fullEndDate,
			searchSessionName: this.state.searchSessionName,
			sessionDate:'',
			startOfWeek: startOfWeekIso,
			endOfWeek: endOfWeekIso
			}
			// SINGLE SIGN IN Branch
			if(osname=="Windows" || osname=="Linux" || osname=="Ubuntu" || osname=="Mac OS"){
				if((ff==true) && (parseFloat(fbrowserversion) < 72)){
					this.setState({
						platformStatus:false
					})
				}
				  if((chrome==true) && (parseFloat(fbrowserversion) < 79)){
					this.setState({
						platformStatus:false
					})
				  }
				  if((edge==true) && (parseFloat(fbrowserversion) < 78)){
					this.setState({
						platformStatus:false
					})
				  }
			  }
			  
			  var checkSessionValidate = this.joinSessionValidate(true, ak.data.responseData.id, ak.data.responseData.token, null);
			  this.setState({ 
					urlPath: checkSessionValidate.urlPathVar,
					urlPathMain: checkSessionValidate.urlPathVar,
					urlElectron: checkSessionValidate.urlElectronVar,
					urlElectronMain: checkSessionValidate.urlElectronVar,
					deepLinkButton: checkSessionValidate.deepLinkButton
			  });

		axios.defaults.headers.common['Authorization'] = token;
		console.log('529');
		
		if ( JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace && JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace == 1 )
			{
				userData['isMarketplace'] = 1;
			}
		
		axios.post(process.env.REACT_APP_NAME+"/api/v1/user/hostDashboardData",userData)
		.then(res => {
			console.log('---------dashboardata--------',res);
		$('.main_loader').hide();		
		if(res.data.responseMessage === "success"){
			 
			 this.setState({
				upcomingSessionLength: res.data.responseData.sessionData,
				upcomingSessionNumber: res.data.responseData.sessionData.length,
			 });
			 
			 // Refresh token
			if ( res.data.responseData.tokenData )
			{
				var userData = JSON.parse(localStorage.getItem('userData'));
				userData.data.responseData['token'] = res.data.responseData.tokenData[0];
				userData.data.responseData['tokenExpire'] = res.data.responseData.tokenData[1];
				
				this.setCookie(process.env.REACT_APP_COOKIE_ID, userData.data.responseData['id'], userData.data.responseData['tokenExpire']);
				this.setCookie(process.env.REACT_APP_COOKIE_TOKEN, userData.data.responseData['token'], userData.data.responseData['tokenExpire']);
				
				localStorage.setItem('userData', JSON.stringify(userData));
				
				this.checkTokenFunction();
				
				console.log('Update token ----', res.data.responseData.tokenData);
			}
			 // Refresh token
			 
			 
			 
			 
			 
			let awk=[];
			let scheduleDate;
			if(res.data.responseData.sessionData.length===0){
			for(let j=0;j<week.length;j++){
				awk.push({
				name:"No Data",
				hostFirstName:'',
				hostLastName:'',
				chargeForSession:0,
				scheduleDate:week[j]
				})	
				
			}
			scheduleDate = awk[6].scheduleDate
			this.setState({
				upcomingSessionArray:[],
				sessionData2:awk,
				currentWeekData:awk,//array of current week but for previous arrow
				//lastestSessionData:res.data.responseData.sessionData,
				sessionCurrentWeek:awk,
				totalSession:0,
				totalUpcomingSession:0,
				latestUpComingSession:[],
	
				//sessionType:'Upcoming',
				upComingScheduleDate:scheduleDate,
				updateForSession:"refreshThisWeek",
				dateOfNextSession:!res.data.responseData.sessionInNextWeek?'':res.data.responseData.sessionInNextWeek,
				// intervalID:setInterval(() => {
				// 	this.setStartDate(this.state.upComingScheduleDate);
				//   }, 10000)
			});
			
			}else{
			scheduleDate =res.data.responseData.sessionData[res.data.responseData.sessionData.length-1].scheduleDate;
			let isTutorialRead;
			for(let j=0;j<week.length;j++){
				let day=new Date(week[j]).getDate();
			for(let i=0;i<res.data.responseData.sessionData.length;i++){
				
				var nextChange = new Date(res.data.responseData.sessionData[i].scheduleDate).getTime() + ( res.data.responseData.sessionData[i].duration * 60 * 1000 );
				nextChange = nextChange - (new Date()).getTime() + 5000;
				if ( nextChange > 1000 )
						{
							if ( window['reloadDashTimer'][res.data.responseData.sessionData[i].id] ) { clearTimeout(window['reloadDashTimer'][res.data.responseData.sessionData[i].id]); }
							window['reloadDashTimer'][res.data.responseData.sessionData[i].id] = setTimeout(function ()
								{
									var url = window.location.href;
									if ( url.toLowerCase().indexOf('/dashboardlanding') < 0 ) { return false; }
									
									if ( $('.ondemandmenu').hasClass('active') )
										{
											mainthis.getOnDemandsSessions();
										}
									else if ( $('.pastsesmenu').hasClass('active') )
										{
											mainthis.pastSessionInfo();
										}
									else
										{
											mainthis.refreshThisWeek();
										}
								}, nextChange);
						}
				
				
				
				let day2=new Date(res.data.responseData.sessionData[i].scheduleDate).getDate();
				if(day===day2){
				day=0;	
				awk.push(res.data.responseData.sessionData[i]);
				}
				if(loginId===res.data.responseData.sessionData[i].userId && res.data.responseData.sessionData[i].isTutorialRead >0){
					isTutorialRead=res.data.responseData.sessionData[i].isTutorialRead;
				}

			}
			if(day===0){

			}else{
			awk.push({
				name:"No Data",
				hostFirstName:'',
				hostLastName:'',
				chargeForSession:0,
				scheduleDate:week[j]
				})	
			}
		}
		scheduleDate = awk[6].scheduleDate
			this.setState({
			upcomingSessionArray:res.data.responseData.sessionData,
			isTutorialRead:isTutorialRead,
			sessionData2:awk,
			lastestSessionData:res.data.responseData.sessionData,
			totalSession:res.data.responseData.sessionData.length,
			latestUpComingSession:res.data.responseData.sessionData,
			//sessionType:'Upcoming',
			upComingScheduleDate:scheduleDate,
			loginName: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName,
			userAvatar: JSON.parse(localStorage.getItem('userData')).data.responseData.image,
			loginId:JSON.parse(localStorage.getItem('userData')).data.responseData.id,
			lastUpcomingDate:scheduleDate,
			dateForUpcomingSession:awk,
			currentWeekData:awk,//array of current week but for previous arrow
			sessionCurrentWeek:awk,
			totalUpcomingSession:res.data.responseData.sessionData.length,
			updateForSession:"refreshThisWeek",
			intervalID:setTimeout(() => {
				this.setStartDate(this.state.upComingScheduleDate);
			  }, 10000)
		});
		
		console.log(this.state.userAvatar, 12212121212121);
		
			this.props.setUserChannel(false, false, res.data.responseData.sessionData.length);
		}
		}else{
		 console.log('=============There Is an Error===================>'); 
		}
		}).catch(err =>{
		console.log('----------there is problem------------',err);
		});
		
		this.pastSessionInfo();
		this.ChannelData();
		this.memeberData();
		this.hostEmailData();
		this.fetchAttributesList();
		this.getReviewNumber();
		
		let groupId = localStorage.getItem('groupId')?localStorage.getItem('groupId'):2;
		if ( groupId == 2 ) { this.getWineProductList(); }
		
		this.getOnDemandsSessions();
		
		if ( JSON.parse(localStorage.getItem('userData')).data.responseData.settings )
			{
				if ( parseInt(JSON.parse(localStorage.getItem('userData')).data.responseData.settings.streamJoiningDefaultTime) > 0 )
					{
						this.setState({defaultJoinTime: parseInt(JSON.parse(localStorage.getItem('userData')).data.responseData.settings.streamJoiningDefaultTime)});
					}
			}
		
		
		}else{
	  browserHistory.push("/login");
  	}
	
	var mainthis = this;
	
	let currentNumber = localStorage.getItem("cardNumber");
	let currentType = localStorage.getItem("cardtype");
	let confNumber = localStorage.getItem('confirmation_number');
	let fullCardInfo = currentType+' ***'+currentNumber;
	
	let postdata = { 'id': JSON.parse(localStorage.getItem('userData')).data.responseData.id};
	let postdataplan = { 'id': JSON.parse(localStorage.getItem('userData')).data.responseData.channel};
    let card_type;
    let card_number;
    let fullCardInfoMain;
	
	if ( JSON.parse(localStorage.getItem('userData')).data.responseData.dashTutorial == 0 )
		{
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkDashboardTutorial", postdata)
			.then(res => {
				if ( res.data.responseData.dashTutorial === 0 )
					{
						$('body').attr({'style':'overflow: hidden'});
						mainthis.setState({
							showTutorial: true
						});
					}
				else
					{
						//get channel Owner and check for channel subscription plan only if Host is channel Owner
						
						let userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
						let channelId = JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
						
						if ( channelId && channelId > 0 )
							{
								axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getChannelOwner", postdataplan)
								.then(res => {
									var ownerId = res.data.responseData[0].userId;
									let channelName = res.data.responseData[0].name;
									if ( userId === ownerId )
										{
											axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkPendingPayments", {"channelId": channelId}).then(res => {
												if ( res.data.responseData && res.data.responseData === 1 ) 
													{ 
														$('#plannotfound').attr({'style':'display:block'}); 
													}
												else
													{
														this.checkWaitingListSettings(true);
													}
											}).catch(err =>{ });
										}
								}).catch(err =>{ });
							}
					}
			}).catch(err =>{ });
		}
	else 
		{
			//get channel Owner and check for channel subscription plan only if Host is channel Owner
						
			let userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
			let channelId = JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
			
			if ( channelId && channelId > 0 )
				{
					axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getChannelOwner", postdataplan)
					.then(res => {
						var ownerId = res.data.responseData[0].userId;
						let channelName = res.data.responseData[0].name;
						if ( userId === ownerId )
							{
								axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkPendingPayments", {"channelId": channelId}).then(res => {
									if ( res.data.responseData && res.data.responseData === 1 ) 
										{ 
											$('#plannotfound').attr({'style':'display:block'}); 
										}
									else
										{
											this.checkWaitingListSettings(true);
										}
								}).catch(err =>{ });
							}
					}).catch(err =>{ });
				}
		}
	window.addEventListener(
	  'message',
	  (e)=>{
		if (this.isCalendlyEvent(e)) {
		  console.log('e.data');
		  console.log(e.data);
		  if ( e.data && e.data.payload && e.data.payload.event && e.data.payload.event.uri)
			{
				this.closeCalendly('close');
				this.saveCalendlyEvent(e.data.payload.event.uri);
			}
		}
	  }
	);
	
	var userData = JSON.parse(localStorage.getItem('userData'));
	if ( userData.data.responseData.type ) { userData.data.responseData.type = 1 };
	localStorage.setItem("userData", JSON.stringify(userData));
	
	 window['checkConnection'] = setInterval(function(){ mainthis.checkConnection(); }, 5000);
	 
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/changeUserType", {'userId': JSON.parse(localStorage.getItem('userData')).data.responseData.id, 'type': 1}).then(res => {
		var userData = JSON.parse(localStorage.getItem('userData'));
		userData.data.responseData.type = 1;
		localStorage.setItem('userData', JSON.stringify(userData));
	}).catch(err =>{ });
	
	if ( window.location.hash == '#classManagement' ) { this.openReview(); }

  }
  
   componentWillUnmount(){
	  clearInterval(window['checkConnection']);
  }
  
  getReviewCount=()=>{
		var postData = { channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel, 
						channelRole: JSON.parse(localStorage.getItem('userData')).data.responseData.channelRole, 
						userId: JSON.parse(localStorage.getItem('userData')).data.responseData.id,
					};
		axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getReviewCount", postData).then(res => {
			this.setState({reviewCount: res.data.responseData});
		}).catch(err =>{ }); 
  }
  
  isCalendlyEvent=e=>{
	  return e.data.event &&
			 e.data.event.indexOf('calendly') === 0;
	};
	
	saveCalendlyEvent=(mod)=>{
		var postData = {userId: JSON.parse(localStorage.getItem('userData')).data.responseData.id, eventLink: mod, channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel};

			$('.main_loader').attr({'style':'display:flex'});
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/saveCalendlyEvent", postData).then(res => {
				$('.main_loader').hide();
				this.checkForPlanAgain();
			}).catch(err =>{ $('.main_loader').hide(); }); 
	}
	
	checkConnection=()=>{
		if ( !window.navigator.onLine ) 
			{ 
				var mainthis = this;
				$('#connection_lost').attr({'style':'display:block'});
				setTimeout(function(){ 
					clearInterval(window['checkConnection']);
					$('#connection_lost').attr({'style':'display:none'});
					localStorage.removeItem('userData');
					localStorage.removeItem('channelId');
					localStorage.removeItem('groupId');
					localStorage.removeItem('channelInterest'); 
					mainthis.deleteCookie(process.env.REACT_APP_COOKIE_ID); 
					mainthis.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); 
					window['user_loggedIn'] = 0; 
					browserHistory.push("/");
				}, 3000);
			}
	}
  
  
  participantShowList=e=>{
	  //< (new Date(row.sessionEndDate.toString()).getTime() + (1000 * 60 * 15))
	  
	let a= e.target.name;
	  	 let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
		 axios.defaults.headers.common['Authorization'] = token;
		 axios.get(process.env.REACT_APP_NAME+"/api/v1/user/"+a+"/getAllSignupParticipant")
		 .then(res => {
			 console.log(res);
			this.setState({
				//currentSessionId:a,
				participantList:res.data.responseData,
				sessionEject: a
			},()=> $("#sign_up_info").attr({'style':'display:block'}))

		 }).catch(err =>{

		 })
			  
  }
  
  participantShowListGrid=(id)=>{
	  let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
		 axios.defaults.headers.common['Authorization'] = token;
		 axios.get(process.env.REACT_APP_NAME+"/api/v1/user/"+id+"/getAllSignupParticipant")
		 .then(res => {
			 console.log(res);
			this.setState({
				participantList:res.data.responseData,
				sessionEject: id
			},()=> $("#sign_up_info").attr({'style':'display:block'}))

		 }).catch(err =>{

		 })
  }
  
  getFaq=(e)=>{
	 axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getFaqsById", {id: e})          
	.then(res => {
		if(e == 32)
			{
				this.setState({mainFaqs: res.data.responseData}, ()=>{this.props.showMainErrorPop(this.state.mainFaqs, true)});
			}
			
		else
			{	
				this.setState({mainFaqs: res.data.responseData}, ()=>{this.props.showMainErrorPop(this.state.mainFaqs)});
			}
	})
	.catch(err =>{ });  
  }
  
  ///////////////////// add product functions fetchProduct Attribute/////// 
  handleProductList = idx => evt => {
    const newShareholders = this.state.productInformation.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, attrValue: evt.target.value };
    });
  
    this.setState({ productInformation: newShareholders },()=> {
      console.log('Product List',this.state.productInformation[idx].attrValue)
    }
    );
  }

	chosePlan = (id) => {
		this.setState({chosenPlanId: id})
	}
	
	selectPlan = () => {
		if ( this.state.chosenPlanId === 0 ) { return false; }
		
		let postData = {userId: JSON.parse(localStorage.getItem('userData')).data.responseData.id, planId: this.state.chosenPlanId, channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel};
											
		axios.post(process.env.REACT_APP_NAME+"/api/v1/user/addUserPlan", postData)
		.then(res => {  
			$("#planSelecting").attr({'style':'display:none'});
			this.props.showMainErrorPop("Plan successfully added.");		
		}).catch(err =>{ this.props.showMainErrorPop("Something went wrong! Please try again.");	});
	}

//////////////////////////////////add product functions end//////////////////
 cancelSessionModal=(e, i)=>{
	let a = e.id;
	let id = i;
	
	
	let userid = JSON.parse(localStorage.getItem('userData')).data.responseData.id
	
	if ( e['lock_user_id'] > 0 && e['lock_user_id'] !== userid && (new Date().getTime()) - (new Date(e['lock_time']).getTime()) < 60 * 60 * 4 * 1000 )
		{
			this.setState({ responseMessage:'Class is currently being edited by ' + e['lock_name'] + ' and can not be canceled.' },()=>$("#error_email_msg").attr({'style':'display:block'}));
			return false;
		}
	
	
	
	if(new Date().getTime()>new Date(this.state.upcomingSessionArray[id].scheduleDate).getTime()){
		this.props.showMainErrorPop("Class in progress, cannot be cancelled.");		
		this.setState({
			cancelSessionId:a,
			cancelSessionDate:''

		});	
	}else{
		if ( parseInt(e.parentRecrId) > 0 && parseInt(e.remainingReccurings) > 1)
			{
				this.setState({
					cancelSessionId: a,
					cancelSessionDate: this.state.upcomingSessionArray[id].scheduleDate,
					cancelReccuringId: parseInt(e.parentRecrId)
				}, () => $("#cancel_session_recc").attr({'style':'display:block'}));
			}
		else
			{
				let responseMessage="Are you sure you want to cancel the class?"	
				this.setState({
					cancelSessionId:a,
					cancelSessionDate:this.state.upcomingSessionArray[id].scheduleDate,
					responseMessage:responseMessage

				},()=>$("#cancel_session").attr({'style':'display:block'}))	
			}
	}

	

 }
cancelSession=e=>{
	
	$('.main_loader').attr({'style':'display:flex'});
	let a = this.state.cancelSessionId;
	let date=this.state.cancelSessionDate;
	this.setState({isFromCancel: true});
	console.log('DATE---', date);
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
		 axios.defaults.headers.common['Authorization'] = token;
		 axios.put(process.env.REACT_APP_NAME+"/api/v1/session/"+a+"/CancelSession", {"isAdmin" : this.state.isAdmin})
		 .then(res => {
			this.ChannelData();
			this.props.showMainErrorPop("Class has been successfully canceled.");
			//$('.main_loader').hide();
			$("#cancel_session_recc").attr({'style':'display:none'})
		 }).catch(err =>{
			if(err.response.data.responseCode===417){
				this.props.showMainErrorPop("You do not have permission to cancel this session/s.");
				$('.main_loader').hide();
			}
		 })	 
}

cancelAllReccuring=()=>{
	$('.main_loader').attr({'style':'display:flex'});
	let date = this.state.cancelSessionDate;
	var postData = { reccuringId: this.state.cancelReccuringId, isAdmin : this.state.isAdmin};
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	axios.put(process.env.REACT_APP_NAME+"/api/v1/session/cancelAllReccuring", postData).then(res => {
		if ( this.state.updateForSession === "refreshThisWeek" )
			{
				this.ChannelData();
				this.memeberData();
				this.refreshThisWeek();
			}
		else
			{
				this.ChannelData();
				this.updateThisWeek(date);
			}
		
		this.props.showMainErrorPop("Classes has been successfully canceled.");
		$("#cancel_session_recc").attr({'style':'display:none'})
	}).catch(err =>{ });
}

openPastDrops=(ev, i, mod)=>{
	ev.preventDefault();
	ev.stopPropagation();
	
	let mainArray = this.state.pastSessionData;
	var last_open = false;
	$.each(mainArray, function( a, b ) {
		if ( a != i )
			{
				b['descriptionDropOpen'] = false;
				b['equipmentDropOpen'] = false;
				b['goalDropOpen'] = false;
			}
		else
			{
				if ( b[mod] ) { last_open = true; }
				if ( b['descriptionDropOpen'] ) { b['descriptionDropOpen'] = false; }
				if ( b['equipmentDropOpen'] ) { b['equipmentDropOpen'] = false; }
				if ( b['goalDropOpen'] ) { b['goalDropOpen'] = false; }
				
			}
	});
	
	if( !last_open ){ mainArray[i][mod] = !mainArray[i][mod]; }

	this.setState({
				pastSessionData: mainArray
			});
}


getRecurringSessionData=e=>{
	let a = e.target.id;
	let id=e.target.name;
	
	let recurrId = this.state.upcomingSessionArray[id].parentRecrId;

	// console.log("RECURRINGSESSIONDATA=====>",this.state.sessionData[id].parentRecrId);

	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
			axios.defaults.headers.common['Authorization'] = token;
			axios.get(process.env.REACT_APP_NAME+"/api/v1/session/"+recurrId+"/getrecurring")
			.then(res => {
				// console.log("RECURRINGDATA=====>>",res.data.responseData);					
			if(res.data.responseData.length>0){
              this.setState({
				recurringSessionData:res.data.responseData  
			  })
			}
			});

}
 

//updateThisWeek
updateThisWeek(date){
	//console.log('refreshThisWeek()=========================================');
	var current = new Date(date)
	var week = [];
	var first ;
	if(current.getDay()===0){
		first = current.getDate() - 7 + 1;
	}else{
		first = current.getDate() - current.getDay() + 1;
	}
	current.setDate(first);
		for (var i = 0; i < 7; i++) {
		week.push(new Date(+current).toISOString());
		current.setDate(current.getDate()+1);
		}
		
		let startOfWeekIso = moment(week[0]).startOf('isoWeek').toDate();
		let endOfWeekIso = moment(week[0]).endOf('isoWeek').toDate();
		
		//this.checkDefaultSession();
		
		let ak = JSON.parse(localStorage.getItem('userData'));
		const userData ={
			email : ak.data.responseData.email,
			type:ak.data.responseData.type,
			id:ak.data.responseData.id,
			filterLevel: this.state.filterLevel,
			filterHost: this.state.filterHost,
			filterIntrest: this.state.filterIntrest,
			filterEquipment: this.state.filterEquipment,
			filterDuration: this.state.filterDuration,
			startDate: this.state.fullStartDate,
			endDate: this.state.fullEndDate,
			searchSessionName: this.state.searchSessionName,
			sessionDate:week[0],
			startOfWeek: startOfWeekIso,
			endOfWeek: endOfWeekIso
			}
			let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
			axios.defaults.headers.common['Authorization'] = token;
			console.log('927');
			
			if ( JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace && JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace == 1 )
				{
					userData['isMarketplace'] = 1;
				}
			
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/hostDashboardData",userData)
			.then(res => {
				console.log('---------dashboardata--------',res);
			 $('.main_loader').hide();
			 if(res.data.responseMessage === "success"){
				 this.setState({
					upcomingSessionLength: res.data.responseData.sessionData,
					upcomingSessionNumber: res.data.responseData.sessionData.length,
				 });
				let awk=[];
				let scheduleDate;
				if(res.data.responseData.sessionData.length===0){
				for(let j=0;j<week.length;j++){
					awk.push({
					name:"No Data",
					hostFirstName:'',
					hostLastName:'',
					chargeForSession:0,
					scheduleDate:week[j]
					
					})	
				}
				this.setState({
					upcomingSessionArray:[],
					sessionData2:awk,
					totalSession:0,
					dateOfNextSession:!res.data.responseData.sessionInNextWeek?'':res.data.responseData.sessionInNextWeek
				});
				
				}else{
				for(let j=0;j<week.length;j++){
					let day=new Date(week[j]).getDate();
				for(let i=0;i<res.data.responseData.sessionData.length;i++){
					let day2=new Date(res.data.responseData.sessionData[i].scheduleDate).getDate();
					if(day===day2){
					day=0;	
					awk.push(res.data.responseData.sessionData[i]);
					}
				}
				if(day===0){
	
				}else{
				awk.push({
					name:"No Data",
					hostFirstName:'',
					hostLastName:'',
					chargeForSession:0,
					scheduleDate:week[j]
					})	
				}
			}
			this.setState({
				upcomingSessionArray:res.data.responseData.sessionData,
				sessionData2:awk,
				totalSession:res.data.responseData.sessionData.length,
				
			})
			
				this.props.setUserChannel(false, false, res.data.responseData.sessionData.length);
			}
			//this.pastSessionInfo();
		}
			else{
			 console.log('=============There Is an Error===================>');
			  
			}
			
			
			}).catch(err =>{
			console.log('----------there is problem------------',err);
				$('.main_loader').hide()
			});
		
	}
// refreshThisWeek
refreshThisWeek(){
	//console.log('refreshhhhhhh');
	//this.checkDefaultSession();
	
	let ak = JSON.parse(localStorage.getItem('userData'));
	
	if ( !ak )
		{
			window.location = '/';
			return false;
		}
	
	var mainthis = this;
	var current = new Date()
	var week = [];
	var first ;
	if(current.getDay()===0){
		first = current.getDate() - 7 + 1;
	}else{
		first = current.getDate() - current.getDay() + 1;
	}
	current.setDate(first);
		for (var i = 0; i < 7; i++) {
		week.push(new Date(+current).toISOString());
		current.setDate(current.getDate()+1);
		}
		
		if(this.state.applySort)
			{
				$('.main_loader').attr({'style':'display:flex'});
			}
		
		let startOfWeekIso = moment().startOf('isoWeek').toDate();
		let endOfWeekIso = moment().endOf('isoWeek').toDate();
		
		const userData ={
			email : ak.data.responseData.email,
			type:ak.data.responseData.type,
			id:ak.data.responseData.id,
			filterLevel: this.state.filterLevel,
			filterHost: this.state.filterHost,
			filterIntrest: this.state.filterIntrest,
			filterEquipment: this.state.filterEquipment,
			filterDuration: this.state.filterDuration,
			startDate: this.state.fullStartDate,
			endDate: this.state.fullEndDate,
			searchSessionName: this.state.searchSessionName,
			sessionDate:'',
			startOfWeek: startOfWeekIso,
			endOfWeek: endOfWeekIso
			}
			let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
			let loginId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
			axios.defaults.headers.common['Authorization'] = token;
			
			if ( JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace && JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace == 1 )
				{
					userData['isMarketplace'] = 1;
				}
			
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/hostDashboardData",userData)
			.then(res => {
				
			if ( !this.state.showloaderfromnative ) { $('.main_loader').hide(); }
				
			 if(res.data.responseMessage === "success"){
				 $('.main_loader').hide();
				 this.setState({
					upcomingSessionLength: res.data.responseData.sessionData,
					applySort: false,
					upcomingSessionNumber: res.data.responseData.sessionData.length,
				 });
				let awk=[];
				let scheduleDate;
				if(res.data.responseData.sessionData.length===0){
				for(let j=0;j<week.length;j++){
					awk.push({
					name:"No Data",
					hostFirstName:'',
					hostLastName:'',
					chargeForSession:0,
					scheduleDate:week[j]
					})	
				}
				scheduleDate = awk[6].scheduleDate
				this.setState({
					upcomingSessionArray:[],
					sessionData2:awk,
					sessionCurrentWeek:awk,
					totalSession:0,
					latestUpComingSession:[],
					lastestSessionData:[],
					//sessionType:'Upcoming',
					upComingScheduleDate:scheduleDate,
					dateOfNextSession:!res.data.responseData.sessionInNextWeek?'':res.data.responseData.sessionInNextWeek
				},()=>{this.ChannelData();})
				
				}else{
				scheduleDate =res.data.responseData.sessionData[res.data.responseData.sessionData.length-1].scheduleDate;
				let isTutorialRead;
				for(let j=0;j<week.length;j++){
					let day=new Date(week[j]).getDate();
				for(let i=0;i<res.data.responseData.sessionData.length;i++){
					
					
					var nextChange = new Date(res.data.responseData.sessionData[i].scheduleDate).getTime() + ( res.data.responseData.sessionData[i].duration * 60 * 1000 );
					nextChange = nextChange - (new Date()).getTime() + 5000;
					if ( nextChange > 1000 )
						{
							if ( window['reloadDashTimer'][res.data.responseData.sessionData[i].id] ) { clearTimeout(window['reloadDashTimer'][res.data.responseData.sessionData[i].id]); }
							window['reloadDashTimer'][res.data.responseData.sessionData[i].id] = setTimeout(function ()
							{
								var url = window.location.href;
								if ( url.toLowerCase().indexOf('/dashboardlanding') < 0 ) { return false; }
								
								if ( $('.ondemandmenu').hasClass('active') )
									{
										mainthis.getOnDemandsSessions();
									}
								else if ( $('.pastsesmenu').hasClass('active') )
									{
										mainthis.pastSessionInfo();
									}
								else
									{
										mainthis.refreshThisWeek();
									}
							}, nextChange);
						}
					
					
					
					let day2=new Date(res.data.responseData.sessionData[i].scheduleDate).getDate();
					if(day===day2){
					day=0;	
					awk.push(res.data.responseData.sessionData[i]);
					}
					if(loginId===res.data.responseData.sessionData[i].userId && res.data.responseData.sessionData[i].isTutorialRead >0){
						isTutorialRead=res.data.responseData.sessionData[i].isTutorialRead;
					}
	
				}
				if(day===0){
	
				}else{
				awk.push({
					name:"No Data",
					hostFirstName:'',
					hostLastName:'',
					chargeForSession:0,
					scheduleDate:week[j]
					})	
				}
			}
			scheduleDate = awk[6].scheduleDate
				//console.log(awk);
				if (this.state.sessionType === 'Upcoming' )
				{
						this.setState({
						upcomingSessionArray:res.data.responseData.sessionData,
						isTutorialRead:isTutorialRead,
						sessionData2:awk,
						lastestSessionData:res.data.responseData.sessionData,
						totalSession:res.data.responseData.sessionData.length,
						latestUpComingSession:res.data.responseData.sessionData,
						//sessionType:'Upcoming',
						upComingScheduleDate:scheduleDate,
						loginName: JSON.parse(localStorage.getItem('userData')).data.responseData.firstName,
						userAvatar: JSON.parse(localStorage.getItem('userData')).data.responseData.image,
						loginId:JSON.parse(localStorage.getItem('userData')).data.responseData.id,
						lastUpcomingDate:scheduleDate,
						dateForUpcomingSession:awk,
						sessionCurrentWeek:awk,
						totalUpcomingSession:res.data.responseData.sessionData.length,
					})
				}
			}
			
			//this.pastSessionInfo();
			this.sortSessionsAll(this.state.caseForSort);
		}else{
			
			 console.log('=============There Is an Error===================>'); 
			}
			}).catch(err =>{
			console.log('----------there is problem------------',err.response);
			$('.main_loader').hide();
			});
		
	}      

	previousWeekData =()=>{
		/////////////////////////important find days of that week//////////////////
		// alert(this.state.upComingScheduleDate);
		// if(new Date(this.state.upComingScheduleDate).getTime()<new Date(this.state.dateForUpcomingSession[0].scheduleDate).getTime()){
		// 	return
		// }
		//console.log(this.state.dateForUpcomingSession[0].scheduleDate,"=========",this.state.upComingScheduleDate)
		let getFullyear2=new Date(this.state.currentWeekData[0].scheduleDate).getFullYear();
		let getMonth2=new Date(this.state.currentWeekData[0].scheduleDate).getMonth();
		let getDay2=new Date(this.state.currentWeekData[0].scheduleDate).getDate();
		//console.log(new Date(getFullyear1,getMonth1,getDay1).getTime());
		
        let getFullyear=new Date(this.state.upComingScheduleDate).getFullYear();
	    let getMonth=(new Date(this.state.upComingScheduleDate).getMonth());
	    let getDay=new Date(this.state.upComingScheduleDate).getDate();
 
	    var current2 = new Date(getFullyear,getMonth,getDay)
		var week = [];
		let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
		var first2=current2.getDate() - 1;
		console.log(first2)
		if(current2.getDay()===0){
			first2 = current2.getDate() - 14 + 1;
			console.log('if!!!!!!!!!!',first2);
		}else{

			first2 = current2.getDate() - current2.getDay() + 1;
			console.log('else>>>>>>>>>>>>>>',first2);
		}
		current2.setDate(first2); 
		for (var i = 0; i < 7; i++) {
		//	console.log(new Date(+current2).toDateString())
        week.push(new Date(+current2).toISOString());

		current2.setDate(current2.getDate()+1);
			}
		getFullyear=new Date(week[0]).getFullYear();
		getMonth=(new Date(week[0]).getMonth());
		getDay=new Date(week[0]).getDate();
		let ak = JSON.parse(localStorage.getItem('userData'));
	var userData;
	if(getDay===getDay2 && getMonth===getMonth2){
		this.setState({
			updateForSession:"refreshThisWeek"
		})
		
		let startOfWeekIso = moment().startOf('isoWeek').toDate();
		let endOfWeekIso = moment().endOf('isoWeek').toDate();
		
		 userData ={
			email : ak.data.responseData.email,
			type:ak.data.responseData.type,
			id:ak.data.responseData.id,
			filterLevel: this.state.filterLevel,
			filterHost: this.state.filterHost,
			filterIntrest: this.state.filterIntrest,
			filterEquipment: this.state.filterEquipment,
			filterDuration: this.state.filterDuration,
			searchSessionName: this.state.searchSessionName,
			sessionDate:'',
			startOfWeek: startOfWeekIso,
			endOfWeek: endOfWeekIso
		}
		console.log('dsdsdsd');
	}else{
		this.setState({
			updateForSession:""
		})
		
		let startOfWeekIso = moment(week[0]).startOf('isoWeek').toDate();
		let endOfWeekIso = moment(week[0]).endOf('isoWeek').toDate();
		
		 userData ={
			email : ak.data.responseData.email,
			type:ak.data.responseData.type,
			id:ak.data.responseData.id,
			filterLevel: this.state.filterLevel,
			filterHost: this.state.filterHost,
			filterIntrest: this.state.filterIntrest,
			filterEquipment: this.state.filterEquipment,
			filterDuration: this.state.filterDuration,
			startDate: this.state.fullStartDate,
			endDate: this.state.fullEndDate,
			searchSessionName: this.state.searchSessionName,
			sessionDate:week[0],
			startOfWeek: startOfWeekIso,
			endOfWeek: endOfWeekIso
		}
	}
	// this.setState({
	// 	updateForSession:""
	// })
	//  userData ={
	// 	email : ak.data.responseData.email,
	// 	type:ak.data.responseData.type,
	// 	sessionDate:week[0],
	// }
		
		console.log('Userdata----------',userData);
		let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    	axios.defaults.headers.common['Authorization'] = token;
		
		if ( JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace && JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace == 1 )
			{
				userData['isMarketplace'] = 1;
			}
		
		axios.post(process.env.REACT_APP_NAME+"/api/v1/user/hostDashboardData",userData)
		.then(res => {
			$('.main_loader').hide();
		if(res.data.responseMessage === "success"){
			  this.setState({
				upcomingSessionLength: res.data.responseData.sessionData,
				upcomingSessionNumber: res.data.responseData.sessionData.length,
			 });
			let scheduleDate;
			let awk=[];
			if(res.data.responseData.sessionData.length===0){
			
			for(let j=0;j<week.length;j++){
				awk.push({
				name:"No Data",
				hostFirstName:'',
				hostLastName:'',
				chargeForSession:0,
				scheduleDate:week[j],
				})	
				//console.log("=================",awk);
				
			}
			scheduleDate = awk[6].scheduleDate
			this.setState({
				upcomingSessionArray:[],
				sessionData2:awk,
				//lastestSessionData:res.data.responseData.sessionData,
				totalSession:0,
				latestUpComingSession:[],
	
				//sessionType:'Upcoming',
				upComingScheduleDate:scheduleDate,
				dateOfNextSession:!res.data.responseData.sessionInNextWeek?'':res.data.responseData.sessionInNextWeek,
				week:'Week of ' + monthArray[new Date(week[0]).getMonth()] +' '+ new Date(week[0]).getDate()
			})
			
			}else{

			
			for(let j=0;j<week.length;j++){
				let day=new Date(week[j]).getDate();
			for(let i=0;i<res.data.responseData.sessionData.length;i++){
				let day2=new Date(res.data.responseData.sessionData[i].scheduleDate).getDate();
				if(day===day2){
				day=0;	
				awk.push(res.data.responseData.sessionData[i]);
				}
			}
			if(day===0){

			}else{
			awk.push({
				name:"No Data",
				hostFirstName:'',
				hostLastName:'',
				chargeForSession:0,
				scheduleDate:week[j],
				week:'Week of ' + monthArray[new Date(week[0]).getMonth()] +' '+ new Date(week[0]).getDate()
				})	
			}
		}
		
		scheduleDate =res.data.responseData.sessionData[res.data.responseData.sessionData.length-1].scheduleDate;
		scheduleDate = awk[6].scheduleDate

		this.setState({
			upcomingSessionArray:res.data.responseData.sessionData,
			sessionData2:awk,
			totalSession:res.data.responseData.sessionData.length,
			latestUpComingSession:res.data.responseData.sessionData,

			//sessionType:'Upcoming',
			upComingScheduleDate:scheduleDate,
			week:'Week of ' + monthArray[new Date(week[0]).getMonth()] +' '+ new Date(week[0]).getDate()
		},()=>console.log(this.state.sessionData2))
			this.props.setUserChannel(false, false, res.data.responseData.sessionData.length);
		}
		
		}else{
		 console.log('=============There Is an Error===================>'); 
		}
		}).catch(err =>{
		console.log('----------there is problem------------',err);
		$('.main_loader').hide();
		});	
		var weekFoward = this.state.weekFoward;
		if (weekFoward > 0)
			{
						weekFoward = weekFoward - 1;	
			}
		this.setState({
			weekFoward : weekFoward,
		})
	}
	
//refresh this week end here 

upComingScheduleDateMethod=(mod)=>{
	if ( mod && mod == 'view')
		{
			let getFullyear=new Date(this.state.dateOfNextSession.scheduleDate).getFullYear();
			let getMonth=(new Date(this.state.dateOfNextSession.scheduleDate).getMonth());
			let getDay=new Date(this.state.dateOfNextSession.scheduleDate).getDate();
			current = new Date(getFullyear,getMonth,getDay)
			var week = [];
			var first ;
			
			if(current.getDay()===0){
				first = current.getDate() - 7 + 1;
			}else{
				first = current.getDate() - current.getDay() + 1;
			}
			current.setDate(first);
			
			this.setState({
				weekFoward : 0,
			});
			
			
			let currentTimeMonday = new Date(current).getTime() / 1000;
			let timeSpace = currentTimeMonday - this.state.calendarStartTime;
			timeSpace = timeSpace / 86400;
			
			let fowardTimes = timeSpace / 7;
			fowardTimes = Math.round(fowardTimes); 
					
			this.setState({
				weekFoward: fowardTimes,
			})
		}
	else
		{
			let getFullyear=new Date(this.state.upComingScheduleDate).getFullYear();
			let getMonth=(new Date(this.state.upComingScheduleDate).getMonth());
			let getDay=new Date(this.state.upComingScheduleDate).getDate();
			var current = new Date(getFullyear,getMonth,getDay)
			var current2 = new Date(getFullyear,getMonth,getDay)
			var week = [];
			// Starting Monday not Sunday 
			var first ;
			//if(current.getDay()===0){
				first = current.getDate() + 1;			
			current.setDate(first);
			
			var weekFoward = this.state.weekFoward;
			weekFoward = weekFoward + 1;
			this.setState({
				weekFoward : weekFoward,
			})
		}
	
	let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
	
	console.log('current');
	console.log(current);
	
	for (var i = 0; i < 7; i++) {
		week.push(new Date(+current).toISOString());
		current.setDate(current.getDate()+1);
	}

	let startOfWeekIso = moment(week[0]).startOf('isoWeek').toDate();
	let endOfWeekIso = moment(week[0]).endOf('isoWeek').toDate();
	
	let ak = JSON.parse(localStorage.getItem('userData'));
		const userData ={
			email : ak.data.responseData.email,
			type:ak.data.responseData.type,
			id:ak.data.responseData.id,
			filterLevel: this.state.filterLevel,
			filterHost: this.state.filterHost,
			filterIntrest: this.state.filterIntrest,
			filterEquipment: this.state.filterEquipment,
			filterDuration: this.state.filterDuration,
			startDate: this.state.fullStartDate,
			endDate: this.state.fullEndDate,
			searchSessionName: this.state.searchSessionName,
			sessionDate:week[0],
			startOfWeek: startOfWeekIso,
			endOfWeek: endOfWeekIso
			}
		let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    	axios.defaults.headers.common['Authorization'] = token;
		console.log('1351');
		if ( JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace && JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace == 1 )
			{
				userData['isMarketplace'] = 1;
			}
		
		axios.post(process.env.REACT_APP_NAME+"/api/v1/user/hostDashboardData",userData)
		.then(res => {
		  $('.main_loader').hide();
		  if(res.data.responseMessage === "success"){
			  this.setState({
				upcomingSessionLength: res.data.responseData.sessionData,
				upcomingSessionNumber: res.data.responseData.sessionData.length,
			 });
			let awk=[];
			let scheduleDate;
			if(res.data.responseData.sessionData.length===0){
			
			for(let j=0;j<week.length;j++){
				awk.push({
				name:"No Data",
				hostFirstName:'',
				hostLastName:'',
				chargeForSession:0,
				scheduleDate:week[j],
				})	
			
				
			}
			scheduleDate = awk[6].scheduleDate
			this.setState({
				upcomingSessionArray:[],
				sessionData2:awk,
				//lastestSessionData:res.data.responseData.sessionData,
				totalSession:0,
				latestUpComingSession:[],
	
				//sessionType:'Upcoming',
				upComingScheduleDate:scheduleDate,
				updateForSession:" ",
				dateOfNextSession:!res.data.responseData.sessionInNextWeek?'':res.data.responseData.sessionInNextWeek,
				week:'Week of ' + monthArray[new Date(week[0]).getMonth()] +' '+ new Date(week[0]).getDate()
				
			})
			
			}else{

			
			for(let j=0;j<week.length;j++){
				let day=new Date(week[j]).getDate();
			for(let i=0;i<res.data.responseData.sessionData.length;i++){
				let day2=new Date(res.data.responseData.sessionData[i].scheduleDate).getDate();
				if(day===day2){
				day=0;	
				awk.push(res.data.responseData.sessionData[i]);
				}
			}
			if(day===0){

			}else{
			awk.push({
				name:"No Data",
				hostFirstName:'',
				hostLastName:'',
				chargeForSession:0,
				scheduleDate:week[j],
				})	
			}
		}
		
			 //console.log(res.data.responseData.sessionData,"================",awk);
			// console.log('==========',week);	




		scheduleDate =res.data.responseData.sessionData[res.data.responseData.sessionData.length-1].scheduleDate;
		scheduleDate = awk[6].scheduleDate

		this.setState({
			upcomingSessionArray:res.data.responseData.sessionData,
			sessionData2:awk,
			//lastestSessionData:res.data.responseData.sessionData,
			totalSession:res.data.responseData.sessionData.length,
			latestUpComingSession:res.data.responseData.sessionData,

			//sessionType:'Upcoming',
			upComingScheduleDate:scheduleDate,
			updateForSession:" ",
			week:'Week of ' + monthArray[new Date(week[0]).getMonth()] +' '+ new Date(week[0]).getDate()
		})
		}
		
		}else{
		 console.log('=============There Is an Error===================>'); 
		}
		}).catch(err =>{
		console.log('----------there is problem------------',err);
		$('.main_loader').hide();
		});
}
  hostEmailData=(e)=>{
//	console.log('Channel Data----------------');
	let ak = JSON.parse(localStorage.getItem('userData'));
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;	 
	axios.get(process.env.REACT_APP_NAME+"/api/v1/user/getAllHostDetail")
	.then(res => {
	 if(res.data.responseMessage === "success"){
	if(res.data.responseData.length>0){	 
	this.setState({
		hostMail:res.data.responseData,
		
	})
	}
	}else{
	 console.log('=============There Is an Error==ChannelData=================>'); 
	}
	}).catch(err =>{
	console.log('----------there is problem-----Channel Data-------',err);
		});
	}
  getSessionTime = (date1)=>{
	let ab = new Date(date1).getTime();
	// console.log(date1);
	// console.log(">>>>>>>>>>>>>>>>.aa",new Date(date1),new Date ('2020-1-24 04:03:00').getUTCMilliseconds(),new Date (ab).getUTCHours(),new Date (ab).getUTCMinutes(),"==========UTC=====",new Date (ab-19800000),">>>>>>>>>>>",new Date(ab+19800000).getHours());
	//console.log('-----------------',new Date(date1).getHours()+new Date(date1).getMinutes())
	let jk=new Date('2020-01-27T12:13:28.000Z').getTime();

	// let hour=new Date(ab).getUTCHours();
	// let minute=new Date(ab).getUTCMinutes();
	let hour=new Date(date1).getHours();
	let minute = new Date(date1).getMinutes();
	//let year=new Date(ab+19800000).getFullYear();
	let sessionTime= hour+':'+minute;
	if(minute<10){
		minute='0'+minute
	}
	
	if(hour<12){
	if(hour<10){	
	sessionTime ='0'+hour+':'+minute+" " +'AM'
	}else{
	sessionTime=hour+':'+minute+" " +'AM'
	}
	return sessionTime ;
	}
	if(hour>12){
	if((hour-12)<10){
		sessionTime='0'+(hour-12)+':'+minute+" " +'PM'	
	}else{
	sessionTime=(hour-12)+':'+minute+" " +'PM'
	}	
	return sessionTime ;
	}
	if(hour==12 && minute>=0){
	sessionTime=hour+':'+minute+" " +'PM'	
	return sessionTime ;
	}    
  }
 getSessionDate=(date1, twoNumberDays)=>{
	let ab = new Date(date1).getTime();
	// console.log(date1);
	 //console.log(new Date (ab+19800000),">>>>>>>>>>>",new Date(ab+19800000).getHours());
	//console.log('-----------------',new Date(date1).getHours()+new Date(date1).getMinutes())
	let day=new Date(ab).getDate();
	let month=(new Date(ab).getMonth())+1;
	let year=new Date(ab).getFullYear();
	if(month<10){
		month='0'+month
	}
	if ( twoNumberDays )
		{
			if(day<10)
				{
					day='0'+day
				}
		}
	let sessionDate= month+'/'+day+'/'+year;
	//let ak= <p className="mb-4"><img src="/images/gray-icons/date.png" className="mr-3"  alt="" />Cut off date {sessionDate}</p>
	//arr.push(<option key={i} value={i}>{i}</option>)

	return sessionDate; 
	  
	
	
}
//GET PRODUCT LIST FOR WINE SECTION
getWineProductList() {  
	let channelId=JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
	//channdlId=1;
	if(!channelId){
		console.log('======sas=======',localStorage.getItem('channelId'))

		channelId=localStorage.getItem('channelId');
	}
    //let  channelId=localStorage.getItem('channelId');
    let groupId=localStorage.getItem('groupId')?localStorage.getItem('groupId'):2;
    let channelInterest=localStorage.getItem("channelInterest"); 
    //  const productData={"channelId":channelId,"groupId":groupId} 

    console.log('-----asdfghjkl----------',channelId);  
    let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;
      axios.post(process.env.REACT_APP_NAME+"/api/v1/session/channel/product-list",{"channelId":channelId,"groupId":groupId})          
      .then(res => {
        console.log('---------channelproduct--------------',res.data.responseData);
        let eqarray=res.data.responseData;        
     
        let ka=[];
        let ka_active=[];
        for(let i=0;i<eqarray.length;i++){
          //type:false,name:"Mersedes Benz"
          let n = {
            id: eqarray[i].id,
            type:false,
            channelId:channelId,
            interestId:channelInterest,
            product_name:eqarray[i].name,
            description: eqarray[i].description,
            image: eqarray[i].image,
            price: eqarray[i].price ? eqarray[i].price : 0
          };  
          ka.push(n)
		  
		  if ( eqarray[i].active == 1 ) { ka_active.push(n); }
        }
		
		let shortWineProduct = ka_active.slice(0,2);

        this.setState({
          wineProduct: ka,
		  shortWineProduct: shortWineProduct
            });
      	})
      .catch(err =>{
          console.log('------Wine Product----there is problem------------');
      });
    }
	
openUpcDrops=(ev, i, mod)=>{
	ev.preventDefault();
	ev.stopPropagation();
	
	let mainArray = this.state.upcomingSessionArray;
	var last_open = false;
	$.each(mainArray, function( a, b ) {
		if ( a != i )
			{
				b['descriptionDropOpen'] = false;
				b['equipmentDropOpen'] = false;
				b['goalDropOpen'] = false;
			}
		else
			{
				if ( b[mod] ) { last_open = true; }
				if ( b['descriptionDropOpen'] ) { b['descriptionDropOpen'] = false; }
				if ( b['equipmentDropOpen'] ) { b['equipmentDropOpen'] = false; }
				if ( b['goalDropOpen'] ) { b['goalDropOpen'] = false; }
				
			}
	});
	
	if( !last_open ){ mainArray[i][mod] = !mainArray[i][mod]; }

	this.setState({
				upcomingSessionArray: mainArray
			});
}	



componentWillUnmount(){
	clearInterval(this.state.intervalID);
	clearInterval(window['mainInterval'])
}

openUpcomingSession=()=>{
	var user = JSON.parse(localStorage.getItem('userData'));
	var checkSessionValidate = this.joinSessionValidate(true, user.data.responseData.id, user.data.responseData.token);
	
	this.setState({
		urlPath: checkSessionValidate.urlPathVar,
		urlElectron: checkSessionValidate.urlElectronVar,
		userSignedUp: true,
		sessionScheduleDate: this.state.upcomingSessionArray[0].scheduleDate,
		sessionTimezone: this.state.upcomingSessionArray[0].timeZone,
		sessionName: this.state.upcomingSessionArray[0].name,
		isOnDemandSession: false
	});
}

renderer = ({ days,hours, minutes, seconds, completed }) => {
	if (completed) 
		{

			return <div className="pdash_button2 in_progress_btn" onClick={this.openUpcomingSession}>IN PROGRESS - JOIN</div>
		} 
	else 
		{
			if(days>0 || hours>0)
				{
					return <div className="pdash_button auto_cursor_btn">JOIN</div>
				}
			if(minutes<this.state.defaultJoinTime)
				{ 
					return <div className="counterHolder" > <div className="pdash_button2" onClick={this.openUpcomingSession}>JOIN</div><div className="timeToStart_sq timer_participant"> {minutes>=10 && seconds >=10?<p className="begins_in_part">Begins in: <span>{minutes}</span>:<span>{seconds}</span></p>:''} {minutes<10 && seconds <10?<p className="begins_in_part">Begins in: <span>0{minutes}</span>:<span>0{seconds}</span></p>:''} {minutes>=10 && seconds <10?<p className="begins_in_part">Begins in: <span>{minutes}</span>:<span>0{seconds}</span></p>:''}{minutes<10 && seconds >=10?<p className="begins_in_part">Begins in: <span>0{minutes}</span>:<span>{seconds}</span></p>:''}	</div></div>
				}
			else
				{
					return <div className="pdash_button auto_cursor_btn">JOIN</div>
				}
		}
};
	
	
  renderer2 = ({ days,hours, minutes, seconds, completed }) => {
	if (completed) 
		{
			return  <button className="new_view_button2 more_padd_join_upcpart active_join" onClick={this.openUpcomingSession}> IN PROGRESS - JOIN</button>
		} 
	else 
		{
			if(days>0 || hours>0)
				{
					return <button className="new_view_button2 more_padd_join_upcpart pdash_button auto_cursor_btn"> JOIN </button>				
		}
	if(minutes<this.state.defaultJoinTime) 
		{
		//	this.checkmethod();
			return <div> 
					<div className="" >
						{minutes>=10 && seconds >=10?
							<p className="begins_in_part">Begins in: <span>{minutes}</span>:<span>{seconds}</span></p>
							:
							''
						} 
						{minutes<10 && seconds <10?
							<p className="begins_in_part">Begins in: <span>0{minutes}</span>:<span>0{seconds}</span></p>
							:
							''
						} 
						{minutes>=10 && seconds <10?
							<p className="begins_in_part">Begins in: <span>{minutes}</span>:<span>0{seconds}</span></p>
							:
							''
						}
						{minutes<10 && seconds >=10?
							<p className="begins_in_part">Begins in: <span>0{minutes}</span>:<span>{seconds}</span></p>
							:
							''
						}
					</div>
					<button className="new_view_button2 more_padd_join_upcpart active_join" onClick={this.openUpcomingSession}> JOIN </button> 
				</div>				
	}
	else
		{
			return <button className="new_view_button2 more_padd_join_upcpart pdash_button auto_cursor_btn"> JOIN </button>
		}
 }
};

  getInitialState=()=>{
    return {
      selectedOption: 'option1'
    };
  }
  dateOfSession = (date2)=>{
//	console.log('>>>>>>>>>>>>>>>>',new Date(date2).getDate());  
	let date=new Date(date2).getDate()
	return date;
  }
  dayOfSession = (date2)=>{
	var dayofWeek=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];  
	let day=dayofWeek[new Date(date2).getDay()];
	return day;
  }
  
	setStartDate =(date)=>{

		
		if ( !this.state.sessionCurrentWeek[6] ) { return false; }
		
		//console.log(this.state.upComingScheduleDate)
		let date1=date;
		let upcomingSession=[];
		let onejan = new Date(date).getFullYear();
		let v=new Date(onejan,0,1);
		//alert(v.getDay());
		let previousWeek = Math.ceil((((new Date(onejan,new Date(date).getMonth(),1) - v) / 86400000) + v.getDay()-1)/7)
		let currentWeek = Math.ceil((((new Date(date) - v) / 86400000) + v.getDay()-1)/7);
		let weekChange =  Math.ceil((((new Date(date) - v) / 86400000) + v.getDay()-1)/7);
	//	console.log(previousWeek,">>>>>>>>>>>>>>>>",currentWeek,"==========",weekChange);
		//console.log('----------------',new Date(date).getMonth(),new Date(date).getDate());
//alert(date);
		/////////////////////////important find days of that week//////////////////
		let getFullyear=new Date(date).getFullYear();
		let getMonth=(new Date(date).getMonth());
		let getDay=new Date(date).getDate();
	//	console.log(new Date(getFullyear,getMonth,getDay).getTime());
		let selectedTime=new Date(getFullyear,getMonth,getDay).getTime();
		
		let getFullyear1=new Date().getFullYear();
		let getMonth1=(new Date().getMonth());
		let getDay1=new Date().getDate();

		let getFullyear2=new Date(this.state.sessionCurrentWeek[6].scheduleDate).getFullYear();
		let getMonth2=new Date(this.state.sessionCurrentWeek[6].scheduleDate).getMonth();
		let getDay2=new Date(this.state.sessionCurrentWeek[6].scheduleDate).getDate();
		//console.log(new Date(getFullyear1,getMonth1,getDay1).getTime());
		let currentTime=new Date(getFullyear1,getMonth1,getDay1).getTime();
		
		//alert(date);

		var current = new Date(date);//.toDateString();
		var week = [];
  // Starting Monday not Sunday 
  //var first = current.getDate() - current.getDay()+1 ;
		var first ;
		if(current.getDay()===0){
			first = current.getDate() - 7 + 1;
		}else{
			first = current.getDate() - current.getDay() + 1;
		} 
	//	console.log(current.getDate(),'first',first,current.getDay());
		current.setDate(first);
		for (var i = 0; i < 7; i++) {
			//console.log(new Date(+current));
			week.push(new Date(+current).toISOString());
			current.setDate(current.getDate()+1);
		}
		////////////////////////////////////////////////
		let dateofMonth = new Date(date).getDate();
		let timeSelection =  (new Date (date).getMonth()) ;
		//console.log(timeSelection);
		date = new Date(Date.UTC(2019, timeSelection, 1));
		 var days = [];
		 var dayofWeek=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
		//console.log('date.getMonth()',date.getMonth());
		 while (date.getMonth() === timeSelection ) {
			//days.push(new Date(date).getDate());
			
			//Take when week need to select
		
			// && currentWeek == weekChange
			// console.log(currentWeek,"==========",weekChange);
		
			// Tahe when end here
			
			weekChange =  Math.ceil((((new Date(date) - v) / 86400000) + v.getDay()+4)/7);
			 let n ={date:new Date(date).getDate(),day:dayofWeek[new Date(date).getDay()]}
			 days.push(n);
			 //days.push(new Date(date).getDay());
			 date.setDate(date.getDate() + 1);
		  }
		  for (let i=dateofMonth-1 ;i<days.length;i++){
		  upcomingSession.push(days[i]);
		  } 
		//   for(let i=0 ;i<days.length;i++){
		// 	console.log('??????',days[i])
		// 	upcomingSession.push(days[i]);
		// } 
		let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]

		this.setState({
			startDate:date1,
			daysOfMonth:days,
			upcomingSession:upcomingSession,
			// week:'week'+currentWeek
			week:'Week of ' + monthArray[new Date(week[0]).getMonth()] +' '+ new Date(week[0]).getDate()
		});

		// Check date current and selected
		if(selectedTime<currentTime || this.state.sessionType==='Past' || this.state.sessionType==='On Demand'){
			console.log('HEY');
			return;
		}
	
	// ******************************************//
	let ak = JSON.parse(localStorage.getItem('userData'));
	var userData;
	//console.log(getDay1,"===========",getDay,"==========",getDay2);
	if(getDay<=getDay2 && getMonth===getMonth1){
		this.setState({
			updateForSession:"refreshThisWeek"
		})
		
		
		
		let startOfWeekIso = moment().startOf('isoWeek').toDate();
		let endOfWeekIso = moment().endOf('isoWeek').toDate();
		
		 userData ={
			email : ak.data.responseData.email,
			type:ak.data.responseData.type,
			id:ak.data.responseData.id,
			filterLevel: this.state.filterLevel,
			filterHost: this.state.filterHost,
			filterIntrest: this.state.filterIntrest,
			filterEquipment: this.state.filterEquipment,
			filterDuration: this.state.filterDuration,
			searchSessionName: this.state.searchSessionName,
			sessionDate:'',
			startOfWeek: startOfWeekIso,
			endOfWeek: endOfWeekIso
		}
	}else{
		this.setState({
			updateForSession:""
		})
		
		let startOfWeekIso = moment(week[0]).startOf('isoWeek').toDate();
		let endOfWeekIso = moment(week[0]).endOf('isoWeek').toDate();
		
		 userData ={
			email : ak.data.responseData.email,
			type:ak.data.responseData.type,
			id:ak.data.responseData.id,
			filterLevel: this.state.filterLevel,
			filterHost: this.state.filterHost,
			filterIntrest: this.state.filterIntrest,
			filterEquipment: this.state.filterEquipment,
			filterDuration: this.state.filterDuration,
			startDate: this.state.fullStartDate,
			endDate: this.state.fullEndDate,
			searchSessionName: this.state.searchSessionName,
			sessionDate:week[0],
			startOfWeek: startOfWeekIso,
			endOfWeek: endOfWeekIso
		}
	}	
		console.log('Userdata----------',userData);
		let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    	axios.defaults.headers.common['Authorization'] = token;
		console.log('1868');
		
		if ( JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace && JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace == 1 )
			{
				userData['isMarketplace'] = 1;
			}
		
		axios.post(process.env.REACT_APP_NAME+"/api/v1/user/hostDashboardData",userData)
		.then(res => {
			console.log('---------dashboardata--------',res);
		  $('.main_loader').hide();
		  if(res.data.responseMessage === "success"){
			  //console.log('--------lalit111-------')
			  this.setState({
				upcomingSessionLength: res.data.responseData.sessionData,
				upcomingSessionNumber: res.data.responseData.sessionData.length,
			 });
			let scheduleDate;
			let awk=[];
			if(!userData.sessionDate &&(this.state.totalUpcomingSession!=res.data.responseData.sessionData.length)){
			this.ChannelData();//update channel detail
			this.memeberData();//update channel member
			}
			
			if(res.data.responseData.sessionData.length===0){
			
			for(let j=0;j<week.length;j++){
				awk.push({
				name:"No Data",
				hostFirstName:'',
				hostLastName:'',
				chargeForSession:0,
				scheduleDate:week[j]
				})	
				
			}
			scheduleDate = awk[6].scheduleDate
			this.setState({
				upcomingSessionArray:[],
				sessionData2:awk,
				//lastestSessionData:res.data.responseData.sessionData,
				totalSession:0,
				totalUpcomingSession:!userData.sessionDate?res.data.responseData.sessionData.length:this.state.totalUpcomingSession,//when tab on upcoming session tab we need current week totalsession
				latestUpComingSession:[],
	
				//sessionType:'Upcoming',
				upComingScheduleDate:scheduleDate,
				dateOfNextSession:!res.data.responseData.sessionInNextWeek?'':res.data.responseData.sessionInNextWeek
				
			})
			
			}else{

			
			for(let j=0;j<week.length;j++){
				let day=new Date(week[j]).getDate();
			for(let i=0;i<res.data.responseData.sessionData.length;i++){
				let day2=new Date(res.data.responseData.sessionData[i].scheduleDate).getDate();
				if(day===day2){
				day=0;	
				awk.push(res.data.responseData.sessionData[i]);
				}
			}
			if(day===0){

			}else{
			awk.push({
				name:"No Data",
				hostFirstName:'',
				hostLastName:'',
				chargeForSession:0,
				scheduleDate:week[j]
				})	
			}
		}
		
		scheduleDate =res.data.responseData.sessionData[res.data.responseData.sessionData.length-1].scheduleDate;
		scheduleDate = awk[6].scheduleDate
	
		if ( this.state.sessionType === 'Upcoming' )
			{
				this.setState({
						upcomingSessionArray:res.data.responseData.sessionData
				});
			}
		this.setState({
			sessionData2:awk,
			totalSession:res.data.responseData.sessionData.length,
			latestUpComingSession:res.data.responseData.sessionData,
			totalUpcomingSession:!userData.sessionDate?res.data.responseData.sessionData.length:this.state.totalUpcomingSession,//when tab on upcoming session tab we need current week totalsession
			//sessionType:'Upcoming',
			upComingScheduleDate:scheduleDate,
			lastestSessionData:!userData.sessionDate?res.data.responseData.sessionData:this.state.lastestSessionData,
		});
			this.props.setUserChannel(false, false, res.data.responseData.sessionData.length);
		}
		//this.pastSessionInfo();// done by lalit
		}else{
		 console.log('=============There Is an Error===================>'); 
		}
		}).catch(err =>{
		console.log('----------there is problem------------',err);
		$('.main_loader').hide();
		});	

		

	}

	// calender date here
	calenderDate =(date)=>{
		let date1=date;
		let upcomingSession=[];
		let onejan = new Date(date).getFullYear();
		
		var choosenDate = moment(date).format('YYYY-MM-DD 00:00:00');
		var currentDateValue = moment(new Date()).format('YYYY-MM-DD 00:00:00');
		
		if ( choosenDate < currentDateValue )
			{
				return false;
			}
		
		let v=new Date(onejan,0,1);
		//alert(v.getDay());
		let previousWeek = Math.ceil((((new Date(onejan,new Date(date).getMonth(),1) - v) / 86400000) + v.getDay()-1)/7)
		let currentWeek = Math.ceil((((new Date(date) - v) / 86400000) + v.getDay()-1)/7);
		let weekChange =  Math.ceil((((new Date(date) - v) / 86400000) + v.getDay()-1)/7);
	//	console.log(previousWeek,">>>>>>>>>>>>>>>>",currentWeek,"==========",weekChange);
		//console.log('----------------',new Date(date).getMonth(),new Date(date).getDate());
//alert(date);
		/////////////////////////important find days of that week//////////////////
		let getFullyear=new Date(date).getFullYear();
		let getMonth=(new Date(date).getMonth());
		let getDay=new Date(date).getDate();
	//	console.log(new Date(getFullyear,getMonth,getDay).getTime());
		let selectedTime=new Date(getFullyear,getMonth,getDay).getTime();
		
		let getFullyear1=new Date().getFullYear();
		let getMonth1=(new Date().getMonth());
		let getDay1=new Date().getDate();

		let getFullyear2=new Date(this.state.sessionCurrentWeek[6].scheduleDate).getFullYear();
		let getMonth2=new Date(this.state.sessionCurrentWeek[6].scheduleDate).getMonth();
		let getDay2=new Date(this.state.sessionCurrentWeek[6].scheduleDate).getDate();
		//console.log(new Date(getFullyear1,getMonth1,getDay1).getTime());
		let currentTime=new Date(getFullyear1,getMonth1,getDay1).getTime();
		
		//alert(date);

		var current = new Date(date);//.toDateString();
		var week = [];
  // Starting Monday not Sunday 
  //var first = current.getDate() - current.getDay()+1 ;
		var first ;
		if(current.getDay()===0){
			first = current.getDate() - 7 + 1;
		}else{
			first = current.getDate() - current.getDay() + 1;
		} 
	//	console.log(current.getDate(),'first',first,current.getDay());
		current.setDate(first);
		for (var i = 0; i < 7; i++) {
			//console.log(new Date(+current));
			week.push(new Date(+current).toISOString());
			current.setDate(current.getDate()+1);
		}
		////////////////////////////////////////////////
		let dateofMonth = new Date(date).getDate();
		let timeSelection =  (new Date (date).getMonth()) ;
		//console.log(timeSelection);
		date = new Date(Date.UTC(2019, timeSelection, 1));
		 var days = [];
		 var dayofWeek=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
		//console.log('date.getMonth()',date.getMonth());
		 while (date.getMonth() === timeSelection ) {
			//days.push(new Date(date).getDate());
			
			//Take when week need to select
		
			// && currentWeek == weekChange
			// console.log(currentWeek,"==========",weekChange);
		
			// Tahe when end here
			
			weekChange =  Math.ceil((((new Date(date) - v) / 86400000) + v.getDay()+4)/7);
			 let n ={date:new Date(date).getDate(),day:dayofWeek[new Date(date).getDay()]}
			 days.push(n);
			 //days.push(new Date(date).getDay());
			 date.setDate(date.getDate() + 1);
		  }
		  for (let i=dateofMonth-1 ;i<days.length;i++){
		  upcomingSession.push(days[i]);
		  } 
		//   for(let i=0 ;i<days.length;i++){
		// 	console.log('??????',days[i])
		// 	upcomingSession.push(days[i]);
		// } 
		let monthArray=["Jan","Feb","March","April","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]

		this.setState({
			startDate:date1,
			daysOfMonth:days,
			upcomingSession:upcomingSession,
			// week:'week'+currentWeek
			week:'Week of ' + monthArray[new Date(week[0]).getMonth()] +' '+ new Date(week[0]).getDate()
		});

		// Check date current and selected
		if(selectedTime<currentTime || this.state.sessionType==='Past' || this.state.sessionType==='On Demand'){
			console.log('HEY');
			return;
		}
		
	// ******************************************//
	let ak = JSON.parse(localStorage.getItem('userData'));
	var userData;
	//console.log(getDay1,"===========",getDay,"==========",getDay2);
	if(getDay<=getDay2 && getMonth===getMonth1){
		this.setState({
			updateForSession:"refreshThisWeek"
		})
		
		let startOfWeekIso = moment().startOf('isoWeek').toDate();
		let endOfWeekIso = moment().endOf('isoWeek').toDate();
		
		 userData ={
			email : ak.data.responseData.email,
			type:ak.data.responseData.type,
			id:ak.data.responseData.id,
			filterLevel: this.state.filterLevel,
			filterHost: this.state.filterHost,
			filterIntrest: this.state.filterIntrest,
			filterEquipment: this.state.filterEquipment,
			filterDuration: this.state.filterDuration,
			searchSessionName: this.state.searchSessionName,
			sessionDate:'',
			startOfWeek: startOfWeekIso,
			endOfWeek: endOfWeekIso
		}
	}else{
		this.setState({
			updateForSession:""
		})
		
		let startOfWeekIso = moment(week[0]).startOf('isoWeek').toDate();
		let endOfWeekIso = moment(week[0]).endOf('isoWeek').toDate();
		
		 userData ={
			email : ak.data.responseData.email,
			type:ak.data.responseData.type,
			id:ak.data.responseData.id,
			filterLevel: this.state.filterLevel,
			filterHost: this.state.filterHost,
			filterIntrest: this.state.filterIntrest,
			filterEquipment: this.state.filterEquipment,
			filterDuration: this.state.filterDuration,
			startDate: this.state.fullStartDate,
			endDate: this.state.fullEndDate,
			searchSessionName: this.state.searchSessionName,
			sessionDate:week[0],
			startOfWeek: startOfWeekIso,
			endOfWeek: endOfWeekIso
		}
	}	
		let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    	axios.defaults.headers.common['Authorization'] = token;
		console.log('2092');
		
		if ( JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace && JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace == 1 )
			{
				userData['isMarketplace'] = 1;
			}
		
		axios.post(process.env.REACT_APP_NAME+"/api/v1/user/hostDashboardData",userData)
		.then(res => {
			console.log('---------dashboardata--------',res);
		  $('.main_loader').hide();
		  if(res.data.responseMessage === "success"){
			  this.setState({
				upcomingSessionLength: res.data.responseData.sessionData,
				upcomingSessionNumber: res.data.responseData.sessionData.length,
			 });
			let scheduleDate;
			let awk=[];
				
			if(res.data.responseData.sessionData.length===0){
			
			for(let j=0;j<week.length;j++){
				awk.push({
				name:"No Data",
				hostFirstName:'',
				hostLastName:'',
				chargeForSession:0,
				scheduleDate:week[j]
				})	
				
			}
			scheduleDate = awk[6].scheduleDate
			this.setState({
				upcomingSessionArray:[],
				sessionData2:awk,
				//lastestSessionData:res.data.responseData.sessionData,
				totalSession:0,
				totalUpcomingSession:!userData.sessionDate?res.data.responseData.sessionData.length:this.state.totalUpcomingSession,//when tab on upcoming session tab we need current week totalsession
				latestUpComingSession:[],
	
				//sessionType:'Upcoming',
				upComingScheduleDate:scheduleDate,
				dateOfNextSession:!res.data.responseData.sessionInNextWeek?'':res.data.responseData.sessionInNextWeek,
				intervalID:setTimeout(() => {
					this.setStartDate(this.state.upComingScheduleDate);
				  }, 10000)
				
			})
			
			}else{

			for(let j=0;j<week.length;j++){
				let day=new Date(week[j]).getDate();
			for(let i=0;i<res.data.responseData.sessionData.length;i++){
				let day2=new Date(res.data.responseData.sessionData[i].scheduleDate).getDate();
				if(day===day2){
				day=0;	
				awk.push(res.data.responseData.sessionData[i]);
				}
			}
			if(day===0){

			}else{
			awk.push({
				name:"No Data",
				hostFirstName:'',
				hostLastName:'',
				chargeForSession:0,
				scheduleDate:week[j]
				})	
			}
		}
		
		scheduleDate =res.data.responseData.sessionData[res.data.responseData.sessionData.length-1].scheduleDate;
		scheduleDate = awk[6].scheduleDate
		this.setState({
			upcomingSessionArray:res.data.responseData.sessionData,
			sessionData2:awk,
			totalSession:res.data.responseData.sessionData.length,
			latestUpComingSession:res.data.responseData.sessionData,
			totalUpcomingSession:!userData.sessionDate?res.data.responseData.sessionData.length:this.state.totalUpcomingSession,//when tab on upcoming session tab we need current week totalsession
			//sessionType:'Upcoming',
			upComingScheduleDate:scheduleDate,
			lastestSessionData:!userData.sessionDate?res.data.responseData.sessionData:this.state.lastestSessionData,
			intervalID:setTimeout(() => {
				this.setStartDate(this.state.upComingScheduleDate);
			  }, 10000)
		})
		
			this.props.setUserChannel(false, false, (!userData.sessionDate?res.data.responseData.sessionData.length:this.state.totalUpcomingSession));
		}
		}else{
		 console.log('=============There Is an Error===================>'); 
		}
		}).catch(err =>{
		console.log('----------there is problem------------',err);
		$('.main_loader').hide();
		});	



	}
	
	

	//calender date end here

closeFullPop = e => 
	{
		$("#session_full").hide();
	}

	setStartDate1 =(date)=>{
		let date1=date;
		let upcomingSession=[];
		console.log('----------------',new Date(date).getMonth(),new Date(date).getDate());
		let dateofMonth = new Date(date).getDate();
		 let timeSelection =  (new Date (date).getMonth()) ;
		 console.log(timeSelection);
		  date = new Date(Date.UTC(2019, timeSelection, 1));
		 var days = [];
		 var dayofWeek=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
		console.log('date.getMonth()',date.getMonth());
		 while (date.getMonth() === timeSelection) {
			//days.push(new Date(date).getDate());
			 let n ={date:new Date(date).getDate(),
				day:dayofWeek[new Date(date).getDay()],
				timestamp:new Date(date).getTime(),
			}
			 days.push(n);
			 //days.push(new Date(date).getDay());
			 date.setDate(date.getDate() + 1);
		  }
		console.log(days)
		  for (let i=dateofMonth-1 ;i<days.length;i++){
			  console.log(days[i])
			  upcomingSession.push(days[i]);
		  } 
		
		this.setState({
			startDate1:date1,
			daysOfMonth:days,
			upcomingSession:upcomingSession,
		},()=>console.log('this.state.daysOfMonth',this.state.upcomingSession));
		
		}

	


	scriptOnDate=(mod)=>{
		let a = [];
		if(!mod)
			{

			}
		else
			{
				for(let i=0;i<this.state.latestUpComingSession.length;i++)
				{
					if(new Date(this.state.latestUpComingSession[i].scheduleDate).getDate()===new Date(mod).getDate()){
					a.push(this.state.latestUpComingSession[i]);
						
					}
					else{
					
					}
				}
				this.setState({
					upcomingSessionArray:a
				})
		}
	}
	uneditableMode=(e)=>{
		e.preventDefault();
		e.stopPropagation();
		var name = e.target.getAttribute('name');
		if ( e.target.id === 'od_open' )
			{
				this.setState({
					copyLink_state : 'onDemand'
				});
			}
		else 
			{
				this.setState({
					copyLink_state : 'session'
				});
			}			
		this.setState({
			urlLink:name
		},()=>$("#successResult").attr({'style':'display:block'}))
		
	}

	updateEdit=(par)=>{
		if ( par === 'reject' )
			{
				$('#editing_alert_session').attr({'style':'display:none'});
			}
		else
			{
				var userName = JSON.parse(localStorage.getItem('userData')).data.responseData.firstName + ' ' + JSON.parse(localStorage.getItem('userData')).data.responseData.lastName;
				var postData = {username: userName, hostId: this.state.sessionHostEdit, userId: JSON.parse(localStorage.getItem('userData')).data.responseData.id, sessionId: this.state.isSessionRecurring > 0 ? this.state.isSessionRecurring : this.state.sessionToEdit};

				var mainthis = this;
				axios.post(process.env.REACT_APP_NAME+"/api/v1/user/makeEditUser", postData).then(res => {
					$('#editing_alert_contdown').attr({'style': 'display:block'});
					$('#editing_alert_session').attr({'style':'display:none'});
					window['intervalSessionEditDash'] = setInterval(function(){
						var seconds = mainthis.state.pendingHostSeconds;
						seconds = seconds - 1;
						if ( seconds == 0 )
							{
								clearInterval(window['intervalSessionEditDash']);
								mainthis.setState({pendingHostSeconds: 30});
								
								if ( mainthis.state.editSessionStatus === 'pending' ) 
									{
										$('#editing_alert_contdown').attr({'style': 'display:none'});
										$('#editing_alert_session').attr({'style':'display:none'});
										mainthis.props.showMainErrorPop('Access granted');
										setTimeout(function(){
											//go to edit session
											let url = process.env.REACT_APP_NAME+"/api/v1/session/"+mainthis.state.sessionToEdit+'/stoplock';
											let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
											axios.defaults.headers.common['Authorization'] = token;
											axios.get(url).then(res => { 
												browserHistory.push("/fitnessdetail/"+mainthis.state.sessionToEdit); 
											}).catch(err =>{ });
										}, 3000);
									}
								
								return false;
							}
							
						mainthis.setState({pendingHostSeconds: seconds});
					}, 1000);

				}).catch(err =>{ });
				let s_id = this.state.isSessionRecurring > 0 ? this.state.isSessionRecurring : this.state.sessionToEdit;
				mainthis.updatesse('sessionEditAnswer', [s_id], function (data)
					{
						if ( data === 'reject' ) { mainthis.editSessionRejected(); }
						else { mainthis.editSessionAccepted(); }
					});
			}
	}
	
	editSessionRejected=()=>{
		clearInterval(window['intervalSessionEditDash']);
		this.setState({editSessionStatus: 'rejected', pendingHostSeconds: 30});
		$('#editing_alert_contdown').attr({'style': 'display:none'});
		$('#editing_alert_session').attr({'style':'display:none'});
		this.ChannelData();
		
		this.props.showMainErrorPop('Access not granted, contact '+this.state.sessionHostEditName+' for access');
	}
	
	editSessionAccepted=()=>{
		clearInterval(window['intervalSessionEditDash']);
		this.setState({editSessionStatus: 'approved', pendingHostSeconds: 30});
		$('#editing_alert_contdown').attr({'style': 'display:none'});
		$('#editing_alert_session').attr({'style':'display:none'});
		this.props.showMainErrorPop('Access granted');
		
		var mainthis = this;
		setTimeout(function(){ browserHistory.push("/fitnessdetail/"+mainthis.state.sessionToEdit); }, 3000);
	}

	editableMode=(e, kid)=>{
	let indexValue=kid;
	let userid = JSON.parse(localStorage.getItem('userData')).data.responseData.id
	
	this.setState({isSessionRecurring: 0});
	if ( e['parentRecrId'] && e['parentRecrId'] > 0 )
		{
			localStorage.setItem('parentRecrId', e['parentRecrId']);
			this.setState({isSessionRecurring: e['parentRecrId']});
		}
	
	if ( e['lock_user_id'] > 0 && e['lock_user_id'] !== userid && (new Date().getTime()) - (new Date(e['lock_time']).getTime()) < 60 * 60 * 4 * 1000 )
		{
			this.setState({
				sessionToEdit: e.id,
				sessionHostEdit: e['lock_user_id'],
				sessionHostEditName: e['lock_name'],
				editSessionStatus: 'pending'
			}, () => {$('#editing_alert_session').attr({'style':'display:block'})});
			
			return false;
		}
	
	let sessionId=e.id;
	
	var isAdmin = this.state.isAdmin;
	if ( this.state.isMarketplaceChannel )
		{
			var channelRole = JSON.parse(localStorage.getItem('userData')).data.responseData.channelRole;
			if ( channelRole !== "QA" )
				{
					isAdmin = 1;
				}
		}
	
	
	if(localStorage.getItem('groupId')==1){
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;	 
	if(new Date().getTime()>new Date(this.state.upcomingSessionArray[indexValue].scheduleDate).getTime()){
		this.props.showMainErrorPop("Class In Progress can not edit Class");
	}else{
	axios.get(process.env.REACT_APP_NAME+"/api/v1/session/"+sessionId+"/sessionUserExist")
	.then(res => {
	  if(res.data.responseData==="yes" || isAdmin == 1){
		//if(userID==='1'){
			let editSessionHostName = JSON.parse(localStorage.getItem('userData')).data.responseData.firstName+' '+JSON.parse(localStorage.getItem('userData')).data.responseData.lastName;
			if(this.state.hostsArray.length > 0)
				{
					for(var a = 0; a<this.state.hostsArray.length; a++)
						{
							if(this.state.hostsArray[a].userId == e.hostId)
								{
									editSessionHostName = e.hostFirstName+' '+e.hostLastName;
								}
						}
				}
			
			localStorage.setItem('hostNameEditSession', editSessionHostName)
			  browserHistory.push("/fitnessdetail/"+sessionId);
		// 	}else{
		// 	browserHistory.push("/winedetail/"+e.target.name);	
		// }
	  }else{
		this.setState({
			responseMessage:'Only Channel Admin or Class Instructor can Edit class.'
		},()=>$("#error_email_msg").attr({'style':'display:block'}))
	  }
	}).catch(err =>{
	console.log('----------there is problem------------',err);
		});
	}
	}else{
		if(new Date().getTime()>new Date(this.state.sessionData[indexValue].scheduleDate).getTime()){
			this.props.showMainErrorPop("Class In Progress can not edit Class");
		}	else{
			browserHistory.push("/winedetail/"+sessionId);
		}

		
	}
	}
	pastSession = (e) =>{
		let pastSessionData = this.state.pastSessionData;
		clearInterval(this.state.intervalID)
		this.setState({
			sessionData:pastSessionData,
			sessionType:'Past',
			toggleDropDown:false,
			sessionData2:[],
			sortedByHost: false,
			sortedBySession: false,
			sortedByDuration: false,
			sortedByLevel: false,
			sortedByinterest: false,
			//sessionPreview: 'list',

		}
		,()=> { console.log("====pastSession",this.state.sessionData); this.sortSessionsAll(this.state.caseForSort); }
		)
		
		$('#searchSessions').val('');
		$('#sortSessions').val('');
	}
	
	loadMorePrevious = (e) => {
		let count_main = this.state.page_count + 1;
		
		this.setState({
			page_count: count_main,
			showLoadMore: true
		});
		
		console.log(count_main);
		let mainthis = this;
		setTimeout(function(){ mainthis.pastSessionInfo(); }, 200);
	}
	pastSessionInfo=(e)=>{
	
	if(this.state.showLoadMore || this.state.applySort)
	  {
		 $('.main_loader').attr({'style':'display:flex'});  
	  }
	
	let count_load = this.state.page_count;
	
	if(this.state.countMaximum)
		{
			count_load = 100000;
		}
	
	let ak = JSON.parse(localStorage.getItem('userData'));
	const userData ={
		email : ak.data.responseData.email,
		type:ak.data.responseData.type,
		id: ak.data.responseData.id,
		sessionDate:'',
		filterHost :this.state.filterHost,
		filterLevel :this.state.filterLevel,
		filterIntrest :this.state.filterIntrest,
		filterEquipment: this.state.filterEquipment,
		filterDuration: this.state.filterDuration,
		searchSessionName: this.state.searchSessionName,
		startDate: this.state.fullStartDate,
		endDate: this.state.fullEndDate,
		count_show: count_load}
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;	 
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/hostPastDashboardData",userData)
	.then(res => {
		$('.main_loader').hide();
	  if(res.data.responseMessage === "success"){
		  
		  
		  this.setState({pastSessionLength: res.data.responseData.dataLength, applySort: false});
		  
		  console.log(res);
	if (res.data.responseData.sessionData.length === 0 && this.state.sessionType === 'Past' )	
		{
			console.log(this.state);
			this.setState({
					sessionData:[],
					pastSessionsCounter: 0,
					currentShownPast: 0
			});
		}		
	if(res.data.responseData.sessionData.length>0)
		{
				this.setState({
					pastSessionData:res.data.responseData.sessionData,
					pastlatestSessionData:res.data.responseData.sessionData,
					currentShownPast: res.data.responseData.shownLength,
					dataLength: res.data.responseData.dataLength,
					pastSessionsCounter: res.data.responseData.dataLength,
					sortedByHost: false,
					sortedBySession: false,
					sortedByDuration: false,
					sortedByLevel: false,
					sortedByinterest: false,
					//sessionPreview: 'list',
				}
				,()=> {
					
					if ( $('.pastsesmenu').hasClass('active') ) { this.pastSession(); }
					else{ this.sortSessionsAll(this.state.caseForSort); }
					
				} )
				console.log(this.state.shownLength, this.state.dataLength, 'LEEEEEEEENGTH');
		}
	else 
		{
			this.setState({
					pastSessionData:res.data.responseData.sessionData,
					pastlatestSessionData:res.data.responseData.sessionData });
		}	
		
	}else{
	 console.log('=============There Is an Error===================>'); 
	}
	}).catch(err =>{
	console.log('----------there is problem------------',err);
		});
	}
 pastScheduleDateMethod=e=>{
	let count_load = this.state.page_count;
	let ak = JSON.parse(localStorage.getItem('userData'));
	const userData ={
		email : ak.data.responseData.email,
		type:ak.data.responseData.type,
		id: ak.data.responseData.id,
		sessionDate:this.state.pastSessionData[this.state.pastSessionData.length-1].scheduleDate,
		filterHost :this.state.filterHost,
		filterLevel :this.state.filterLevel,
		filterIntrest :this.state.filterIntrest,
		filterEquipment: this.state.filterEquipment,
		filterDuration: this.state.filterDuration,
		searchSessionName: this.state.searchSessionName,
		startDate: this.state.fullStartDate,
		endDate: this.state.fullEndDate,
		count_show: count_load}
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;	 
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/hostPastDashboardData",userData)
	.then(res => {
	  console.log('---------PastSession-------------->',res.data.responseData.sessionData);
	 if(res.data.responseMessage === "success"){
	if(res.data.responseData.sessionData.length>0){	 
	this.setState({
		pastSessionData:res.data.responseData.sessionData,
		shownLength: res.data.responseData.shownLength,
		dataLength: res.data.responseData.dataLength
	},()=>console.log(Intl.DateTimeFormat().resolvedOptions().timeZone,this.state.sessionData))
	}
	}else{
	 console.log('=============There Is an Error===================>'); 
	}
	}).catch(err =>{
	console.log('----------there is problem------------',err);
		});
 }
 memeberData=(e)=>{
	let channdlId=JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
	//channdlId=1;
	if(!channdlId){
		console.log('======sas=======',localStorage.getItem('channelId'))

		channdlId=localStorage.getItem('channelId');
	}
	//console.log('===s===sas=======',localStorage.getItem('channelId'))
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;	 
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getMemberChannelWise", {'channelId': localStorage.getItem('channelId'), 'searchText': this.state.searchValue})          
	.then(res => {
	 // console.log('---------ChannelData-----------result--->',res.data.responseData);
	 if(res.data.responseMessage === "success"){
	if(res.data.responseData.length>1){
		let ak=[];
		let k=0;
		for(k;k<=1;k++){
			
			ak.push(res.data.responseData[k])
		}	 
	this.setState({
		memeberData:ak
	})
	}
	if(res.data.responseData.length===1){
		this.setState({
			memeberData:res.data.responseData
		})
	}
	}else{
	 console.log('=============There Is an Error==ChannelData=================>'); 
	}
	}).catch(err =>{
	console.log('----------there is problem-----Channel Data-------',err);
		});
 }
 addMembers=(e)=>{
	if (this.memeberValidator.allValid() ) {
	$('.main_loader').attr({'style':'display:flex'}); 
    let channelId = JSON.parse(localStorage.getItem('userData')).data.responseData.channel;
	if(!channelId){
		channelId=localStorage.getItem('channelId');
	}
	const userData ={
        channelId:channelId,
        firstName:this.state.firstName,
        lastName:this.state.lastName,
		email:this.state.email}
	//	console.log(userData);
     let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
    axios.defaults.headers.common['Authorization'] = token;
    axios.post(process.env.REACT_APP_NAME+"/api/v1/session/member",userData)
    .then(responseData => {
         this.memeberData();
		 $('.main_loader').hide();
        $("#add_member_popup").attr({'style':'display:block'});
        $("#members_addModal").attr({'style':'display:none'});
    }).catch(err =>{
		$('.main_loader').hide();
       // console.log('----------there is problem------------',err.response.data.errorData);
	if(err.response.data.responseCode===417){
		$("#add_member_popup2").attr({'style':'display:block'});
	}	
	});
 	}else{
		this.memeberValidator.showMessages();
		if(!this.memeberValidator.fieldValid('First name') ){
			this.setState({
			  errorClass_MemberFirstName:true
			})
			this._newMemberFirstName.focus();
		}
		if(!this.memeberValidator.fieldValid('Last Name') ){
			this.setState({
			  errorClass_MemberLastName:true
			})
			this._newMemberLastName.focus();
		}
		if(!this.memeberValidator.fieldValid('Email Id') ){
			this.setState({
			  errorClass_MemberEmail:true
			})
			this._newMemberEmail.focus();
		  }
	  // rerender to show messages for the first time
	  // you can use the autoForceUpdate option to do this automatically`
	  this.forceUpdate();
	 }	
    
  }
	ChannelData=(e)=>{
		    
			
		
			console.log('Channel Data----------------');
			let refreshUpcoming = localStorage.getItem('refreshUpcoming');
			localStorage.removeItem('refreshUpcoming');
			let ak = JSON.parse(localStorage.getItem('userData'));
			let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
			axios.defaults.headers.common['Authorization'] = token;	 
			axios.get(process.env.REACT_APP_NAME+"/api/v1/session/channelList/Host")
			.then(res => {
				console.log('--------------------HOST',res);
			  console.log('---------ChannelData-------------->',res.data.responseData);
			 if(res.data.responseMessage === "success"){
				$('.main_loader').hide();
			let adminpath2 = process.env.REACT_APP_PATH;
			adminpath2 = adminpath2.replace('http://', '.').replace('https://', '.');
			
			this.setState({ 
				fullsubpath: adminpath2, 
				channelName: ( res.data.responseData && res.data.responseData[0] && res.data.responseData[0].name ) ? res.data.responseData[0].name : '', 
				sessionChannelName: ( res.data.responseData && res.data.responseData[0] && res.data.responseData[0].name ) ? res.data.responseData[0].name : ''
			});
				 
				 
			if(res.data.responseData.length>0){
			//localStorage.setItem("channelId",res.data.responseData[0].id)		 
			localStorage.setItem("channelId",res.data.responseData[0].id)
			localStorage.setItem("groupId",res.data.responseData[0].groupId)
			localStorage.setItem("channelInterest",res.data.responseData[0].channelInterest[0].id)
			localStorage.setItem("is_admin",res.data.responseData[0].is_admin)
			localStorage.setItem("channelCouponType", res.data.responseData[0].couponType)
			
			this.setState({
				channelData: res.data.responseData,
				totalSessionAll: res.data.responseData[0] ? res.data.responseData[0].totalUpcomming : 0,
				invsub_heading: res.data.responseData[0] ? res.data.responseData[0].signup_text : '',
				invsub_text: res.data.responseData[0] ? res.data.responseData[0].signup_welcome_text : '',
				isAdmin: res.data.responseData[0].is_admin
			});
			
			localStorage.setItem('channelSessionCount', res.data.responseData[0] ? res.data.responseData[0].totalUpcomming : 0);
			
			if ( res.data.responseData[0] && res.data.responseData[0].id )
				{
					var mainthis = this;
					mainthis.updatesse('channelId,userId', [res.data.responseData[0].id, ak.data.responseData.id], function ()
						{
							let ak = JSON.parse(localStorage.getItem('userData'));
							if ( !ak )
								{
									window.location = '/';
									return false;
								}
							
							var url = window.location.href;
							if ( url.toLowerCase().indexOf('/dashboardlanding') < 0 ) { return false; }
							
							if ( $('.ondemandmenu').hasClass('active') )
								{
									mainthis.getOnDemandsSessions();
								}
							else if ( $('.pastsesmenu').hasClass('active') )
								{
									mainthis.pastSessionInfo();
								}
							else
								{
									if ( mainthis.state.isFromCancel ) { mainthis.updateThisWeek(mainthis.state.cancelSessionDate); mainthis.setState({isFromCancel: false}); }
									else { mainthis.refreshThisWeek(); }
								}
							
							if ( JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace && JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace == 1 )
								{
									mainthis.getReviewCount();
								}		
							
							mainthis.ChannelData();
							mainthis.getReviewCount();
						});
				}
				
			if ( refreshUpcoming )
				{
					if ( this.state.isFromCancel ) { this.updateThisWeek(this.state.cancelSessionDate); this.setState({isFromCancel: false}); }
					else { this.refreshThisWeek(); }
				}
			
			if ( res.data.responseData[0].subdomain )
				{
					let adminpath = process.env.REACT_APP_PATH;
					adminpath = adminpath.replace('http://', '.').replace('https://', '.');
					
					this.setState({
									fullsubdomain: res.data.responseData[0].subdomain + adminpath,
									fullsubdomaininvite: res.data.responseData[0].subdomain + '-signup' + adminpath,
									fullsubdomainbrand: res.data.responseData[0].subdomain + '-ba' + adminpath,
									hasAmbassador: res.data.responseData[0].hasAmbassador ? 1 : ''
									},
					() => {})
					
				}
			}
			if(res.data.responseData.length===1){
				localStorage.setItem("channelId",res.data.responseData[0].id)
				localStorage.setItem("groupId",res.data.responseData[0].groupId)
				localStorage.setItem("channelInterest",res.data.responseData[0].channelInterest[0].id)
						
				this.setState({
					channelData: res.data.responseData,
					
					invsub_heading: res.data.responseData[0] ? res.data.responseData[0].signup_text : '',
					invsub_text: res.data.responseData[0] ? res.data.responseData[0].signup_welcome_text : '',
					
					channelInterestSelection:res.data.responseData[0].channelInterest,
					channelInterestSubcategories:res.data.responseData[0].channelInterestSubcategories,
					sessionTypeRedirectTo:res.data.responseData[0].groupId===1?'/FitnessSessionCreation':'/FitnessSessionCreation'
				})
				
				if ( res.data.responseData[0].subdomain )
					{
						let adminpath = process.env.REACT_APP_PATH;
						adminpath = adminpath.replace('http://', '.').replace('https://', '.');
						
						this.setState({
										fullsubdomain: res.data.responseData[0].subdomain + adminpath,
										fullsubdomaininvite: res.data.responseData[0].subdomain + '-signup' + adminpath,
										fullsubdomainbrand: res.data.responseData[0].subdomain + '-ba' + adminpath,
										hasAmbassador: res.data.responseData[0].hasAmbassador ? 1 : ''
									}, () =>
						{})
					}
				
				}
				
			
			this.props.setUserChannel(this.state.channelData, this.state.fullsubdomain );
			
			}else{
					console.log('=============There Is an Error==ChannelData=================>'); 
					$('.main_loader').hide();
			}
			
				// Set Marketplace channel role ! //
				if ( res.data.responseData[0] && res.data.responseData[0].channelRole !== "" )
					{
						var currentChannelRole = res.data.responseData[0].channelRole;
						var userData = JSON.parse(localStorage.getItem('userData'));
						userData.data.responseData.channelRole = currentChannelRole;
						localStorage.setItem("userData", JSON.stringify(userData));
						var permissions = this.userPermissions(currentChannelRole);
						this.setState({userPermissions: permissions});
					}					
			}).catch(err =>{
					console.log('----------there is problem-----Channel Data-------',err);
					$('.main_loader').hide();
				});
			}
		


	upcomingSession=(e)=>{
		if(this.state.lastestSessionData.length===0){
			console.log(this.state.latestUpComingSession);
			let scheduleDate= '';
			if ( this.state.sessionCurrentWeek && this.state.sessionCurrentWeek[6] )
				{
					scheduleDate = this.state.sessionCurrentWeek[6].scheduleDate;
				}
			
			this.setState({
				// sessionData:this.state.lastestSessionData,
				//sessionData:this.state.latestUpComingSession,
				sessionData:[],
				sessionData2:this.state.sessionCurrentWeek,
				sessionCurrentWeek:this.state.sessionCurrentWeek,
				//lastestSessionData:res.data.responseData.sessionData,
				totalSession:0,
				latestUpComingSession:[],
				totalUpcomingSession:0,
	
				sessionType:'Upcoming',
				//sessionPreview: 'list',
				upComingScheduleDate:scheduleDate,
				updateForSession:"refreshThisWeek",
				sortedByHost: false,
				sortedBySession: false,
				sortedByDuration: false,
				sortedByLevel: false,
				sortedByinterest: false,
			})
		}else{	
				this.setState({
							//sessionData:this.state.latestUpComingSession,
							sessionType:'Upcoming',
							//sessionPreview: 'list',
							toggleDropDown:false,

							sessionData:this.state.lastestSessionData,
							latestUpComingSession : this.state.lastestSessionData,
							sessionData2:this.state.dateForUpcomingSession,
							totalSession:this.state.totalUpcomingSession,
							upComingScheduleDate:this.state.lastUpcomingDate,
							updateForSession:"refreshThisWeek",
							sortedByHost: false,
							sortedBySession: false,
							sortedByDuration: false,
							sortedByLevel: false,
							sortedByinterest: false,
							intervalID:setTimeout(() => {
								this.setStartDate(this.state.upComingScheduleDate);
							  }, 10000)
				
					})			
		this.props.setUserChannel(false, false, this.state.totalUpcomingSession);
		
		$('#searchSessions').val('');
		$('#sortSessions').val('');
	}
	
	this.refreshThisWeek();
 }
 
 onDemand=(e)=>{
	if ( this.state.channelData.length > 1 )
		{
			this.setState({
				channelInterestSelection : this.state.channelData[0].channelInterest
			});
		}
	
	
	  this.setState({
		sessionData:[],
		sessionType:'On Demand',
		toggleDropDown:false,
		sessionData2:[],
		sortedByHost: false,
		sortedBySession: false,
		sortedByDuration: false,
		sortedByLevel: false,
		sortedByinterest: false,
		filterHost: '',
		filterIntrest: '',
		filterEquipment: '',
		filterDuration: '',
		filterLevel: '',
		searchSessionName: '',
		pickedDate: '',
		pickedStartDate: '',
		pickedEndDate: '',
		fullStartDate: '',
		fullEndDate: '',
		expandSearchStatus: false,
		selectedinterestsNamesArray : [],
		selectedinterestsNames : '',
		newInstrestSelected : [],
		searchedBy: '',
		countMaximum: false
		//sessionPreview: 'list',
	}, () => {
		this.getOnDemandsSessions();
	});
 
	$('#searchSessions').val('');
	$('#sortSessions').val('');
 }
 
 
checkCanEditChannel = (e) => {
	
	let userid = JSON.parse(localStorage.getItem('userData')).data.responseData.id
	
	if ( e['lock_user_id'] > 0 && e['lock_user_id'] !== userid && (new Date().getTime()) - (new Date(e['lock_time']).getTime()) < 60 * 60 * 4 * 1000 )
		{
			this.setState({ responseMessage: 'Channel is currently being edited by ' + e['lock_name'] + ' and can not be edited on two places at the same time.' },()=>$("#error_email_msg").attr({'style':'display:block'}));
			return false;
		}
	
	browserHistory.push('/editChannel/'+e.id);
}
 
isNumeric(value) {
    return /^-{0,1}\d+$/.test(value);
}


importDemandImage=(ev, type)=>{
	let file = ev.target ? ev.target.files[0] : ev;
	if ( type === 'image' && ( file.type === 'image/png' || file.type === 'image/jpeg' ) )
		{
			var olddmnd = this.state.hasOnDemandFiles;
			olddmnd['image'] = file;
			
			this.setState({hasOnDemandFiles: olddmnd});
			
			var mthis = this;
			var fr=new FileReader();
			fr.onload = function(e)
				{
					mthis.setState({onDemandImage: this.result});
				};
			fr.readAsDataURL(file);
		}
	else if ( type === 'video' && ( file.type === 'video/mp4' || file.type === 'video/3gpp' || file.type === 'video/quicktime' || file.type === 'video/mpeg' || file.type === 'video/webm' || file.type === 'video/x-ms-wmv' ) )
		{ 
			var kilobytes = file.size / 1024;
			var megabytes = kilobytes / 1024;
			var gigabyites = Math.round(megabytes / 1024);
			
			if ( gigabyites > 4 )
				{
					this.props.showMainErrorPop('Maximum File size for On Demand video is 4GB.');
					return false;
				}
			
			if ( file.size > 400 * 1024 * 1024 )
				{
					console.log('size', file.size);
					
					if (  file.type === 'video/mp4' )
						{
							console.log('large');
							
							var fName = file.name.split('.');
							var newFileName = 'ondemand/'+fName[0]+'_'+(new Date()).getTime()+'.mp4';
							
							$('.main_loader').attr({'style':'display:flex'});
							axios.post(process.env.REACT_APP_NAME+"/api/v1/upload/createLink", {'fileName': newFileName})
									 .then(res => {
											if ( res && res.data && res.data.result && res.data.result.link && res.data.result.fullLink )
												{
													var olddmnd = this.state.hasOnDemandFiles;
													olddmnd['video'] = file;
													olddmnd['videoLarge'] = true;
													olddmnd['videoLargeLink'] = res.data.result.link;
													olddmnd['videoLargeFile'] = res.data.result.fullLink;
													
													this.setState({hasOnDemandFiles: olddmnd});
												}
											else
												{
													this.props.showMainErrorPop('Something went wrong. Please check your Internet connection and try again.');
												}
											
										$('.main_loader').hide();
									 });
						}
					else
						{
							this.props.showMainErrorPop("Maximum file size is 400MB for non-MP4 videos.");
						}
				}
			else
				{
					var olddmnd = this.state.hasOnDemandFiles;
					olddmnd['video'] = file;
					olddmnd['videoLarge'] = false;
					olddmnd['videoLargeLink'] = '';
					olddmnd['videoLargeFile'] = '';
					
					this.setState({hasOnDemandFiles: olddmnd});
				}
		}
	else
		{
			if ( type === 'video' )
				{
					var olddmnd = this.state.hasOnDemandFiles;
					olddmnd['video'] = '';
			
					this.setState({
							onDemandVideoName: '',
							hasOnDemandFiles: olddmnd
						}, ()=>{ this.props.showMainErrorPop("This format is not supported."); });
				}
			else
				{
					this.props.showMainErrorPop("This format is not supported.");
				}
		}
		
	console.log(file.type);
}

importDemandImageCut=(image)=>{
	
	var arr = image.split(','), mime = arr[0].match(/:(.*?);/)[1],
	bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
	while(n--){
	u8arr[n] = bstr.charCodeAt(n);
	}
	
	var newblb = new Blob([u8arr], {type:mime});
	
	this.importDemandImage(newblb, 'image');
}

uploadImageAndVideo=()=>{
	if ( this.state.hasOnDemandFiles.image == '' && this.state.hasOnDemandFiles.video == '' ) { this.saveOnDemandSession(); return true; }
	
	let file = null;
	var olddmnd = this.state.hasOnDemandFiles;
	var type = 'image';
	
	if ( this.state.hasOnDemandFiles.image !== '' ) { file = this.state.hasOnDemandFiles.image; olddmnd['image'] = '';  }
	else if ( this.state.hasOnDemandFiles.video !== '' ) { file = this.state.hasOnDemandFiles.video; olddmnd['video'] = ''; type = 'video'; }
	
	if ( type == 'video' && olddmnd['videoLarge'] )
		{
			$('.main_loader').attr({'style':'display:flex'});
			
			delete axios.defaults.headers.common['Authorization'];
			axios.put(olddmnd['videoLargeLink'], file, {headers: {'Content-Type': 'video/mp4'}}).then(res => {
				
							$('.main_loader').hide();
							
							this.setState({onDemandVideoName: olddmnd['videoLargeFile'], errorDemandVideo: false }, function () { this.uploadImageAndVideo(); $('.main_loader').hide(); })
							
							let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
							axios.defaults.headers.common['Authorization'] = token; // return old token
							
						}).catch(err =>{ 
							$('.main_loader').hide();
							console.log(err);
							this.props.showMainErrorPop('Something went wrong. Please check your Internet connection and try again.');
							
							let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
							axios.defaults.headers.common['Authorization'] = token; // return old token
						});
			
			olddmnd['videoLarge'] = false;
			this.setState({ onDemandVideo: '', hasOnDemandFiles: olddmnd });
		}
	else
		{
			let reader = new FileReader();
			let canAddAttach = false;
			reader.onloadend = () => {
				var uplink = process.env.REACT_APP_NAME+"/api/v1/upload/upload";
				if ( type === 'image' && (file.type === 'image/png' || file.type === 'image/jpeg') )
					{
						this.setState({ onDemandImage: reader.result, hasOnDemandFiles: olddmnd });
						canAddAttach = true;
					}
				else if ( type === 'video' && ( file.type === 'video/mp4' || file.type === 'video/3gpp' || file.type === 'video/quicktime' || file.type === 'video/mpeg' || file.type === 'video/webm' || file.type === 'video/x-ms-wmv' ) )
					{
						this.setState({ onDemandVideo: reader.result, hasOnDemandFiles: olddmnd });
						canAddAttach = true;
						uplink = process.env.REACT_APP_NAME+"/api/v1/upload/uploadondemand";
					}
					
				if ( canAddAttach )
					{
						 $('.main_loader').attr({'style':'display:flex'});
						 
						 let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
						 axios.defaults.headers.common['Authorization'] = token;
						 
						 const data = new FormData();
						 data.append('file', file);
						 data.append('onDemand', 1);
						 axios.post(uplink, data)
						 .then(res => {
							$('.main_loader').hide();
							if ( type === 'image' ) { this.setState({ onDemandImageName: res.data.responseData.file, errorDemandImage: false}, function () { this.uploadImageAndVideo(); }) }
							else { this.setState({ onDemandVideoName: res.data.responseData.file, onDemandVideoSavePath: res.data.responseData.uploadURL, errorDemandVideo: false }, function () { this.uploadImageAndVideo(); }) }
							 
						 }).catch(err =>{ 
								$('.main_loader').hide();  
								$('#upload_error').attr({'style':'display:block'});
								if ( err.response.data.errorData !== '' )
									{
										this.setState({ uploadError: err.response.data.errorData }); 
									}
								else
									{
										this.setState({ uploadError: 'Something went wrong. Please check your Internet connection and try again.' }); 
									}
						})
					}
				else
					{
						$('#upload_error').attr({'style':'display:block'});
						this.setState({ uploadError: 'Please enter valid '+type+' format' }); 
					}
			 }
			  
			reader.readAsDataURL(file);
		}
}

openAddingOnDemand=(e)=>{
	let isChannelMarketPlace = JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace;
	
	this.setState({
				isChannelMarketPlace : isChannelMarketPlace
			});
	
	let hostNameOnDemand = JSON.parse(localStorage.getItem('userData')).data.responseData.firstName+' '+JSON.parse(localStorage.getItem('userData')).data.responseData.lastName;
	let hostIdOnDemand = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
	this.setState({
		openAddDemand: true,
		onDemandHostId: hostIdOnDemand,
		onDemandHostName: hostNameOnDemand
	});
}

cancelAddingnDemand=(e)=>{
	this.setState({
		openAddDemand: false,
		onDemandName: '',
		onDemandLevel: '',
		onDemandMemberCharges: 0,
		onDemandNonMemberCharges: 0,
		onDemandDescription: '',
		onDemandGoal: '',
		onDemandInterest: [],
		onDemandEquipment: '',
		autoDeleteAfter: '',
		onDemandImage: '',
		onDemandImageName: '',
		onDemandVideo: '',
		onDemandVideoName: '',
		onDemandVideoSavePath: '',
		errorSessionOnDemand: false,
		demandToEdit: 0,
		errorDemandName: false,
		errorDemandLevel: false,
		errorDemandMembersCharges: false,
		errorDemandNonMembersCharges: false,
		errorDemandImage: false,
		errorDemandDescription: false,
		errorDemandVideo: false,
		errorDemandGoal: false,
		errorDemandInterest: false,
		errorDemandEquipment: false,
		errorDemandAutoDelete: false,
		expandNewIntrest: false,
		selectedinterestsNamesDemand: '',
		selectedinterestsNamesArrayDemand: [],
		onDemandHostId: 0,
		onDemandHostName: '',
		expandNewIntrestSubcategories: false
	});
}

saveOnDemandSession=(e)=>{
	var canAddDemand = true;
	
	this.setState({
		errorSessionOnDemand: false,
		errorDemandName: false,
		errorDemandLevel: false,
		errorDemandMembersCharges: false,
		errorDemandNonMembersCharges: false,
		errorDemandImage: false,
		errorDemandDescription: false,
		errorDemandVideo: false,
		errorDemandGoal: false,
		errorDemandInterest: false,
		errorDemandEquipment: false,
		errorDemandAutoDelete: false
	});
	
	// !this.isNumeric(this.state.onDemandMemberCharges) || !this.isNumeric(this.state.onDemandNonMemberCharges) (add later to if)
	
	if ( this.state.onDemandName.trim() === '' || this.state.onDemandLevel == '' || this.state.onDemandDescription.trim() === '' || this.state.onDemandInterest.length === 0 || this.state.autoDeleteAfter === '' || this.state.onDemandVideoName === '' )
		{
			canAddDemand = false;
			
			this.setState({
				errorSessionOnDemand: true
			});
			
			if ( this.state.onDemandName.trim() === '' ) { this.setState({ errorDemandName: true }); }
			if ( this.state.onDemandLevel === '' ) { this.setState({ errorDemandLevel: true }); }
			/* if ( !this.isNumeric(this.state.onDemandMemberCharges) ) { this.setState({ errorDemandMembersCharges: true }); }
			if ( !this.isNumeric(this.state.onDemandNonMemberCharges) ) { this.setState({ errorDemandNonMembersCharges: true }); } */
			if ( this.state.onDemandDescription.trim() === '' ) { this.setState({ errorDemandDescription: true }); }
			/* if ( this.state.onDemandGoal === '' ) { this.setState({ errorDemandGoal: true }); } */
			if ( this.state.onDemandInterest.length === 0 ) { this.setState({ errorDemandInterest: true }); }
			/* if ( this.state.onDemandEquipment === '' ) { this.setState({ errorDemandEquipment: true }); } */
			if ( this.state.autoDeleteAfter === '' ) { this.setState({ errorDemandAutoDelete: true }); }
			/* if ( this.state.onDemandImageName === '' ) { this.setState({ errorDemandImage: true }); } */
			if ( this.state.onDemandVideoName === '' ) { this.setState({ errorDemandVideo: true }); }
		}
	
	if ( canAddDemand )
		{
			this.setState({
				expandNewIntrest: false,
				expandChangeHost: false
			});
			//var selectedInterest = $("#onDemandInterest").val(this.state.onDemandInterest).find("option[value=" + this.state.onDemandInterest +"]")[0].innerHTML;
			
			let objectToInsert = {
									'channelId': JSON.parse(localStorage.getItem('userData')).data.responseData.channel,
									'hostId': this.state.onDemandHostId > 0 ? this.state.onDemandHostId : JSON.parse(localStorage.getItem('userData')).data.responseData.id,
									'sessionId': 0,
									'name': this.state.onDemandName.trim(),
									'level': this.state.onDemandLevel,
									'memberCharges': 0, //this.state.onDemandMemberCharges,
									'nonMemberCharges': 0, //this.state.onDemandNonMemberCharges,
									'description': this.state.onDemandDescription.trim(),
									'goal': this.state.onDemandGoal.trim(),
									'image': this.state.onDemandImageName,
									'video': this.state.onDemandVideoName,
									'videoConvert': this.state.onDemandVideoSavePath,
									'interest': JSON.stringify(this.state.onDemandInterest),
									'equipment': this.state.onDemandEquipment.trim(),
									'autoDelete': this.state.autoDeleteAfter,
									'duration': 0,
									'editId': this.state.demandToEdit,
									"channelPublish": this.state.sessionPublishPage == true ? 1 : 0,
									"channelPrivateMemberOnly": this.state.sessionPrivateMemberOnly == true ? 1 : 0,
									'processed': this.state.hasOnDemandFiles['videoLargeFile'] ? 1 : 0,
									"interestSubcategories": JSON.stringify(this.state.onDemandInterestSubcategories)
								};
							
			$('.main_loader').attr({'style':'display:flex'});
			
			let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
			axios.defaults.headers.common['Authorization'] = token;
			
			if ( this.state.demandToEdit == 0 )
				{
					axios.post(process.env.REACT_APP_NAME+"/api/v1/session/addOnDemand", objectToInsert)
					 .then(res => {
						 this.setState({
							 onDemandInterest : [],
							 selectedinterestsNamesDemand: '',
							 selectedinterestsNamesArrayDemand: [],
							 onDemandHostId: 0,
							 onDemandHostName: ''
						 });
						this.getOnDemandsSessions(); 
						this.cancelAddingnDemand();
						$('.main_loader').hide();
					 }).catch(err =>{ $('.main_loader').hide(); })
				}
			else
				{
					axios.post(process.env.REACT_APP_NAME+"/api/v1/session/updateOnDemand", objectToInsert)
					 .then(res => {
						  this.setState({
							 onDemandInterest : [],
							 selectedinterestsNamesDemand : '',
							 selectedinterestsNamesArrayDemand: [],
							 onDemandHostId: 0,
							 onDemandHostName: ''
						 });
						this.getOnDemandsSessions(); 
						this.cancelAddingnDemand();
						$('.main_loader').hide();
					 }).catch(err =>{ $('.main_loader').hide(); })
				}
		}
}


checkUpdateODSessions = () => {
	
	let mainArray = this.state.mainDemandObject;
	let hasChanges = false;
	let hasAnyItem = false;
	
	for ( let itm in mainArray['allDemandSession'] )
		{
			if ( mainArray['allDemandSession'][itm]['processed'] == 0 && mainArray['allDemandSession'][itm]['progressBarData'] && mainArray['allDemandSession'][itm]['progressBarDuration'] )
				{
					hasAnyItem = true;
					var timePassed = (new Date().getTime() - mainArray['allDemandSession'][itm]['progressBarData']) + this.state.ODCheckCorrection;
					var videoDurationAdd = parseInt(mainArray['allDemandSession'][itm]['progressBarDuration'])+120000; // Add at least one minute more
					var getPercent = (timePassed / (videoDurationAdd/2))*100;
					
					if ( getPercent < 0 ) { getPercent = 0; }
					if ( getPercent > 100 ) { getPercent = 100; }
					
					if ( getPercent !== mainArray['allDemandSession'][itm]['progressBarValue'] )
						{
							hasChanges = true;
							mainArray['allDemandSession'][itm]['progressBarValue'] = getPercent;
						}
				}
		}
	
	if ( hasChanges )
		{
			this.setState({mainDemandObject: mainArray});
		}
	
	if ( !hasAnyItem )
		{
			if ( this.state.ODCheckInterval ) { clearInterval(this.state.ODCheckInterval); }
		}
}

getOnDemandsSessions=(title)=>{
	if(this.state.applySort)
		{
			$('.main_loader').attr({'style':'display:flex'});
		}
	
	
	let data = {
				channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel, 
				userId: JSON.parse(localStorage.getItem('userData')).data.responseData.id, 
				filterHost :this.state.filterHost,
				filterLevel :this.state.filterLevel,
				filterIntrest :this.state.filterIntrest,
				filterEquipment: this.state.filterEquipment,
				filterDuration: this.state.filterDuration,
				searchSessionName: this.state.searchSessionName,
				startDate: this.state.fullStartDate,
				endDate: this.state.fullEndDate
			}
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getAllOnDemand", data)
	 .then(res => {
		 
		$('.main_loader').hide();
		let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
		let loginId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
		
		
		 var alldemand = [];
		 
		 var hasPendingOD = false;
		 var correctTimeOD = 0;
		 if ( res.data.responseData['allDemandSession'] )
			 {
				 for ( let dem in res.data.responseData['allDemandSession'] )
					 {
						 if ( res.data.responseData['allDemandSession'][dem]['processed'] == 0 && res.data.responseData['allDemandSession'][dem]['progressBarData'] && res.data.responseData['allDemandSession'][dem]['progressBarDuration'] )
							{
								hasPendingOD = true;
								if ( res.data.responseData['allDemandSession'][dem]['progressBarTime'] )
									{
										correctTimeOD = res.data.responseData['allDemandSession'][dem]['progressBarTime'] - new Date().getTime();
									}
							}
						 
							res.data.responseData['allDemandSession'][dem]['deeplink'] = process.env.REACT_APP_URL+'auto-login'+'/'+loginId+'/'+token + '/' +res.data.responseData['allDemandSession'][dem]['id'];
							
							if ( android )
								{
									//res.data.responseData['allDemandSession'][dem]['deeplink'] = process.env.REACT_APP_URL+'auto-login'+'/'+loginId+'/'+token + '/' +res.data.responseData['allDemandSession'][dem]['id'];
									res.data.responseData['allDemandSession'][dem]['deeplink'] = 'intent://virdio/?userId='+loginId+'&userToken='+token+'&onDemandId='+res.data.responseData['allDemandSession'][dem]['id']+'#Intent;package=mvvm.f4wzy.com.virdioApp;scheme=open_app;end;';
								}
							else if ( ios )
								{
									res.data.responseData['allDemandSession'][dem]['deeplink'] = process.env.REACT_APP_URL+"auth/?userId="+loginId+"&token="+token + '&ondemandid=' +res.data.responseData['allDemandSession'][dem]['id'];
								}
							else if ( osname == "Windows" || osname == "Mac OS" )
								{
									res.data.responseData['allDemandSession'][dem]['electronlink'] = 'virdioapp://login?userId='+loginId+'&userToken='+token+'&ondemandId='+res.data.responseData['allDemandSession'][dem]['id'];
								}
					 }
			 }
			
		if ( res.data.responseData['allDemandSessionsByInterest'] )
			{
				for ( let dem in res.data.responseData['allDemandSessionsByInterest'] )
					{
						if ( res.data.responseData['allDemandSessionsByInterest'][dem] )
							{
								for ( let dem2 in res.data.responseData['allDemandSessionsByInterest'][dem] )
									{
										res.data.responseData['allDemandSessionsByInterest'][dem][dem2]['deeplink'] = process.env.REACT_APP_URL+'auto-login'+'/'+loginId+'/'+token + '/' +res.data.responseData['allDemandSessionsByInterest'][dem][dem2]['id'];
										
										if ( android )
											{
												//res.data.responseData['allDemandSessionsByInterest'][dem][dem2]['deeplink'] = process.env.REACT_APP_URL+'auto-login'+'/'+loginId+'/'+token + '/' +res.data.responseData['allDemandSessionsByInterest'][dem][dem2]['id'];
												res.data.responseData['allDemandSessionsByInterest'][dem][dem2]['deeplink'] = 'intent://virdio/?userId='+loginId+'&userToken='+token+'&onDemandId='+res.data.responseData['allDemandSessionsByInterest'][dem][dem2]['id']+'#Intent;package=mvvm.f4wzy.com.virdioApp;scheme=open_app;end;';
											}
										else if ( ios )
											{
												res.data.responseData['allDemandSessionsByInterest'][dem][dem2]['deeplink'] = process.env.REACT_APP_URL+"auth/?userId="+loginId+"&token="+token + '&ondemandid=' +res.data.responseData['allDemandSessionsByInterest'][dem][dem2]['id'];
											}
										else if ( osname == "Windows" || osname == "Mac OS" )
											{
												res.data.responseData['allDemandSessionsByInterest'][dem][dem2]['electronlink'] = 'virdioapp://login?userId='+loginId+'&userToken='+token+'&ondemandId='+res.data.responseData['allDemandSessionsByInterest'][dem][dem2]['id'];
											}
									}
							}
					}
			}
			
		 
		 this.setState({
				mainDemandObject: res.data.responseData,
				ODCheckCorrection: correctTimeOD,
				applySort: false
			}, () => {
				
		
				if ( this.state.ODCheckInterval ) { clearInterval(this.state.ODCheckInterval); }
				if ( hasPendingOD )
					{
						var mainThis = this;
						var odref = setInterval(function () { mainThis.checkUpdateODSessions(); }, 1000);
						this.setState({ODCheckInterval: odref}, () => { mainThis.checkUpdateODSessions(); });
					}
			});
			
		if ( res.data.responseData.allDemandSession.length > 25 && res.data.responseData.allInterests.length > 1 )
			{
				this.setState({
					gridDemandView: true,
					hasGridView: true
				});
				
				title = title ? title : this.state.ondemandopentitle;
				if ( title ) { this.openGridView(title); }
			}
			
		this.sortDemandSessions(this.state.caseForSort);

	 }).catch(err =>{})
}

openGridView=(title)=>{
	$('#searchDemand').val('');
	
	let mainArray = this.state.mainDemandObject;
	mainArray.allDemandSession = JSON.parse(JSON.stringify(mainArray.allDemandSessionsByInterest[title]));
	
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getTitleId", {"title" : title})
	 .then(res => {
		if ( res.data.responseData )
			{
				//console.log(res.data.responseData[0]);
				if (this.state.sessionType === 'On Demand' )
					{
						this.setState({
							filterIntrest : res.data.responseData[0]['id']
						});
					}
			}
		}).catch(err =>{})
	
	this.setState({
					gridDemandView: false,
					mainDemandObject: mainArray,
					ondemandopentitle: title,
				});
}

closeGridView=()=>{
	$('#searchDemand').val('');
	this.setState({
					gridDemandView: true,
					ondemandopentitle: '',
					filterHost: '',
					filterIntrest: '',
					filterEquipment: '',
					filterDuration: '',
					filterLevel: '',
					pickedDate: '',
					pickedStartDate: '',
					pickedEndDate: '',
					fullStartDate: '',
					fullEndDate: '',
					searchedBy: '',
					countMaximum: false
				}, () => {
					this.getOnDemandsSessions();
				});			
}

preventDef=(e)=> {
	e.preventDefault();
	e.stopPropagation();
};

openDemandDrops=(ev, i, mod)=>{
	ev.preventDefault();
	ev.stopPropagation();
	
	let mainArray = this.state.mainDemandObject;
	var last_open = false;
	$.each(mainArray.allDemandSession, function( a, b ) {
		if ( a != i )
			{
				b['descriptionDropOpen'] = false;
				b['equipmentDropOpen'] = false;
				b['goalDropOpen'] = false;
			}
		else
			{
				if ( b[mod] ) { last_open = true; }
				if ( b['descriptionDropOpen'] ) { b['descriptionDropOpen'] = false; }
				if ( b['equipmentDropOpen'] ) { b['equipmentDropOpen'] = false; }
				if ( b['goalDropOpen'] ) { b['goalDropOpen'] = false; }
				
			}
	});
	
	if( !last_open ){ mainArray.allDemandSession[i][mod] = !mainArray.allDemandSession[i][mod]; }

	this.setState({
				mainDemandObject: mainArray
			});
}

changeDemandScreen=(mod)=>{
	localStorage.setItem('demandPreview', mod);
	this.setState({
				demandPreview: mod
			});
}

changeSessionScreen=(mod)=>{
	console.log('aaaaaaaaaa');
	console.log(this.state.sessionType);
	if ( this.state.sessionType === 'Upcoming' )
		{
			this.setState({
				upcomingSessionPreview : mod
			});
			localStorage.setItem('upcomingSessionPreview', mod);
		}
	else 
		{
			this.setState({
				pastSessionPreview : mod
			});
			localStorage.setItem('pastSessionPreview', mod);
		}		
	this.setState({
				sessionPreview: mod
			});
}

openDeleteDemand=(ev, id)=>{
	ev.preventDefault();
	ev.stopPropagation();
	this.setState({
				demandToDelete: id
			});
			
	$("#delete_demand").attr({'style':'display:block'});
}

openEditDemand=(ev, i)=>{
	ev.preventDefault();
	ev.stopPropagation();
	
	this.setState({
		selectedinterestsNamesArrayDemand : [],
		selectedinterestsNamesDemand: '',
		onDemandInterest : [],
		onDemandInterestSubcategories: [],
		onDemandInterestSubcategoriesNamesArray: [],
		selectedinterestsSubcategoriesNames: ''
	});
	
	if (this.state.channelData.length > 1 )
		{
			this.setState({
				channelInterestSelection : this.state.channelData[0].channelInterest,
			});
		}
	let mainArray = JSON.parse(JSON.stringify(this.state.mainDemandObject.allDemandSession));
	for ( var y = 0; y < mainArray.length; y++ )
		{
			mainArray[y].isOpenViews = false;
		}
		
	var mainDemandObjectVar = this.state.mainDemandObject;
	mainDemandObjectVar.allDemandSession = mainArray;
	this.setState({mainDemandObject: mainDemandObjectVar})
	
	let topOffset = $('#onDemandPart').offset();
	$("html, body").animate({ scrollTop: topOffset.top }, "slow");
	
	setTimeout(function(){ 
		if ( mainArray[i].level !== '' )
			{
				$("#onDemandLevel").val(mainArray[i].level).find("option[value='" + mainArray[i].level +"']").attr('selected', true);
			}
		if ( mainArray[i].autoDelete !== '' )
			{
		$("#autoDeleteAfter").val(mainArray[i].autoDelete).find("option[value='" + mainArray[i].autoDelete +"']").attr('selected', true);
			}
	}, 100);
	
	let interest_titles = '';
	for ( var c = 0; c < mainArray[i].interest_array_titles.length; c++ )
		{
			interest_titles += mainArray[i].interest_array_titles[c] + ', ';
		}
	if ( interest_titles.length > 0 )
			{
				interest_titles = interest_titles.substring(0, interest_titles.length - 2); 
			}

	this.addSubcategories(mainArray[i].interest_array);
			
	this.setState({
		openAddDemand: true,
		onDemandName: mainArray[i].name,
		onDemandLevel: mainArray[i].level,
		onDemandMemberCharges: mainArray[i].memberCharges,
		onDemandNonMemberCharges: mainArray[i].nonMemberCharges,
		onDemandDescription: mainArray[i].description,
		onDemandGoal: mainArray[i].goal,
		onDemandInterest: mainArray[i].interest_array,
		selectedinterestsNamesDemand : interest_titles,
		selectedinterestsNamesArrayDemand : mainArray[i].interest_array_titles,
		onDemandEquipment: mainArray[i].equipment,
		autoDeleteAfter: mainArray[i].autoDelete,
		onDemandImage: mainArray[i].image,
		onDemandImageName: mainArray[i].image,
		onDemandVideo: mainArray[i].video,
		onDemandVideoName: mainArray[i].video,
		errorSessionOnDemand: false,
		demandToEdit: mainArray[i].id,
		editOnDemandVideo: mainArray[i],
		sessionPublishPage: mainArray[i].channelPublish,
		sessionPrivateMemberOnly: mainArray[i].channelPrivateMemberOnly,
		onDemandHostId: mainArray[i].hostId,
		onDemandHostName: mainArray[i].firstName+' '+mainArray[i].lastName,
		onDemandInterestSubcategories: mainArray[i].interest_subcategory_array,
		onDemandInterestSubcategoriesNamesArray: mainArray[i].interest_subcategory_array_titles,
		selectedinterestsSubcategoriesNames: mainArray[i].interest_subcategory_titles,
	});
}

deleteDemandSession=()=>{
	let postdata = {id: this.state.demandToDelete, channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel}
	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/deleteDemandSession", postdata)
	 .then(res => {
		this.getOnDemandsSessions();
		$('.main_loader').hide();
		$("#delete_demand").attr({'style':'display:none'});
		this.props.showMainErrorPop('On-Demand sucessfully deleted.');
	 }).catch(err =>{})
}

sortDemandSessions=(selected)=>{
	//let selected = $('#sortDemand').val();
	let mainArray = this.state.mainDemandObject;
	
	if ( selected === 'by Host' )
		{
			if ( !this.state.sortedByHost )
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.firstName < b.firstName) { return -1; }
						if(a.firstName > b.firstName) { return 1; }
						return 0;
					})
					
					//this.setState({sortedByHost: true});
				}
			else
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.firstName > b.firstName) { return -1; }
						if(a.firstName < b.firstName) { return 1; }
						return 0;
					})
					
					this.setState({sortedByHost: false});
				}
		}
		
	if ( selected === 'by Session' )
		{
			if ( !this.state.sortedBySession )
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.name < b.name) { return -1; }
						if(a.name > b.name) { return 1; }
						return 0;
					})
					
					//this.setState({sortedBySession: true});
				}
			else
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.name > b.name) { return -1; }
						if(a.name < b.name) { return 1; }
						return 0;
					})
					
					this.setState({sortedBySession: false});
				}
		}
		
	if ( selected === 'by Duration' )
		{
			if ( !this.state.sortedByDuration )
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.duration < b.duration) { return -1; }
						if(a.duration > b.duration) { return 1; }
						return 0;
					})
					
					//this.setState({sortedByDuration: true});
				}
			else
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.duration > b.duration) { return -1; }
						if(a.duration < b.duration) { return 1; }
						return 0;
					})
					
					this.setState({sortedByDuration: false});
				}
		}
		
	if ( selected === 'by Level' )
		{
			if ( !this.state.sortedByLevel )
				{
					mainArray.allDemandSession.sort(function(a, b){
						if ( a.level !== null && a.level !== null )
							{
								if(a.level < b.level) { return -1; }
								if(a.level > b.level) { return 1; }
							}
						return 0;
					})
					
					//this.setState({sortedByLevel: true});
				}
			else
				{
					mainArray.allDemandSession.sort(function(a, b){
						if ( a.level !== null && a.level !== null )
							{
								if(a.level > b.level) { return -1; }
								if(a.level < b.level) { return 1; }
							}
						return 0;
					})
					
					this.setState({sortedByLevel: false});
				}
		}
		
	if ( selected === 'by Interest' )
		{
			if ( !this.state.sortedByinterest )
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.interestTitles < b.interestTitles) { return -1; }
						if(a.interestTitles > b.interestTitles) { return 1; }
						return 0;
					})
					
					//this.setState({sortedByinterest: true});
				}
			else
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(a.interestTitles > b.interestTitles) { return -1; }
						if(a.interestTitles < b.interestTitles) { return 1; }
						return 0;
					})
					
					this.setState({sortedByinterest: false});
				}
		}
		
	if ( selected === 'by Recent Date' )
		{
			if ( !this.state.sortedDateRecent )
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()) { return -1; }
						if(new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()) { return 1; }
						return 0;
					})
				}
				
		}
		
	if ( selected === 'by Older Date' )
		{
			if ( !this.state.sortedDateOlder )
				{
					mainArray.allDemandSession.sort(function(a, b){
						if(new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()) { return -1; }
						if(new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()) { return 1; }
						return 0;
					})
				}
				
		}
		
	this.setState({
				mainDemandObject: mainArray
			});
}

toggleSortObjects=(selected)=>{
	if(selected != this.state.choosenSort)
		{
			this.setState({caseForSort: selected, choosenSort: selected});
		}
	
	else
		{
			this.setState({caseForSort: '', choosenSort: ''});
		}
}

sortSessionsAll=(selected)=>{
	//let selected = $('#sortSessions').val();
	let mainArray = this.state.sessionData;
	
	if ( selected === 'by Host' )
		{
			if ( !this.state.sortedByHost )
				{
					mainArray.sort(function(a, b){
						if(a.hostFirstName < b.hostFirstName) { return -1; }
						if(a.hostFirstName > b.hostFirstName) { return 1; }
						return 0;
					})
					
					//this.setState({sortedByHost: true});
				}
			else
				{
					mainArray.sort(function(a, b){
						if(a.hostFirstName > b.hostFirstName) { return -1; }
						if(a.hostFirstName < b.hostFirstName) { return 1; }
						return 0;
					})
					
					this.setState({sortedByHost: false});
				}
		}
		
	if ( selected === 'by Duration' )
		{
			if ( !this.state.sortedByDuration )
				{
					mainArray.sort(function(a, b){
						if(a.duration < b.duration) { return -1; }
						if(a.duration > b.duration) { return 1; }
						return 0;
					})
					
					//this.setState({sortedByDuration: true});
				}
			else
				{
					mainArray.sort(function(a, b){
						if(a.duration > b.duration) { return -1; }
						if(a.duration < b.duration) { return 1; }
						return 0;
					})
					
					this.setState({sortedByDuration: false});
				}
		}
		
	if ( selected === 'by Session' )
		{
			if ( !this.state.sortedBySession )
				{
					mainArray.sort(function(a, b){
						if(a.name < b.name) { return -1; }
						if(a.name > b.name) { return 1; }
						return 0;
					})
					
					//this.setState({sortedBySession: true});
				}
			else
				{
					mainArray.sort(function(a, b){
						if(a.name > b.name) { return -1; }
						if(a.name < b.name) { return 1; }
						return 0;
					})
					
					this.setState({sortedBySession: false});
				}
		}
		
	if ( selected === 'by Level' )
		{
			if ( !this.state.sortedByLevel )
				{
					mainArray.sort(function(a, b){
						if ( a.level !== null && a.level !== null )
							{
								if(a.level < b.level) { return -1; }
								if(a.level > b.level) { return 1; }
							}
						return 0;
					})
					
					//this.setState({sortedByLevel: true});
				}
			else
				{
					mainArray.sort(function(a, b){
						if ( a.level !== null && a.level !== null )
							{
								if(a.level > b.level) { return -1; }
								if(a.level < b.level) { return 1; }
							}
						return 0;
					})
					
					this.setState({sortedByLevel: false});
				}
		}
		
	if ( selected === 'by Interest' )
		{
			if ( !this.state.sortedByinterest )
				{
					mainArray.sort(function(a, b){
						if(a.interestTitles < b.interestTitles) { return -1; }
						if(a.interestTitles > b.interestTitles) { return 1; }
						return 0;
					})
					
					//this.setState({sortedByinterest: true});
				}
			else
				{
					mainArray.sort(function(a, b){
						if(a.interestTitles > b.interestTitles) { return -1; }
						if(a.interestTitles < b.interestTitles) { return 1; }
						return 0;
					})
					
					this.setState({sortedByinterest: false});
				}
		}
		
	if ( selected === 'by Recent Date' )
		{
			if(this.state.sessionType == 'Upcoming')
				{
					if ( !this.state.sortedDateRecent )
						{
							mainArray.sort(function(a, b){
								if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return -1; }
								if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return 1; }
								return 0;
							})
						}
				}
			
			else
				{
					if ( !this.state.sortedDateRecent )
						{
							mainArray.sort(function(a, b){
								if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return -1; }
								if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return 1; }
								return 0;
							})
						}
				}
				
		}
		
	if ( selected === 'by Older Date' )
		{
			if(this.state.sessionType == 'Upcoming')
				{
					if ( !this.state.sortedDateOlder )
						{
							mainArray.sort(function(a, b){
								if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return -1; }
								if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return 1; }
								return 0;
							})
						}
				}
			
			else
				{
					if ( !this.state.sortedDateOlder )
						{
							mainArray.sort(function(a, b){
								if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return -1; }
								if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return 1; }
								return 0;
							})
						}
				}
				
		}
		
	if ( selected === '' )
		{
			if(this.state.sessionType == 'Past')
				{
					if (mainArray.length > 0 )
					{
						mainArray.sort(function(a, b){
							if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return -1; }
							if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return 1; }
							return 0;
						})
					}
					else 
					{
						return false;
					}
				}
			
			else
				{
					if (mainArray.length > 0 )
						{
							mainArray.sort(function(a, b){
								if(new Date(a.scheduleDate).getTime() < new Date(b.scheduleDate).getTime()) { return -1; }
								if(new Date(a.scheduleDate).getTime() > new Date(b.scheduleDate).getTime()) { return 1; }
								return 0;
							})
						}
					else 
						{
							return false;
						}						
				}
		}
		
	this.setState({
				sessionData: mainArray
			});
}

handleFilterChange = e => {
	console.log(e.target.value);
	this.setState({
		[e.target.id]: e.target.value
	});	
}

searchResults=(e, mod)=>{
	if ( e )
		{
			e.preventDefault();
			e.stopPropagation();
		}
	if((this.state.pickedStartDate !== '' && this.state.pickedEndDate == '') || (this.state.pickedStartDate == '' && this.state.pickedEndDate != ''))
		{
			this.props.showMainErrorPop('If you want to search by Date, both Start Date and End Date cannot be empty');
			return false;
		}
	
	let filterInterestName = '';
	let hostName = '';
	
	if(this.state.channelData[0].channelInterest.length > 0)
		{
			for(var i = 0; i<this.state.channelData[0].channelInterest.length; i++)
				{
					if(this.state.channelData[0].channelInterest[i].id == this.state.filterIntrest)
						{
							filterInterestName = this.state.channelData[0].channelInterest[i].title;
						}
				}
		}
		
	if(this.state.hostsArray.length > 0)
		{
			for(var a = 0; a<this.state.hostsArray.length; a++)
				{
					if(this.state.hostsArray[a].userId == this.state.filterHost)
						{
							hostName = this.state.hostsArray[a].username;
						}
				}
		}
	
	let sessionNameInfo = this.state.searchSessionName != '' ? this.state.searchSessionName.trim() + ', ' : '';
	let interestInfo =  filterInterestName != '' ? filterInterestName + ', ' : '';
	let hostInfo =  hostName != '' ? hostName + ', ' : '';
	let levelInfo =  this.state.filterLevel != '' ? this.state.filterLevel + ', ' : '';
	let durationInfo =  this.state.filterDuration != '' ? this.state.filterDuration + ', ' : '';
	let equipmentInfo =  this.state.filterEquipment != '' ? this.state.filterEquipment.trim() + ', ' : '';
	let startDateInfo =  this.state.fullStartDate != '' ? this.state.fullStartDate + ', ' : '';
	let endDateInfo =  this.state.fullEndDate != '' ? this.state.fullEndDate + ', ' : '';
	let searchInfo = sessionNameInfo + interestInfo + hostInfo + levelInfo + durationInfo + equipmentInfo + startDateInfo + endDateInfo;
	searchInfo = searchInfo.slice(0, -2)
	this.setState({searchedBy: searchInfo});
	
	if(sessionNameInfo == '' && hostInfo == '' && levelInfo == '' && durationInfo == '' && startDateInfo == '' && endDateInfo == '' && (interestInfo != '' || equipmentInfo != ''))
		{
			this.setState({ countMaximum: true });
		}
	
	else
		{
			this.setState({ countMaximum: false });
		}
	
	this.setState({applySort: true, expandSearchStatus: false, noFoundSearch: mod == 1 ? true : false}, ()=>{
		if ( this.state.sessionType == 'On Demand' )
			{
				this.getOnDemandsSessions();
			}
		else if ( this.state.sessionType == 'Past' )
			{
				this.pastSessionInfo();
			}
		else
			{
				this.refreshThisWeek();
			}
	});
	
}

openAddMember = (mod) => {
	
		console.log(mod);
		let maxSignUp = mod['maxAttendee'];
		let currentSignUp = mod['totalSignUp'];
		let spots_left = maxSignUp - currentSignUp;
		this.setState({
			choosenSessionId : mod['id'],
			choosenSessionLink: mod['sessionLink'],
			choosenSessionSpotsLeft : spots_left,
		});
		$('.hiddenDivMembersList').trigger('click');
		$('.main_loader').hide();
		$("#members_list").attr({'style':'display:block'});
		$("html, body").attr({'style' : 'overflow-y: hidden'});
}

closeAddMember = e => {
	this.setState({
		choosenSessionId : '',
		choosenSessionLink: '',
		mebersInChoosenSession : []
	});
	$("#members_list").attr({'style':'display:none'});
	$("html, body").attr({'style' : 'overflow-y: auto'});
}

getHosts=()=>{
	let postdata = { id: JSON.parse(localStorage.getItem('userData')).data.responseData.channel, dashStatus : true }
    axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getChannelHosts", postdata)
	.then(res => {
			this.setState({
				hostsArray: res.data.responseData
			});
		
	}).catch(err =>{ });
}	
searchOnDemand=()=>{
	let searchDemand = $('#searchDemand').val();
	let mainArray = this.state.mainDemandObject;

	if ( searchDemand.length > 0 )
		{
			$.each(mainArray.allDemandSession, function(a, b) {
				b['isHidden'] = false;
			
				if ( b['interestTitle'].toLowerCase().indexOf(searchDemand.toLowerCase()) == -1 && b['firstName'].toLowerCase().indexOf(searchDemand.toLowerCase()) == -1 && b['lastName'].toLowerCase().indexOf(searchDemand.toLowerCase()) == -1 && b['level'].toLowerCase().indexOf(searchDemand.toLowerCase()) == -1 )
					{
						b['isHidden'] = true;
					}
			});
		}
	else
		{
			$.each(mainArray.allDemandSession, function(a, b) { b['isHidden'] = false; });
		}
		
	this.setState({
				mainDemandObject: mainArray,
				gridDemandView: false
			});
}

searchSessionsAll=()=>{
	let searchMain = $('#searchSessions').val();
	let mainArray = this.state.sessionData;
	
	if ( searchMain.length > 0 )
		{
			$.each(mainArray, function(a, b) {
				b['isHidden'] = false;
				if ( typeof b['interestTitle'] !== 'undefined' )
					{
						if ( b['interestTitle'].toLowerCase().indexOf(searchMain.toLowerCase()) == -1 && b['hostFirstName'].toLowerCase().indexOf(searchMain.toLowerCase()) == -1 && b['hostLastName'].toLowerCase().indexOf(searchMain.toLowerCase()) == -1 && b['level'].toLowerCase().indexOf(searchMain.toLowerCase()) == -1 )
						{
							b['isHidden'] = true;
						}
					}
				else
					{
						b['isHidden'] = true;
					}
				
			});
		}
	else
		{
			$.each(mainArray, function(a, b) { b['isHidden'] = false; });
		}
	
	this.setState({
				sessionData: mainArray
			});
}
 
 nextDate = (e)=>{
	//  console.log(e.target,	  this.state.upcomingSession[this.state.upcomingSession.length-1].timestamp);
  let date = 1574726400000 ;
	  let date1=date;
	let upcomingSession=[];
	console.log('----------------',new Date(date).getMonth(),new Date(date).getDate());
	let dateofMonth = new Date(date).getDate();
	 let timeSelection =  (new Date (date).getMonth()) ;
	 console.log(timeSelection);
	  date = new Date(Date.UTC(2019, timeSelection, 1));
	 var days = [];
	 var dayofWeek=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
	console.log('date.getMonth()',date.getMonth());
	 while (date.getMonth() === timeSelection) {
		//days.push(new Date(date).getDate());
		 let n ={date:new Date(date).getDate(),
			day:dayofWeek[new Date(date).getDay()],
			timestamp:new Date(date).getTime(),
		}
		 days.push(n);
		 //days.push(new Date(date).getDay());
		 date.setDate(date.getDate() + 1);
	  }
	console.log(days)
	  for (let i=dateofMonth-1 ;i<days.length;i++){
		  console.log(days[i])
		  upcomingSession.push(days[i]);
	  } 
	
	this.setState({
		startDate:date1,
		daysOfMonth:days,
		upcomingSession:upcomingSession,
	},()=>console.log('this.state.daysOfMonth',this.state.upcomingSession));

 }
mail=e=>{
	let ak =parseInt(e.target.value);
	console.log('========>>>>>>>>>>...',this.state.hostMail[ak]);
	this.setState({
		[e.target.id]:e.target.value
	})
	for(let i=0;i<this.state.hostMail.length;i++){
		if(ak===this.state.hostMail[i].id){
			this.setState({
				inviteHostFirtsName:this.state.hostMail[i].firstName,
				inviteHostLastName:this.state.hostMail[i].lastName,
				inviteHostEmail:this.state.hostMail[i].email
			},()=>console.log(this.state.hostMail[i],"======================",this.state.inviteHostLastName,this.state.inviteHostFirtsName))
		}
	}
	
}

checkDefaultSession =(e)=> {
	
	if ( this.state.completedDefaultSession == 'no' && localStorage.getItem('completedDefaultSession') )
		{
			this.setState({completedDefaultSession: localStorage.getItem('completedDefaultSession')});
		}
	let groupId = localStorage.getItem('groupId');
	if ( groupId == 1 )
		{
			let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
			axios.defaults.headers.common['Authorization'] = token;	
			axios.get(process.env.REACT_APP_NAME+"/api/v1/session/host/defaultSessionCompleted")          
				.then(res => {
					if(res.data.responseData==="openPopup") { this.setState({completedDefaultSession: 'openPopup'}); localStorage.setItem('completedDefaultSession', 'openPopup'); }
					if ( res.data.responseData==="yes" ) { this.setState({completedDefaultSession: 'yes'}); localStorage.setItem('completedDefaultSession', 'yes'); }
					if ( res.data.responseData==="no" ) { this.setState({completedDefaultSession: 'no'}); localStorage.setItem('completedDefaultSession', 'no'); }
			})
			.catch(err =>{ });
		}
}



goToSessionPage =(e)=>{
	
	let isChannelMarketPlace = JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace;
	this.setState({
				isChannelMarketPlace : isChannelMarketPlace
			});
	
	let selectedArray = this.state.channelData[0]['channelInterest'];
	if (localStorage['newChannelInterest'] && this.state.channelData.length === 1 )
		{
			let interests_ids = localStorage['newChannelInterest'];
			let ineterests_ids_array = interests_ids.split(',');
			let new_array_interests = [];
			for ( var i = 0; i<ineterests_ids_array.length; i++ )
				{
					for ( var c = 0; c<selectedArray.length; c++ )
						{
							if (ineterests_ids_array[i] == selectedArray[c]['id'] )
								{
									new_array_interests.push(parseInt(ineterests_ids_array[i]));
								}
						}
				}	
			this.setState({
				newInstrestSelected : new_array_interests
			}, ()=> {  if ( this.state.isChannelMarketPlace ) 
						{
							this.addLiveSubcategories(this.state.newInstrestSelected);
						}});
		}
	
	
	
	if ( localStorage['newChannelInterestNamesArray'] && this.state.channelData.length === 1 )
		{
			let interests_string = localStorage['newChannelInterestNamesArray'];
			let split_interests_strings = interests_string.split(',');
			let storageInterestsArrays = [];
			for (var i = 0; i<split_interests_strings.length; i++)
				{
					for ( var c = 0; c<selectedArray.length; c++ )
						{
							if (split_interests_strings[i] == selectedArray[c]['title'] )
								{
									storageInterestsArrays.push(split_interests_strings[i]);
								}
						}
				}
			this.setState({
				selectedinterestsNamesArray : storageInterestsArrays
			}, () => {
				let selected_names_array = this.state.selectedinterestsNamesArray;
				let selected_names_string = '';
				for (var i = 0; i < selected_names_array.length; i++)
				{
					selected_names_string += selected_names_array[i] + ', '; 
				}
			if ( selected_names_string.length > 0 )
				{
					selected_names_string = selected_names_string.substring(0, selected_names_string.length - 2); 
				}
								
				this.setState({
					selectedinterestsNames : selected_names_string
				});
			});
		}
	
	let groupId = localStorage.getItem('groupId');
	
	if ( groupId == 2 || this.state.completedDefaultSession == 'yes' )
		{
			$("#pick_channel").attr({'style':'display:block'});
		}
	else if ( this.state.completedDefaultSession == 'openPopup' )
		{
			$("#go_Dashboard").attr({'style':'display:block'})
		}
	else  if ( this.state.completedDefaultSession == 'no' )
		{
			this.setState({ responseMessage:'You are requested to use your default class. Once done, you will be allowed to create any new class.' },() => $("#error_email_msg").attr({'style':'display:block'}));
		}
}

sessionType1=e=>{
console.log(e.target.value);
	this.setState({
		[e.target.id]:e.target.value,
		},()=>console.log(this.state.sessionInterest,'=============',this.state.sessionTypeRedirectTo))
	// console.log("e.target.value",e.target.value,e.target.id,e.target.name)
	// if(e.target.value===1){
	// this.setState({
	// 	[e.target.id]:e.target.value,
	// 	sessionTypeRedirectTo:'/FitnessSessionCreation'
	// 	},()=>console.log(this.state.sessionTypeRedirectTo))
	// }
	// if(e.target.value===2){
	// 	this.setState({
	// 		[e.target.id]:e.target.value
	// 		})
	// 	}
	for(let i=0;i<this.state.channelData.length;i++){
		for(let k=0;k<this.state.channelData[i].channelInterest.length;k++)
	   {
		console.log(e.target.value,"=============",this.state.channelData[i].channelInterest[k].id)   
		if(e.target.value==this.state.channelData[i].channelInterest[k].id){
			localStorage.setItem("channelInterest",this.state.channelData[i].channelInterest[k].id)
			return;	
		}
	}
   }
}
switchToHost=e=>{
		
	let	loginName= JSON.parse(localStorage.getItem('userData')).data.responseData.email;
	let	type= JSON.parse(localStorage.getItem('userData')).data.responseData.type

	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;	
	axios      
		.post(process.env.REACT_APP_NAME+"/api/v1/user/rollChange",{"email" : loginName,"userType":2})          
		.then(res => {
		$("#email_verification").attr({'style':'display:block'});
		//browserHistory.push('/login')
	})
		.catch(err =>{
			console.log('----------there is problem------------');

		});
	}
sessionType=(e)=>{
	if ( this.state.channelData.length > 1 )
		{
			let userId = parseInt(e.target.value);
			if (userId)
				{
					for (var i = 0; i < this.state.channelData.length; i++)
						{
							if ( this.state.channelData[i].id === userId )
								{
									this.setState({
										channelInterestSelectionNew : this.state.channelData[i].channelInterest,
										newInstrestSelected : [],
										selectedinterestsNamesArray : [],
										selectedinterestsNames : ''
									});
								}
						}
				}
			else
				{
					this.setState({
								channelInterestSelectionNew : [],
								newInstrestSelected : [],
								selectedinterestsNamesArray : [],
								selectedinterestsNames : ''
							});
				}	
		}
	
		this.setState({
			[e.target.id]:e.target.value,
			
		})
	
	for(let i=0;i<this.state.channelData.length;i++){
		
		if(e.target.value==this.state.channelData[i].id){
			
			//let ch=[{id:1,title:'Fitness'},{id:2,title:'Wine'}]
			localStorage.setItem("channelId",this.state.channelData[i].id)
			localStorage.setItem("groupId",this.state.channelData[i].groupId)
			localStorage.setItem("channelInterest",this.state.channelData[i].channelInterest[0].id)
			this.setState({
			 channelInterestSelection:this.state.channelData[i].channelInterest,
			// channelInterestSelection:ch,
			sessionTypeRedirectTo:this.state.channelData[i].groupId===1?'/FitnessSessionCreation':'/FitnessSessionCreation'
		
			})	
		}
	}
}

setPlaceholderToValue=(e)=>{
	
	if ( this.state.channelData[0] )
		{
			let subdomain = this.state.channelData[0].name.toLowerCase().replace(/\s/g, '');

			this.setState({ subdomain: subdomain, existSubdomain: false, emptySubdomain: false });
		}
}

removeAlert=(e)=>{
	this.setState({ existSubdomain: false, emptySubdomain: false });
}

openSubdomain=(e)=>{
	window.open('https://'+this.state.fullsubdomain);
}

openSubdomain2=(e)=>{
	window.open('https://'+this.state.fullsubdomaininvite);
}

updateMessageInvite =(e)=> {

	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/updateChannelPageInfo",{"channel_id": this.state.channelData[0].id, "invitehead" : $('#invsub_heading').val(), "invitetext" : $('#invsub_text').val() })          
		.then(res => {
			$('.main_loader').hide();
		window.$('#generate_subdomaininvite').modal('hide');
		this.props.showMainErrorPop("Changes have been saved.");
		
		});
}


generatePage=(e)=>{
	
	let newsub = this.state.subdomain.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?!=<>{}]/gi, '').replace('https', '').replace('http', '');
	this.setState({ subdomain: newsub, existSubdomain: false, emptySubdomain: false });
	
	if ( this.state.excludeSubdomain.indexOf(newsub) > -1 )
		{
			this.props.showMainErrorPop("Your subdomain contains reserved words. Please enter valid subdomain.");
			return false;
		}
		
	if ( newsub != '' ) 
		{
			var mainthis = this;
			
			$('.main_loader').attr({'style':'display:flex'});
			axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/checksubdomain", {"subdomain" : newsub })          
			.then(res => {
					
					let data = res.data;
					
					if ( $.isEmptyObject(data) )
						{
							axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/updatesubdomain",{"channel_id": this.state.channelData[0].id, "subdomain" : newsub })          
								.then(res => {
									$('.main_loader').hide();
								window.$('#generate_subdomain').modal('hide');
								window.$('#success_subdomain').modal('show');
								
								
								var adminpath = process.env.REACT_APP_PATH;
								adminpath = adminpath.replace('http://', '.').replace('https://', '.');
							
								this.setState({
													fullsubdomain: newsub + adminpath,
													fullsubdomaininvite: newsub + '-signup' + adminpath,
													fullsubdomainbrand: newsub + '-ba' + adminpath
												});
								
								mainthis.ChannelData();
							})
					}
					else
						{
							this.setState({ existSubdomain: true , showExsistSubdomainStatus : true});
							$('.main_loader').hide();
						}
			})

		}
	else
		{
			this.setState({ emptySubdomain: true });

		}

}

reopenSession=(id, i, item)=>{
	if ( item['totalSignUp'] > 0 )
		{
			this.setState({ 
					remId: id,
					remIndex: i
				}, () => window.$('#reopen_session_alert').modal('show')
			);
		}
	else 
		{
			
			this.setState({ 
					remId: id,
					remIndex: i
				}, () => this.sessionReopen(2));
		}		
	
}

sessionReopen=(mod)=>{
	
	let sessionDataVar = this.state.sessionData;
	 sessionDataVar.splice(this.state.remIndex, 1);
	
	 $('.main_loader').attr({'style':'display:flex'});
     let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	 axios.defaults.headers.common['Authorization'] = token;
	 axios.put(process.env.REACT_APP_NAME+"/api/v1/session/"+this.state.remId+"/reopenSession", {'choice': mod})
	 .then(res => {
		$('.main_loader').hide();
		this.setState({ sessionData: sessionDataVar }, ()=>{  window.$('#reopen_session_alert').modal('hide'); localStorage.setItem("refreshUpcoming",true); this.ChannelData();});
		
	 }).catch(err =>{

	 })
}

closeReopenSessionAlert=()=>{
	this.setState({ 
			remId: '',
			remIndex: ''
		}, () => window.$('#reopen_session_alert').modal('hide')
	);
}

reopenSessionDefault=(id, i)=>{
	let sessionDataVar = this.state.sessionData;
	 sessionDataVar.splice(i, 1);
	
	 $('.main_loader').attr({'style':'display:flex'});
     let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	 axios.defaults.headers.common['Authorization'] = token;
	 axios.put(process.env.REACT_APP_NAME+"/api/v1/session/"+id+"/reopenSessionDefault")
	 .then(res => {
		$('.main_loader').hide();
		this.setState({ sessionData: sessionDataVar }, ()=> {this.ChannelData();} );
		
	 }).catch(err =>{

	 })
}

changeTabs = (mod) => {
	let pastSessionData = this.state.pastSessionData;
	clearInterval(this.state.intervalID);
	this.setState({
		expandSearchStatus: false,
		filterHost : '',
		filterIntrest: '',
		filterEquipment: '',
		filterDuration: '',
		searchSessionName: '',
		filterLevel: '',
		caseForSort: '',
		pickedDate: '',
		pickedStartDate: '',
		pickedEndDate: '',
		fullStartDate: '',
		fullEndDate: '',
		sessionData: pastSessionData,
		searchedBy: '',
		noFoundSearch: false,
		countMaximum: false
	},  () => {
	 this.pastSessionInfo();
	 this.pastSession();
	 this.getOnDemandsSessions();
	 
	 if(pastSessionData.length > 0)
		 {
			this.sortSessionsAll(this.state.caseForSort);
		 }
	 
	 this.toggleSortObjects(this.state.caseForSort);
	 if ( mod !== 'Past' )
		{
			 this.upcomingSession();
		}
		
	if (mod === 'Upcoming')	
		{
			let upcoming_select = this.state.upcomingSessionPreview;
			this.setState({
				sessionPreview: upcoming_select
			});
		}
	else if ( mod === 'Past' )
		{
			let past_select = this.state.pastSessionPreview;
			this.setState({
				sessionPreview: past_select
			});
		}		
		
	});
	
	console.log(mod);
}
closeExpandFilter = e =>
	{
		this.setState({
			filterHost : '',
			filterIntrest: '',
			filterLevel: '',
			filterDuration: '',
			pickedDate: '',
			pickedStartDate: '',
			pickedEndDate: '',
			filterEquipment: '',
			searchSessionName: '',
			caseForSort: '',
			fullStartDate: '',
			fullEndDate: '',
			choosenSort: '',
			noFoundSearch: false,
			countMaximum: false
		}, ()=>{console.log(this.state.noFoundSearch, 'NOT FOUND');
			this.searchResults(null, 2);
	/* if ( $('.ondemandmenu').hasClass('active') )
		{
			this.getOnDemandsSessions();
		}
	else if ( $('.pastsesmenu').hasClass('active') )
		{
			this.pastSessionInfo();
		}
	else
		{
			this.refreshThisWeek();
		} */});
	}

changeTutorialIndex=(pg)=>{
	let index = this.state.chosenTutorialIndex;
	let groupId = parseInt(localStorage.getItem('groupId')); 
	
	let allWidth = $(window).width();
	
	if ( pg == 'Next' ) 
		{ 
			if ( groupId == 1 && index == 13 ) 
				{
					index = 15;
				}
			else
				{
					index++; 
				}
			
		}
	else 
		{ 
			if ( groupId == 1 && index == 15 ) 
				{
					index = 13;
				}
			else
				{
					index--; 
				}
		}
	
	// User is not Admin, skip settings
	if ( index == 8 && $('#editChannelPart img').length == 0 )
		{
			index = 13;
		}
	
	this.setState({ chosenTutorialIndex: index });
	

	if ( index == 1 || index == 2 || index == 3 || index == 4 || index == 5 )
		{
			if ( allWidth > 552 )
				{
					$("html, body").animate({ scrollTop: 0 }, "slow");
				}
				
			else
				{
					if( index != 3 )
						{	
							$("html, body").animate({ scrollTop: 230 }, "slow");
						}
						
					else
						{
							$("html, body").animate({ scrollTop: 0 }, "slow");
						}
					
					if ( index == 3 || index == 4 || index == 5 )
						{
							if ( allWidth < 553 )
								{
									this.setState({toggleDropDown:true});
								}
						}
				}
		}
		
	
	if ( index == 6 )
		{
			this.setState({toggleDropDown:false});
			let scrollTBtn = $('#join_scroll').offset();
			if(allWidth > 768)
				{
					$("html, body").animate({ scrollTop: (scrollTBtn.top) }, "slow");
				}
			
			else
				{
					$("html, body").animate({ scrollTop: (scrollTBtn.top + 140) }, "slow");
				}
		}
	
				
	if ( index == 7 || index == 8 || index == 9 || index === 10 || index === 11 )
		{
			if ( this.state.channelData.length === 0 )
				{
					setTimeout(function(){ $('body').attr({'style': 'overflow: auto'}); }, 1000);
					this.setState({ showTutorial: false })
					
					return;
				}
			
			let scrollEdit;
			if ( index == 7 || index == 8 )
				{
					scrollEdit = $('#editChannelPart').offset();
					$("html, body").animate({ scrollTop: (scrollEdit.top - 20) }, "slow");
				}
				
			if ( index == 9 || index === 10 || index === 11 )
				{
					scrollEdit = $('#tutorial_ch_settings').offset();
					$("html, body").animate({ scrollTop: (scrollEdit.top - 20) }, "slow");
				}
		}
	
	if ( index == 12 )
		{
			let scrollmanage = $('#currenthhost').offset();
			$("html, body").animate({ scrollTop: (scrollmanage.top - 20) }, "slow");
		}
		
	if ( index == 13 )
		{
			if ( this.state.channelData.length === 0 )
				{
					setTimeout(function(){ $('body').attr({'style': 'overflow: auto'}); }, 1000);
					this.setState({ showTutorial: false })
					
					return;
				}
			
			
			let scrollmembers = $('#channel_members').offset();
			if ( typeof scrollmembers !== 'undefined' )
				{
					$("html, body").animate({ scrollTop: scrollmembers.top }, "slow");
				}
				
		}
		
	if ( index == 14 )
		{
			let channel_products = $('#channel_products').offset();
			$("html, body").animate({ scrollTop: channel_products.top }, "slow");
		}
		
	if ( index == 15 )
		{
			let createsess = $('#upcoming_tut').offset();
			$("html, body").animate({ scrollTop: (createsess.top - 20) }, "slow");
		}
}

skipTutorial=()=>{
	let postdata = { id: JSON.parse(localStorage.getItem('userData')).data.responseData.id };
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updateDashboardTutorial", postdata)
    .then(res => {
		this.setState({
			showTutorial: false 
		}, () => { 
			this.checkWaitingListSettings('after_tutorial');
		})
		
		let allUserData = JSON.parse(localStorage.getItem('userData'));
		let userDataNew = JSON.parse(localStorage.getItem('userData')).data.responseData;
		userDataNew['dashTutorial'] = 1;
		allUserData['data']['responseData'] = userDataNew;
		localStorage.setItem("userData", JSON.stringify(allUserData));
	
		$('body').attr({'style':'overflow: auto'});
	}).catch(err =>{ });
}

checkWaitingListSettings = (mod) =>{
	if (!mod)
		{
			$('.main_loader').attr({'style':'display:flex'});
		}
	axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/getChannelWaitingListSettings",{'get_result': true, userId: JSON.parse(localStorage.getItem('userData')).data.responseData.id, channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel})          
	  .then(res => {
		$('.main_loader').hide();	
		console.log(res);
		if(res.data.responseData && !res.data.responseData.can_register)
			{
				this.setState({channelWaitingListOn: true});
			}
			
		let userData = JSON.parse(localStorage.getItem('userData')).data.responseData;
		
		if ( userData.channel )
			{
				if ( mod && mod == 'after_tutorial' )
					{
						if( res.data.responseData && res.data.responseData.waitingListStatus && res.data.responseData.waitingListStatus == 1 )
							{
								this.setState({
									channelWaitingListOn: false 
								}, () => { 
									window.$('#schedule_onboarding_session').attr({'style':'display:block'});
								})
							}
						else
							{
								window.$('#schedule_onboarding_session').attr({'style':'display:block'});
							}
					}
				else
					{
						if( res.data.responseData && res.data.responseData.waitingListStatus && res.data.responseData.waitingListStatus == 1 )
							{
								this.checkForPlanAgain();
							}
						else
							{
								window.$('#schedule_onboarding_session').attr({'style':'display:block'});
							}
					}
			}
	  })
	  .catch(err =>{
		  console.log('----------there is problem------------');
			$('.main_loader').hide();	
	  });
}

checkForPlanAgain=()=>{
	let postdata = {'id': JSON.parse(localStorage.getItem('userData')).data.responseData.id};
	let postdataplan = {'id': JSON.parse(localStorage.getItem('userData')).data.responseData.channel};
    let card_type;
    let card_number;
    let fullCardInfoMain;
	
	let userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
	let channelId = JSON.parse(localStorage.getItem('userData')).data.responseData.channel;

	if ( channelId && channelId > 0 )
		{
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getChannelOwner", postdataplan)
			.then(res => {
				var ownerId = res.data.responseData[0].userId;
				let channelName = res.data.responseData[0].name;
				if ( userId === ownerId )
					{
						axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkPendingPayments", {"channelId": channelId}).then(res => {
							if ( res.data.responseData && res.data.responseData === 1 ) { $('#plannotfound').attr({'style':'display:block'}); }
						}).catch(err =>{ });
					}
			}).catch(err =>{ });
		}
}

getVideoDuration(videoURL){
	return new Promise(function (resolve, reject) {
		if ( videoURL == '' ) { resolve(0); return false; }
	
		var video = document.createElement('video');
		video.preload = 'metadata';

		video.onloadedmetadata = function() { resolve(video.duration); }
		video.onerror = function(e) { console.log('Video error', e); reject('error'); }

		video.src = videoURL;
	});
}


openEditRecording=()=>{
	let mainthis = this;
	$('.main_loader').attr({'style':'display:flex'});
	this.getVideoDuration(this.state.editOnDemandVideo.video).then(function (dur)
		{
			$('.main_loader').hide();
			if ( dur === 0 ) { mainthis.props.showMainErrorPop("Video duration is 00:00. Because of that it can't be edited."); return false; } 
			mainthis.setState({openEditVideo: true})
		}, function ()
			  {
				 $('.main_loader').hide();
				 mainthis.props.showMainErrorPop('Video is not available.');
			  });
}


openEditRecordingImage=()=>{
	let mainthis = this;
	$('.main_loader').attr({'style':'display:flex'});
	this.getVideoDuration(this.state.editOnDemandVideo.video).then(function (dur)
		{
			$('.main_loader').hide();
			if ( dur === 0 ) { mainthis.props.showMainErrorPop("Video duration is 00:00. Because of that it can't be played."); return false; } 
			mainthis.setState({openEditVideoImage: true})
		}, function ()
			  {
				 $('.main_loader').hide();
				 mainthis.props.showMainErrorPop('Video is not available.');
			  });
}


closeVideoEdit=()=>{
	this.setState({openEditVideo: false, openEditVideoImage: false});
}


checkSelectedType =()=>{
	
	if ( this.state.channelData.length > 1 )
		{
			if ( this.state.newInstrestSelected.length === 0 || this.state.channelInterestSelectionNew.length === 0 )
				{
					this.props.showMainErrorPop("Please select channel and class interest.");
				}
			else 
				{
					localStorage.setItem("newChannelInterest",this.state.newInstrestSelected);
					localStorage.setItem("newChannelInterestNamesArray",this.state.selectedinterestsNamesArray);
					localStorage.setItem("newChannelInterestNamesString",this.state.selectedinterestsNames);
					localStorage.setItem('sessionInterestArray', JSON.stringify(this.state.newInstrestSelected));
					localStorage.setItem('channelInterestSelection', JSON.stringify(this.state.channelInterestSelection));
					localStorage.setItem('channelInterestSelectionNew', JSON.stringify(this.state.channelInterestSelectionNew));
					localStorage.setItem('liveInterestSubcategories', JSON.stringify(this.state.liveInterestSubcategories));
					localStorage.setItem("liveSelectedInterestsSubcategoriesNames",this.state.liveSelectedInterestsSubcategoriesNames);
					localStorage.setItem('channelLiveSubcategoriesSelection', JSON.stringify(this.state.channelLiveSubcategoriesSelection));
					localStorage.setItem('channelInterestSubcategories', JSON.stringify(this.state.channelInterestSubcategories));
					if ( this.state.isChannelMarketPlace && this.state.liveInterestSubcategories.length > 0 )
						{
							localStorage.setItem('sessionInterestSubcategories', JSON.stringify(this.state.liveInterestSubcategories));
						}
					$("#pick_channel").attr({'style':'display:none'});
					browserHistory.push(this.state.sessionTypeRedirectTo)
				}				
		}
	else 
		{
			if ( this.state.sessionTypeRedirectTo === '/DashboardLanding' || this.state.newInstrestSelected.length === 0 )
				{
					this.props.showMainErrorPop("Please select channel and class interest.");
				}
			else
				{
					localStorage.setItem("newChannelInterest",this.state.newInstrestSelected);
					localStorage.setItem("newChannelInterestNamesArray",this.state.selectedinterestsNamesArray);
					localStorage.setItem("newChannelInterestNamesString",this.state.selectedinterestsNames);
					localStorage.setItem('sessionInterestArray', JSON.stringify(this.state.newInstrestSelected));
					localStorage.setItem('channelInterestSelection', JSON.stringify(this.state.channelInterestSelection));
					localStorage.setItem('channelInterestSelectionNew', JSON.stringify(this.state.channelInterestSelectionNew));
					localStorage.setItem('liveInterestSubcategories', JSON.stringify(this.state.liveInterestSubcategories));
					localStorage.setItem("liveSelectedInterestsSubcategoriesNames",this.state.liveSelectedInterestsSubcategoriesNames);
					localStorage.setItem('channelLiveSubcategoriesSelection', JSON.stringify(this.state.channelLiveSubcategoriesSelection));
					localStorage.setItem('channelInterestSubcategories', JSON.stringify(this.state.channelInterestSubcategories));
					if ( this.state.isChannelMarketPlace && this.state.liveInterestSubcategories.length > 0 )
						{
							localStorage.setItem('sessionInterestSubcategories', JSON.stringify(this.state.liveInterestSubcategories));
						}
					$("#pick_channel").attr({'style':'display:none'});
					browserHistory.push(this.state.sessionTypeRedirectTo)
				}
	
		}	
}

openInviteMessage = () => {
	
	if ( this.state.fullsubdomaininvite )
		{
			window.$('#generate_subdomaininvite').modal('show');
		}
	else
		{
			window.$('#generate_subdomain').modal('show');
		}
}

openBrandPop = () => {
	window.$('#show_brand_link').modal('show');
}

openChannelSettings = () => {
	$(".channel_settings_openabs").show();
	$(document).on('mousedown', function(e)
		{
			if ( $(e.target).hasClass('noclickh') == false ) { $('.channel_settings_openabs').hide(); }
		});
}


restartTutorial = () => {
	$('body').attr({'style':'overflow: hidden'});
	this.setState({ 
					showTutorial: true, 
					chosenTutorialIndex: 0
				}, () => { $('.pastsesmenu').removeClass('active'); $('.ondemandmenu').removeClass('active'); $('.upcomingmenu').addClass('active'); this.changeTabs('Upcoming'); });
}

updateState = (newState) => {
	this.setState(newState);
}

checkPrivateMemberOnly = () =>{
	setTimeout( ()=>{
	if ( !this.state.sessionPublishPage )
		{
			this.setState({ sessionPrivateMemberOnly : false });
		}
	}, 1);
}

updateStateSearch = (e, newState) => {
	e.preventDefault();
	e.stopPropagation();
	this.setState(newState);
}

hideNativePop = () => {
	let mainthis = this;
	
	setTimeout(function() { mainthis.setState({urlPath: mainthis.state.urlPathMain, urlElectron: mainthis.state.urlElectronMain}) },100);
		
		
	window.$('#autologinnative').modal('hide'); 
}

openNativeUrl = (nurl) => {
	var globalSettings = JSON.parse(localStorage.getItem('userData')).data.responseData.settings;

	this.setState({appDownload: ( osname == "Windows" ? globalSettings.windowsAppLink : globalSettings.macAppLink ), showloaderfromnative: true});
	$('.main_loader').attr({'style':'display:flex'});
	customProtocolCheck(nurl,
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative').modal('hide');
							$('#nativedownload').show();
							this.setState({showloaderfromnative: false})
						  },
						  () => {
							$('.main_loader').hide();
							window.$('#autologinnative').modal('hide');
							this.setState({showloaderfromnative: false, urlPath: this.state.urlPathMain, urlElectron: this.state.urlElectronMain});
						  }, 15000);
}

updateUserDemand=()=>{
	if ( this.state.sessionType !== 'On Demand' ) { return false; }
	
	var onDemandId = this.state.onDemandToOpen;
	var userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
	var postData = {onDemandId: onDemandId, userId: userId};
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updateUserDemand", postData).then(res => { }).catch(err =>{ });
}

openNativeDemand = (item) => {
	var user = JSON.parse(localStorage.getItem('userData'));
	
	var checkSessionValidate = this.joinSessionValidate(true, user.data.responseData.id, user.data.responseData.token, item.id);
	this.setState({ 
			urlPath: checkSessionValidate.urlPathVar,
			urlElectron: checkSessionValidate.urlElectronVar,
			deepLinkButton: checkSessionValidate.deepLinkButton,
			onDemandToOpen: item.id,
			userSignedUp: true,
			sessionScheduleDate: item.createdAt,
			sessionTimezone: '',
			sessionName: item.name,
			isOnDemandSession: true
	});
}

expandSearch = e =>
{
	this.setState({
		expandSearchStatus : true
	});
}
openNewIntrest = (ev) =>
{
	ev.preventDefault();
	ev.stopPropagation();
	if (!this.state.expandNewIntrest)
		{
			this.setState({
				expandNewIntrest: true,
				expandChangeHost: false,
				expandNewIntrestSubcategories: false,
				expandLiveIntrestSubcategories: false
			});
		}
	else 
		{
			this.setState({
				expandNewIntrest: false
			});
		}
}

openNewIntrestSubcategories = (ev) =>
{
	ev.preventDefault();
	ev.stopPropagation();
	if (!this.state.expandNewIntrestSubcategories)
		{
			this.setState({
				expandNewIntrestSubcategories: true,
				expandChangeHost: false,
				expandNewIntrest: false
			});
		}
	else 
		{
			this.setState({
				expandNewIntrestSubcategories: false
			});
		}
}

openLiveIntrestSubcategories = (ev)=>{
	ev.preventDefault();
	ev.stopPropagation();
	if (!this.state.expandLiveIntrestSubcategories)
		{
			this.setState({
				expandLiveIntrestSubcategories: true,
				expandNewIntrest: false
			});
		}
	else 
		{
			this.setState({
				expandLiveIntrestSubcategories: false
			});
		}
}

openNewHost = (ev) =>
{
	console.log('openNewHost');
	ev.preventDefault();
	ev.stopPropagation();
	if (!this.state.expandChangeHost)
		{
			this.setState({
				expandChangeHost: true,
				expandNewIntrest: false,
				expandNewIntrestSubcategories: false
			});
		}
	else 
		{
			this.setState({
				expandChangeHost: false
			});
		}
}

setSearchDate =(date, mod)=>{
	let date1=date;
	var momentDateTime = moment(date).format('YYYY-MM-DD 00:00:01');
	var momentDateTimeEnd = moment(date).format('YYYY-MM-DD 23:59:59');
	
	console.log(momentDateTime, 'full date');
	console.log('----------------',new Date(date).getMonth(),new Date(date).getDate());
	let dateofMonth = new Date(date).getDate();
	let timeSelection =  (new Date (date).getMonth()) ;
	console.log(timeSelection);
	date = new Date(Date.UTC(2019, timeSelection, 1));
	var days = [];
	var dayofWeek=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
	console.log('date.getMonth()',date.getMonth());
	while (date.getMonth() === timeSelection) 
		{
			//days.push(new Date(date).getDate());
			let n ={date:new Date(date).getDate(),
			day:dayofWeek[new Date(date).getDay()],
			timestamp:new Date(date).getTime(),
			}
			days.push(n);
			//days.push(new Date(date).getDay());
			date.setDate(date.getDate() + 1);
		}
	console.log(days)
	
	if(mod == 1)
		{
			this.setState({
				pickedDate:date1,
				pickedStartDate:date1,
				pickedEndDate:date1,
				fullStartDate: momentDateTime,
				fullEndDate: momentDateTimeEnd
			});

		}
		
	else if(mod == 2)
		{
			this.setState({
				pickedStartDate:date1,
				fullStartDate: momentDateTime,
			});
		}
	
	else
		{
			this.setState({
				pickedEndDate:date1,
				fullEndDate: momentDateTimeEnd
			});
		}
}

newSelectIntrest = (type, mod, text, ev) =>
{	
	console.log("THIS ! ! !");
	
	ev.preventDefault();
	ev.stopPropagation();
	
	if (type != 'demand')
		{
			var selected_array =  this.state.newInstrestSelected;
			var selected_names_string = '';
			var selected_names_array = this.state.selectedinterestsNamesArray;
		
			if (this.state.newInstrestSelected.indexOf(mod) < 0 )
				{
					if ( this.state.isChannelMarketPlace )
						{
							selected_array = [];
							selected_names_array = [];
						}
					selected_array.push(mod);
					selected_names_array.push(text);
		
					this.setState({
						newInstrestSelected : selected_array,
						selectedinterestsNamesArray : selected_names_array
					}, ()=> {  if ( this.state.isChannelMarketPlace ) {this.addLiveSubcategories(this.state.newInstrestSelected);}});
				}
			else 
				{
					selected_array.splice(selected_array.indexOf(mod),1);
					selected_names_array.splice(selected_names_array.indexOf(text),1);
					
					
					this.setState({
						newInstrestSelected : selected_array,
						selectedinterestsNamesArray : selected_names_array
					}, ()=> { if ( this.state.isChannelMarketPlace ) {this.addLiveSubcategories(this.state.newInstrestSelected);}});
				}
		
			for (var i = 0; i < selected_names_array.length; i++)
				{
					selected_names_string += selected_names_array[i] + ', '; 
				}
			if ( selected_names_string.length > 0 )
				{
					selected_names_string = selected_names_string.substring(0, selected_names_string.length - 2); 
				}	
			
			this.setState({
				selectedinterestsNames : selected_names_string
			}, 
			() => 
				{
					localStorage.setItem("newChannelInterestNamesString",this.state.selectedinterestsNames);
				});
			
			localStorage.setItem("newChannelInterest",this.state.newInstrestSelected);
			localStorage.setItem("newChannelInterestNamesArray",this.state.selectedinterestsNamesArray);
		}
	else 
		{
			var selected_array_demand = this.state.isChannelMarketPlace ? [] : this.state.onDemandInterest;
			var selected_names_string = '';
			var selected_names_array = this.state.isChannelMarketPlace ? [] : this.state.selectedinterestsNamesArrayDemand;
			
			if (this.state.onDemandInterest.indexOf(mod) < 0 )
				{
					
					let marketplaceStatus = JSON.parse(localStorage.getItem('userData')).data.responseData.channelIsMarketplace;
					
					if ( marketplaceStatus )
						{
							selected_array_demand = [];		
							selected_names_array = [];		
						}
					
					selected_array_demand.push(mod);
					selected_names_array.push(text);
					
					this.setState({
						onDemandInterest : selected_array_demand
					}, ()=> { this.addSubcategories(this.state.onDemandInterest);});
				}
			else 
				{
					selected_names_array.splice(selected_names_array.indexOf(text),1);
					selected_array_demand.splice(selected_array_demand.indexOf(mod),1);
					this.setState({
						onDemandInterest : selected_array_demand
					}, ()=> { this.addSubcategories(this.state.onDemandInterest);});
				}
			for (var i = 0; i < selected_names_array.length; i++)
				{
					selected_names_string += selected_names_array[i] + ', '; 
				}
			if ( selected_names_string.length > 0 )
				{
					selected_names_string = selected_names_string.substring(0, selected_names_string.length - 2); 
				}
			this.setState({
				selectedinterestsNamesDemand : selected_names_string
			});	
		}
	this.setState({	
		expandNewIntrest: false
	});
}

addLiveSubcategories = (mod)=>{
	let showSubcategories = [];
	if ( mod.length > 0 )
		{
			for(let i=0; i<this.state.channelInterestSubcategories.length; i++)
				{
					if ( mod.indexOf(this.state.channelInterestSubcategories[i]['interestId']) > -1 )
						{
							showSubcategories.push(this.state.channelInterestSubcategories[i]);
						}
				}
		}
		
	this.setState({
				liveInterestSubcategories: [],
				liveInterestSubcategoriesNamesArray: [],
				liveSelectedInterestsSubcategoriesNames: '',
				channelLiveSubcategoriesSelection: showSubcategories
			}, ()=>{
				
				if ( localStorage.getItem('sessionInterestSubcategories') )
					{
						let arraySubs = JSON.parse(localStorage.getItem('sessionInterestSubcategories'));
						
						for ( var i = 0; i<this.state.channelLiveSubcategoriesSelection.length; i++ )
							{
								let currentId = this.state.channelLiveSubcategoriesSelection[i]['id'];
								if ( arraySubs.indexOf(currentId) > -1 )
									{
										let currentTitle = this.state.channelLiveSubcategoriesSelection[i]['title'];
										this.liveSelectIntrestSubcategory(currentId, currentTitle)
									}
							}
					}	
			});
			
	
}

liveSelectIntrestSubcategory = (mod, text, ev) =>{
	if ( ev )
		{
			ev.preventDefault();
			ev.stopPropagation();
		}
	var selected_array =  this.state.liveInterestSubcategories;
	var selected_names_string = '';
	var selected_names_array = this.state.liveInterestSubcategoriesNamesArray;

	if (this.state.liveInterestSubcategories.indexOf(mod) < 0 )
		{
			selected_array = [];
			selected_names_array = [];
			
			selected_array.push(mod);
			selected_names_array.push(text);

			this.setState({
				liveInterestSubcategories : selected_array,
				liveInterestSubcategoriesNamesArray : selected_names_array
			});
		}
	else 
		{
			selected_array.splice(selected_array.indexOf(mod),1);
			selected_names_array.splice(selected_names_array.indexOf(text),1);
			
			
			this.setState({
				liveInterestSubcategories : selected_array,
				liveInterestSubcategoriesNamesArray : selected_names_array
			});
		}

	for (var i = 0; i < selected_names_array.length; i++)
		{
			selected_names_string += selected_names_array[i] + ', '; 
		}
	if ( selected_names_string.length > 0 )
		{
			selected_names_string = selected_names_string.substring(0, selected_names_string.length - 2); 
		}	
	
	this.setState({
		liveSelectedInterestsSubcategoriesNames : selected_names_string,
		expandLiveIntrestSubcategories: false
	});
}

addSubcategories = (mod) =>{
	let showSubcategories = [];
	if ( mod.length > 0 )
		{
			for(let i=0; i<this.state.channelInterestSubcategories.length; i++)
				{
					if ( mod.indexOf(this.state.channelInterestSubcategories[i]['interestId']) > -1 )
						{
							showSubcategories.push(this.state.channelInterestSubcategories[i]);
						}
				}
		}
		
	if ( this.state.onDemandInterestSubcategories.length > 0 )
		{
			let onDemandInterestSubcategories = this.state.onDemandInterestSubcategories;
			let onDemandInterestSubcategoriesNamesArray = this.state.onDemandInterestSubcategoriesNamesArray;
			for (let i=0; i<onDemandInterestSubcategories.length; i++)
				{
					let find = false;
					for ( let j=0; j<showSubcategories.length; j++ )
						{
							if ( onDemandInterestSubcategories[i] == showSubcategories[j]['id'] )
								{
									find = true;
								}
						}
					if ( !find )
						{
							onDemandInterestSubcategories.splice(i,1);
							onDemandInterestSubcategoriesNamesArray.splice(i,1);
						}
				}
				
			var selected_names_string = '';
			for (var i = 0; i < onDemandInterestSubcategoriesNamesArray.length; i++)
				{
					selected_names_string += onDemandInterestSubcategoriesNamesArray[i] + ', '; 
				}
			if ( selected_names_string.length > 0 )
				{
					selected_names_string = selected_names_string.substring(0, selected_names_string.length - 2); 
				}
			
			this.setState({
				onDemandInterestSubcategories: onDemandInterestSubcategories,
				onDemandInterestSubcategoriesNamesArray: onDemandInterestSubcategoriesNamesArray,
				selectedinterestsSubcategoriesNames: selected_names_string
			});
		}
	this.setState({ channelInterestSubcategoriesSelection: showSubcategories});
}

newSelectIntrestSubcategory = (mod, text, ev) =>{
	
	if ( ev )
		{
			ev.preventDefault();
			ev.stopPropagation();
		}
	
	
	var selected_array =  this.state.onDemandInterestSubcategories;
	var selected_names_string = '';
	var selected_names_array = this.state.onDemandInterestSubcategoriesNamesArray;

	if (this.state.onDemandInterestSubcategories.indexOf(mod) < 0 )
		{
			selected_array = [];
			selected_names_array = [];
			selected_array.push(mod);
			selected_names_array.push(text);

			this.setState({
				onDemandInterestSubcategories : selected_array,
				onDemandInterestSubcategoriesNamesArray : selected_names_array
			});
		}
	else 
		{
			selected_array.splice(selected_array.indexOf(mod),1);
			selected_names_array.splice(selected_names_array.indexOf(text),1);
			
			
			this.setState({
				onDemandInterestSubcategories : selected_array,
				onDemandInterestSubcategoriesNamesArray : selected_names_array
			});
		}

	for (var i = 0; i < selected_names_array.length; i++)
		{
			selected_names_string += selected_names_array[i] + ', '; 
		}
	if ( selected_names_string.length > 0 )
		{
			selected_names_string = selected_names_string.substring(0, selected_names_string.length - 2); 
		}	
	
	this.setState({
		selectedinterestsSubcategoriesNames : selected_names_string
	});
}

newSelectHost = (ind, ev) =>
{
	ev.preventDefault();
	ev.stopPropagation();
	
	var channelHosts = this.state.hostsArray;
	this.setState({
					onDemandHostId : channelHosts[ind]['userId'],
					onDemandHostName : channelHosts[ind]['username'],
					expandChangeHost: false
				});
}

closeNewInterest = e =>
{
	this.setState({
				expandNewIntrest: false,
				expandChangeHost: false,
				expandNewIntrestSubcategories: false,
				expandLiveIntrestSubcategories: false
			});
}

closeSearch = e =>
{
	if(this.state.expandSearchStatus)
		{
			this.setState({
				expandSearchStatus: false
			});
		}
	
}

stayOpenedSearch = (e) =>
{
	e.preventDefault();
	e.stopPropagation();
	this.setState({
		expandSearchStatus: true
	});
}

closeCopyPop = e =>
{
	$("#successResult").attr({'style':'display:none'});
	this.setState({
		buttonText: "Copy URL"
	})
}

closeCopyChannelPop = e =>
	{
		this.setState({
			buttonText2: "Copy Url"
		})
	}
	
checkClosingPlanPop=()=>{
	var postData = {channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel};
	
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/checkExpiredPlanTime", postData).then(res => {
		if ( res.data.responseData === 1 )
			{
				$('#plannotfound').attr({'style':'display:none'});
				$('#planexpired').attr({'style':'display:none'});
			}
	}).catch(err =>{ }); 
}

goToPlans=(e)=>{
	e.preventDefault();
	e.stopPropagation();
	browserHistory.push("/trainingPlans");
}

logOutPlan=(e)=>{
	e.preventDefault();
	e.stopPropagation();
	localStorage.removeItem('userData');
	localStorage.removeItem('channelId');
	localStorage.removeItem('groupId');
	localStorage.removeItem('channelInterest'); 
	this.deleteCookie(process.env.REACT_APP_COOKIE_ID); 
	this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); 
	window['user_loggedIn'] = 0; 
	browserHistory.push("/");
}

openEjectUser=(part)=>{
	if ( part.image == 'null' || part.image == null || part.image == '' ) { part.image = '/images/host-user.png'; } 
	this.setState({participantToRemove: part}, () => {$('#eject_participant').attr({'style':'display:block'})});
}

cancelEject=()=>{
	this.setState({participantToRemove: {}, reasonEject: '', errorReason: false}, () => {$('#eject_participant').attr({'style':'display:none'})});
}

ejectParticipant=()=>{
	if ( this.state.reasonEject === '' ) 
		{
			this.setState({errorReason: true});
			return false;
		}
		
	var postData = {sessionId: this.state.sessionEject, userId: this.state.participantToRemove.userId, channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel, reason: this.state.reasonEject};

	$('.main_loader').attr({'style':'display:flex'});
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/ejectParticipantFromSession", postData).then(res => {
		this.cancelEject();
		this.refreshThisWeek();
		$("#sign_up_info").attr({'style':'display:none'});
		$('.main_loader').hide();
	}).catch(err =>{ $('.main_loader').hide(); }); 
}

openActivityPop = (mod) =>{
	this.setState({
		individualSession: mod
	}, ()=> {console.log(this.state.individualSession.activityArray)});
	window.$('#activityPop').modal('show');
}

getReviewNumber=()=>{
	var postData = {channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel};
	axios.post(process.env.REACT_APP_NAME+"/api/v1/session/getReviewNumber", postData).then(res => {
		this.setState({reviewLength: res.data.responseData.count, showReview: res.data.responseData.isIntegrated, reviewHosts: res.data.responseData.hostsArray});
	}).catch(err =>{ }); 
}

goToCalendly=()=>{
	window.$('#schedule_onboarding_session').attr({'style':'display:none'});
	window.$('#calendly_event').attr({'style':'display:block'});
	this.setState({
		skipedCalendly: false
	});
	
	console.log('process.env.REACT_APP_CALENDLY_LINK');
	console.log(process.env.REACT_APP_CALENDLY_LINK);
	window.Calendly.initInlineWidget({
	 url: process.env.REACT_APP_CALENDLY_LINK,
	 parentElement: document.querySelector('.calendly-inline-widget'),
	});
}

skipCalendly=()=>{
	if ( !this.state.skipedCalendly )
		{
			this.setState({skipedCalendly: true});
			window.$('#schedule_onboarding_session').attr({'style':'display:none'});
			setTimeout(function(){
				window.$('#schedule_onboarding_session').attr({'style':'display:block'});
			}, 500);
		}
	else
		{
			var postData = {channelId: JSON.parse(localStorage.getItem('userData')).data.responseData.channel};
			$('.main_loader').attr({'style':'display:flex'});
			axios.post(process.env.REACT_APP_NAME+"/api/v1/channel/updateWaitingListStatus", postData).then(res => {
				window.$('#schedule_onboarding_session').attr({'style':'display:none'});
				this.setState({skipedCalendly: false});
				
				this.checkForPlanAgain();
				$('.main_loader').hide();
			}).catch(err =>{ $('.main_loader').hide(); }); 
			
		}
}

closeCalendly=(mod)=>{
	window.$('.calendly-inline-widget').html("");
	window.$('#calendly_event').attr({'style':'display:none'});
	if ( mod != 'close' )
		{
			window.$('#schedule_onboarding_session').attr({'style':'display:block'});
		}
}

openInfoPopUp = (e, mod) => {
	if(mod == 'over')
		{
			e.stopPropagation(); 
			$('.tooltip_hold').removeClass('tooltip_active');
			$(e.currentTarget).find('.tooltip_hold').addClass('tooltip_active');
		}
	else
		{
			$('.tooltip_hold').removeClass('tooltip_active');
		}
}

openInfoPopUpNew = (e, mod) => {
	if(mod == 'over')
		{
			e.stopPropagation(); 
			$('.tooltip_hold').removeClass('tooltip_active2');
			$(e.currentTarget).find('.tooltip_hold').addClass('tooltip_active2');
		}
		
	else
		{
			e.stopPropagation(); 
			$('.tooltip_hold').removeClass('tooltip_active2');
		}
}

openDownloadOnDemand = (ev, item) => {
	ev.preventDefault();
	ev.stopPropagation();
	
	let url = item.video;
	window.open(url, '_blank');
}

clickOnDataPicker=(e)=>{
	$('#datepicker').blur();
}

openReview=()=>{
	this.setState({isOpenReviewClasses: true});
}

 render() {
	let {imagePreviewUrl} = this.state;
    let $imagePreview = null;
    // if (imagePreviewUrl) {
      $imagePreview = (<div className="form-group mb-0 position-relative"><span className="cover-border"></span>
	  <label className="label">Image</label>
	  <div className="custom-file mb-3">
	  	<input className="fileInput custom-file-input" type="file" id="customFile_img" onChange={(e)=>this._handleImageChange(e)} />
		  <label className="custom-file-label custom-file-label11 mb-0 input-field position-relative" htmlFor="customFile_img">
		  <img src={imagePreviewUrl} className="browse_image" alt= ''/>
		  </label>
	  </div>                                            
  </div>);
    // } else {
    //   $imagePreview = (<div className="previewText"><img src="/images/browse-image.png" /></div>);
    // }

    return (
    <div className="mainDashboardLandingDiv">
    <div id="root">
    <div className="App">
        <div className="container-fluid custom-width" onClick={()=>{this.closeSearch()}}>
			<div className="row top-header px-1 px-sm-4">
                <div className="col-lg-6 d-flex justify-content-center justify-content-sm-start flex-wrap px-2">
					<div className="align-self-center">
						<img src="/images/login-logo.png" className="logo" alt="logo" />
					</div>
					<div className="d-flex d-md-block justify-content-center p-4">
						<div className="user-info d-flex align-items-center"><div className={ "user-avtar pic profile_avatar " + ( this.state.userAvatar ? "" : "main_def_image" ) } style={{ backgroundImage: this.state.userAvatar ? "url(" + this.state.userAvatar + ")" : 'url(/images/attendee.png)' }}></div>
							<div className="pl-4">
								<h3>Welcome {this.state.loginName}</h3>
								<p>You have {this.state.totalSessionAll} classes coming up</p>
							</div>
						</div>
                	</div>
				</div> 
                <div className="col-lg-6 ">
                    <div className="d-flex justify-content-end flex-wrap relative">
							{ this.state.isMarketplaceChannel &&
								<div className="workflow_btn" onClick={this.openReview}>Class Management
									{ this.state.reviewCount > 0 &&
										<div className="review_count">{this.state.reviewCount > 999 ? "999+" : this.state.reviewCount}</div>
									}
								</div>
							}
							{ ( this.state.reviewHosts.indexOf(JSON.parse(localStorage.getItem('userData')).data.responseData.id) > -1 || ( this.state.channelData && this.state.channelData[0] && this.state.channelData[0].is_admin === 1 && this.state.showReview ) ) && this.state.reviewLength > 0 &&
								<div className="pending_btn" onClick={()=> browserHistory.push("/SessionReviews")}>Review
									<div className="review_number">{this.state.reviewLength > 9 ? '9+' : this.state.reviewLength}</div>
								</div>
							}
							{ ( this.state.channelData && this.state.channelData[0] && this.state.channelData[0].is_admin === 1 && this.state.showReview ) && this.state.reviewLength === 0 &&
								<div className="pending_btn opacity_pending">Review</div>
							}
						  <div className="">    
							<div className="setting-icon position-relative py-2 cursor-pointer" onMouseOver={ (e) => this.openInfoPopUpNew(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUpNew(e, 'leave') } id="settings_openicon" data-toggle="dropdown">
								<img src="../images/setting.png" />
								<div className="tooltip_hold pink_info more_top_pos">
									<div className="tooltip_text">Settings</div>
								</div>
							</div>
							<div className="dropdown-menu left_menu_drop">
							<Link to ="/myProfile" className="dropdown-item"><img src="../images/gray-profile.png" className="mr-2" />Profile</Link>
							<Link to ="/DashboardLanding" className="dropdown-item d-none" data-toggle="modal" data-target="#coming_soon_alert" ><img src="../images/gray-setting.png" className="mr-2" />Security &amp; Settings</Link>
							{ /* <Link to ="/participant-dashboard" className="dropdown-item"><img src="../images/gray-profile.png" className="mr-2" />Switch to Member</Link> */ }
							<Link to ="/HostSettings" className="dropdown-item"><img src="../images/gray-setting.png" className="mr-2" />Instructor Settings</Link>
							<Link to ="/UserPlaylists" className="dropdown-item" ><img src="../images/music.png" className="mr-2" />Playlist</Link>
							{/* <Link to ="/PayInfo" className="dropdown-item" ><img src="../images/money.png" className="mr-2" />Class Payments</Link> */}
							{ this.state.fullsubdomain && <Link to ="/DashboardLanding" className="dropdown-item" data-toggle="modal" data-target="#show_subdomain"><img src="../images/icon_eye.svg" className="mr-2 iconsubdomain2" />View Channel Page URL</Link> }
							<Link to ="/support" className="dropdown-item"><img src="../images/gray-contact.png" className="mr-2" />Customer Support</Link>
							<Link to ="/DashboardLanding" className="dropdown-item" onClick={()=> { this.restartTutorial(); }}><img src="../images/gray-setting.png" className="mr-2 iconsubdomain2" />Re-open Walkthrough</Link>

							{ this.state.isAdminReports == 1 && <Link to ="/AdminReports" className="dropdown-item"><img src="../images/reports_gray.png" className="mr-2 adm_rep" />Admin Reports</Link> }

							<Link to ='/' className="dropdown-item" onClick={e=>{localStorage.removeItem('userData');localStorage.removeItem('channelId');localStorage.removeItem('groupId');localStorage.removeItem('channelInterest');localStorage.removeItem('completedDefaultSession'); this.deleteCookie(process.env.REACT_APP_COOKIE_ID); this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); window['user_loggedIn'] = 0; browserHistory.push("/")}} ><img src="../images/gray-logout.png" className="mr-2"  />Logout</Link>

							</div>
							{ this.state.showTutorial && ( this.state.chosenTutorialIndex == 1 || this.state.chosenTutorialIndex == 2 ) &&
								<div className="dropdown-menu drop_tutorial show">
									<div className="dropdown-item none_space"><img src="../images/gray-profile.png" className="mr-2" />Profile</div>
									<div className="dropdown-item none_space" data-toggle="modal" data-target="#coming_soon_alert" ><img src="../images/gray-profile.png" className="mr-2" />Switch to Instructor</div>
									<div className="dropdown-item none_space relative"><img src="../images/gray-setting.png" className="mr-2" />Instructor Settings
										{  this.state.chosenTutorialIndex == 1 && this.state.showTutorial && 
											<div className="all_tutorials tutorial_second_host">
												<MainTutorial
													chosenTutorial="hostDashboardTutorial"
													state={this.state}
													changeTutorialIndex={this.changeTutorialIndex}
													skipTutorial={this.skipTutorial}
												></MainTutorial>
												<div className="arrow_tutorial"></div>
											</div>
										}
									</div>
									<div className="dropdown-item none_space relative" ><img src="../images/music.png" className="mr-2" />Playlist
										{  this.state.chosenTutorialIndex == 2 && this.state.showTutorial && 
											<div className="all_tutorials tutorial_second_host">
												<MainTutorial
													chosenTutorial="hostDashboardTutorial"
													state={this.state}
													changeTutorialIndex={this.changeTutorialIndex}
													skipTutorial={this.skipTutorial}
												></MainTutorial>
												<div className="arrow_tutorial"></div>
											</div>
										}
									</div>
									<div className="dropdown-item none_space" data-toggle="modal" data-target="#coming_soon_alert"><img src="../images/gray-contact.png" className="mr-2" />Contact Us</div>
									<div className="dropdown-item none_space" onClick={e=>{localStorage.removeItem('userData');localStorage.removeItem('channelId');localStorage.removeItem('groupId');localStorage.removeItem('channelInterest'); this.deleteCookie(process.env.REACT_APP_COOKIE_ID); this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); window['user_loggedIn'] = 0;browserHistory.push("/")}} >
									<img src="../images/gray-logout.png" className="mr-2"  />Logout</div>
								</div>
							}
						</div>
                    </div>
            </div>
            </div>
			<div className="tab-sec px-0 px-sm-3 pb-5">
				<div className="cont">
					<div className="row">
						<div className="col-xl-8 col-lg-7">
							<ul className="nav nav-tabs tab-dropdown" role="tablist">
							    <li className="nav-item relative">
							      <Link to="#us" className="nav-link upcomingmenu active" id="upcoming_tab" data-toggle="tab" onClick={ () => this.changeTabs('Upcoming')}>Upcoming Class ({this.state.upcomingSessionNumber})</Link>
									{  this.state.chosenTutorialIndex == 3 && this.state.showTutorial && 
										<div className="all_tutorials tutorial_fifth_host">
											<MainTutorial
												chosenTutorial="hostDashboardTutorial"
												state={this.state}
												changeTutorialIndex={this.changeTutorialIndex}
												skipTutorial={this.skipTutorial}
											></MainTutorial>
											<div className="arrow_tutorial arrow_tutorial_nav"></div>
										</div>
									}
							    </li>
							    <li className="nav-item relative">
								  <Link to="#ps" className="nav-link pastsesmenu" data-toggle="tab"
								   onClick={  () => this.changeTabs('Past') }>Past Class {this.state.pastSessionLength > 0 ? '('+this.state.pastSessionLength+')' : ''}</Link>
									{  this.state.chosenTutorialIndex == 4 && this.state.showTutorial && 
										<div className="all_tutorials tutorial_sixth_host">
											<MainTutorial
												chosenTutorial="hostDashboardTutorial"
												state={this.state}
												changeTutorialIndex={this.changeTutorialIndex}
												skipTutorial={this.skipTutorial}
											></MainTutorial>
											<div className="arrow_tutorial arrow_tutorial_nav"></div>
										</div>
									}
							    </li>
							    <li className="nav-item relative">
							      <Link to="#onde" className="nav-link ondemandmenu" data-toggle="tab" onClick={this.onDemand}>On Demand {this.state.mainDemandObject && this.state.mainDemandObject.allDemandSession && this.state.mainDemandObject.allDemandSession.length > 0 ? '('+this.state.mainDemandObject.allDemandSession.length+')' : '(0)'}</Link>
								  {  this.state.chosenTutorialIndex == 5 && this.state.showTutorial && 
										<div className="all_tutorials tutorial_sixth_host">
											<MainTutorial
												chosenTutorial="hostDashboardTutorial"
												state={this.state}
												changeTutorialIndex={this.changeTutorialIndex}
												skipTutorial={this.skipTutorial}
											></MainTutorial>
											<div className="arrow_tutorial arrow_tutorial_nav"></div>
										</div>
									}
							    </li>
						    </ul>
							<div className="drodown-tabs text-center">
								<button type="button" className="btn btn-primary dropdown-toggle" data-toggle="collapse" onClick={e=>this.setState({toggleDropDown:true})} data-target="#demo">{this.state.sessionType}</button>
								<div id="demo" className={(this.state.toggleDropDown?"collapse show":"collapse")}>
									<ul className="nav nav-tabs" role="tablist">
										<li className="nav-item">
										<Link to="#us" className="nav-link active" data-toggle="tab" onClick={this.upcomingSession} >Upcoming Class</Link>
											{  this.state.chosenTutorialIndex == 3 && this.state.showTutorial && 
												<div className="all_tutorials tutorial_fifth_host">
													<MainTutorial
														chosenTutorial="hostDashboardTutorial"
														state={this.state}
														changeTutorialIndex={this.changeTutorialIndex}
														skipTutorial={this.skipTutorial}
													></MainTutorial>
													<div className="arrow_tutorial arrow_tutorial_nav"></div>
												</div>
											}
										</li>
										<li className="nav-item">
										<Link to="#ps" className="nav-link" data-toggle="tab"
										 onClick={()=>{clearInterval(this.state.intervalID);
										this.pastSessionInfo(); this.pastSession(); }} >Past Class</Link>
										{  this.state.chosenTutorialIndex == 4 && this.state.showTutorial && 
											<div className="all_tutorials tutorial_sixth_host">
												<MainTutorial
													chosenTutorial="hostDashboardTutorial"
													state={this.state}
													changeTutorialIndex={this.changeTutorialIndex}
													skipTutorial={this.skipTutorial}
												></MainTutorial>
												<div className="arrow_tutorial arrow_tutorial_nav"></div>
											</div>
										}
										</li>
										<li className="nav-item">
										<Link to="#onde" className="nav-link" data-toggle="tab" onClick={this.onDemand} >On Demand</Link>
											 {  this.state.chosenTutorialIndex == 5 && this.state.showTutorial && 
												<div className="all_tutorials tutorial_sixth_host">
													<MainTutorial
														chosenTutorial="hostDashboardTutorial"
														state={this.state}
														changeTutorialIndex={this.changeTutorialIndex}
														skipTutorial={this.skipTutorial}
													></MainTutorial>
													<div className="arrow_tutorial arrow_tutorial_nav"></div>
												</div>
											}
										</li>
									</ul>
								</div>
							</div>
						</div>
						{ this.state.sessionType === 'Upcoming' &&
							<div className="col-xl-4 col-lg-5 flex-wrap datepick pr-4 py-2 d-flex justify-content-start justify-content-md-end align-items-center">
								{/*{this.state.week}, dateFormat="MMMM yyyy" <input type="text" name="" className="form-control" id="datepicker" /> */}
								<div className="flex-grow-1 pl-2 position-relative cal-backg d-flex justify-content-end align-items-center">
								{/* <div className="whitesmoke font-18">{this.state.week}</div>,<DatePicker className="form-control pr-4" id="datepicker"  selected={this.state.startDate} onChange={date => this.setStartDate(date)} dateFormat="MMMM d, yyyy" /></div> */}
								<div className="whitesmoke font-18 mr-4 mr-md-5">{this.state.week}</div>
									<div className="pos_rel_pass" onMouseOver={ (e) => this.openInfoPopUpNew(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUpNew(e, 'leave') } >
										<DatePicker onFocus={e=>{this.clickOnDataPicker(e)}} className="form-control pr-4" id="datepicker"   onChange={date =>{clearInterval(this.state.intervalID);this.calenderDate(date);}} dateFormat="MMMM d, yyyy" />
										<div className="tooltip_hold pink_info dashboard_pos">
											<div className="tooltip_text">Calendar</div>
										</div>
									</div>
								</div>
								{/* <div className="c_icon position-relative mx-4"><img src="/images/cal.png" className=""  alt="" /><img src="images/angle-down.png" className=""  alt="" /></div> */}
								{/* @ak original Code for search <div className="nc_icon position-relative"><img src="/images/search1.png" className="mr-2"  alt="" id = "searchContainer" onClick={(e)=> this.setState({[e.target.id]:!this.state.searchContainer})}/></div> */}
							</div>
						}
					</div>
					{/* search Container */}
					{this.state.searchContainer ?
					<div className="parent_field_cont pb-4">
						<div className="input_field_container round">
							<div className="row">
								<div className="col-xl-5 pr-lg-0">
									<input type="text" className="form-control" placeholder="Search for interest channels, instructors or keywords" name="" />
								</div>
								<div className="col-xl-7 pl-4">
									<div className="row mx-0 mt-3 mt-xl-0">
										<div className="col-md-12">
											<div className="custom-control custom-checkbox mb-3 text_input">
											<input type="checkbox" className="custom-control-input" id="customCheck1" name="example1" defaultChecked={this.state.customCheck1} onChange = {(e)=>{this.setState({[e.target.id]:!this.state.customCheck1},()=>console.log('customCheck1',this.state.customCheck1))}} />
											<label className="custom-control-label" htmlFor="customCheck1">Advance</label>
											</div>
										</div>
									</div>
									
									{this.state.customCheck1 ?
									<div className="parent-row">
										<div className="row mx-0 row1 d-flex">
											<div className="col-lg-4 col-md-6">
												<p>On a specific date</p>
												
												<DatePicker className="form-control dt_input" placeholderText="mm/dd/yy" id="datepicker" selected={this.state.startDate1} onChange={date => this.setStartDate1(date)} />
											</div>
											<div className="col-lg-3 col-md-6 text-md-center mt-3 mt-md-0">
												<p>On Demand</p>
												<div className="custom-control custom-checkbox mb-3">
												<input type="checkbox" className="custom-control-input" id="customCheck" name="" />
												<label className="custom-control-label" htmlFor="customCheck"></label>
												</div>
											</div>
											<div className="col-lg-5 col-md-6">
												<p>Length of session</p>
												{/* <div className="custom-select1">
													<select name="">
													<option value="30">Any length</option>
													<option value="40">Less than 30 minutes</option>
													<option value="30">Less than an hour</option>
													<option value="30">Less than 2 hours</option>
													<option value="30">More than 2 hours</option>
													</select>
												</div> */}
												<select className="input-field-session" id="">
													<option>Any length</option>
													<option>Less than 30 minutes</option>
													<option>Less than an hour</option>
													<option>Less than 2 hours</option>
													<option>More than 2 hours</option>													
												</select>
											</div>
										</div>
									</div>
									:''}
								
								</div>
							</div>
							<div className="right-small-box">
								<img src="images/search.png" alt=''/>
							</div>						
						</div>
						<div className="row mt-4">
							<div className="col-md-3">
								<h4 className="white mt-3 mb-3 font-22">Channel</h4>
								{this.state.channelData.map((row,i)=>
								<div className="" key ={i}>								
									<label className="custom-control custom-checkbox lebelheight d-flex pl-0">
										<input type="checkbox" className="form-radio" />                                    
										<p className="text-white mt-1 pt-1 font-14 mb-0">{row.name}</p>
									</label>
								</div>
								)}
								{/* <div className="">								
									<label className="custom-control custom-checkbox lebelheight d-flex pl-0">
										<input type="checkbox" className="form-radio" />                                    
										<p className="text-white mt-1 pt-1 font-14 mb-0">Boisset Wines</p>
									</label>
								</div> */}
							</div>
							<div className="col-md-5">
								<h4 className="white mt-3 mb-3 font-22">Instructors</h4>								
								{this.state.channelData.map((row,i)=>
								(row.channelHost.map((chn_Host,l)=>
								<div className="" key ={l}>								
									<label className="custom-control custom-checkbox lebelheight d-flex pl-0">
										<input type="checkbox" className="form-radio" />                                    
										<p className="text-white mt-1 pt-1 font-14 mb-0">{chn_Host.name}</p>
									</label>
								</div>
								))
								)}
								
								
							</div>
							<div className="col-md-4">
								<h4 className="white mt-3 mb-3 font-22">Category</h4>						
								{this.state.channelData.map((row,i)=>
								(row.channelInterest.map((chn_interest,l)=>
								<div className="" key ={l}>								
									<label className="custom-control custom-checkbox lebelheight d-flex pl-0">
										<input type="checkbox" className="form-radio" />                                    
										<p className="text-white mt-1 pt-1 font-14 mb-0">{chn_interest.title}</p>
									</label>
								</div>
								))
								)}
								
								
							</div>														
						</div>							
					</div>
					:''}
					{/* Search container end */}
				</div>

				
				<div className="cont1">
				
				    <div className="tab-content">
						<div id="us" className="tab-pane active">
							{/*
						    <ul className="nav nav-tabs mx-0" role="tablist">
							
							{this.state.sessionType==="Upcoming" && this.state.currentWeekData.length>0?
							(new Date(this.state.currentWeekData[0].scheduleDate).getDate() === new Date(this.state.sessionData2[0].scheduleDate).getDate()?'':<li className="nav-item angle-img pt-2 pt-md-1">
								  <Link to="#dt3" className="nav-link" data-toggle="tab" ><img src="/images/Triangle.png" onClick={this.previousWeekData} alt="arrow" /></Link>
							    </li>
							):''}	
							{this.state.sessionData.length>0 && this.state.sessionData2.length>0 && this.state.sessionType==="Upcoming"?
							(this.state.sessionData2.map((row,i)=>
							    <li className="nav-item flex-fill text-center" key={i}>
								  {row.name==="No Data"?
										<div className="nav-link"  id = {row.scheduleDate} name={row.scheduleDate} >{this.dateOfSession(row.scheduleDate)}<br /><span>{this.dayOfSession(row.scheduleDate)}</span></div> 
										:
										<Link to="#dt1" className="nav-link act active" data-toggle="tab" id = {row.scheduleDate} name={row.scheduleDate} onClick={this.scriptOnDate}>
											{this.dateOfSession(row.scheduleDate)}<br /><span>{this.dayOfSession(row.scheduleDate)}</span>
										</Link>
										}
								</li>
								)):
								''}


					{this.state.sessionData.length===0 && this.state.sessionType==="Upcoming"?
							(this.state.sessionData2.map((row,i)=>
							    <li className="nav-item flex-fill text-center" key={i}>
								{row.name==="No Data"?
									<div className="nav-link"  id = {row.scheduleDate} name={row.scheduleDate}>{this.dateOfSession(row.scheduleDate)}<br /><span>{this.dayOfSession(row.scheduleDate)}</span></div> 
									:
									<Link to="#dt1" className="nav-link act active" data-toggle="tab" id = {row.scheduleDate} name={row.scheduleDate} onClick={this.scriptOnDate}>
										{this.dateOfSession(row.scheduleDate)}<br /><span>{this.dayOfSession(row.scheduleDate)}</span>
									</Link>
								 }
								</li>
								)):
								''}



							    {this.state.sessionType==="Upcoming" && this.state.currentWeekData.length>0?<li className="nav-item angle-img pt-2 pt-md-1">
								  <Link to="#dt3" className="nav-link" data-toggle="tab" ><img src="/images/Triangle-right.png"  onClick={this.upComingScheduleDateMethod} alt="arrow" /></Link>
							    </li>:''}
						    </ul>
								*/}
						{this.state.sessionType==="Upcoming" && this.state.sessionData2.length > 0 &&
							<div className="cal_holder" >
								{this.state.weekFoward > 0 &&
									<div className="left_arrow_holder no_overflow_tools" onClick={this.previousWeekData} onMouseOver={ (e) => this.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUp(e, 'leave') }>
										<div className="tooltip_hold pink_info arrow_info">
											<div className="tooltip_text">Move Back</div>
										</div>
									</div>
								}
								<div className="cal_right_arrow no_overflow_tools" onClick={this.upComingScheduleDateMethod} onMouseOver={ (e) => this.openInfoPopUp(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUp(e, 'leave') }>
									<div className="tooltip_hold pink_info arrow_info">
										<div className="tooltip_text">Move Forward</div>
									</div>
								</div>
								{this.state.sessionData2.map((row, i) => 
									<div className="date_holder" key={i} >
										{row.id && row.id > 0 ? 
										<div className={"date_in_holder " +( row.id && row.id > 0  ? 'active_cal_holder' : '' )} name={row.scheduleDate} onClick={ e=> {this.scriptOnDate(row.scheduleDate)}} >
											{this.state.currentDayChoosen == row.scheduleDate &&
											<div className="active_circle"></div>
											}
											<div className="date_val" >{this.dateOfSession(row.scheduleDate)}</div>
											<div className="mon_text" >{this.dayOfSession(row.scheduleDate)}</div>
											{row.id && row.id != 0 &&
												<div className="active_border"></div>
											}
										</div>
										:
										<div className="date_in_holder" >
											<div className="date_val" >{this.dateOfSession(row.scheduleDate)}</div>
											<div className="mon_text" >{this.dayOfSession(row.scheduleDate)}</div>
										</div>
									}
									</div>
								)}
							</div>
						}
						    <div className="content-container mt-2">
								<div className="row">
									<div className="col-md-12 mt-2">
										<div className={ "inner_containr px-4 py-3 mx--6 d-flex justify-content-between align-items-center relative demand_hold " + ( this.state.expandSearchStatus ? "extended_search_hold" : "" ) }>
											<div>
												<div className="px-2 m-0 white relative" id="upcoming_tut">{this.state.sessionType} Classes
													<div>
														{ this.state.sessionType === 'Upcoming' &&
															<div>
																{ ( (this.state.channelData && this.state.channelData[0] && !this.state.isMarketplaceChannel) || (this.state.channelData && this.state.channelData[0] && this.state.isMarketplaceChannel && this.state.userPermissions.createClass) ) &&
																	<div className="abs_new_session" onMouseOver={ (e) => this.openInfoPopUpNew(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUpNew(e, 'leave') }  >
																		<img src="/images/add.png" className="px-2 width-60 cursor-pointer" alt="" data-toggle="modal" onClick={this.goToSessionPage} />
																		<div className="tooltip_hold pink_info dashboard_pos">
																			<div className="tooltip_text">Create New Class</div>
																		</div>
																	</div>
																}
																{  this.state.chosenTutorialIndex == 15 && this.state.showTutorial && 
																	<div className="all_tutorials tutorial_add_sess">
																		<MainTutorial 
																			chosenTutorial="hostDashboardTutorial"
																			state={this.state}
																			changeTutorialIndex={this.changeTutorialIndex}
																			skipTutorial={this.skipTutorial}
																		></MainTutorial>
																		<div className="arrow_tutorial arrow_tutorial_editch"></div>
																	</div>
																}
																{ /* this.state.channelData && this.state.channelData[0] &&
																	<img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer manage_bulb upcoming_bulb" onClick={e=>{this.props.showMainErrorPop('Select the "+" icon next to Upcoming Classes and follow the steps outlined below:\
																	-Select the Class Type; you can choose multiple Areas of Interest.\
																	-Complete the Class Info tab; this is the only required information.\
																	-Workout Builder tab; Adding activities to the Class is optional but recommended for the best experience.\
																	-Select your Settings for the Class.\
																	-Add any Equipment you would like your Members to have for the Class. This information will be available to Members when they sign up.', true)}}/> */
																}
															</div>
														}
													</div>
													<div>
														{ this.state.sessionType === 'On Demand' &&
															<>
																{ ( (this.state.channelData && this.state.channelData[0] && !this.state.isMarketplaceChannel) || (this.state.channelData && this.state.channelData[0] && this.state.isMarketplaceChannel && this.state.userPermissions.addOnDemad) ) &&
																	<div className={"abs_new_session " + (this.state.channelData && this.state.channelData[0] && !this.state.openAddDemand   ? 'new_right' : 'new_right2')} >
																		{ this.state.channelData && this.state.channelData[0] && !this.state.openAddDemand &&
																			<div onMouseOver={ (e) => this.openInfoPopUpNew(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUpNew(e, 'leave') } >
																				<img src="/images/add.png" className="px-2 width-60 cursor-pointer"  onClick={this.openAddingOnDemand} />
																				<div className="tooltip_hold pink_info dashboard_pos">
																					<div className="tooltip_text">Create New On Demand Class</div>
																				</div>
																			</div>		
																		}
																		{ this.state.channelData && this.state.channelData[0] && !this.state.openAddDemand ?
																			<img src="/images/bulb.png" className={"ml-3 mb-2 cursor-pointer manage_bulb record_ondemand_bulb " + (!this.state.channelData[0] ? 'record_ondemand_bulb_nc' : '')} onClick={e=>{this.getFaq(63)}}/>
																			:
																			<img src="/images/bulb.png" className="width-24 cursor-pointer" onClick={e=>{this.getFaq(63)}}/>
																		}
																		
																	</div>
																}
															</>
														}
													</div>
												</div>
											</div>	
											{ this.state.sessionType !== "On Demand" &&
												<div className="header_filters">
												{/*
													<div className="inline_btns_header w300">
														<div className="flex-grow-1 input_field_container py-0 radius-8 main_search_item">
															<div className="row mx-0 frst-input-row shadow-1 padd_searchh align-items-center radius-8">
																<div className="flex-grow-1 search_header">
																	<input type="text" className="form-control search_input_dashboard font14" id="searchSessions" placeholder="Interest, Instructor, Level" onKeyUp={this.searchSessionsAll} />
																</div>
															</div>
															<div className="right-small-box">
																<img src="/images/search.png" />
															</div>
														</div>
													</div>
													*/}
													<div className="channel_name channel_name3 new_width_search relative">
														{ this.state.channelData.length != 0 &&
															<div className="flex-grow-1 input_field_container py-0 radius-8 main_search_item2 name_new_stats dashboard_search_hold cursor" id="expandSearchStatus" onClick = {(e)=>{this.updateStateSearch(e, {[e.target.id]: !this.state[e.target.id]})}} >
																<div className="row mx-0 frst-input-row shadow-1 py-2 align-items-center radius-8 filter_hold_icon cursor" id="expandSearchStatus" onClick = {(e)=>{this.updateStateSearch(e, {[e.target.id]: !this.state[e.target.id]})}}>
																	<div className="flex-grow-1"><input readOnly="" type="text" className="form-control search_input_dashboard_new cursor" placeholder="Search Classes and Equipments" name="" value={this.state.searchedBy} id="expandSearchStatus" onClick = {(e)=>{this.updateStateSearch(e, {[e.target.id]: !this.state[e.target.id]})}} disabled/>
																	</div>
																</div>
																<div className="right-small-box filter_dropdown_icon" id="expandSearchStatus" onClick = {(e)=>{this.updateStateSearch(e, {[e.target.id]: !this.state[e.target.id]})}}></div>
															</div>
														}
														{ this.state.expandSearchStatus &&
															<div className="expanded_search_hold" onClick={(e)=>{this.stayOpenedSearch(e)}}>
																<div className="filter_sortby_hold">
																	<div className="filter_container_title">Sort By</div>
																	<div className="sort_by_inside">
																		<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Session')}}>Class Name {this.state.choosenSort == 'by Session' && <div className="checked_sort"></div>}</div>
																		<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Interest')}}>Category {this.state.choosenSort == 'by Interest' && <div className="checked_sort"></div>}</div>
																		<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Duration')}}>Duration {this.state.choosenSort == 'by Duration' && <div className="checked_sort"></div>}</div>
																		<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Level')}}>Level {this.state.choosenSort == 'by Level' && <div className="checked_sort"></div>}</div>
																		<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Host')}}>Instructor {this.state.choosenSort == 'by Host' && <div className="checked_sort"></div>}</div>
																		<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Recent Date')}}>Date (Recent to Older) {this.state.choosenSort == 'by Recent Date' && <div className="checked_sort"></div>}</div>
																		<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Older Date')}}>Date (Older to Recent) {this.state.choosenSort == 'by Older Date' && <div className="checked_sort"></div>}</div>
																	</div>
																</div>
																<div className="filter_main_hold">
																	<div className="filter_container_title">Filter By</div>
																	<div className="filter_inside">
																		<div className="form-group-header">
																			<span className="cover-border"></span>
																			<label className="label dashboard_search_label">Class Name</label>
																			<input className="input-field" id="searchSessionName" value={this.state.searchSessionName} onChange={this.handleFilterChange} />
																		</div>
																		{ this.state.channelData && this.state.channelData[0] &&
																			<div className="form-group-header interest_search">
																				<span className="cover-border"></span>
																				<label className="label dashboard_search_label">Category</label>
																				<select className="input-field dashboard_main_select" id="filterIntrest" value={this.state.filterIntrest} onChange={this.handleFilterChange} >
																					<option value=""></option>
																						{ this.state.channelData[0].channelInterestAll.map((item, key)=> 
																							<option value={item.id} key={key}>{item.title}</option>
																						)}
																				</select>
																			</div>
																		 }
																		 <div className="form-group-header">
																			<span className="cover-border"></span>
																			<label className="label dashboard_search_label">Instructor</label>
																			<select className="input-field dashboard_main_select" id="filterHost" value={this.state.filterHost} onChange={this.handleFilterChange} >
																				<option value=""></option>
																				{ this.state.hostsArray.sort(function(a, b){ if(a.username < b.username) { return -1; } if(a.username > b.username) { return 1; } return 0; }).map((item, key) => 
																					<option value={item.userId} key={key}>{item.username}</option>
																				)}
																			</select>
																		</div>
																		<div className="form-group-header">
																			<span className="cover-border"></span>
																			<label className="label dashboard_search_label">Level</label>
																			<select className="input-field dashboard_main_select" id="filterLevel" value={this.state.filterLevel} onChange={this.handleFilterChange} >
																				<option value=""></option>
																				<option value="Beginner">Beginner</option>
																				<option value="Intermediate">Intermediate</option>
																				<option value="Advanced">Advanced</option>
																				<option value="Multi-Level">Multi-Level</option>
																				
																			</select>
																		</div>
																		<div className="form-group-header">
																			<span className="cover-border"></span>
																			<label className="label dashboard_search_label">Duration (*Minutes)</label>
																			<select className="input-field dashboard_main_select" id="filterDuration" value={this.state.filterDuration} onChange={this.handleFilterChange} >
																				<option></option>
																				<option value="29">Less than 30 Minute</option>
																				<option value="30">30 Minutes</option>
																				<option value="45">45 Minutes</option>
																				<option value="60">More than 60min</option>
																			</select>
																		</div>
																		 <div className="form-group-header">
																			<span className="cover-border"></span>
																			<label className="label dashboard_search_label">Equipment</label>
																			<input type="text" className="input-field dashboard_main_select" id="filterEquipment" value={this.state.filterEquipment} onChange={this.handleFilterChange} />
																		</div>
																		 <div className="form-group-header add_margtp40 vert_bottom_filter">
																			<span className="cover-border"></span>
																			<label className="label dashboard_search_label">Pick Date</label>
																			<div className="">
																				<input type="text" value={this.state.searchShowTime || ''} className="input-field layout_border" readOnly/>
																				<div className="date_picker_hold dashboard_search_date_picker"><DatePicker className="form-control dt_input date_picker_hold date_picker_choose dashboard_search_date_picker" id="datepicker" selected={this.state.pickedDate} onChange={date => this.setSearchDate(date, 1)} /></div>
																				<div className="when-icon search_calendar_icon">
																					<div className="flex_calendar_hold">
																					</div>
																				</div>
																			</div>
																		</div>
																		 <div className="form-group-header filter_date_hold">
																			<div className="above_picker">From</div>
																			<span className="cover-border"></span>
																			<label className="label dashboard_search_label">Start Date</label>
																			<div className="">
																				<input type="text" value={this.state.searchStartTime || ''} className="input-field layout_border" readOnly/>
																				<div className="date_picker_hold dashboard_search_date_picker"><DatePicker className="form-control dt_input date_picker_hold date_picker_choose dashboard_search_date_picker" id="datepicker" selected={this.state.pickedStartDate} onChange={date => this.setSearchDate(date, 2)} /></div>
																				<div className="when-icon search_calendar_icon">
																					<div className="flex_calendar_hold">
																					</div>
																				</div>
																			</div>
																		</div>
																		 <div className="form-group-header filter_date_hold">
																			<div className="above_picker">To</div>
																			<span className="cover-border"></span>
																			<label className="label dashboard_search_label">End Date</label>
																			<div className="">
																				<input type="text" value={this.state.searchShowTime || ''} className="input-field layout_border" readOnly/>
																				<div className="date_picker_hold dashboard_search_date_picker"><DatePicker className="form-control dt_input date_picker_hold date_picker_choose dashboard_search_date_picker" id="datepicker" selected={this.state.pickedEndDate} onChange={date => this.setSearchDate(date, 3)} /></div>
																				<div className="when-icon search_calendar_icon">
																					<div className="flex_calendar_hold">
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="filter_bottom_options">
																	<div className="filter_clear_all" onClick={()=>{this.closeExpandFilter(2)}}>Clear All</div>
																	<div className="filter_apply" onClick={(e)=>{this.searchResults(e, 1)}}>APPLY</div>
																</div>
															</div>
														}
													</div>
													<div className={"inline_btns_header btns_show grid_view pos_rel_pass" + (this.state.sessionPreview === 'square' ? ' active' : '')} onMouseOver={ (e) => this.openInfoPopUpNew(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUpNew(e, 'leave')} onClick={ () => { this.changeSessionScreen('square') }}>
														<div className="tooltip_hold pink_info more_top_pos">
															<div className="tooltip_text">Panel View</div>
														</div>
													</div>
													<div className={"inline_btns_header btns_show list_view pos_rel_pass" + (this.state.sessionPreview === 'list' ? ' active' : '')} onMouseOver={ (e) => this.openInfoPopUpNew(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUpNew(e, 'leave')} onClick={ () => { this.changeSessionScreen('list') }}>
														<div className="tooltip_hold pink_info more_top_pos">
															<div className="tooltip_text">List View</div>
														</div>
													</div>
													
												</div>
											}
											{ this.state.sessionType === "On Demand" &&
												<div className="header_filters header_filters_new_width" >
													{ !this.state.gridDemandView &&
														<div className="inline inline_all channel_name2 ">
														{ !this.state.openAddDemand && this.state.channelData.length != 0 &&
															<div className="flex-grow-1 input_field_container py-0 radius-8 main_search_item2 name_new_stats dashboard_search_hold channel_name channel_name3 new_width_search relative cursor">
																<div className="row mx-0 frst-input-row shadow-1 py-2 align-items-center radius-8 filter_hold_icon cursor"  id="expandSearchStatus" onClick = {(e)=>{this.updateState({[e.target.id]: !this.state[e.target.id]})}}>
																	<div className="flex-grow-1"><input readOnly="" type="text" className="form-control search_input_dashboard_new cursor" placeholder="Search Classes and Equipments" name="" value={this.state.searchedBy} id="expandSearchStatus" onClick = {(e)=>{this.updateState({[e.target.id]: !this.state[e.target.id]})}} disabled/>
																	</div>
																</div>
																<div className="right-small-box filter_dropdown_icon" id="expandSearchStatus" onClick = {(e)=>{this.updateState({[e.target.id]: !this.state[e.target.id]})}}></div>
															</div>
														}
												{ this.state.expandSearchStatus &&
													<div className="expanded_search_hold expanded_ondemand" onClick={(e)=>{this.stayOpenedSearch(e)}}>
														<div className="filter_sortby_hold">
															<div className="filter_container_title">Sort By</div>
															<div className="sort_by_inside">
																<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Session')}}>Class Name {this.state.choosenSort == 'by Session' && <div className="checked_sort"></div>}</div>
																<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Interest')}}>Category {this.state.choosenSort == 'by Interest' && <div className="checked_sort"></div>}</div>
																<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Duration')}}>Duration {this.state.choosenSort == 'by Duration' && <div className="checked_sort"></div>}</div>
																<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Level')}}>Level {this.state.choosenSort == 'by Level' && <div className="checked_sort"></div>}</div>
																<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Host')}}>Instructor {this.state.choosenSort == 'by Host' && <div className="checked_sort"></div>}</div>
																<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Recent Date')}}>Date (Recent to Older) {this.state.choosenSort == 'by Recent Date' && <div className="checked_sort"></div>}</div>
																<div className="sort_by_container" onClick={()=>{this.toggleSortObjects('by Older Date')}}>Date (Older to Recent) {this.state.choosenSort == 'by Older Date' && <div className="checked_sort"></div>}</div>
															</div>
														</div>
														<div className="filter_main_hold">
															<div className="filter_container_title">Filter By</div>
															<div className="filter_inside">
																<div className="form-group-header">
																	<span className="cover-border"></span>
																	<label className="label dashboard_search_label">Class Name</label>
																	<input className="input-field ondemand_input_hold" id="searchSessionName" value={this.state.searchSessionName} onChange={this.handleFilterChange} />
																</div>
																{ this.state.channelData && this.state.channelData[0] &&
																	<div className="form-group-header interest_search">
																		<span className="cover-border"></span>
																		<label className="label dashboard_search_label">Category</label>
																		<select className="input-field dashboard_main_select ondemand_input_hold" id="filterIntrest" value={this.state.filterIntrest} onChange={this.handleFilterChange} >
																			<option value=""></option>
																				{ this.state.channelData[0].channelInterestAll.map((item, key)=> 
																					<option value={item.id} key={key}>{item.title}</option>
																				)}
																		</select>
																	</div>
																 }
																<div className="form-group-header">
																	<span className="cover-border"></span>
																	<label className="label dashboard_search_label">Instructor</label>
																	<select className="input-field dashboard_main_select ondemand_input_hold" id="filterHost" value={this.state.filterHost} onChange={this.handleFilterChange} >
																		<option value=""></option>
																		{ this.state.hostsArray.sort(function(a, b){ if(a.username < b.username) { return -1; } if(a.username > b.username) { return 1; } return 0; }).map((item, key) => 
																			<option value={item.userId} key={key}>{item.username}</option>
																		)}
																	</select>
																</div>
																<div className="form-group-header">
																	<span className="cover-border"></span>
																	<label className="label dashboard_search_label">Level</label>
																	<select className="input-field dashboard_main_select ondemand_input_hold" id="filterLevel" value={this.state.filterLevel} onChange={this.handleFilterChange} >
																		<option value=""></option>
																		<option value="Beginner">Beginner</option>
																		<option value="Intermediate">Intermediate</option>
																		<option value="Advanced">Advanced</option>
																		<option value="Multi-Level">Multi-Level</option>
																	</select>
																</div>
																{/*<div className="form-group-header">
																	<span className="cover-border"></span>
																	<label className="label dashboard_search_label">Duration (*Minutes)</label>
																	<input type="text" className="input-field dashboard_main_select ondemand_input_hold" id="filterDuration" value={this.state.filterDuration} onChange={this.handleFilterChange} />
																</div>*/}
																<div className="form-group-header">
																	<span className="cover-border"></span>
																	<label className="label dashboard_search_label">Duration (*Minutes)</label>
																	<select className="input-field dashboard_main_select ondemand_input_hold" id="filterDuration" value={this.state.filterDuration} onChange={this.handleFilterChange} >
																		<option></option>
																		<option value="29">Less than 30 Minute</option>
																		<option value="30">30 Minutes</option>
																		<option value="45">45 Minutes</option>
																		<option value="60">More than 60min</option>
																	</select>
																</div>
																 <div className="form-group-header">
																	<span className="cover-border"></span>
																	<label className="label dashboard_search_label">Equipment</label>
																	<input type="text" className="input-field dashboard_main_select ondemand_input_hold" id="filterEquipment" value={this.state.filterEquipment} onChange={this.handleFilterChange} />
																</div>
																 <div className="form-group-header">
																	<span className="cover-border"></span>
																	<label className="label dashboard_search_label">Pick Date</label>
																	<div className="">
																		<input type="text" value={this.state.searchShowTime || ''} className="input-field layout_border ondemand_input_hold" readOnly/>
																		<div className="date_picker_hold date_picker_demand dashboard_search_date_picker"><DatePicker className="form-control dt_input date_picker_choose dashboard_search_date_picker" id="datepicker" selected={this.state.pickedDate} onChange={date => this.setSearchDate(date, 1)} /></div>
																		<div className="when-icon when_icon_demand search_calendar_icon">
																			<div className="flex_calendar_hold">
																			</div>
																		</div>
																	</div>
																</div>
																 <div className="form-group-header filter_date_hold">
																	<div className="above_picker">From</div>
																	<span className="cover-border"></span>
																	<label className="label dashboard_search_label">Start Date</label>
																	<div className="">
																		<input type="text" value={this.state.searchStartTime || ''} className="input-field layout_border ondemand_input_hold" readOnly/>
																		<div className="date_picker_hold date_picker_demand dashboard_search_date_picker"><DatePicker className="form-control dt_input date_picker_choose dashboard_search_date_picker" id="datepicker" selected={this.state.pickedStartDate} onChange={date => this.setSearchDate(date, 2)} /></div>
																		<div className="when-icon when_icon_demand search_calendar_icon">
																			<div className="flex_calendar_hold">
																			</div>
																		</div>
																	</div>
																</div>
																 <div className="form-group-header filter_date_hold">
																	<div className="above_picker">To</div>
																	<span className="cover-border"></span>
																	<label className="label dashboard_search_label">End Date</label>
																	<div className="">
																		<input type="text" value={this.state.searchShowTime || ''} className="input-field layout_border ondemand_input_hold" readOnly/>
																		<div className="date_picker_hold date_picker_demand dashboard_search_date_picker"><DatePicker className="form-control dt_input date_picker_choose dashboard_search_date_picker" id="datepicker" selected={this.state.pickedEndDate} onChange={date => this.setSearchDate(date, 3)} /></div>
																		<div className="when-icon when_icon_demand search_calendar_icon">
																			<div className="flex_calendar_hold">
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="filter_bottom_options">
															<div className="filter_clear_all" onClick={()=>{this.closeExpandFilter(2)}}>Clear All</div>
															<div className="filter_apply" onClick={(e)=>{this.searchResults(e, 1)}}>APPLY</div>
														</div>
													</div>
												}
															<div className={"inline_btns_header pos_rel_pass btns_show grid_view" + (this.state.demandPreview === 'square' ? ' active' : '')} onClick={ () => { this.changeDemandScreen('square') }} onMouseOver={ (e) => this.openInfoPopUpNew(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUpNew(e, 'leave')}>
																<div className="tooltip_hold pink_info more_top_pos">
																	<div className="tooltip_text">Panel View</div>
																</div>
															</div>
															<div className={"inline_btns_header pos_rel_pass btns_show list_view" + (this.state.demandPreview === 'list' ? ' active' : '')} onClick={ () => { this.changeDemandScreen('list') }} onMouseOver={ (e) => this.openInfoPopUpNew(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUpNew(e, 'leave')}>
																<div className="tooltip_hold pink_info more_top_pos">
																	<div className="tooltip_text">List View</div>
																</div>
															</div>
														</div>
													}
													{/*
													<div className="inline_btns_header" onClick={this.openAddingOnDemand}>
														<img src="/images/add.png" className="cursor-pointer" />
													</div>
													*/}
												</div>
											}
										</div>
									</div>
								</div>
						    	<div className={classnames("row", 'mb-3', 'upcoming_holder_main ', { 'new_top_margin': ( this.state.sessionPreview === 'list')})} id="join_scroll">
								{ this.state.showTutorial &&
									<div className="demand_preview_square host_more_hg2 margin_top_showTutorial">
										<div className="all_inner_square new_back auto_cursor padd_participant pb120">
											<div className="footer_pad_participant">
												<div className="pdash_50 txt_lft top_align">
													<div className="pdash_fnt16 host_title_position">
														<span className="relative_interest">Yoga</span>
														{ !this.state.isMarketplaceChannel &&
															<div className="abs_levelsupc">
																<div className="level_title">Beginner</div>
																<div className="level_square green_square"></div>
															</div>
														}
													</div>
												</div>
												<div className="pdash_50 txt_rght">
													<button className="new_view_button upc_list_canc new_edit_btn host_btn_padd">EDIT</button>
													<button className="new_view_button upc_list_canc host_btn_padd">CANCEL</button>
												</div>
											</div>
											<div className="footer_pad">
												<div className="footer_square100 main_color_part less_wd mb0 font17">Upcoming Class Example</div>
												<div>
													<p className="mr-3 font_size_dash">Signed Up 0/10 (max)
														<span className="w20 marg_left_span"><img src="/images/information.png" className="mr-3 w-20 cursor-pointer mr0"  alt="" /></span>
													</p>
												</div>
												<div className="all_width_participant">
													<div className="host_name_prt">{this.state.mainUserData.firstName} {this.state.mainUserData.lastName}</div>
													<div className="inline_parts_participant">
														<div className="inline_parts_img time_img"></div>
														1/01/2020 @ 01:00 PM
													</div>
													<div className="inline_parts_participant">
														<div className="inline_parts_img clock_img"></div>
														30 Min
													</div>
												</div>
											</div>
											<div className="footer_pad marg_tp10">
												<div className="relative pl25 pr25 description_demand text_wrap btn_disp">Description
													<div className="icon_foot_part_l list_img"></div>
												</div>
											</div>
											<div className="new_list_mych">
												<div className="description_demand relative pl25 pr25">Equipment name
													<div className="icon_foot_part_l wrench_img"></div>									
												</div>
											</div>
											<div className="footer_pad abs_btns_participants new_abs_pos">
												<div className="pdash_50 txt_lft new_align_upc">	
													<div className="new_view_button upc_list_canc btn_disp host_btn_padd">CLASS LINK</div>								
												</div>
												<div className="pdash_50 txt_rght pdash_marg_tp10 new_align_upc">
													<div className="new_view_button upc_list_canc btn_disp host_btn_padd">SIGN UP/INVITE</div>
												</div>
											</div>
											<div className="footer_pad abs_btns_participants">
												<div className="pdash_50 txt_lft new_align_upc">	
													<div className="new_view_button upc_list_canc btn_disp active_join">JOIN</div>
													{  this.state.chosenTutorialIndex == 6 && this.state.showTutorial && 
														<div className="all_tutorials tutorial_seventh_host new_join_position">
															<MainTutorial
																chosenTutorial="hostDashboardTutorial"
																state={this.state}
																changeTutorialIndex={this.changeTutorialIndex}
																skipTutorial={this.skipTutorial}
															></MainTutorial>
															<div className="arrow_tutorial arrow_tutorial_join"></div>
														</div>
													}
												</div>
												<div className="pdash_50 txt_rght pdash_marg_tp10 new_align_upc">
													<div className="new_view_button upc_list_canc btn_disp">VIEW ACTIVITY LIST</div>
												</div>	
											</div>
										</div>
									</div>
								}
								{/*								
								{this.state.sessionData.length > 0 && this.state.sessionType !== 'On Demand' && this.state.sessionPreview === 'list'  ?
						    	(this.state.sessionData.map((item,i)=>
									
									<div className={classnames("one_list_demand", 'dashboardlisting', { 'even_list': i % 2 == 0 })} key={i}>
										<div className="list_header">
											<div className="sess_interest inline_interest less_margin_right new_titles_dash_list">{item.interestTitles}</div>
											<div className={"display_change_dash " + ( this.state.sessionType === 'Past' ? 'no_right_dash' : ''  ) }>
												<div className="sess_interest inline">{item.level}</div>
												{ item.level === 'Beginner' &&
													<div className="level_square green_square"></div>
												}
												{ item.level === 'Intermediate' &&  
													<div className="level_square yellow_square"></div>
												}
												{ item.level === 'Advanced' &&
													<div className="level_square red_square"></div>
												}
												{ item.level === 'Multi-Level' &&
													<div className="level_square multi_level_square"></div>
												}
											</div>	
										</div>
										<div className="relative_list_info">
											<div className="abs_demand_image" style={{backgroundImage: "url('" + (item.sessionImage ? item.sessionImage : item.interestImage) + "')"}}></div>
											<div className="footer_pad">
												<div className="footer_square25">{item.name}</div>
												<div className="footer_square25 list_name"><img src="/images/gray-icons/user.png" className="mr-3" /> {item.hostFirstName} {item.hostLastName}</div>
												<div className="footer_square25 relative pl25">{item.duration > 1000 ? '0 hour 20 minute' : Math.floor(item.duration/60)+' hour ' + (item.duration%60)+ ' minute'}
													<div className="icon_foot_part_l clock_img"></div>
												</div>
											</div>
											<div className="footer_pad">
												<div className="footer_square75">
													{ item.description.length <= 80 &&
														<div className="relative pl25 pr25 description_demand">{item.description}
															<div className="icon_foot_part_l list_img"></div>
														</div>
													}
													{ item.description.length > 80 &&
														<div className="relative pl25 pr25 description_demand">{item.description.slice(0, 80)}...
															<div className="icon_foot_part_l list_img"></div>
														</div>
													}
												</div>
												{ this.state.sessionType == 'Upcoming' && item.isPending === 1 &&
													<div className="footer_square75">
														<div className="relative description_demand">Class from Mindbody. Pending to be edited.</div>
													</div>
												}
												{ this.state.sessionType == 'Upcoming' &&
													<div className="footer_square25 text-right"><button className="session_btn text-uppercase align-self-start session_link_btn_list link_list_position" id={item.groupId} name={item.sessionLink} onClick={this.uneditableMode}>Class Link</button></div> 
												}
												
												{ this.state.sessionType === 'Past' && item.sessionStat === 'Canceled' && new Date().getTime() < new Date(item.sessionEndDate.toString()).getTime() &&
												  <div className="footer_square25 text-right"><button className="session_btn text-uppercase abs_reopen_upc new_reopen_button" onClick={ () => this.reopenSession(item.id, i) }>Re-Open</button></div>
												}
												
												{ this.state.sessionType === 'Past' && item.sessionStat === 'Attended' && new Date().getTime() < (new Date(item.sessionEndDate.toString()).getTime()) &&
												  <div className="footer_square25 text-right"><button className="session_btn text-uppercase abs_reopen_upc new_reopen_button" onClick={ () => this.reopenSession(item.id, i) }>Re-Open</button></div>
												}
												
												{ this.state.sessionType === 'Past' && item.sessionStat === 'Skipped' && new Date().getTime() < (new Date(item.sessionEndDate.toString()).getTime()) &&
												  <div className="footer_square25 text-right"><button className="session_btn text-uppercase abs_reopen_upc new_reopen_button" onClick={ () => this.reopenSession(item.id, i) }>Re-Open</button></div>
												}
											
											
											</div>
											<div className="footer_pad">
												<div className="footer_square25 description_demand relative pl25">{this.getSessionDate(item.scheduleDate)} {this.getSessionTime(item.scheduleDate)}
													<div className="icon_foot_part_l record_img"></div>
												</div>
												{item.totalSignUp && item.totalSignUp > 0 ?
													<div className="footer_square25 description_demand new_desc_width"><img src="/images/gray-icons/teamwork.png" className="mr-3"  alt="" />Signed Up {item.totalSignUp}/{item.maxAttendee} (max) </div>
													:
													<div className="footer_square25 description_demand new_desc_width"><img src="/images/gray-icons/teamwork.png" className="mr-3"  alt="" />Signed Up 0/{item.maxAttendee} (max)</div>
												}
												{this.state.sessionType==="Upcoming" && <div className="footer_square50 description_demand demand_timer_start new_margin_line">
												
													{item.hostId === this.state.loginId && ( item.isTutorialRead===1 || item.isTutorialRead=== 2 ) && <Countdown date={Date.now() + ((new Date(item.scheduleDate).getTime()-new Date().getTime()))} renderer={this.renderer2}></Countdown>}
													{item.hostId === this.state.loginId && item.isTutorialRead===0 && <Countdown date={Date.now() + ((new Date(item.scheduleDate).getTime()-new Date().getTime()))} renderer={this.renderer}></Countdown>}
													
												</div>}
												
												{ this.state.sessionType==='Past' && <div className="footer_square25 description_demand"><p className="text-white">{item.sessionStat}</p></div> }
											</div>
										</div>
											
										<div className="rightIconsDash more_top_dash">
											{ this.state.sessionType == 'Upcoming' && item.duration < 1000 && item.hostId == JSON.parse(localStorage.getItem('userData')).data.responseData.id &&
													<img src="/images/main_cancel.svg" className="ml-2 cursor-pointer new_small_width" alt="" name={i} id={item.id} onClick={ () => this.cancelSessionModal(item, i) } /> 
											}									
										</div>
										<div className="new_btns_holder">
											{ this.state.sessionType === "Upcoming" && 
											
												<div className="session_btn edit_btn sign_btn sign_btn_line text-uppercase" onClick={ () => this.openAddMember(item)} >Sign up/Invite</div>
											}
											{ this.state.sessionType === "Upcoming" &&  
												<div className="session_btn edit_btn edit_btn_list text-uppercase" onClick={ () => this.editableMode(item, i) } >EDIT</div>
											}										
										</div>		
									</div>
								)):''}
								*/}
								{ this.state.sessionType !== 'On Demand' &&
									<div>
									{this.state.sessionType === 'Upcoming' ?
										<ParticipantDashboardUpcomingSessions
											state={this.state}
											renderer={this.renderer}
											renderer2={this.renderer2}
											getSessionTime={this.getSessionTime}
											cancelSessionModal={this.cancelSessionModal}
											editableMode={this.editableMode}
											openActivityPop={this.openActivityPop}
											uneditableMode={this.uneditableMode}
											openAddMember={this.openAddMember}
											openUpcDrops={this.openUpcDrops}
											participantShowList={this.participantShowList}
											getRecurringSessionData={this.getRecurringSessionData}
										>
										</ParticipantDashboardUpcomingSessions>
										:
										<div className="wdth100">
											{this.state.pastSessionData.length > 0 ?
												<PastSessionsParticipant
													state={this.state}
													openPastDrops={this.openPastDrops}
													getMorePastSessiosns={this.getMorePastSessiosns}
													openActivityPop={this.openActivityPop}
													getSessionTime={this.getSessionTime}
													reopenSession={this.reopenSession}
													loadMorePrevious={this.loadMorePrevious}
													openPastDrops={this.openPastDrops}
													participantShowList={this.participantShowList}
												>
												</PastSessionsParticipant>
												:
												<div className="no_upc_sessions_participant" >Can't find any past classes</div>
											}
											
										</div>
									}
									</div>
								}
								{ this.state.sessionData.length > 0 && this.state.sessionType !== 'On Demand' && this.state.sessionPreview === 'square' &&
									<div className="row mb-3" id="join_scroll">
										{/*
										{this.state.sessionData.map((row,i)=>
											<div className={classnames("col-12", 'col-lg-6','col-xl-4', 'col-sm-6', 'mt-3', 'px-2')}  key={i}  >
												{/*
													<div className="inner_containr px-4 py-3 position-relative">
													
													
													   
													<div className="d-flex justify-content-between align-itmes-center">
														<div className="d-flex">
															<h4 className="white mb-3">{row.name}</h4>
															{row.parentRecrId>0?
															<img src="../images/r-icon.png" className="ml-3 align-self-start w-auto cursor-pointer px-2" data-toggle="modal" data-target="#recurringInfo" id={row.parentRecrId} name = {i} onClick={this.getRecurringSessionData} />
															:'' }
														</div>
														{this.state.sessionType =='Upcoming' && (this.state.isAdmin == 1 && ( row.duration<1500 || this.state.isAdmin == 1) || ( (row.hostId == JSON.parse(localStorage.getItem('userData')).data.responseData.id) ||  this.state.isAdmin == 1 )) &&
															<button className="session_btn text-uppercase align-self-start" id={row.id} name = {i} onClick={ () => this.cancelSessionModal(row, i) }>
																Cancel
															</button>
														}
														
														{this.state.sessionType==='Past'?<p className="text-white ">{row.sessionStat}</p>
														:''									
														}
														

													</div>
														<p className="paddr_reopen"><img src="/images/gray-icons/user.png" className="mr-3"  alt="" />{row.hostFirstName} {row.hostLastName}</p>
														
														
														{row.chargeForSession>0?<p><img src="/images/gray-icons/dollar.png" className="mr-3"  alt="" />${row.chargeForSession} per class</p>:''}
														<p className=""><img src="/images/gray-icons/date.png" className="mr-3"  alt="" />{this.getSessionDate(row.scheduleDate)} {this.getSessionTime(row.scheduleDate)}</p>
														{row.duration>1439?
														<p className=""><img src="/images/gray-icons/clock.png" className="mr-3"  alt="" />0 hour 20 minute</p>
														:
														<p className=""><img src="/images/gray-icons/clock.png" className="mr-3"  alt="" />{Math.floor(row.duration/60)} hour {(row.duration)%60} minute</p>}
														<div className="d-flex justify-content-between">
															<div className="d-flex">
															{ row.totalSignUp && row.totalSignUp > 0 ?
																<p className="mr-3"><img src="/images/gray-icons/teamwork.png" className="mr-3"  alt="" />Signed Up {row.totalSignUp}/{row.maxAttendee} (max)</p>
																:
																<p className="mr-3"><img src="/images/gray-icons/teamwork.png" className="mr-3"  alt="" />Signed Up 0/{row.maxAttendee} (max)</p>
															}
														
															<div className="position-relative d-none">
															{row.totalSignUp>0?<div className="w20" onClick={this.participantShowList}><img src="/images/information.png" name = {row.id}  className="mr-3 w-20 cursor-pointer mr0"  alt="" /></div>:''}
																
															</div>
															<div className="position-relative ml-2">
															{row.totalSignUp>0?<div className="w20" onClick={this.participantShowList}><img src="/images/information.png" name = {row.id}  className="mr-3 w-20 mr0"  alt="asa" /></div>:''}											
															</div>
															</div>
														</div>
														{ this.state.sessionType === "Upcoming" && row.isPending === 1 &&
															<p>Class from Mindbody. Pending to be edited.</p>
														}
														
														{ this.state.sessionType === 'Past' && row.sessionStat === 'Canceled' && new Date().getTime() < new Date(row.sessionEndDate.toString()).getTime() &&  !row.isDefault &&
														  <div className="d-flex flex-wrap justify-content-between align-items-center">
																<div className="flex-grow-1">
																	<button className="session_btn text-uppercase abs_reopen_upc" onClick={ () => this.reopenSession(row.id, i) }>Re-Open</button>
																</div>
														  </div>
														}
														
														{ this.state.sessionType === 'Past' && row.sessionStat === 'Attended' && new Date().getTime() < (new Date(row.sessionEndDate.toString()).getTime()) && !row.isDefault &&
														  <div className="d-flex flex-wrap justify-content-between align-items-center">
																<div className="flex-grow-1">
																	<button className="session_btn text-uppercase abs_reopen_upc" onClick={ () => this.reopenSession(row.id, i) }>Re-Open</button>
																</div>
														  </div>
														}
														
														{ this.state.sessionType === 'Past' && new Date().getTime() < (new Date(row.sessionEndDate.toString()).getTime() + (1000 * 60 * 60 * 24 * 6)) &&  row.isDefault &&
														  <div className="d-flex flex-wrap justify-content-between align-items-center">
																<div className="flex-grow-1">
																	<button className="session_btn text-uppercase abs_reopen_upc" onClick={ () => this.reopenSessionDefault(row.id, i) }>Re-Open</button>
																</div>
														  </div>
														}
														
														

													{this.state.sessionType==="Upcoming"?
													<div className="d-flex flex-wrap justify-content-between align-items-center">
													<div className="flex-grow-1 new_session_link_width relative new_rel_sq sq_last_width">
													<button className="session_btn text-uppercase new_session_btn_padd"
													 id={row.groupId} name={row.sessionLink} onClick={this.uneditableMode}>
													 {row.groupId ===1 ? 'Session Link': 'Session Link'}</button><img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer manage_bulb invite_participants_bulb less_margin_bulb" onClick={e=>{this.getFaq(32)}}/></div> 
															
															<div className="add_new_margin new_marg_bottom new_btns_sq" onClick={ () => this.openAddMember(row)} >
																<div className="session_btn edit_btn sign_btn text-uppercase" onClick={ () => this.openAddMember(row)} >Sign up/Invite</div>
															</div>
															 <div className="new_btns_sq more_left_btn_sq">													
																<div className="session_btn edit_btn text-uppercase" onClick={ () => this.editableMode(row, i) } >EDIT</div>
															</div>
														
													</div>
													:''}

																	
														
														{/* <button className="session_btn text-uppercase mt-3" id="">Cancel</button> */}
														
														{/* {this.state.sessionType==="Upcoming"?
														(new Date(row.scheduleDate).getTime()>new Date().getTime()?
														<Countdown date={Date.now() + ((new Date(row.sessionEndDate).getTime()-new Date().getTime()))} renderer={this.renderer}>
														</Countdown>
														
														:
														<div className="d-flex justify-content-between align-items-center">
														<div className="mt-3">
															<p className="timer1 font-18 mb-0 text-white"></p>
														</div>
														{row.userId===this.state.loginId && row.isTutorialRead>0?
													
														<div className="mt-3"><button className="join_button text-uppercase" id={row.userId} onClick={this.currentUrlMethod} ><a href ={this.state.CurrentUrl} target="_blank">join</a></button></div>
														
														:<div className="mt-3"><button className="join_button text-uppercase"><a href ={process.env.REACT_APP_URL} target="_blank">join</a></button></div>}	
														</div>
														):''} 

														{this.state.sessionType==="Upcoming"?
														(row.hostId===this.state.loginId && ( row.isTutorialRead===1 || row.isTutorialRead===2 )?
														 <Countdown date={Date.now() + ((new Date(row.scheduleDate).getTime()-new Date().getTime()))} renderer={this.renderer2} >
														 </Countdown>
																									
														:
														''	
														):''}

														{this.state.sessionType==="Upcoming"?
														(row.hostId===this.state.loginId && row.isTutorialRead===0?
														<Countdown date={Date.now() + ((new Date(row.scheduleDate).getTime()-new Date().getTime()))} renderer={this.renderer}  >
														</Countdown>
														
														:
														''):''}
														{/* {this.state.sessionType==="Upcoming"?intervalDelay={10000} onTick={this.tick} onComplete={this.onFinsih}
														(row.hostId!==this.state.loginId && row.isTutorialRead===0?
														<Countdown date={Date.now() + ((new Date(row.sessionEndDate).getTime()-new Date().getTime()))} renderer={this.renderer}>
														</Countdown>
														
														:
														''):''} 

														
														
													</div>
													
											</div>
										)}*/}
									</div>
								}
								
									
										<div className={(this.state.sessionType != "On Demand" && !this.state.noFoundSearch ? ( this.state.upcomingSessionArray.length === 0 ? 'col-12 col-lg-6 col-xl-10 d-flex align-items-center col-sm-6' : 'col-12 col-lg-6 col-xl-4 d-flex align-items-center col-sm-6' ) : 'ond_width')}>
										{this.state.upcomingSessionArray.length===0 && this.state.sessionType == "Upcoming" ?
										<div className={"inner_containr p-4 mt-3"}>
											
											{this.state.noFoundSearch ?
												<div>
													{this.state.sessionType != 'Past' &&
														<div className="no_results_dashboard">
															No Results Found
														</div>
													}
												</div>
												:
												<div>
													{(this.state.dateOfNextSession && this.state.sessionType=="Upcoming")?
														<div className="font-20" style={{color:'#d6d6d6'}}>
															<div>{this.state.loginName}, you have no class scheduled in the current week. Your next class is on {this.getSessionDate(this.state.dateOfNextSession.scheduleDate, true)}, to view the class, press <button onClick={ () => this.upComingScheduleDateMethod('view') } type="button" class="join_button text-uppercase manage_btn" id="0">VIEW</button></div>
														</div>
														:
														<div className="font-20" style={{color:'#d6d6d6'}}>
															You have no {this.state.sessionType=="Upcoming"?"upcoming":''} classes {this.state.totalSession==0?'this week':''}. Let's create a class and invite members! 
															{this.state.sessionType=="Upcoming"?' Click on the "+" button to create a class.':''}
														</div>
													}
												</div>
											}
											
										</div>:''}
																			
										{ this.state.sessionType === "On Demand" &&
											<div id="onDemandPart" onClick={this.closeNewInterest} >
												{  this.state.openAddDemand &&
													<div className="ondemand_form">
														<div className="inner_containr px-4 py-3 mx--6 d-flex justify-content-between align-items-center">
															<div className="form ond_width">
																<div className="form-content mb-0 ond_width">
																	<div className="one_w_demand">
																		<div className="inner_w_demand">
																			<div className="form-group">
																				<span className="cover-border"></span>
																				<label className="label">Name</label>
																				<input type="text" className={"input-field " + (this.state.errorDemandName ? 'red-outline' : '')} maxLength="30" id="onDemandName" value={this.state.onDemandName} onChange={(e)=>this.setState({[e.target.id]:e.target.value, errorDemandName: false})} /> 
																			</div>
																		</div>
																	</div>
																	<div className="one_w_demand">
																		<div className="inner_w_demand">
																			<div className="form-group">
																				<span className="cover-border"></span>
																				<label className="label">Level</label>
																				<select className={"input-field " + (this.state.errorDemandLevel ? 'red-outline' : '')} id="onDemandLevel" value={this.state.onDemandLevel}  onChange={(e)=>this.setState({[e.target.id]:e.target.value, errorDemandLevel: false})}>
																					<option value=""></option>
																					<option value="Beginner">Beginner</option>
																					<option value="Intermediate">Intermediate</option>
																					<option value="Advanced">Advanced</option>
																					<option value="Multi-Level">Multi-Level</option>
																				</select>
																			</div>
																		</div>
																	</div>
																	<div className="one_w_demand charges_dem d-none">
																		<div className="inner_w_demand">
																			<div className="one_w_50">
																				<div className="inner_w_demand">
																					<div className="pb7">
																						<span>Member</span>
																						{ /* <img src="/images/bulb.png" className="ml-3 mb-2 cursor" onClick={e=>{this.props.showMainErrorPop('Coming Soon.')}} /> */ }
																					</div>
																					<div className="relative">
																						<div className="cover-border"></div>
																						<label className="label">Charges</label>
																						<div>
																							<input type="text" className={"input-field add_inp " + (this.state.errorDemandMembersCharges ? 'red-outline' : '')} id="onDemandMemberCharges" value={this.state.onDemandMemberCharges} onChange={(e)=>this.setState({[e.target.id]:e.target.value, errorDemandMembersCharges: false})} /> 
																							<span className="dollar_sign"></span>
																						</div>
																					</div>
																				</div>
																			</div>
																			<div className="one_w_50">
																				<div className="inner_w_demand">
																					<div className="pb7">
																						<span>Non-Member</span>
																						{ /* <img src="/images/bulb.png" className="ml-3 mb-2 cursor" onClick={e=>{this.props.showMainErrorPop('Coming Soon.')}} /> */ }
																					</div>
																					<div className="relative">
																						<div className="cover-border"></div>
																						<label className="label">Charges</label>
																						<div>
																							<input type="text" className={"input-field add_inp " + (this.state.errorDemandNonMembersCharges ? 'red-outline' : '')} id="onDemandNonMemberCharges" value={this.state.onDemandNonMemberCharges} onChange={(e)=>this.setState({[e.target.id]:e.target.value, errorDemandNonMembersCharges: false})} /> 
																							<span className="dollar_sign"></span>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="form-content mb-0 ond_width">
																	<div className={"one_w_demand " + (this.state.demandToEdit > 0 ? 'half_w_demand' : '')}>
																		<div className="inner_w_demand">
																			<div className="form-group">
																				<span className="cover-border"></span>
																				<label className="label">On Demand Image</label>
																				<div className={"input-field relative height_imgadd " + (this.state.errorDemandImage ? 'red-outline' : '')} >
																					{ (this.state.demandToEdit == 0 || this.state.editOnDemandVideo.processed == 0) && <div>
																						<input type="file" className="custom-file-input opacity_file" id="onDemandImage" name="file" onChange={ (e) => { this.importDemandImage(e, 'image') }} />
																						<img src={(this.state.onDemandImageName == '' && this.state.hasOnDemandFiles.image == '' ) ? "/images/browse-image.png ":this.state.onDemandImage} className="browse_image" />
																					</div>}
																					
																					{this.state.demandToEdit > 1 && this.state.editOnDemandVideo.processed == 1 && <div>
																						<img src={(this.state.onDemandImageName == '' && this.state.hasOnDemandFiles.image == '' ) ? "/images/browse-image.png ":this.state.onDemandImage} className="browse_image" />
																						<div className="selOnImage">
																							<div><button type="button" className="join_button text-uppercase">Local Image</button><input type="file" className="custom-file-input opacity_file" id="onDemandImage" name="file" onChange={ (e) => { this.importDemandImage(e, 'image') }} /></div>
																							<div><button type="button" className="join_button text-uppercase" onClick={(e)=>this.openEditRecordingImage()}>Video Image</button></div>
																						</div>
																					</div>}
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className={"one_w_demand " + (this.state.demandToEdit > 0 ? 'half_w_demand' : '')}>
																		<div className="inner_w_demand">
																			<div className="form-group">
																				<span className="cover-border"></span>
																				<label className="label ch_top19">Description</label>
																				<textarea className={"input-field " + (this.state.errorDemandDescription ? 'red-outline' : '')} maxLength="250" id="onDemandDescription" value={this.state.onDemandDescription} onChange={(e)=>this.setState({[e.target.id]:e.target.value, errorDemandDescription: false})}></textarea>
																			</div>
																		</div> 
																	</div>
																	{ this.state.demandToEdit === 0 &&
																	<div className="one_w_demand">
																		<div className="inner_w_demand">
																			<div className="form-group">
																				<span className="cover-border"></span>
																				<label className="label">On Demand Video <img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer manage_bulb video_ondemand_bulb" onClick={e=>{this.props.showMainErrorPop('Virdio supports .mp4 and .mov files. File size should not exceed 400mb.')}}/></label>
																				<div className={"input-field relative height_imgadd " + (this.state.errorDemandVideo ? 'red-outline' : '')}>
																					<input type="file" className="custom-file-input opacity_file" id="onDemandVideo" name="file" onChange={ (e) => { this.importDemandImage(e, 'video') }} />
																					<img src={"/images/video.png"} className="browse_video" />
																					{ ( this.state.onDemandVideoName != '' || this.state.hasOnDemandFiles.video !== '' ) &&
																						<div className="video_info">Video has been selected.</div>
																					}
																				</div>
																			</div>
																		</div>
																	</div>
																	}
																</div>
																<div className="form-content mb-0 ond_width">
																	<div className="one_w_demand">
																		<div className="inner_w_demand">
																			<div className="form-group">
																				<span className="cover-border"></span>
																				<label className="label">Goal</label>
																				<input type="text" className={"input-field " + (this.state.errorDemandGoal ? 'red-outline' : '')} id="onDemandGoal" value={this.state.onDemandGoal} onChange={(e)=>this.setState({[e.target.id]:e.target.value, errorDemandGoal: false})} /> 
																			</div>
																			
																		</div>
																	</div>
																	
																	<div className="one_w_demand">
																		<div className="inner_w_demand">
																			<div className="form-group">
																				<span className="cover-border"></span>
																				<label className="label">Equipment</label>
																				<input type="text" className={"input-field " + (this.state.errorDemandEquipment ? 'red-outline' : '')} id="onDemandEquipment"  value={this.state.onDemandEquipment} onChange={(e)=>this.setState({[e.target.id]:e.target.value, errorDemandEquipment: false})} maxLength="250" /> 
																			</div>
																		</div>
																	</div>
																	<div className="one_w_demand">
																		<div className="inner_w_demand">
																			<div className="form-group">
																				<span className="cover-border"></span>
																				<label className="label">Auto Delete After</label>
																				<select className={"input-field " + (this.state.errorDemandAutoDelete ? 'red-outline' : '')} id="autoDeleteAfter" onChange={(e)=>this.setState({[e.target.id]:e.target.value, errorDemandAutoDelete: false})}>
																					<option value=""></option>
																					<option value="Never">Never</option>
																					<option value="24 hours">24 hours</option>
																					<option value="48 hours">48 hours</option>
																					<option value="30 days">30 days</option>
																					<option value="60 days">60 days</option>
																					<option value="90 days">90 days</option>
																					<option value="120 days">120 days</option>
																					<option value="150 days">150 days</option>
																					<option value="180 days">180 days</option>
																				</select>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="one_w_demand demand_middle">
																	<div className="inner_w_demand">
																		{ this.state.channelData && this.state.channelData[0] &&
																			<div className="form-group">
																				<span className="cover-border"></span>
																				<label className="label">Category</label>
																				<div className={"input-field select_arrow_div text_wrap_demand padd_r40 " + (this.state.errorDemandInterest ? 'red-outline' : '' ) } onClick={ e =>{this.openNewIntrest(e)}}>{this.state.onDemandInterest.length === 0 ?  'Category' : this.state.selectedinterestsNamesDemand}</div>
																				{this.state.expandNewIntrest &&
																					<div className="intrest_select">
																						{ this.state.channelInterestSelection.sort(function(a, b){ if(a.title < b.title) { return -1; } if(a.title > b.title) { return 1; } return 0; }).map((row,i)=>											
																	
																							<div className="newIntrestName" key = {i}  onClick={ e=>{this.newSelectIntrest('demand', row.id, row.title, e)} } >{row.title}
																								<div className="checkBox new_checkBox_size"  className={"checkBox new_checkBox_size " + (this.state.onDemandInterest.indexOf(row.id) > -1 ? 'active' : '' )} ></div>
																							</div>
																				
																						)}
																					</div>
																				}
																			</div>
																		}
																	</div>
																</div>
																
																{ this.state.onDemandInterest && this.state.onDemandInterest.length > 0 && this.state.isChannelMarketPlace ?
																	<div className="one_w_demand demand_middle">
																		<div className="inner_w_demand">
																			{ this.state.channelData && this.state.channelData[0] &&
																				<div className="form-group">
																					<span className="cover-border"></span>
																					<label className="label">Sub-Categories</label>
																					<div className={"input-field select_arrow_div text_wrap_demand padd_r40 " + (this.state.errorDemandInterest ? 'red-outline' : '' ) } onClick={ e =>{this.openNewIntrestSubcategories(e)}}>{this.state.onDemandInterestSubcategories.length === 0 ?  'Sub-Categories' : this.state.selectedinterestsSubcategoriesNames}</div>
																					{this.state.expandNewIntrestSubcategories &&
																						<div className="intrest_select">
																							{ this.state.channelInterestSubcategoriesSelection.sort(function(a, b){ if(a.title < b.title) { return -1; } if(a.title > b.title) { return 1; } return 0; }).map((row,i)=>											
																								<div className="newIntrestName" key = {i}  onClick={ e=>{this.newSelectIntrestSubcategory(row.id, row.title, e)} } >{row.title}
																									<div className="checkBox new_checkBox_size"  className={"checkBox new_checkBox_size " + (this.state.onDemandInterestSubcategories.indexOf(row.id) > -1 ? 'active' : '' )} ></div>
																								</div>
																					
																							)}
																						</div>
																					}
																				</div>
																			}
																		</div>
																	</div>
																	: 
																	''
																}

																{ ( (this.state.onDemandHostId == JSON.parse(localStorage.getItem('userData')).data.responseData.id) || this.state.isAdmin == 1 || this.state.userPermissions.addOnDemad ) &&
																	<div className="one_w_demand demand_middle">
																		<div className="inner_w_demand ch_padd_r20">
																			<div className="form-group publish_holder">
																			<span className="main_settings_text">Publish on Channel Page</span>
																				<label className="switch toggle_switch">
																					<input type="checkbox" id = "sessionPublishPage" checked={this.state.sessionPublishPage} onChange = {(e)=>{this.updateState({[e.target.id]:!this.state.sessionPublishPage}); this.checkPrivateMemberOnly()}}/>
																					<span className="slider round"></span>
																				</label>
																				<div className="toggle_option toggle_top">{this.state.sessionPublishPage ? 'ON' : 'OFF'}</div>
																				{/* <div className="demand_bulb_hold">
																					<img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer manage_bulb ondemand_bulb" onClick={e=>{this.getFaq(20)}}/>
																				</div> */}
																			</div>
																		</div>
																		<div className="inner_w_demand heigh40">
																			<div className="form-group publish_holder heigh40">
																				{ this.state.sessionPublishPage && !this.state.isMarketplaceChannel ?
																					<div className="heigh40">
																						<span className="main_settings_text">Private/Members Only</span>
																						<label className="switch">
																							<input type="checkbox" id = "sessionPrivateMemberOnly" checked={this.state.sessionPrivateMemberOnly} onChange = {(e)=>{ this.updateState({[e.target.id]:!this.state.sessionPrivateMemberOnly})}}/>
																							<span className="slider round"></span>
																						</label>
																						<div className="toggle_option toggle_top">{this.state.sessionPrivateMemberOnly ? 'ON' : 'OFF'}</div>
																					</div>
																					: ''
																				}
																			</div>
																		</div>
																	</div>
																}

																
																{ this.state.demandToEdit > 0 && this.state.editOnDemandVideo.processed == 1 && <div className="one_w_demand marg_bott1060">
																	<div className="inner_w_demand"><div className="editrecord_btn"><button type="button" className="join_button text-uppercase" onClick={(e)=>this.openEditRecording()}>Edit Recording</button></div></div></div> }
																
																<div className="form-content mb-0 ond_width">
																	<div className="one_w_demand demand_middle">
																		<div className="inner_w_demand">
																			{ this.state.channelData && this.state.channelData[0] &&
																				<div className="form-group">
																					<span className="cover-border"></span>
																					
																					<label className="label">Instructor</label>
																					<div className="input-field select_arrow_div text_wrap_demand marg_tp padd_r40" onClick={ e =>{this.openNewHost(e)}}>{this.state.onDemandHostName}</div>
																						{this.state.expandChangeHost &&
																							<div className="intrest_select">
																								{ this.state.hostsArray.sort(function(a, b){ if(a.username < b.username) { return -1; } if(a.username > b.username) { return 1; } return 0; }).map((row,i)=>											
																			
																									<div className="newIntrestName" key = {i}  onClick={ e=>{this.newSelectHost(i, e)} } >{row.username}
																										<div className="checkBox new_checkBox_size"  className={"checkBox new_checkBox_size " + (this.state.onDemandHostId == row.userId ? 'active' : '' )} ></div>
																									</div>
																						
																								)}
																							</div>
																						}
																				</div>
																			}
																		</div>
																	</div>
																</div>
																{ this.state.errorSessionOnDemand &&
																	<div className="ondemand_error">Please Fill all form Correctly.</div>
																}
																<div className="form-content mb-0 ond_width">
																	<div className="text-center">
																		<button type="button" className="custom_btn1 mt-3" onClick={this.cancelAddingnDemand}>Cancel</button>
																		<button type="button" className="custom_btn1 mt-3 ml20" onClick={this.uploadImageAndVideo}>Save</button>
																	</div>
																</div>
															</div>
														</div>
														
														{ this.state.openEditVideo && <EditVideoCut state={this.state} closeVideoEdit={this.closeVideoEdit} ></EditVideoCut> }
														{ this.state.openEditVideoImage && <EditVideoCutImage state={this.state} closeVideoEdit={this.closeVideoEdit} importDemandImageCut={this.importDemandImageCut} ></EditVideoCutImage> }
													</div>
												}
												
												{  !this.state.openAddDemand && this.state.mainDemandObject && this.state.mainDemandObject.allDemandSession &&
													<div>
														{ this.state.mainDemandObject.allDemandSession.length === 0 &&
															<div className="ondemand_form">
																{!this.state.noFoundSearch ?
																	<div className="ondemand_empty">You have no On-demand recordings. To create one, record your next class and come back to this tab to see the listing.</div>
																	: 
																	<div className="ondemand_empty">No Results Found</div>
																}
															</div>
														}
														{ this.state.mainDemandObject.allDemandSession.length > 0 &&
													<div className={"ondemand_form" + (this.state.demandPreview === 'list' && !this.state.gridDemandView ? ' non_padd' : '')}>
														<OnDemandSessions
															state={this.state}
															openDemandDrops={this.openDemandDrops}
															openDeleteDemand={this.openDeleteDemand}
															openEditDemand={this.openEditDemand}
															openGridView={this.openGridView}
															closeGridView={this.closeGridView}
															preventDef={this.preventDef}
															openNativeDemand={this.openNativeDemand}
															openDownloadOnDemand={this.openDownloadOnDemand}
															uneditableMode={this.uneditableMode}
															updateStateFromChild={this.updateStateFromChild}
															openDownloadOnDemand={this.openDownloadOnDemand}
														>
														</OnDemandSessions>
													</div>
												}
											</div>
										}
											</div>
										}
										
						    		</div>
						    	</div> 	
						    </div>

						  <div className="inner_containr px-3 px-lg-4 py-2 mx--6 d-flex justify-content-between flex-wrap align-items-center">
						  <div><h4 className="px-2 my-2 m-0 m-0 white">Current Channel</h4></div>						
						    	<div className="d-flex flex-wrap align-items-center">
									{this.state.channelData.length>0?'':<div className="text-light font-18 font-medium mr-4 my-2">Welcome, get started, tap on this to create a channel</div>}
									{this.state.channelData.length>0?'':<Link to="/ChannelCreation"><img src="/images/add.png" className="px-2 autowidth my-2"  alt="" /></Link>}
								
								</div>
						    </div>
							
						    <div className="row">
							{this.state.channelData.map((row,i)=>
						    	<div className="col-lg-4 mt-3 px-2" key={i}>
								
						    		<div className="inner_containr px-3 py-4 onechanneldiv">
						    			<div className="d-flex justify-content-between flex-wrap relative title_dashboard_mob" id="editChannelPart">
						    				<h4 className="hdng1 font-weight-bold relative channel_info_heading" id="tutorial_ch_settings">{row.name}
												{ ( row.is_admin===1 ) && <div className="channel_settings_open" onMouseOver={ (e) => this.openInfoPopUpNew(e, 'over') } onMouseLeave={ (e) => this.openInfoPopUpNew(e, 'leave') }>
													<img onClick={(e)=>this.openChannelSettings()} src="../images/setting.png"/>
													<div className="tooltip_hold pink_info">
														<div className="tooltip_text">Channel Settings</div>
													</div>
													{ this.state.showTutorial && i === 0 && ( this.state.chosenTutorialIndex == 9 || this.state.chosenTutorialIndex == 10 || this.state.chosenTutorialIndex == 11 ) &&
														<div className="channel_settings_openabs noclickh d_bl">
															<div className="noclickh menu_div_chh relative">Generate Channel Page
																{  this.state.chosenTutorialIndex == 9 && this.state.showTutorial && i === 0 && 
																	<div className="all_tutorials tutorial_eight_host edit_ch_tutorial main_edit_ch_sett">
																		<MainTutorial
																			chosenTutorial="hostDashboardTutorial"
																			state={this.state}
																			changeTutorialIndex={this.changeTutorialIndex}
																			skipTutorial={this.skipTutorial}
																		></MainTutorial>
																		<div className="arrow_tutorial arrow_tutorial_editch main_edit_ch_arrow"></div>
																	</div>
																}
															</div>
															<div className="noclickh menu_div_chh relative">Setup Sign Up Page
																{  this.state.chosenTutorialIndex == 10 && this.state.showTutorial && i === 0 && 
																	<div className="all_tutorials tutorial_eight_host edit_ch_tutorial main_edit_ch_sett">
																		<MainTutorial
																			chosenTutorial="hostDashboardTutorial"
																			state={this.state}
																			changeTutorialIndex={this.changeTutorialIndex}
																			skipTutorial={this.skipTutorial}
																		></MainTutorial>
																		<div className="arrow_tutorial arrow_tutorial_editch main_edit_ch_arrow"></div>
																	</div>
																}
															</div>
															<div className="noclickh menu_div_chh relative">Channel Settings</div>
															<div className="noclickh menu_div_chh relative">Reports
																{  this.state.chosenTutorialIndex == 11 && this.state.showTutorial && i === 0 && 
																	<div className="all_tutorials tutorial_eight_host edit_ch_tutorial main_edit_ch_sett">
																		<MainTutorial
																			chosenTutorial="hostDashboardTutorial"
																			state={this.state}
																			changeTutorialIndex={this.changeTutorialIndex}
																			skipTutorial={this.skipTutorial}
																		></MainTutorial>
																		<div className="arrow_tutorial arrow_tutorial_editch main_edit_ch_arrow"></div>
																	</div>
																}
															</div>
														</div>
													}
													<div className="channel_settings_openabs noclickh" id="channel_settings_openabs">
														<Link className="noclickh relative" to="/channelSubscription"><div className="noclickh menu_div_chh">Channel Subscription</div></Link>
														{ this.state.fullsubdomain ?
															<div className="noclickh menu_div_chh relative" data-toggle="modal" data-target="#show_subdomain">View Channel Page URL</div>
															: 
															<div className="noclickh relative" onClick={this.setPlaceholderToValue}><div className="noclickh menu_div_chh" data-toggle="modal" data-target="#generate_subdomain">Generate Channel Page</div></div>
														}
														{ row.is_admin===1 && this.state.fullsubdomain &&  <div className="noclickh menu_div_chh relative" onClick={(e)=>this.openInviteMessage()}>Setup Sign Up Page</div> }
														{ row.is_admin===1 && this.state.fullsubdomain && this.state.hasAmbassador && <div className="noclickh menu_div_chh" onClick={()=>this.openBrandPop()}>Brand Ambassador URL</div> }
														{ row.is_admin===1 && <Link className="noclickh relative" to="/ChannelSettings"><div className="noclickh menu_div_chh">Channel Settings</div></Link> }
														{ row.is_admin===1 && <Link className="noclickh relative" to="/reports"><div className="noclickh menu_div_chh">Reports</div></Link> }
													</div>
													
													</div> }
													{  this.state.chosenTutorialIndex == 8 && this.state.showTutorial && i === 0 && 
														<div className="all_tutorials tutorial_eight_host edit_ch_tutorial main_edit_ch">
															<MainTutorial
																chosenTutorial="hostDashboardTutorial"
																state={this.state}
																changeTutorialIndex={this.changeTutorialIndex}
																skipTutorial={this.skipTutorial}
															></MainTutorial>
															<div className="arrow_tutorial arrow_tutorial_editch main_edit_ch_arrow"></div>
														</div>
													}
											
											</h4>
											{row.is_admin===1 && <img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer manage_bulb channel_bulb_main" onClick={e=>{this.props.showMainErrorPop('Select the “Edit” icon next to your channel name. Make any changes you want then press Save.')}}/> }
						    				{row.is_admin===1?<div className="for_ch_edit"><Link to={`/editChannel/`+row.id}><div className="flex-grow-1"><button className="session_btn text-uppercase">Edit</button></div></Link></div>:''}
						    			
											{  this.state.chosenTutorialIndex == 7 && this.state.showTutorial && i === 0 && 
												<div className="all_tutorials tutorial_eight_host edit_ch_tutorial">
													<MainTutorial
														chosenTutorial="hostDashboardTutorial"
														state={this.state}
														changeTutorialIndex={this.changeTutorialIndex}
														skipTutorial={this.skipTutorial}
													></MainTutorial>
													<div className="arrow_tutorial arrow_tutorial_editch"></div>
												</div>
											}
						    			</div>
						    			{/* <img src="/images/session-banner.jpg" alt="" className="d-block img-fluid inner_containr my-3" /> */}
										<img src={row.image==="default.png"?"/images/session-banner.jpg":row.image} className="d-block mx-auto img-fluid inner_containr my-3 channel_dash_img" alt= ''/>
										{/* <img src="/images/session-banner.jpg" alt="" className="d-block img-fluid inner_containr my-3" /> */}
						    			<div className="d-flex justify-content-between flex-wrap">
						    				<div className="">
						    					<p className="checktxt mb-0 mt-2">Upcoming</p>
						    					{row.totalUpcomming>0?
												<p className="pick text-center">{row.totalUpcomming}</p>
												:
												<p className="pick text-center">0</p>}
						    				</div>
						    				<div className="px-3">
						    					<p className="checktxt mb-0 mt-2">Past Class</p>
						    					<p className="pick text-center">{row.totalPast}</p>
						    				</div>
						    				<div className="">
						    					<p className="checktxt mb-0 mt-2 text-right">Next Class by </p>
						    					{!row.nextSession?
												<p className="pick text-center">NONE</p>
												:
												<p className="pick text-center breakW">{row.nextSession}</p>}
						    				</div>
						    			</div>

						    			<div className="d-flex justify-content-between flex-wrap align-items-center mt-3 relative" id="currenthhost">
						    				<div>
												<div className="bulbTextHolder" >
													<h4 className="white">Current Instructor</h4>
												</div>
												{ row.is_admin===1 && 
													<div className="bulbHolderImg" >
														<img src="/images/bulb.png" className="ml-3 mb-2 cursor-pointer" onClick={e=>{this.props.showMainErrorPop('Select the Manage icon. You can then send an invitation via email to another person to be a instructor or a instructor/administrator on your channel.')}}/> 
													</div>
												}
											</div>
											{ ( (row.is_admin===1 && !this.state.isMarketplaceChannel) || (this.state.isMarketplaceChannel && this.state.userPermissions.addInstructors) ) && 
												<Link to="/channelHosts" className="cursor-pointer"><button type="button" className="join_button text-uppercase manage_btn" id={i}>MANAGE</button></Link> 
											}
											{  this.state.chosenTutorialIndex == 12 && this.state.showTutorial && i === 0 &&
												<div className="all_tutorials tutorial_eight_host edit_ch_tutorial">
													<MainTutorial
														chosenTutorial="hostDashboardTutorial"
														state={this.state}
														changeTutorialIndex={this.changeTutorialIndex}
														skipTutorial={this.skipTutorial}
													></MainTutorial>
													<div className="arrow_tutorial arrow_tutorial_editch"></div>
												</div>
											}
											
						    			</div>
										{row.channelHost.map((channl_array,l)=>
						    			<div className="d-flex mt-3" key={l}>
				                            <img src={channl_array.image ? channl_array.image : '/images/pic.png'} className="mr-3 mt-1 align-self-start" alt="user-icon" />
				                            <div>
				                                <p className="checktxt_name pb-0 mb-2 breakW">{channl_array.name}</p>
												{channl_array.sessionList.length>0?
												<p className="checktxt mb-0">Next class on {this.getSessionDate(channl_array.sessionList[0].scheduleDate)} </p>
												:
												<p className="checktxt mb-0">Next class on NONE </p>
												}
												{/* <p className="checktxt mb-0">Next class NONE </p> */}
												{/* @ak <p className="checktxt mb-0">Past Revenue 0$</p> */}
				                            </div>
						    			</div>
										)}

						    			<div className="d-flex justify-content-between flex-wrap mt-4 align-items-center">
						    				<h4 className="white">Current Categories</h4>
											{ row.is_admin===1 && <Link to="/channelInterests" className="cursor-pointer"><button type="button" className="join_button text-uppercase manage_btn">MANAGE</button></Link> }
						    			</div>
										{row.channelInterest.map((inter_array,l)=>
						    			<div className="d-flex mt-3" key={l}>
				                            <img src={inter_array.image} className="mr-3 mt-1 align-self-start" alt="user-icon" />
				                            <div className="flex-grow-1">
				                                <p className="checktxt_name pb-0 mb-2">{inter_array.title}</p>
				                                <div className="overflow-hidden">
					                                <p className="checktxt mb-0 float-left">Upcoming Classes {inter_array.sessionList}</p>
					                                <p className="checktxt mb-0 float-right">Past Classes {inter_array.sessionListPast}</p>
				                            	</div>
				                            	<div className="clearfix"></div>
				                            </div>
						    			</div>
										)}
										{/* memeber Addition */}
										{/* {row.is_admin===1?<div className="d-flex justify-content-between flex-wrap mt-4 align-items-center">
						    				<h4 className="white">Channel Members <img src="/images/bulb.png" className="mb-2 cursor-pointer" onClick={e=>{$("#lightbulb_popup").attr({'style':'display:block'})}}/></h4>
											<Link className="pick" ><img src="/images/add.png" className="cursor-pointer" id={i}
											 onClick={e=>{$("#members_addModal").attr({'style':'display:block'})}} alt="" /></Link>											 
						    			</div> :''}
										{row.is_admin===1?
										(this.state.memeberData.map((memberlist,m)=>
						    			<div className="d-flex mt-3" key={m}>
				                            <img src="/images/pic.png" className="mr-3 mt-1 align-self-start" alt="user-icon" />
				                            <div className="flex-grow-1">
				                                <p className="checktxt_name pb-0 mb-2">{memberlist.firstName} {memberlist.lastName}</p>
				                                <p className="checktxt mb-0">{memberlist.email}</p>			                               
				                            	<div className="clearfix"></div>
				                            </div>
						    			</div>
										)):''}
										
										{this.state.memeberData.length>0 && row.is_admin===1?
										<div className="text-right mt-3"><button className="join_button text-uppercase" onClick={e=>browserHistory.push('/member')}>view more</button></div>
										:''} */}

											<div className="d-flex justify-content-between flex-wrap mt-4 align-items-center relative" id="channel_members">
						    				<h4 className="white members_title">Channel Members <img src="/images/bulb.png" className="mb-2 cursor-pointer" onClick={()=>{this.props.showMainErrorPop('You can set a Class to be Private, which means only your Channel Members can attend those Classes. Add/Manage Members in your channel by clicking here.')}}/></h4>
											<Link className="pick" ><button type="button" className="join_button text-uppercase manage_btn" id={i}
											 onClick={e=>browserHistory.push('/member')}
											 >MANAGE</button></Link>											 
											{  this.state.chosenTutorialIndex == 13 && this.state.showTutorial && i === 0 &&
												<div className="all_tutorials tutorial_eight_host edit_ch_tutorial">
													<MainTutorial
														chosenTutorial="hostDashboardTutorial"
														state={this.state}
														changeTutorialIndex={this.changeTutorialIndex}
														skipTutorial={this.skipTutorial}
													></MainTutorial>
													<div className="arrow_tutorial arrow_tutorial_editch"></div>
												</div>
											}											 
						    			</div>
										<div className="d-flex justify-content-between flex-wrap mt-4 align-items-center relative" id="channel_members">
						    				<h4 className="white members_title">Class Templates <img src="/images/bulb.png" className="mb-2 cursor-pointer" onClick={()=>{this.props.showMainErrorPop('You can build Templates for your workouts using this feature.')}}/></h4>
											<Link className="pick" ><button type="button" className="join_button text-uppercase manage_btn" id={i}
											 onClick={e=>browserHistory.push('/SessionTemplates')}
											 >MANAGE</button></Link>										 
						    			</div> 
										{
										(this.state.memeberData.map((memberlist,m)=>
						    			<div className="d-flex mt-3" key={m}>
				                            <div className="flex-grow-1">
				                                <p className="checktxt_name pb-0 mb-2">{memberlist.firstName} {memberlist.lastName}</p>
				                                <p className="checktxt mb-0">{memberlist.email}</p>			                               
				                            	<div className="clearfix"></div>
				                            </div>
						    			</div>
										))}
										
										{this.state.memeberData.length>0?
										<div className="text-right mt-3"><button className="join_button text-uppercase d-none" onClick={e=>browserHistory.push('/member')}>view more</button></div>
										:''}

										{/* Add Product start here */}
										<div>
										{localStorage.getItem('groupId')==2?	
										<div className="d-flex justify-content-between flex-wrap mt-4 align-items-center relative" id="channel_products">
						    				<h4 className="white">Channel Products </h4>
											<Link className="pick" ><img src="/images/add.png" className="cursor-pointer" data-toggle="modal" data-target="#product_lst_modal"  /></Link>
										
											{  this.state.chosenTutorialIndex == 14 && this.state.showTutorial && i === 0 && 
												<div className="all_tutorials tutorial_eight_host tutorial_prod">
													<MainTutorial
														chosenTutorial="hostDashboardTutorial"
														state={this.state}
														changeTutorialIndex={this.changeTutorialIndex}
														skipTutorial={this.skipTutorial}
													></MainTutorial>
													<div className="arrow_tutorial arrow_tutorial_editch"></div>
												</div>
											}
										</div>
										:''}
										{this.state.shortWineProduct.length>0 && localStorage.getItem('groupId')==2?
										<div>
											{this.state.shortWineProduct.map((row,i) =>
											<div className="d-flex mt-3 channeloneproduct" key={i}>
												<img src={row.image} className="mr-3 mt-1 align-self-start" alt="" />
												<div className="flex-grow-1">
													<p className="checktxt_name pb-0 mb-2 channeloneproductname">{row.product_name}</p>
													{row.price && <p className="checktxt_name pb-0 mb-2 channeloneproductprice">Price: ${row.price}</p>}
												</div>
											</div>
											)}
											<div className="text-right mt-3"><button className="join_button text-uppercase" onClick={e=>browserHistory.push('/products')}>view more</button></div>
										</div>
										:''}

										{/* <div className="col-lg-4 col-sm-6 mt-4 mt-sm-3">
                                         <div className="form-group input-txt">
                                            <label className="switch">
                                                <input type="checkbox" 
                                                id='productStatus'
                                                value = {this.state.productStatus}
                                                onChange={(e)=>{this.setState({[e.target.id]:!this.state.productStatus},()=>console.log(this.state.productStatus))}}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                            <span>Has Product List?</span>
                                        </div>
                                        {this.state.productStatus? //data-toggle="modal" data-target="#product_lst_modal"
											<div className="add_text">
												<Link to="ChannelCreation" className="bg-circle mr-4" data-toggle="modal" data-target="#product_lst_modal"  ><i className="fa fa-plus" aria-hidden="true"></i></Link>
												<span className="gray-text"> Product Added</span>
											</div>
											:''
											}      
										</div> */}
										</div>
										{/* add product end here*/}
						    		</div>
								
								</div>
								)}

						    
						    </div>

						{/* JOIN BUTTON POPUP */}
						<div className="modal py-3" id="streaming_option">
							<div className="modal-dialog mw-770 modal-dialog-centered">
								<div className="modal-content bg-transparent px-2">
									<div className="modal-body px-1 py-1 py-sm-3 px-sm-3 modalbg radius-10">
										<button type="button" className="close white closepurple translatX-Y" data-dismiss="modal" onClick={e=>$("#streaming_option").attr({'style':'display:none'})} ><span>x</span></button>
										<div className="p-md-4 p-3 text-center">
											<h4 className="white text-center font-25">Virdio Streaming Options</h4> 
											<p className="text-white font-18 mb-0 text-center mt-md-2 d-inline-block"><b>Join at: 
											<input type="text" ref={(textarea)=>this.textArea=textarea}  className="input-field2" placeholder="Class Name" value={process.env.REACT_APP_URL} onChange={(e)=>console.log()}/></b></p>
											<Link to = "/DashboardLanding" className="join_button ml-2 d-inline-block" onClick={e=>{this.textArea.select();document.execCommand('copy');e.target.focus();$("#streaming_option").attr({'style':'display:none'});}}>Copy URL</Link>
											<p className="text-white font-18 text-center mt-md-2">You can copy and paste a link for {process.env.REACT_APP_URL} if you have one of the browsers:</p> 
											<div className="table-responsive">
												<table className="table table-bordered">
												<tbody>
													<tr className="text-white">
													<td>MacBook</td>
													<td><a href="https://apps.apple.com/us/app/virdio/id1489704718" target="_blank">Virdio App</a> | <a href="https://www.google.com/chrome/" target="_blank">Chrome</a> - v 79 + | <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a> - v 72 +</td>
													</tr>
													<tr className="text-white">
													<td>Windows</td>
													<td><a href="https://www.google.com/chrome/" target="_blank">Chrome</a> - v 78 + | <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a> - v 72 +| <a href="https://www.microsoft.com/en-us/edge" target="_blank">Edge</a> - v 79 +</td>
													</tr>
													<tr className="text-white">
													<td>iOS</td>
													<td><a href="https://apps.apple.com/us/app/virdio/id1489704718" target="_blank">Virdio App</a></td>
													</tr>
													<tr className="text-white">
													<td>Android</td>
													<td><a href="https://play.google.com/store/apps/details?id=mvvm.f4wzy.com.virdioApp&hl=en" target="_blank">Virdio App</a></td>
													</tr>
													<tr className="text-white">
													<td>Linux</td>
													<td><a href="https://www.google.com/chrome/" target="_blank">Chrome</a> - v 78 + | <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a> - v 72 +| <a href="https://www.microsoft.com/en-us/edge" target="_blank">Edge</a> - v 79 +</td>
													</tr>
												</tbody>
												</table>
											</div>                        
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* RECURRING SESSION CLICK ON INFORMATION MODAL */}
						<div className="modal" id="recurringInfo">
								<div className="modal-dialog mw-770 mx-auto modal-dialog-centered">
									<div className="modal-content bg-transparent">
										<div className="modal-body px-2 px-md-3 pt-3 m-2 radius-8">
											<div className="d-flex justify-content-end">
												<button type="button" className="close whitecross" data-dismiss="modal" onClick={e=>$("#recurringInfo").attr({'style':'display:none'})} >&times;</button>
											</div>
												<div className="table_box2 px-2 pb-2">
													<h4 className="white text-center border-bottom pb-2">Classes Listing</h4>
													<div className="parti_id_box over_x">                                                  
													<table className="table table-bordereless text-white radius-10 min_w550">
													{/* <thead className="text-white border-bottom text-center">
													<th className="pt-0">Classes Listing</th>
													</thead> */}
													<thead>
													<tr className="text-white">
														<th className="pt-0">Date & Time</th>
														<th className="pt-0">Status</th>
														<th className="pt-0">Signed Up/Attended</th>
													</tr>
													</thead>
													<tbody>
													{(this.state.recurringSessionData.map((row,i)=>
													<tr className="text-white" key={i}>
													<td className="py-0">{ this.getSessionDateUtil(row['scheduleDate'])} @ {this.getSessionTimeUtil(row['scheduleDate'])}</td>
													<td className="py-0">{row.SessionStatus}</td>
													<td className="py-0">{row.signedUP-1}/{row.attended}</td>
													</tr> 
													))}                                      
													</tbody>
													</table> 
													</div>                                               
												</div>
										</div>
									</div>
								</div>
							</div>
							
							{/* PARTICIPANT SIGNUP INFORMATION MODAL */}
							<div className="modal " id="sign_up_info">
								<div className="modal-dialog mw-770 mx-auto modal-dialog-centered">
									<div className="modal-content bg-transparent">
										<div className="modal-body px-2 px-md-3 pt-3 m-2 radius-8">
											<div className="d-flex justify-content-end">
												<button type="button" className="close whitecross" data-dismiss="modal" onClick={e=>$("#sign_up_info").attr({'style':'display:none'})} >&times;</button>
											</div>
												<div className="table_box2 px-2 pb-2">
													<div className="parti_id_box">                                                  
													<table className="table table-bordereless text-white radius-10" id="participant_table">
														<thead>
															<tr className="text-white">
																<th className="pt-0">Name</th>
																<th className="pt-0">Email</th>
																<th className="pt-0">Status</th>
																{ this.state.sessionType === 'Upcoming' && this.state.participantList[0] && this.state.participantList[0].scheduleDate && (new Date().getTime()  + (1000 * 60 * this.state.defaultJoinTime)) < new Date(this.state.participantList[0].scheduleDate.toString()).getTime() && <th className="pt-0">Remove</th> }
															</tr>
														</thead>
														<tbody>
														{this.state.participantList.map((pList,pL)=>
															<tr className="text-white" key={pL}>
																<td className="py-0">{pList.firstName} {pList.lastName}</td>
																<td className="py-0">{pList.email}</td>
																{ pList.sessionStatus===1 && this.state.sessionType==='Past'?<td className="py-0">Attended </td>:'' }
																{ pList.sessionStatus===2 && this.state.sessionType==='Past'?<td className="py-0">Cancelled</td>:'' }
																{ pList.sessionStatus===0 && this.state.sessionType==='Past'?<td className="py-0">Skipped</td>:'' }
																{ pList.sessionStatus===2 && this.state.sessionType==='Upcoming'?<td className="py-0">Cancelled</td>:'' }
																{ pList.sessionStatus===0 && this.state.sessionType==='Upcoming'?<td className="py-0">Signed up</td>:'' }
																{ pList.sessionStatus===1 && this.state.sessionType==='Upcoming'?<td className="py-0">Attending a class</td>:'' }
																{ pList.sessionStatus===4 && (this.state.sessionType==='Upcoming' || this.state.sessionType==='Past')?<td className="py-0">Cancelled</td>:'' }
																{ pList.sessionStatus===3 ? <td className="py-0">Ejected</td> : '' } 
																{ this.state.sessionType === 'Upcoming' && pList.sessionStatus !==3 && pList.scheduleDate && (new Date().getTime()  + (1000 * 60 * this.state.defaultJoinTime)) < new Date(pList.scheduleDate.toString()).getTime() && <td className="py-0"><div className="join_button text-uppercase eject_btn" onClick={() => {this.openEjectUser(pList)}}>Eject</div></td> }
																{ this.state.sessionType === 'Upcoming' && pList.sessionStatus === 3 && pList.scheduleDate && (new Date().getTime()  + (1000 * 60 * this.state.defaultJoinTime)) < new Date(pList.scheduleDate.toString()).getTime() && <td className="py-0">Removed</td> }
															</tr> 
														)}                                         
														</tbody>
													</table> 
													</div>                                               
												</div>
										</div>
									</div>
								</div>
							</div>				
							{/* add member popup  */}
							<div className="modal member_screen_mdl" id="members_addModal" role="dialog">
                				<div className="modal-dialog modal-dialog-centered">						
                    				<div className="modal-content bg-transparent translatee py-3">
										<div className="d-flex justify-content-end">
											<button type="button" className="close white closepurple translate-5"
											 onClick={e=>{
											this.setState({
												firstName:'',
												lastName:'',
												email:''});
											$("#members_addModal").attr({'style':'display:none'})}}>
											<span>x</span>
											</button>
										</div>
										{/* <div className="modal-header pb-2 border border-0 px-0 justify-content-center"> */}
										<div className="text-center"><img src="/images/host.png" alt="host" />
										<h4 className="white text-center mt-2 mb-3">Add a Member</h4>
										</div>
										{/* </div> */}
										<div className="modal-body py-3 px-4 modalbg radius-10">								
											<div className="row mt-4 px-2">										
												<div className="col-md-6 mt-3">
													<div className="form-group">
														<span className="cover-border bg_gray_clr"></span>
														<label className="label">Enter First Name</label>
														<input type="text" 
														className={(this.state.errorClass_MemberFirstName?"input-field red-outline":"input-field")} 
														id = "firstName"
														ref={nMFirstName=> (this._newMemberFirstName = nMFirstName)}
														value= {this.state.firstName}
														onChange = {(e)=>this.setState({[e.target.id] : e.target.value,errorClass_MemberFirstName:false})}
														placeholder="First Name" maxLength="30" />
														{this.memeberValidator.message('First name' , this.state.firstName, 'required')}
														<span className="signedup_2"></span>
													</div>
												</div>
												<div className="col-md-6 mt-3">
													<div className="form-group">
														<span className="cover-border bg_gray_clr"></span>
														<label className="label">Enter Last Name</label>
														<input type="text" 
														className={(this.state.errorClass_MemberLastName?"input-field red-outline":"input-field")} 
														id="lastName"
														ref={nMLastName=> (this._newMemberLastName = nMLastName)}
														value= {this.state.lastName} 
														onChange = {(e)=>this.setState({[e.target.id] : e.target.value,errorClass_MemberLastName:false})} placeholder="Last Name" maxLength="30" />
														{this.memeberValidator.message('Last name' , this.state.lastName, 'required')}
														<span className="signedup_2"></span>
													</div>
												</div>
											</div>
											<div className="row px-2">
												<div className="col-md-12 mt-3">
												<div className="form-group">
														<span className="cover-border bg_gray_clr"></span>
														<label className="label">Enter Email</label>
														<input type="text"
														className={(this.state.errorClass_MemberEmail?"input-field red-outline":"input-field")} 
														id="email"
														ref={nMEmail=> (this._newMemberEmail = nMEmail)}
														value= {this.state.email}
														onChange = {(e)=>this.setState({[e.target.id] : e.target.value,errorClass_MemberEmail:false})}  
														placeholder="Email" />
														{this.memeberValidator.message('Email Id' , this.state.email, 'required|email')}
														<span className="signedup_2"></span>
													</div>
												</div>
											</div>
										</div>
										<div className="text-center">
											<button type="button" className="done mt-3" onClick={this.addMembers}
											>Save</button>
										</div>
                    				</div>
                				</div>
            				</div>
			{/* verify pop_up */}
			
			<div className="modal member_screen_mdl" id="add_member_popup">
                <div className="modal-dialog dialogwidth modal-dialog-centered">
                    <div className="modal-content bg-transparent px-2">
                        <div className="modal-body py-3 px-3 modalbg radius-10">
                            <button type="button" className="close white closepurple translatX-Y" data-dismiss="modal" onClick={e=>{$("#add_member_popup").attr({'style':'display:none'})}}><span>x</span></button>
                            <div className="p-4">
                                <p className="text-center text-white font-20 my-4">Member has been added successfully!</p>                            </div>
                        </div>
                        <div className="modal-footer border border-0 justify-content-center">
							<button type="button" className="done mx-2 mt-3"
							 onClick={e=>{
								this.setState({
								firstName:'',
								lastName:'',
								email:''});
								 $("#add_member_popup").attr({'style':'display:none'});
								 }}>
							Ok</button>
                        </div>
                    </div>
                </div>
            </div>

			{/* open pop up light bulb click */}			
			<div className="modal member_screen_mdl" id="lightbulb_popup">
                <div className="modal-dialog dialogwidth modal-dialog-centered">
                    <div className="modal-content bg-transparent px-2">
                        <div className="modal-body py-3 px-3 modalbg radius-10">
                            <button type="button" className="close white closepurple translatX-Y" data-dismiss="modal" onClick={e=>{$("#lightbulb_popup").attr({'style':'display:none'})}}><span>x</span></button>
                            <div className="p-4">
                                <p className="text-center text-white font-20 mb-0">You can set a Class to be Private, which means only your Channel Members can attend those Classes. Add/Manage Members in your channel by clicking here.</p>
							</div>
                        </div>
                        <div className="modal-footer border border-0 justify-content-center">	
                        </div>
                    </div>
                </div>
            </div>

			<div className="modal member_screen_mdl" id="add_member_popup2">
                <div className="modal-dialog dialogwidth modal-dialog-centered">
                    <div className="modal-content bg-transparent px-2">
                        <div className="modal-body py-3 px-3 modalbg radius-10">
                            <button type="button" className="close white closepurple translatX-Y" data-dismiss="modal" onClick={e=>{$("#add_member_popup2").attr({'style':'display:none'})}}><span>x</span></button>
                            <div className="p-4">
                                <p className="text-center text-white font-20 my-4">Member already exists.</p>                            </div>
                        </div>
                        <div className="modal-footer border border-0 justify-content-center">
                            <button type="button" className="done mx-2 mt-3" onClick={e=>{$("#add_member_popup2").attr({'style':'display:none'})}}>Ok</button>
                        </div>
                    </div>
                </div>
            </div>

						{/* Coming Soon model  */}
						<div className="modal pr-0" id="coming_soon_alert" aria-modal="true">
							<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
								<div className="modal-content bg-transparent">
									<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
										<p className="mb-0 py-4 white text-center">Coming Soon</p>
									</div>
									<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal">ok</button></div>
								</div>
							</div>
						</div>
						
						{this.state.channelData[0] ?
						
						<div className="modal pr-0" id="generate_subdomain" aria-modal="true">
							<div className="modal-dialog mw-570 mx-auto modal-dialog-centered">
								<div className="modal-content bg-transparent">
									<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
										<button type="button" className="close white closepurple p-0 translatX-Y" data-dismiss="modal" onClick={this.removeAlert}><span>&times;</span></button>
										<div className="title_subd"><span className="relative">Generate Channel Page Subdomain <img src="/images/bulb.png" className="generate_bulb" onMouseDown={e=>{this.getFaq(19)}}/></span></div>
										<div className="subd_exam">Enter the subdomain you want for your channel.<br />At the bottom of the screen you will see the full web address as you type the subdomain name of your choice.</div>
										<div className = "form-group pb-3 mb-0 mt-4">
											<label className="mainLabel">Subdomain Name</label>
											<input type="text"  id="subdomain" value={this.state.subdomain.replace('https', '').replace('http', '').replace(/\s/g, '').replace(/[&\/\\#,+()$~%.'":*?!=<>{}]/gi, '').replace(/[^\w\s]/gi, '')} placeholder={this.state.channelData[0].name.toLowerCase().replace(/\s/g, '')} className = "form-control white_clr inputRight_space" onChange = {(e)=> this.setState({[e.target.id]:e.target.value, showExsistSubdomainStatus : false})} maxlength="100" />
											<span className="globeinpput"></span>
										</div>
										{ this.state.subdomain != '' &&
											<div className="subd_exam">Your URL is: {'https://'+this.state.subdomain.replace('https', '').replace('http', '').replace(/\s/g, '').replace(/[&\/\\#,+()$~%.'":*?!=<>{}]/gi, '').replace(/[^\w\s]/gi, '') + this.state.fullsubpath} </div>
										}
										
										
										{ this.state.emptySubdomain &&
											<div className="subd_exam red_alert">Please enter valid subdomain.</div>
										}
										
										{ this.state.existSubdomain && this.state.showExsistSubdomainStatus &&
											<div className="subd_exam red_alert">{this.state.subdomain.replace('https', '').replace('http', '').replace(/\s/g, '').replace(/[&\/\\#,+()$~%.'":*?!=<>{}]/gi, '').replace(/[^\w\s]/gi, '') + this.state.fullsubpath}  has already been reserved, please choose a different subdomain.</div>
										}
									</div>
									<div className="text-center"><button type="button" className="custom_btn1 mt-3" onClick={this.generatePage}>Generate Channel Page</button></div>
								</div>
							</div>
						</div> : '' }
						
						<div className="modal pr-0" id="success_subdomain" aria-modal="true">
							<div className="modal-dialog mw-570 mx-auto modal-dialog-centered">
								<div className="modal-content bg-transparent">
									<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
										<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
											<button type="button" className="close white closepurple translatX-Y abs_close_sub" data-dismiss="modal"><span>x</span></button>
											<p className="mb-0 py-4 white text-center dAddHeight">{this.state.fullsubdomain} has been created.</p>
										</div>
									</div>
									<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal">OK</button></div>
								</div>
							</div>
						</div>
						
						{this.state.channelData[0] ?
						<div className="modal pr-0" id="show_subdomain" aria-modal="true">
							<div className="modal-dialog mw-770 mx-auto modal-dialog-centered">
								<div className="modal-content bg-transparent">
									<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
										<button type="button" className="close white closepurple p-0 translatX-Y" data-dismiss="modal" onClick={this.closeCopyChannelPop} ><span>&times;</span></button>
										<div className="title_subd">View Channel Page URL</div> 
										<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
											<div className="d-flex flex-wrap">
												<div className="flex-grow-1 mx-2">
													<input type="text" value={'https://'+this.state.fullsubdomain} readOnly className="input-field" />
												</div>
												<div className="mx-2 copy_link_hold">
													<CopyToClipboard text={'https://'+this.state.fullsubdomain}><button className="copy_link btn btn-primary font-16 mt-2" onClick={this.copyClick2}>{this.state.buttonText2}</button></CopyToClipboard>
												</div>
											</div>
										</div>
									</div>
									<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal" onClick={this.closeCopyChannelPop}>OK</button></div>
								</div>
							</div>
						</div> : '' }
						
						{ this.state.channelData[0] &&
							<div className="modal pr-0" id="show_brand_link" aria-modal="true">
								<div className="modal-dialog mw-770 mx-auto modal-dialog-centered">
									<div className="modal-content bg-transparent">
										<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
											<button type="button" className="close white closepurple p-0 translatX-Y" data-dismiss="modal" onClick={this.closeCopyChannelPop}><span>&times;</span></button>
											<div className="title_subd">Brand Ambassador Page URL</div> 
											<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
												<div className="d-flex flex-wrap">
													<div className="flex-grow-1 mx-2">
														<input type="text" value={'https://'+this.state.fullsubdomainbrand} readOnly className="input-field" />
													</div>
													<div className="mx-2 copy_link_hold">
														<CopyToClipboard text={'https://'+this.state.fullsubdomainbrand}><button className="copy_link btn btn-primary font-16 mt-2" onClick={this.copyClick2}>{this.state.buttonText2}</button></CopyToClipboard>
													</div>
												</div>
											</div>
										</div>
										<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal" onClick={this.closeCopyChannelPop}>OK</button></div>
									</div>
								</div>
							</div>
						}
						
						
						{this.state.channelData[0] && this.state.fullsubdomaininvite ?
						
						<div className="modal pr-0" id="generate_subdomaininvite" aria-modal="true">
							<div className="modal-dialog mw-570 mx-auto modal-dialog-centered">
								<div className="modal-content bg-transparent">
									<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
										<button type="button" className="close white closepurple p-0 translatX-Y" data-dismiss="modal" onClick={this.removeAlert}><span>&times;</span></button>
										<div className="title_subd">Members Sign Up Link and Message</div>
										<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
											<p className="mb-0 py-4 white cursor dAddHeight2" onClick={this.openSubdomain2}>https://{this.state.fullsubdomaininvite}</p>
										</div>
										<div className = "form-group pb-3 mb-0 mt-4">
											<label className="mainLabel">Heading text (max 40 characters)</label>
											<input type="text" id="invsub_heading" value={this.state.invsub_heading} className="form-control white_clr" onChange={(e)=> this.setState({[e.target.id]:e.target.value})} maxLength="40" />
										</div>
										<div className = "form-group pb-3 mb-0 mt-4">
											<label className="mainLabel">Enter welcome message</label>
											<textarea id="invsub_text" value={this.state.invsub_text} className="form-control white_clr" onChange={(e)=> this.setState({[e.target.id]:e.target.value})}></textarea>
										</div>
										
										
									</div>
									<div className="text-center"><button type="button" className="custom_btn1 mt-3" onClick={this.updateMessageInvite}>Update</button></div>
								</div>
							</div>
						</div> : '' }
						
						
						
						
						

						{/* CancelSessionModel  */}
						<div className="modal pr-0" id="cancel_session" aria-modal="true">
						<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
							<div className="modal-content bg-transparent">
								<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
									<div className="py-4 text-light text-center font-20">{this.state.responseMessage}</div>
								</div>
								{this.state.cancelSessionDate?
								<div className="text-center">
									
								<button type="button" 
									className="custom_btn1 mx-2 mt-3" 
									data-dismiss="modal" 
									onClick={e=>{
									$("#cancel_session").attr({'style':'display:none'});
									}}>No</button>
									<button type="button" 
									className="custom_btn1 mt-3 mx-2" 
									data-dismiss="modal" 
									onClick={e=>{
									this.cancelSession();	
									$("#cancel_session").attr({'style':'display:none'});
									}}>Yes</button>
								
								</div>
								:
								<div className="text-center">
									
								<button type="button" 
									className="custom_btn1 mx-2 mt-3" 
									data-dismiss="modal" 
									onClick={e=>{
									$("#cancel_session").attr({'style':'display:none'});
									}}>ok</button>
									</div>
							}
							</div>
							</div>
						</div>
						
						<div className="modal pr-0" id="cancel_session_recc" aria-modal="true">
							<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
								<div className="modal-content bg-transparent">
									<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
										<button type="button" className="close white closepurple p-0 translatX-Y"  data-dismiss="modal" onClick={e=>$("#cancel_session_recc").attr({'style':'display:none'})}><span>×</span></button>
										<div className="py-4 text-light text-center font-20">Do you want to Cancel Only the Current Class or All Future Classes?</div>
									</div>
									<div className="text-center">
										<button type="button" className="custom_btn1 mx-2 mt-3" onClick={this.cancelSession}>Current Class</button>
										<button type="button" className="custom_btn1 mt-3 mx-2" onClick={this.cancelAllReccuring}>All Future Classes</button>
									</div>	
								</div>
							</div>
						</div>


						{/* Start Pop up for invite someone to be host model  */}
						<div className="modal pr-0" id="error_email_msg" aria-modal="true">
						<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
							<div className="modal-content bg-transparent">
								<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
									<div className="py-4 text-light text-center font-20"> {this.state.responseMessage} </div> 	
								</div>
								<div className="text-center">
									<button type="button" 
									className="custom_btn1 mt-3" 
									data-dismiss="modal" 
									onClick={e=>{
									$("#error_email_msg").attr({'style':'display:none'});
									// if(!this.validator.fieldValid('email') ){
									// 	this.setState({
									// 	errorClass_Email:true
									// 	})
									// 	this._email.focus();
									// }
									}}>ok</button>
								</div>
							</div>
							</div>
						</div>
                        
						{/* ADD PRODUCT SUCCESS POP UP */}
						<div className="modal pr-0" id="addProdSuccess_popup" aria-modal="true">
							<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
								<div className="modal-content bg-transparent">
									<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
									<div className="py-4 text-light text-center font-20"> {this.state.responseMessage} </div> 	
									
									<div className="text-center">
										<button type="button" 
										className="custom_btn1 mt-3" 
										data-dismiss="modal" 
										onClick={e=>{
											this.setState({
											inviteHostEmail:''
											},()=>{
											$("#addProdSuccess_popup").attr({'style':'display:none'});
											$("#dasboard_myModal2").attr({'style':'display:none'});
											$("#product_lst_modal").attr({'style':'display:block'});
										})
										}}>ok</button>
									</div>
									</div>
								</div>
							</div>
						</div>

						{/* invitaion pop up  */}
						<div className="modal pr-0" id="invitation_popup" aria-modal="true">
						<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
							<div className="modal-content bg-transparent">
								<div className="modal-body px-4 pb-4 m-2 radius-8 modl_bg_drk shadow-none">
									<div className="py-4 text-light text-center font-20"> {this.state.responseMessage} </div> 	
								</div>
								<div className="text-center">
									<button type="button" 
									className="custom_btn1 mt-3" 
									data-dismiss="modal" 
									onClick={e=>{
										this.setState({
											inviteHostEmail:''
										},()=>{
										$("#invitation_popup").attr({'style':'display:none'});
									$("#dasboard_myModal2").attr({'style':'display:none'});
										}
										)
									
									// if(!this.validator.fieldValid('email') ){
									// 	this.setState({
									// 	errorClass_Email:true
									// 	})
									// 	this._email.focus();
									// }
									}}>ok</button>
								</div>
							</div>
							</div>
						</div>

						{/* Select add  channel host End */}
						<div className="modal" id="current_interest">
						<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content translatee">
							{/* <div className="d-flex justify-content-end">
								<button type="button" className="close white closepurple translatX-Y" data-dismiss="modal"><span>x</span></button>
							</div> */}
							{/* <div className="text-center">
								<img src="/images/host.png" alt="" />
								<p className="white mt-2">Invite Someone to be a Instructor</p>
							</div> */}
							<div className="modal-body round p-3">
							
								<button type="button" className="close white closepurple translatX-Y" data-dismiss="modal"><span>x</span></button>
						
								<div className="card cardbg">
									<div className="px-3">
									<div className="row mx-0 px-sm-4">
										<div className="col-lg-4 col-md-6 pl-md-0">
											<p className="checktxt_name mb-3 ml-5 mt-4">Cardio</p>
											<label className="custom-control custom-checkbox lebelheight pl-0">
												<input type="checkbox" id="0" className="form-radio" />
												<img src="../images/session-image.jpg" className="ml-2 mr-3 interest_img" alt="user-icon" />
											</label>
										</div>
										<div className="col-lg-4 col-md-6 pl-md-0">
											<p className="checktxt_name mb-3 ml-5 mt-4">Strength </p>
											<label className="custom-control custom-checkbox lebelheight pl-0">
												<input type="checkbox" id="0" className="form-radio" />
												<img src="../images/session-image.jpg" className="ml-2 mr-3 interest_img" alt="user-icon" />
											</label>
										</div>
										<div className="col-lg-4 col-md-6 pl-md-0">
											<p className="checktxt_name mb-3 ml-5 mt-4">Yoga/Joga</p>
											<label className="custom-control custom-checkbox lebelheight pl-0">
												<input type="checkbox" id="0" className="form-radio" />
												<img src="../images/session-image.jpg" className="ml-2 mr-3 interest_img" alt="user-icon" />
											</label>
										</div>
									</div>
									</div>
								</div>
							</div>
							<div className="donebg"><button type="button"  className="done" id="checkHost">Add</button></div>
						</div>
						</div>
						</div>

						{/* Session Link model start */}
						<div className="modal" id="successResult">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content equipmodalbg">

							<div className="modal-header headerborder">
								{this.state.copyLink_state === 'session' ? 
									<h4 className="modal-title white">Class Link</h4>
									:
									<h4 className="modal-title white">On Demand Link</h4>
								}
								<button type="button" className="close white closepopup" onClick={this.closeCopyPop} data-dismiss="modal">&times;</button> 
								
							</div>
							<div className="modal-body">
								<div className="d-flex flex-wrap">
									<div className="flex-grow-1 mx-2">
										<input type="text" value = {this.state.urlLink} onChange = {(e)=>console.log(e.target.value)} className="input-field" />
									</div>
									<div className="mx-2">
										<CopyToClipboard text={this.state.urlLink}>								
										<button className="copy_link btn btn-primary font-16 mt-2" onClick={this.copyClick}>
										{this.state.buttonText}
										</button>
										</CopyToClipboard>
									</div>	
								</div>						
							</div>							
							</div>
						</div>
						</div> 
						{/* Session Link model ends */}
						{/* defalut session model */}
						<div className="modal pr-0" id="go_Dashboard" aria-modal="true">
						<div className="modal-dialog dialogwidth mx-auto modal-dialog-centered">
						<div className="modal-content bg-transparent">
							<div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
										<p className="py-4 text-light text-center font-20">It looks like you started your tutorial and we cannot tell if you successfully completed it.  If it was successfully completed, press the “Completed” button below.  If you still need to complete your tutorial, press “Tutorial Not Completed” button below.</p> 	
							
							</div>
							<div className="text-center">
							<button type="button" className="custom_btn1 mt-3 mx-3"  data-dismiss="modal" onClick={this.tutorialRead}>Completed</button>
							<button type="button" className="custom_btn1 mt-3 mx-3"  data-dismiss="modal" onClick={e=>{console.log('not completed');$("#go_Dashboard").attr({'style':'display:none'});browserHistory.push('/DashboardLanding')}}>Tutorial Not Completed</button>
							</div>
						</div>
						</div>
					</div>

							{/* Select add participient Start */}
							<div className="modal" id="dash_add_participent">
								<div className="modal-dialog modal-dialog-centered">
								<div className="modal-content py-4">
									<div className="text-center">
										<img src="/images/host.png" alt="" className="wd-70" />
										<p className="white font-20">Invite Someone to be a Member</p>
									</div>
									<div className="modal-body p-4 radius-8">
										<div className="card cardbg">
										<div style={{color:"red"}}>This feature is't available</div>
										<div className="form-group mb-0 mt-4">
											<div className="row">												
												<div className="col-md-6 pr-md-2">
													<div className="custom-control custom-radio mb-20">
														<input type="radio" className="custom-control-input" id="customRadio3" name="example3" checked={this.state.customRadio3} onChange={this.checkHost3}  />
														<label className="custom-control-label" htmlFor="customRadio3">New Member</label>
													</div>  
												</div>
												<div className="col-md-6 pr-md-2">
													<div className="custom-control custom-radio">
														<input type="radio" className="custom-control-input" id="customRadio4" name="example3" checked={this.state.customRadio4} onChange={this.checkHost4} />
														<label className="custom-control-label" htmlFor="customRadio4"> Existing Member</label>
													</div>    
												</div>
												<div className="clearfix"></div>
												
													{this.state.customRadio3 ?
													<div className="col-md-6 pr-md-2" id="dash_land_block">
														<span className="cover-border "></span>
														<label className="label">Enter First Name</label>
														<div className="">
															<input type="text" className="input-field" value={this.state.sessionData[this.state.participientPopup].oName} onChange={e=>console.log()} placeholder="First name" />
															<span className="signedup_2"></span>
														</div>
													</div>
													:''}	
													{this.state.customRadio3 ?
													<div className="col-md-6 pr-md-2" id="dash_land_block">
														<span className="cover-border "></span>
														<label className="label">Enter Last Name</label>
														<div className="">
															<input type="text" className="input-field" placeholder="Last name" />
															<span className="signedup_2"></span>
														</div>
													</div>
													:''}
												
												{this.state.customRadio3 ?
												<div className="col-md-12 pr-md-2">
													<span className="cover-border "></span>
													<label className="label">Email Address</label>
													<div className="">
														<input type="email" className="input-field" value={this.state.email} placeholder="Email Address" />
														<span className="dashboard_land"></span>
													</div>
												</div>
												:''}
												{/* XYZ */}
												{/* new user*/}
												{this.state.customRadio4 ?
													<div className="col-md-6 pr-md-2 mt-3">
														<span className="cover-border "></span>
														<label className="label">Enter First Name</label>
														<div className="">
															<input type="text" className="input-field"  placeholder="First name" disabled/>
															<span className="signedup_2"></span>
														</div>
													</div>
													:''}
													{this.state.customRadio4 ?
													<div className="col-md-6 pr-md-2 mt-3">
														<span className="cover-border "></span>
														<label className="label">Enter Last Name</label>
														<div className="">
															<input type="text" className="input-field" placeholder="Last name" disabled/>
															<span className="signedup_2"></span>
														</div>
													</div>
													:''}
												{this.state.customRadio4 ?
												<div className="col-md-12 pr-md-2 mt-3">
													<span className="cover-border "></span>
													<label className="label">Email Address</label>
													<div className="">
														{/* <input type="email" className="input-field" placeholder="Last name" /> */}
														<select className="input-field" id="mail" value={this.state.mail} onChange={this.mail}>                     
															{this.state.hostMail.map((row,i)=>
															<option key={i} value={row.mail}>{row.mail}</option>
															)}  
														</select>
														{/* <span className="dashboard_land"></span> */}
														{/* <select className="input-field">                     
															{this.forEmailer()}
														</select> */}
														{/* <select className="input-field" id="emailer" value = {this.state.emailer} onChange = {this.sessionInfo} > */}
														
															{/* </select> */}
															{/* <option>Select Email Address</option>											
															<option>2</option>
															<option>3</option>
															<option>4</option>
															<option>5</option> */}
														
														{/* {this.validator.message('emailer', this.state.emailer,)} */}
													</div>
												</div>
												:''}
												</div>
											</div>
										</div>
									</div>
									<div className="donebg"><button type="button" data-toggle="modal" data-dismiss="modal"  className="done" id="checkParticipient" >Invite</button></div>
								</div>
								
								</div>
								
							</div>
							{/* Select add participient End */}
							{/* Add Product Popup start  */}

							<div className="modal pr-0 py-3" id="product_lst_modal">
								<AddEditProduct 
									state={this.state}
									addAttribute={this.addAttribute}
									saveProductList={this.saveProductList}
									handleProductList={this.handleProductList}
									updateStateFromChild={this.updateStateFromChild}
									fetchAttributesList={this.fetchAttributesList}
									getWineProductList={this.getWineProductList}
								> </AddEditProduct>
							
							</div>
							{/* Add Product Popup End */}
									
							<div className="modal pr-0 pt-4 pt-md-5" id="email_verification" aria-modal="true" >
								<div className="modal-dialog dialogwidth mx-auto">
									<div className="modal-content bg-transparent">
										<div className="modal-body px-4 pb-4 modl_bg_drk shadow-none radius-8">
											<p className="py-4 text-light text-center font-20">Congratulations!, Virdio has updated your profile. After pressing "OK" button, You will be asked to login again to refresh your dashboard view.</p>							
										</div>
										<div className="text-center">
											<button type="button" className="custom_btn1 mt-3"  data-dismiss="modal" onClick={e=>{$("#email_verification").attr({'style':'display:none'}); localStorage.removeItem('userData'); browserHistory.push('/login');}}>ok</button>
										</div>
									</div>
								</div>
							</div>


							{/* Pick session by nitin  */}
							<div className={"modal " + (this.state.isChannelMarketPlace ? "wi1000" : "")} id="pick_channel" onClick={this.closeNewInterest } >
							<div className="modal-dialog modal-dialog-centered">
								<div className="modal-content px-3 bg-transparent">
									
									<div className="modal-body pb-md-4  modl_bg_drk radius-8">
									<button type="button" className="close white closepurple p-0 translatX-Y"  data-dismiss="modal" onClick={e=>$("#pick_channel").attr({'style':'display:none'})}><span>×</span></button>
										<div className="pt-md-5 pb-md-4">
											<h4 className="white text-center mb-md-4">Pick Class Type</h4>
											<div className="d-flex justify-content-center align-items-center flex-wrap">
												<div className="f_box mb-0 mb-md-3">
													{this.state.channelData.length>1?
													<div className="form-group">													
													<span className="cover-border bg_gray_clr"></span>
													<label className="label">Channel Type</label>														
													<select
														className="input-field chnel_type"
														id="exampleFormControlSelect1"
														value = {this.state.exampleFormControlSelect1}
														onChange = {this.sessionType}
													>
														<option value=''>Channel Type</option>											
														{this.state.channelData.map((row,i)=>											
                                						<option value={row.id} name={row.name} key = {i}>{row.name}</option>
														)}
														{/* <option value="channel1">wine</option>
														<option value="channel2">wine2</option> */}

														{/* <option>5</option> */}
													</select>
													 </div>:
													<div className="form-group">													
													<span className="cover-border bg_gray_clr"></span>
													<select
														className="input-field chnel_type"
														id="exampleFormControlSelect1"
														value = {this.state.exampleFormControlSelect1}
														onChange = {this.sessionType}
													>
																									
														{this.state.channelData.map((row,i)=>											
                                						<option value={row.id} name={row.name} key = {i}>{row.name}</option>
														)}
														{/* <option value="channel1">wine</option>
														<option value="channel2">wine2</option> */}

														{/* <option>5</option> */}
													</select>
                         							</div>
												
														}
												</div>
												{this.state.channelData.length>1?
													<div className="f_box mb-0 mb-md-3 new_margin_int_holder">
														<div className="form-group new_rel_pos">													
														<span className="cover-border bg_gray_clr"></span>
														<div className="input-field chnel_type select_arrow_div text_wrap_demand set_new_padd" onClick={ e =>{this.openNewIntrest(e)}}>{this.state.newInstrestSelected.length === 0 ?  'Channel Interest' : this.state.selectedinterestsNames}</div>
														{this.state.expandNewIntrest &&
															<div className="intrest_select">
																{ this.state.channelInterestSelectionNew.sort(function(a, b){ if(a.title < b.title) { return -1; } if(a.title > b.title) { return 1; } return 0; }).map((row,i)=>											
											
																	<div className="newIntrestName" key = {i}  onClick={ e=>{this.newSelectIntrest('upcoming', row.id, row.title, e)} } >{row.title}
																		<div className="checkBox new_checkBox_size"  className={"checkBox new_checkBox_size " + (this.state.newInstrestSelected.indexOf(row.id) > -1 ? 'active' : '' )} ></div>
																	</div>
														
																)}
															</div>
														}
														</div>
													</div>
													:
													<div className="f_box mb-0 mb-md-3">
														<div className="form-group new_rel_pos">													
														<span className="cover-border bg_gray_clr"></span>
														<div className="input-field chnel_type select_arrow_div text_wrap_demand set_new_padd" onClick={ e =>{this.openNewIntrest(e)}}>{this.state.newInstrestSelected.length === 0 ?  'Channel Interest' : this.state.selectedinterestsNames}</div>
														{this.state.expandNewIntrest &&
															<div className="intrest_select">
																{ this.state.channelInterestSelection.sort(function(a, b){ if(a.title < b.title) { return -1; } if(a.title > b.title) { return 1; } return 0; }).map((row,i)=>											
											
																	<div className="newIntrestName" key = {i}  onClick={ e=>{this.newSelectIntrest('upcoming', row.id, row.title, e)} } >{row.title}
																		<div className="checkBox new_checkBox_size"  className={"checkBox new_checkBox_size " + (this.state.newInstrestSelected.indexOf(row.id) > -1 ? 'active' : '' )} ></div>
																	</div>
														
																)}
															</div>
														}
														</div>
													</div>
												}
												{this.state.isChannelMarketPlace ?
													<div className="f_box mb-0 mb-md-3">
														<div className="form-group new_rel_pos">
															<span className="cover-border bg_gray_clr"></span>
															<div className="input-field chnel_type select_arrow_div text_wrap_demand set_new_padd" onClick={ e =>{this.openLiveIntrestSubcategories(e)}}>{this.state.liveInterestSubcategories.length === 0 ?  'Sub-Categories' : this.state.liveSelectedInterestsSubcategoriesNames}</div>
															{this.state.expandLiveIntrestSubcategories &&
																<div className="intrest_select">
																	{ this.state.channelLiveSubcategoriesSelection.sort(function(a, b){ if(a.title < b.title) { return -1; } if(a.title > b.title) { return 1; } return 0; }).map((row,i)=>											
																		<div className="newIntrestName" key = {i}  onClick={ e=>{this.liveSelectIntrestSubcategory(row.id, row.title, e)} } >{row.title}
																			<div className="checkBox new_checkBox_size"  className={"checkBox new_checkBox_size " + (this.state.liveInterestSubcategories.indexOf(row.id) > -1 ? 'active' : '' )} ></div>
																		</div>
															
																	)}
																</div>
															}
														</div>
													</div>
													:
													''
												}
											</div>
											{/* <div className="donebg"><button type="button" className="done"  onClick={e=>{$("#pick_channel").attr({'style':'display:none'});browserHistory.push(this.state.sessionTypeRedirectTo)}}>Next</button></div> */}
										</div>
									</div>
									
									<div className="modal-footer border border-0">
									<div className="donebg"><button type="button" data-dismiss="modal" className="done mt-3"  onClick={e=>{ this.checkSelectedType(); }}>Next</button></div>
									</div>
								</div>
							</div>
						</div>
							{/* Pick session by nitin end */}

						</div>
					    {/* <div id="ps" className="container tab-pane fade"><br />
					      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries. </p>
					    </div>
					    <div id="onde" className="container tab-pane fade"><br />
					      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries. </p>
					    </div> */}
					</div>

				</div>

			</div>
		</div>
	</div>
	
</div>
		<div className="main_loader">
			<div className="main_loader_inner"></div>
		</div>
		
		<div className="modal pr-0" id="upload_error" aria-modal="true">
			<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
						<p className="mb-0 py-4 white text-center">{this.state.uploadError}</p>
					</div>
					<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal" onClick={e=>$("#upload_error").attr({'style':'display:none'})}>ok</button></div>
				</div>
			</div>
		</div>
		
		{/* Members List Component start */}
		<div className="modal pr-0 py-3 new_modal_color" id="members_list">
			<MembersList 
				state={this.state}
				addAttribute={this.addAttribute}
				saveProductList={this.saveProductList}
				handleProductList={this.handleProductList}
				updateStateFromChild={this.updateStateFromChild}
				fetchAttributesList={this.fetchAttributesList}
				getWineProductList={this.getWineProductList}
				closeAddMember={this.closeAddMember}
				refreshThisWeek={this.refreshThisWeek}
				checkDefaultSession={this.checkDefaultSession}
			> </MembersList>
		</div>
		
		{/*  Members List Component end */}

		
		<div className="modal pr-0" id="delete_demand" aria-modal="true">
			<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent relative">
					<button type="button" className="close white closepurple translatX-Y abs_close" onClick={e=>$("#delete_demand").attr({'style':'display:none'})}><span>x</span></button>
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
						<p className="mb-0 py-4 white text-center">Are you sure you want to delete Video?</p>
					</div>
					<div className="text-center"><button type="button" className="custom_btn1 mt-3" onClick={this.deleteDemandSession}>Delete</button></div>
				</div>
			</div>
		</div>
		
		<div className="modal pr-0" id="autologinnative" aria-modal="true">
			<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="join_pop_container">
						<button type="button" className="close white closepurple join_close_iks" data-dismiss="modal" onClick={this.hideNativePop}><span>x</span></button>
						{ this.state.showWindowsApp && this.state.urlElectron !== '' &&
							<div className="join_parent">
								<div className="join_title">Virdio recommends the Virdio App for the best experience</div>
								<div className="button_join_container">
									{ mobileTab && ios ?
										<a href={this.state.urlElectron} target="_blank"><div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron); this.updateUserDemand(); }}>VIRDIO APP</div></a>
										:
										<div className="join_pop_btn" onClick={() => { this.openNativeUrl(this.state.urlElectron); this.updateUserDemand(); }}>VIRDIO APP</div>
									}
								</div>
								<div className="browser_run">
									{ this.state.urlPath !== '' && <a href={this.state.urlPath} target="_blank"><span className="run_web" onClick={this.hideNativePop}>Run in the browser instead</span></a> }
								</div>
							</div>
						}
						{ !this.state.showWindowsApp || this.state.urlElectron === '' &&
							<div className="join_parent">
								<div className="join_title">Virdio recommends Web browser for the best experience</div>
								<div className="button_join_container">
									 <a href={this.state.urlPath} target="_blank"><div className="join_pop_btn" onClick={this.hideNativePop}>Run in the browser</div></a>
								</div>
								<div className="browser_run"></div>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
		
		<div className="download_back_container" id="nativedownload">     
			<div className="download_content">
				<div className="down_close" onClick={e=> { $("#nativedownload").hide(); }}></div>
				<div className="for_down_logo">
					<div className="download_logo"></div>
				</div>
				<div className="download_text">Download the Virdio App to install on your device.</div>                
				<div className="download_text">Once downloaded, double click on the installer then drag and drop the Virdio icon into the Applications folder.</div>                
				<div className="for_down_btn">
					<a href={this.state.appDownload} target="_blank"><div className="down_btn" onClick={e=> { $("#nativedownload").hide(); window.$("#nativedownload2").modal('show') }}>Download</div></a>
				</div>
			</div>
		</div> 
			
		<div className="modal pr-0" id="nativedownload2" aria-modal="true">
			<div className="modal-dialog mw-900 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
						<button type="button" className="close white closepurple translatX-Y" data-dismiss="modal"><span>x</span></button>
						{ ( chrome || edge ) && <p className="mb-0 py-4 white text-center">1. Once the download has been completed the file should be accessible on the bottom left of the browser, click on the file to Install Virdio.</p> }
						{ !chrome && !edge && <p className="mb-0 py-4 white text-center">1. Once the download has been completed the file should be accessible at the top right of the browser, click on the file to Install Virdio.</p> }
						<p className="mb-0 py-4 white text-center">2. After the Installation is complete, click on the JOIN button below.</p>
					</div>
					<div className="text-center"><button type="button" className="custom_btn1 col-4 mt-3" onClick={() => { this.openNativeUrl(this.state.urlElectron); window.$("#nativedownload2").modal('hide'); window.$("#autologinnative").modal('show'); }}>JOIN</button></div>
				</div>
			</div>
		</div>
			
			
		<div className="modal pr-0" id="notloggedin" aria-modal="true">
			<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
						<p className="mb-0 py-4 white text-center">You have been logged off from Virdio as your class has expired. Please log back in to continue.</p>
					</div>
					<div className="text-center"><Link to="/login"><button type="button" className="custom_btn1 mt-3">Login</button></Link></div>
				</div>
			</div>
		</div>
		
		<div className="modal pr-0" id="session_full" aria-modal="true">
			<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
						<p className="mb-0 py-4 white text-center">This class is already full.</p>
					</div>
					<div className="text-center" onClick={this.closeFullPop} ><button type="button" className="custom_btn1 mt-3">Close</button></div>
				</div>
			</div>
		</div>

				{ this.state.chosenTutorialIndex == 0 && this.state.showTutorial &&
					<div className="all_tutorials tutorial_first">
						<MainTutorial
							chosenTutorial="hostDashboardTutorial"
							state={this.state}
							changeTutorialIndex={this.changeTutorialIndex}
							skipTutorial={this.skipTutorial}
						></MainTutorial>
					</div>
				}

		
		<div className="modal pr-0" id="plannotfound" aria-modal="true" onClick={this.checkClosingPlanPop}>
			<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none" onClick={(e) => this.preventDef(e)}>
						<p className="mb-0 py-4 white text-center">Hi {this.state.loginName}, it's time to select your Live Streaming and On-demand subscription plans.</p>
					</div>
					<div className="text-center">
						<Link onClick={(e) => this.goToPlans(e)}><button type="button" className="col-4 custom_btn1 mt-3">Select Plan</button></Link>
						<Link onClick={(e) => this.logOutPlan(e)}><button type="button" className="custom_btn1 col-4 mt-3" style={{marginLeft: '50px'}}>LOGOUT</button></Link>
					</div>
				</div>
			</div>
		</div>
		
		<div className="modal pr-0" id="planexpired" aria-modal="true" onClick={this.checkClosingPlanPop}>
			<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none" onClick={(e) => this.preventDef(e)}>
						<p className="mb-0 py-4 white text-center">Hi {this.state.loginName}, your plan is expired, please click bellow and update your plan.</p>
					</div>
					<div className="text-center"> 
						<Link onClick={(e) => this.goToPlans(e)}><button type="button" className="col-4 custom_btn1 mt-3">Select Plan</button></Link>
						<Link onClick={(e) => this.logOutPlan(e)}><button type="button" className="custom_btn1 col-4 mt-3" style={{marginLeft: '50px'}}>LOGOUT</button></Link>
					</div>
				</div>
			</div>
		</div>
		
		<div className="modal pr-0" id="eject_participant" aria-modal="true">
			<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative_eject">
						<div className="abs_participant_img" style={{backgroundImage: "url('" + this.state.participantToRemove.image + "')"}}></div>
						<p className="mb-0 py-4 white text-center breakWord">Are you sure you want to remove {this.state.participantToRemove.firstName} {this.state.participantToRemove.lastName} from class?</p>
						<div className="for_area_eject">
							<textarea className={"input-field " + (this.state.errorReason ? 'error_reason' : '')} maxLength="300" value={this.state.reasonEject} id="reasonEject" placeholder="Reason to eject..." onChange={(e)=>this.setState({[e.target.id]:e.target.value, errorReason: false})}></textarea>
						</div>
					</div>
					<div className="text-center"> 
						<button type="button" className="col-4 custom_btn1 mt-3" onClick={this.cancelEject}>Cancel</button>
						<button type="button" className="custom_btn1 col-4 mt-3" style={{marginLeft: '50px'}} onClick={this.ejectParticipant}>Eject</button>
					</div>
				</div>
			</div>
		</div>
		
		<div className="modal pr-0" id="editing_alert_session" aria-modal="true">
			<div className="modal-dialog mx-auto modal-dialog-centered mw900">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
						<p className="mb-0 py-4 white text-center">{this.state.sessionHostEditName} is currently editing this Class, would you like to check with {this.state.sessionHostEditName} if they will allow you to edit the Class?</p>
					</div>
					<div className="text-center"> 
						<button type="button" className="col-4 custom_btn1 mt-3" onClick={() => this.updateEdit('reject')}>Don’t Ask</button>
						<button type="button" className="custom_btn1 col-4 mt-3" style={{marginLeft: '50px'}} onClick={() => this.updateEdit('accept')}>Ask {this.state.sessionHostEditName} for Access</button>
					</div>
				</div>
			</div>
		</div>
		
		<div className="modal pr-0" id="connection_lost" aria-modal="true">
			<div className="modal-dialog mx-auto modal-dialog-centered mw900">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
						<p className="mb-0 py-4 white text-center">Your connection is lost. You will be redirected to Homepage now.</p>
					</div>
				</div>
			</div>
		</div>

		<div className="modal pr-0" id="activityPop" aria-modal="true">
			<ActivityList
			state={this.state}
			getSessionTime={this.getSessionTime}
			getSessionDate={this.getSessionDate}
			></ActivityList>
		</div>

		<div className="modal pr-0" id="editing_alert_contdown" aria-modal="true">
			<div className="modal-dialog mw-700 mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
						<p className="mb-0 py-4 white text-center">Requesting access from {this.state.sessionHostEditName}.</p>
						<p className="mb-0 py-4 white text-center">If they do not respond in 30 seconds, you will get access to edit the Class.</p>
						<p className="mb-0 py-4 white text-center">{this.state.pendingHostSeconds}</p>
					</div>
				</div>
			</div>
		</div>
		<div className="modal pr-0" id="schedule_onboarding_session" aria-modal="true">
			<div className="modal-dialog mx-auto modal-dialog-centered">
				<div className="modal-content bg-transparent inline_pop">
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none relative pop700">
						<p className="mb-0 py-4 white text-center font-20 no_pad_bott breakW">{this.state.loginName}, welcome to Virdio.</p>
						<p className="mb-0 py-4 white text-center font-20">For the best experience, let’s get you scheduled for a quick onboarding class.</p>
					</div>
					<div className="text-center flex-wrap d-flex justify-content-center">
						<div className="">
						{ !this.state.channelWaitingListOn &&
							<button className="custom_btn1 mt-3 mx-2" onClick={this.skipCalendly}>SKIP</button>
						}
							<button className="custom_btn1 mt-3 mx-2" onClick={this.goToCalendly}>OK</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="modal pr-0" id="calendly_event" aria-modal="true">
			<button type="button" className="close white closepurple translatX-Y abs_close ch_close_abs" onClick={this.closeCalendly}><span>x</span></button>
			<div className="calendly-inline-widget" style={{minWidth:'320px', height:'630px'}} data-auto-load="false"></div>
		</div>
		<div className="modal pr-0" id="reopen_session_alert" aria-modal="true">
			
			<div className="modal-dialog mx-auto modal-dialog-centered mw900">
				<div className="modal-content bg-transparent">
					<button type="button" className="close white closepurple translatX-Y abs_close" onClick={this.closeReopenSessionAlert}><span>x</span></button>
					<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
						<p className="mb-0 py-4 white text-center">Do you want to sign up all Members that were previously signed up for this Class or would you like to invite them to sign up?</p>
					</div>
					<div className="text-center"> 
						<button type="button" className="col-4 custom_btn1 mt-3 btn1_cust btns_3_pos" onClick={() => this.sessionReopen(1)}>Sign up</button>
						<button type="button" className="custom_btn1 col-4 mt-3 btn1_cust cst_btn btns_3_pos" style={{marginLeft: '50px'}} onClick={() => this.sessionReopen(2)}>Invite</button>
						<button type="button" className="custom_btn1 col-4 mt-3 btn1_cust cst_btn btns_3_pos" style={{marginLeft: '50px'}} onClick={() => this.sessionReopen(3)}>Do nothing</button>
					</div>
				</div>
			</div>
		</div>
		
		{ this.state.userSignedUp &&
			<div className="full_popup">
				<ParticipantSignedUp 
					state={this.state}
					updateStateFromChild={this.updateStateFromChild}
					openNativeUrl={this.openNativeUrl}
					sessionStartTimeHigher15Min={this.state.sessionStartTimeHigher15Min}
					urlElectron={this.state.urlElectron}
					urlPath={this.state.urlPath}
					scheduleDate={this.state.sessionScheduleDate}
					timeZone={this.state.sessionTimezone}
					sessionChannelName={this.state.sessionChannelName}
					isHost={this.state.isHost}
					sessionName={this.state.sessionName}
					isOnDemandSession={this.state.isOnDemandSession}
					onDemandId={this.state.onDemandToOpen}
					>
				</ParticipantSignedUp>
			</div>
		}
		
		{ this.state.isOpenReviewClasses &&
			<div className="review_holder">
				<ReviewClassesContainer updateStateFromChild={this.updateStateFromChild}></ReviewClassesContainer>
			</div>
		}
		
		<SupportContainer supportPageName={this.state.supportPageName} supportPageApi={this.state.supportPageApi} userTypeFaqs={this.state.userTypeFaqs}></SupportContainer>
        </div>
    )
 }
}

export default connect(
  null,
  { 
	setUserChannel,
	showMainErrorPop
  }
)(DashboardLanding);