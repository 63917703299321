import React, { Component } from "react";
import classnames from "classnames";
import { Link } from "react-router";

class RegisterSelect extends Component {
  
  constructor(props) {
	super(props);
	
	this.state={
		appUrl: process.env.REACT_APP_URL+'login',
		
    }
	
}
 
componentDidMount(){
	
}

  render() {
	 return (
		<div className="memberRegisterHolder more_width_welcome">
			<div className="welcomeTextHolder">
				<div className="verifyH1">{this.props.state.challengeJoin ? 'Join Challenge' : 'Start your free trial'}</div>
				<div className="verifyH2">No credit card required. Cancel Anytime. </div>
			</div>
			{ /*
			<div className="fbButtonPart" className={classnames({'fbButtonPart': true, 'opacityFbButton': this.props.state.showRegisterStep})} onClick={(e)=>this.props.loginFacebook()}>
				<div className="fbLogoImg"></div>
				<div className="fbText">Continue with Facebook</div>
			</div>
			*/ }
			{/*
			<div className="fbButtonPart" className={classnames({'fbButtonPart': true, 'opacityFbButton': this.props.state.showRegisterStep})}>
				<div className="fbLogoImg googleNewLogo"></div>
				<div className="fbText">Continue with Google</div>
			</div>
			*/}
			<div className="fbButtonPart" onClick={(e)=>this.props.chooseRegisterStep()}>
				<div className="fbLogoImg emailIcon"></div>
				<div className="fbText">Continue with Email</div>
			</div>
			{this.props.state.showRegisterStep &&	
				<div className="newRegisterHolder">
					<div className="info_row_holder">				
						<div className="one_form_container">
							<div className="form_title">Full Name</div>
							<div className="relative_holder">
								<input type="text" tabIndex="1" className={classnames({'gray_inputs': true, 'error_input': this.props.state.nameError})} 
								id="nameValue" 
								value={this.props.state.nameValue} 
								placeholder='Enter your full name'
								onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value, nameError: false})} 
								onFocus={() => this.props.updateStateFromChild({focusedElement: 'nameValue'})} 
								onBlur={() => this.props.updateStateFromChild({focusedElement: ''})} />
								{ this.props.state.nameValue !== '' &&
									<div className="empty_input" onClick={()=>this.props.updateStateFromChild({nameValue: ""})}></div>
								}
								{ this.props.state.nameError &&
									<div className="passwordErrorMessageTxt">Name field can't be empty</div>
								}
							</div>
						</div>
					</div>
					<div className="info_row_holder">
						<div className="one_form_container">
							<div className="form_title">Email address</div>
							<div className="relative_holder">
								<input type="text" tabIndex="2" className={classnames({'gray_inputs': true, 'error_input': this.props.state.errorEmail})} 
								id="emailInput" 
								value={this.props.state.emailInput}
								placeholder='Enter Email Address'
								onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value, errorEmail: false})} 
								onFocus={() => this.props.updateStateFromChild({focusedElement: 'emailInput'})} 
								onBlur={() => this.props.updateStateFromChild({focusedElement: ''})} />
								{ this.props.state.emailInput !== '' &&
									<div className="empty_input" onClick={()=>this.props.updateStateFromChild({emailInput: ""})}></div>
								}
								{ this.props.state.errorEmail &&
									<div className="passwordErrorMessageTxt">Insert correct email address</div>
								}
							</div>
						</div>
					</div>
					<div className="info_row_holder">
						<div className="one_form_container">
							<div className="form_title">Password</div>
							<div className="relative_holder">
								<input 
								type={this.props.state.showPassword ? "text" : "password"} 
								placeholder='Set your password'
								tabIndex="3" 
								className={classnames({'gray_inputs': true, 'error_input': this.props.state.errorPassword})} 
								id="passwordInput" 
								value={this.props.state.passwordInput} 
								onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value, errorPassword: false})} 
								onFocus={() => this.props.updateStateFromChild({focusedElement: 'password'})} 
								onBlur={() => this.props.updateStateFromChild({focusedElement: ''})} />
								<div className="eye_show" id="showPassword" onClick={(e)=>this.props.updateStateFromChild({showPassword: !this.props.state.showPassword})}>
									{ this.props.state.showPassword && <div className="crossed_eye"></div> }
								</div>
								{ this.props.state.focusedElement == 'password' && 
									<div className="password_check_form">
										<div className="pass_check_title">Your password needs to:</div>
										<div className="pass_main_line">
											<div className={ "pass_done_icon " + ( !this.props.state.contain8Ch ? "pass_iks_icon" : "" ) }></div>
											<div className="pass_line_text">Minimum of 8 characters</div>
										</div> 
										<div className="pass_main_line">
											<div className={ "pass_done_icon " + ( !this.props.state.letterContain ? "pass_iks_icon" : "" ) }></div>
											<div className="pass_line_text">Minimum of 1 Letter</div>
										</div>
										<div className="pass_main_line">
											<div className={ "pass_done_icon " + ( !this.props.state.numberContain ? "pass_iks_icon" : "" ) }></div>
											<div className="pass_line_text">Minimum of 1 Number</div>
										</div>
										<div className="pass_main_line">
											<div className={ "pass_done_icon " + ( !this.props.state.specialSymbol ? "pass_iks_icon" : "" ) }></div>
											<div className="pass_line_text">Minimum of 1 Special Character</div>
											<div className="pass_line_text spec_char">(e.g. !,@,#,$,%,&,*)</div>
										</div>
									</div>
								}
							</div>
							{ this.props.state.errorPassword &&
								<div className="passwordErrorMessageTxt">Password does not meet required criteria</div>
							}
						</div>
					</div>
					<div className="verifyNewButton" onClick={(e)=>this.props.registerNewMember()}>Get Started</div>
				</div>
			}
			<div className="videoNewHolder">
				<a href={this.props.state.videoLink} target="_blank" >
					<div className="registerVideoHolder" style={{ backgroundImage: 'url('+this.props.state.videoImage+')'}}>
					</div>
				</a>
				<div className="videoNameHolder">
					<div className="seeText1">See how it works</div>
					<a href={this.props.state.videoLink} target="_blank" >
						<div className="watchVideoText">Watch Video</div>
					</a>
				</div>
			</div>
			<div className="welcomeTextHolder">
				<Link to={'/login'+(this.props.state.challengeJoin ? '?joinChallenge=1' : '') + (this.props.state.iFrameLink ? '?iFrameOpen' : '') }>
					<div className="registerText">Already have an account? <span className="signInSpan"> Sign in</span>
					</div>
				</Link>
			</div>
			<div className="welcomeTextHolder">
				<div className="privacyTextNewRegister">By signing up you agree to our 
					<Link to="/TermsofService" target="_blank"> <span className="signInSpan"> Terms of Service </span>  </Link>
					and 
					<Link to="/PrivacyPolicy" target="_blank"> <span className="signInSpan"> Privacy Policy.</span> </Link> 
					</div>
			</div>
		</div>
    );
  }
}
export default RegisterSelect;
