import React, { Component } from "react";
import axios from "axios";
import { osName as osname, isTablet as tablet, isMobile as mobile, isAndroid as android, isIOS as ios, deviceType as deviceType, browserName as browserName } from "react-device-detect";
import moment1 from 'moment-timezone';
import { deleteCookie } from '../auth/Logout';
import { Link } from 'react-router';
import MarketplaceHeader from '../home/MarketplaceHeader';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class ParticipantSignedUp extends Component {
  constructor(props) {
  super(props);
  this.state={
		emailSent: false,
		appType: '',
		isSending: false,
		showClose: true,
		showDesktopApp: false,
		showMobileApp: false,
		showWatchApp: false,
		loggedNative: 0,
		loggedRemote: 0,
		loggedWatch: 0,
		desktopAppName: '',
		showQR: false,
		typeQR: '',
		qrImage: '',
		participantLivePopText: '',
		hostLivePopText: '',
		participantDemandPopText : '',
		hostDemandPopText : '',
		isOtherDevice: false,
		hasAllApps: false,
		otherDeviceURL: '',
		launchText: '',
		mobileHref: '',
		additionalMobilePopup: false,
		isParticipantSignUpPop: true,
		newMemberHeader: true,
  }
  
  this.deleteCookie = deleteCookie.bind(this);
}
 
componentDidMount(){
	document.body.style.overflowY = 'hidden';
	this.setState({loggedNative: this.props.state.loggedUserData.loggedNative, loggedRemote: this.props.state.loggedUserData.loggedRemote, loggedWatch: this.props.state.loggedUserData.loggedWatch});
	
	var mainthis = this;
	var url = window.location.href;
	if ( url.indexOf('session') > -1 || url.indexOf('ondemand') > -1 ) { this.setState({showClose: false}); }
	
	if ( ( osname === "Windows" || osname === "Mac OS" ) && !tablet )
		{
			//show watch app only for participant on desktop
			
			if ( this.props && this.props.isHost )
				{
					this.setState({showDesktopApp: true, showMobileApp: true});
				}
				
			if ( this.props && !this.props.isHost )
				{
					this.setState({showDesktopApp: true, showMobileApp: true, showWatchApp: true});
				}
				
			if ( osname === "Windows" ) { this.setState({desktopAppName: 'Windows'}); }
			if ( osname === "Mac OS" ) { this.setState({desktopAppName: 'macOS'}); }
		}
		
	if ( ios )
		{
			//same for host and participant ios devices
			
			if ( deviceType === 'mobile' )
				{
					this.setState({showDesktopApp: true, showMobileApp: true});
					
					if ( !this.props.isHost ) { this.setState({showWatchApp: true}); }
				}
			
			if ( deviceType === 'tablet' || tablet )
				{
					this.setState({showDesktopApp: true, showMobileApp: true});
					
					if ( !this.props.isHost ) { this.setState({showWatchApp: true}); }
				}
				
			this.setState({desktopAppName: 'Native', mobileHref: this.props.urlElectron});
		}
		
	if ( android )
		{
			//same for host and participant android devices
			
			if ( deviceType === 'mobile' )
				{
					this.setState({showDesktopApp: true, showMobileApp: true});
					
					if ( !this.props.isHost ) { this.setState({showWatchApp: true}); }
				}
			
			if ( deviceType === 'tablet' || tablet )
				{
					this.setState({showDesktopApp: true, showMobileApp: true});
					
					if ( !this.props.isHost ) { this.setState({showWatchApp: true}); }
				}
				
			this.setState({desktopAppName: 'Native', mobileHref: this.props.urlElectron});
		}
		
	if ( osname !== "Windows" && osname !== "Mac OS" && !ios && !android ) 
		{ 
			this.setState({isOtherDevice: true});

			if ( !this.props.isHost )
				{
					this.setState({showDesktopApp: true, showMobileApp: true, showWatchApp: true});
				}
			else
				{
					this.setState({showDesktopApp: true, showMobileApp: true});
				}
				
			this.setState({desktopAppName: 'Native', otherDeviceURL: mainthis.props.urlPath});
		}
		
	if ( !this.props.isHost )
		{
			if ( !this.props.isOnDemandSession ) 
				{
					var partTextLive = this.props.state.loggedUserData.firstName + ', you have successfully signed up for the class. Be on top of your game download Virdio before your next class '+this.props.sessionName+' at '+this.getSessionDate(this.props.scheduleDate)+' @ '+this.getSessionTime(this.props.scheduleDate)+' '+moment1().tz(moment1.tz.guess()).format('z')+'.';
					
					if ( url.indexOf('participant-dashboard') > -1 || url.indexOf('login') > -1 )
						{
							partTextLive = this.props.state.loggedUserData.firstName + ', you are all set! Use Virdio native apps to up your workout!';
						}
						
					if ( ( url.indexOf('session') === -1 || url.indexOf('ondemand') === -1 ) && this.props.logedUserAlreadyParticate  )
						{
							partTextLive = this.props.state.loggedUserData.firstName + ', you have already signed up for the class. Be on top of your game download Virdio before your next class '+this.props.sessionName+' at '+this.getSessionDate(this.props.scheduleDate)+' @ '+this.getSessionTime(this.props.scheduleDate)+' '+moment1().tz(moment1.tz.guess()).format('z')+'.';
						}
					
					if ( url.indexOf('participant-dashboard') === -1 && url.indexOf('login') === -1 && url.indexOf('session') === -1 && url.indexOf('ondemand') === -1 )
						{
							partTextLive = this.props.state.loggedUserData.firstName + ', you are all set! Be on top of your game download Virdio before your next class '+this.props.sessionName+' at '+this.getSessionDate(this.props.scheduleDate)+' @ '+this.getSessionTime(this.props.scheduleDate)+' '+moment1().tz(moment1.tz.guess()).format('z')+'.';
						}
					
					this.setState({participantLivePopText: partTextLive});
				}
			else
				{
					var partTextDemand = this.props.state.loggedUserData.firstName + ', up your workout by using Virdio Apps!';
					
					this.setState({participantDemandPopText: partTextDemand});
				}
		}
	else
		{
			if ( !this.props.isOnDemandSession ) 
				{
					var hostTextLive = this.props.state.loggedUserData.firstName + ', you are hosting this class. Be on top of your game download Virdio before your next class '+this.props.sessionName+' at '+this.getSessionDate(this.props.scheduleDate)+' @ '+this.getSessionTime(this.props.scheduleDate)+' '+moment1().tz(moment1.tz.guess()).format('z')+'.';
				
					this.setState({hostLivePopText: hostTextLive});
				}
			else
				{
					var partTextHost = this.props.state.loggedUserData.firstName + ', you are hosting '+this.props.sessionName+' On Demand class.';
					
					this.setState({hostDemandPopText: partTextHost});
				}
		}
		
	//get user loggs from database
	var postData = {userId: this.props.state.loggedUserData.id};
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getUserLoggs", postData).then(res => {
		this.setState({loggedNative: res.data.responseData.loggedNative, loggedRemote: res.data.responseData.loggedRemote, loggedWatch: res.data.responseData.loggedWatch});
		
		if ( !this.props.isHost )
			{
				if ( res.data.responseData.loggedNative === 1 && res.data.responseData.loggedRemote === 1 && res.data.responseData.loggedWatch === 1 )
					{
						this.setState({hasAllApps: true});
					}
			}
		else
			{
				if ( res.data.responseData.loggedNative === 1 && res.data.responseData.loggedRemote === 1 )
					{
						this.setState({hasAllApps: true});
					}
			}
			
		var userData = JSON.parse(localStorage.getItem('userData'));
		userData.data.responseData.loggedNative = res.data.responseData.loggedNative;
		userData.data.responseData.loggedRemote = res.data.responseData.loggedRemote;
		userData.data.responseData.loggedWatch = res.data.responseData.loggedWatch;
		localStorage.setItem('userData', JSON.stringify(userData));
		
	}).catch(err =>{ });


	if ( this.props.state.continueWithFacebook )
		{
			$('.main_loader').attr({'style':'display:flex'});
			let mainthis = this;
			setTimeout(function(){
				$('.main_loader').hide();
				mainthis.openNativeUrl(mainthis.props.urlElectron);				
			}, 300);
			
		}
		
	if ( !this.props.state.isProfile && this.props.state.isMarketplaceUser )
		{
			this.setState({
				newHeader: true,
			})
		}
}

componentWillUnmount(){
	document.body.style.overflowY = 'auto';
}

closePopup=(e)=> {
	
	if ( this.props.isChannelPage )
		{	
			this.props.closePartSignedUpChannel();
		}
	else 
		{
			this.props.updateStateFromChild({
					userSignedUp: false,
				}, ()=>{
					var url = window.location.href;	
					if ( url.indexOf('login') > -1 )
						{
							this.logoutUser();
						}
				});
			window['lastUserId'] = 0;
		}		
	
		
};

sendInstruction=(type, isDesktop, additionalPop)=>{
	if ( !this.state.isSending )
		{
			/* if ( ( osname === "Windows" || osname === "Mac OS" ) && (type === 'Remote Control' || type === 'Smartwatch' || type === 'Mobile') )
				{
					var imgScan = '';
					if ( osname === "Windows" && type !== 'Smartwatch' )
						{
							imgScan = 'https://virdios3.s3.us-east-2.amazonaws.com/documents/qr_android.png';
						}
						
					if ( osname === "Mac OS" && type !== 'Smartwatch' )
						{
							imgScan = 'https://virdios3.s3.us-east-2.amazonaws.com/documents/qr_iphone.png';
						}
						
					if ( type === 'Smartwatch' )
						{
							imgScan = 'https://virdios3.s3.us-east-2.amazonaws.com/documents/qr_watch.png';
						}
					
					this.setState({typeQR: type, showQR: true, qrImage: imgScan});
					return false;
				} */
				
			if ( type === 'Mobile' && !this.state.additionalMobilePopup && !additionalPop )
				{
					this.setState({additionalMobilePopup: true});
					return false;
				}
			
			
			var urlApp = '';
			if ( (type === 'Windows' || type === 'Mac OS' || type === 'iOS' || type === 'Android') && !tablet )
				{
					if ( type === 'Windows'  ) { urlApp = this.props.state.loggedUserData.settings.windowsAppLink; type = 'Windows'; }
					if ( type === 'Android' ) { type = 'Windows'; }
					if ( type === 'Mac OS' ) { urlApp = this.props.state.loggedUserData.settings.macAppLink; type = 'Mac OS'; }
					if ( type === 'iOS' ) { type = 'Mac OS'; }
					
					if ( type === 'Windows' || type === 'Mac OS' ) { this.setState({loggedNative: 1}); } 
				}
				
			if ( type === 'Remote Control' || type === 'Smartwatch' || type === 'Mobile' )
				{
					if ( android ) { urlApp = 'https://play.google.com/store/apps/details?id=mvvm.f4wzy.com.virdioApp'; }
					if ( ios ) { urlApp = 'https://apps.apple.com/us/app/virdio/id1489704718'; }
				}
				
			if ( isDesktop && this.state.isOtherDevice ) { type = 'Native'; }
			if ( isDesktop && type === 'iOS' ) { type = 'Mac OS'; }
			if ( isDesktop && type === 'Android') { type = 'Windows'; }
			
			if ( urlApp !== '' ) { window.open(urlApp) };
			
			console.log(urlApp);
			
			this.setState({isSending: true, appType: type}, () => {
				var postData = {type: type, email: this.props.state.loggedUserData.email, isDesktop: isDesktop, isOtherDevice: this.state.isOtherDevice};
				axios.post(process.env.REACT_APP_NAME+"/api/v1/user/sendAppsEmail", postData).then(res => {
					this.setState({emailSent: true});
					var mainthis = this;
					setTimeout(function(){ mainthis.setState({isSending: false}); }, 2000);
				}).catch(err =>{ });
			});
		}
}

sendInstructionQR=(type)=>{
	if ( !this.state.isSending )
		{
			this.setState({isSending: true, appType: type}, () => {
				var postData = {type: type, email: this.props.state.loggedUserData.email, isDesktop: false, isOtherDevice: this.state.isOtherDevice};
				axios.post(process.env.REACT_APP_NAME+"/api/v1/user/sendAppsEmail", postData).then(res => {
					this.setState({emailSent: true});
					this.closeQR();
					var mainthis = this;
					setTimeout(function(){ mainthis.setState({isSending: false}); }, 2000);
				}).catch(err =>{ });
			});
		}
}

closeQR=()=>{
	this.setState({typeQR: '', showQR: false, qrImage: ''});
}

dismissSend=()=>{
	this.setState({emailSent: false});
}

getSessionDate=(date)=>{
	let sessionDate = '';
	let dateVar = new Date(date).getTime();
	if ( dateVar && dateVar !== 'Invalid Date' )
		{
			let day = new Date(dateVar).getDay();
			let dateNum = new Date(dateVar).getDate();
			let month = new Date(dateVar).getMonth();
			let weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
			let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			sessionDate = weekday[day]+ ', ' + months[month] + ' ' + dateNum;
		}
		
	return sessionDate;
}

getSessionTime=(date)=>{
	let ab = new Date(date);
	if ( ab && ab !== 'Invalid Date' )
		{
			let hour = new Date(date).getHours();
			let minute = new Date(date).getMinutes();
			let sessionTime = hour+':'+minute;
			
			if ( minute < 10 )
				{
				  minute = '0'+minute
				}

			if ( hour < 12 )
				{
					if ( hour < 10 )
						{	
							sessionTime = '0'+hour+':'+minute+" " +'AM';
						}
					else
						{
							sessionTime = hour+':'+minute+" " +'AM';
						}
					
					return sessionTime;
				}
			
			if ( hour > 12 )
				{
					if ( ( hour - 12 ) < 10 )
						{
							sessionTime = '0'+( hour - 12 )+':'+minute+" " +'PM';
						}
					else
						{
							sessionTime = ( hour - 12 )+':'+minute+" " +'PM';
						}	
					return sessionTime ;
				}
			
			if ( hour == 12 && minute >= 0 )
				{
					sessionTime = hour+':'+minute+" " +'PM';	
					return sessionTime;
				}
		}
	else
		{
			return '-';
		}
}

openNativeUrl=(nurl)=>{
	this.props.openNativeUrl(this.props.urlElectron);
	
	if ( this.state.showClose && this.props.state.continueWithFacebook )
		{
			this.props.updateStateFromChild({userSignedUp: false});
		}
		
	this.updateUserDemand();
}

triggerOpened=()=>{
	this.props.triggerOpened();
}

logoutUser=(e)=>{
	localStorage.removeItem('userData'); 
	this.deleteCookie(process.env.REACT_APP_COOKIE_ID); 
	this.deleteCookie(process.env.REACT_APP_COOKIE_TOKEN); 
	window['user_loggedIn'] = 0;
	window.location.reload();
}

updateUserDemand=()=>{
	if ( this.props.isOnDemandSession )
		{
			var onDemandId = this.props.onDemandId;
			var userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
			var postData = {onDemandId: onDemandId, userId: userId};
			let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
			axios.defaults.headers.common['Authorization'] = token;
			axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updateUserDemand", postData).then(res => { }).catch(err =>{ });
		}
}

closeMemberProfile=()=>{
	if ( this.props && this.props.updateStateFromChild )
		{
			this.props.updateStateFromChild({showLaunchPop: false});
		}
}

downloadMob = ()=>{
	this.sendInstruction('Mobile', false, true);
	this.setState({additionalMobilePopup: false});
}

goToManagePage=()=>{
	
	var userId = JSON.parse(localStorage.getItem('userData')).data.responseData.id;
	axios.post(process.env.REACT_APP_NAME+"/api/v1/user/updateMemberVisitValues", {memberRandomId: 0, field: 'membership', userId: userId}).then(res => {
			window.top.location = '/MemberProfile';
	}).catch(err =>{
			window.top.location = '/MemberProfile';
			console.log('-error', err);
		});

	
}

	render() {
	 
	 return (
		 <div className="page_back">
			{ this.props && this.props.state && this.props.state.loggedUserData && this.props.state.loggedUserData.id &&
				<div>
					{ this.state.emailSent &&
						<div className="email_msg">
							<div className="email_cont">We sent you an email to <span>{this.props.state.loggedUserData.email}</span> with steps to download the {this.state.appType} app</div>
							<div className="dismis_msg" onClick={this.dismissSend}>Dismiss</div>
						</div>
					}
					<div className={"memberRegisterContainer memberProfilePage launchHolder launchHeight " + (!this.state.showClose ? 'p80b' : '')}>
						<MarketplaceHeader
							state={this.state}
						>
						</MarketplaceHeader>
						{!this.props.state.isProfile ?
							<div className={"closeLaunchPop " + ( !this.state.showClose ? 'stl500' : 'mediaDisplay')}>
								{ this.state.showClose && this.props.state.isMarketplaceUser && this.props.isWebLogin &&
									<div className="newManageButton" onClick={() => this.goToManagePage()} >							
										<div className="join_browser"> Manage Membership </div>
										<div className="arrow_next"></div>												
									</div>															
								}
								
								{/* <div className="close_sign" onClick={this.closePopup}></div> */}
								
								{ !this.state.showClose && <div className="switch_user" onClick={this.logoutUser}>Switch User</div> }
							</div>
							:
							<div className="closeLaunchPop">
								<div className="close_sign" onClick={this.closeMemberProfile}></div>
							</div>
						}
						<div className="registerMainContainer" >
							<div className="memberRegisterHolder">
								<div className="welcomeTextHolder">
									<div className="welcomeTextH1">								
										<div className="welcomePart"> <div className="emojiDiv">👋</div> Welcome {this.props.state.loggedUserData.firstName}, </div>
										<div className="welcomePart2" > you’re all set!</div>
									</div>	
									<div className="welcomeTextH2">Virdio classes can only be attended in the Virdio Apps, get started by downloading the Virdio apps for your devices.</div>						
								</div>
								<div className="outside_apps">
									{ ( ( this.state.showDesktopApp && !this.state.isOtherDevice ) || ( this.state.showDesktopApp && this.state.isOtherDevice && this.props.sessionStartTimeHigher15Min ) ) &&																																																			
										<div className="one_part">
											<div className="inside_one_part desktop">
												<div className="one_part_title">{this.state.loggedNative === 1 && !tablet && ( osname === "Windows" || osname === "Mac OS" ) ?  'Launch the '+this.state.desktopAppName+' app' : 'Get the most out of your workout' }</div>
												<div className="one_part_subtitle">{this.state.loggedNative === 1 && !tablet && ( osname === "Windows" || osname === "Mac OS" ) ? 'Recommended for best performance and experience' : 'Download the Desktop app to experience classes with Virtual Equipment.' }</div>
												{ /* this.state.loggedNative === 0 && osname === "Mac OS" && !tablet &&
													<div className="one_part_subtitle pt10">or, for Macs with Apple silicon chip, click <a href={this.props.state.loggedUserData.settings.macAppSiliconChipLink} target="_blank"><span className="macos_span">here to download</span></a></div> */
												}
												{ /* this.state.loggedNative === 1 && osname === "Mac OS" && !tablet &&
													<div className="one_part_subtitle pt10">or, if you just switched to a Mac with Apple silicon chip,​ click <a href={this.props.state.loggedUserData.settings.macAppSiliconChipLink} target="_blank"><span className="macos_span">here to download</span></a></div> */
												}
												<div className="signup_btn" onClick={ (this.state.loggedNative === 1 && !tablet && ( osname === "Windows" || osname === "Mac OS" ) ) ? () => this.openNativeUrl(this.props.urlElectron) : () =>  this.sendInstruction(osname, true) }>{(this.state.loggedNative === 1 && !tablet && ( osname === "Windows" || osname === "Mac OS" ) ) ? 'Launch' : 'Download'}</div>
											</div>
										</div>
									}
									{ this.state.showDesktopApp && this.state.isOtherDevice && !this.props.sessionStartTimeHigher15Min &&
										<div className="one_part">
											<div className="inside_one_part desktop">
												<div className="one_part_title">Join with browser for Chromebook</div>
												<div className="one_part_subtitle">Recommended for best performance and experience</div>
												<a href={this.state.otherDeviceURL} target="_blank"><div className="signup_btn" onClick={this.state.showClose ? this.closePopup : this.triggerOpened}>Launch</div></a>
											</div>
										</div>
									}
									{ this.state.showMobileApp &&
										<div className="one_part">
											<div className="inside_one_part smartphone">
												<div className="one_part_title">{this.state.loggedRemote === 1 ? 'Use the Mobile and Remote control app' : 'Join classes on the go'}</div>
												<div className="one_part_subtitle">{this.state.loggedRemote === 1 ? 'Use your mobile phone to manage your class' : this.props.isHost ? 'Download the Mobile App to use your mobile device as a remote control to manage your Class' : 'Download the Mobile App to book and attend classes. No Virtual Equipment (coming soon)'}</div>
												<a href={this.state.mobileHref !== '' ? this.state.mobileHref : undefined} target="_blank"><div className="signup_btn" onClick={( deviceType === 'mobile' || deviceType === 'tablet' || tablet ) ? () => this.updateUserDemand() : () => this.sendInstruction(this.props.isHost ? 'Remote Control' : 'Mobile')}>{( deviceType === 'mobile' || deviceType === 'tablet' || tablet ) ? 'Launch' : 'Download'}</div></a>
											</div>
										</div>
									}
									{/* this.state.showWatchApp &&
										<div className="one_part">
											<div className="inside_one_part smart_watch">
												<div className="one_part_title">{this.state.loggedWatch === 1 ? 'Use the smartwatch app' : 'Track calories burned'}</div>
												<div className="one_part_subtitle">{this.state.loggedWatch === 1 ? 'Remember to use your smartwatch to track your heart rate, calories, and VO2Max in class' : 'Download the smartwatch app to track your Heart Rate, Calories, and VO2Max in class'}</div>
												<div className="signup_btn" onClick={() => this.sendInstruction('Smartwatch')}>Download</div>
											</div>
										</div>
									*/}
									{!this.props.state.isProfile &&
										<div>
											{!this.props.state.isMarketplaceUser ?
												<div className="centHolder">
													{ ( ( this.props && !this.props.isHost && ( osname === "Windows" || osname === "Mac OS" ) && !tablet ) || ( this.props && this.props.isHost && (tablet || osname === "Windows" || osname === "Mac OS" ) && browserName === 'Chrome' ) || (this.props && this.props.isOnDemandSession && !tablet && deviceType !== 'tablet' && deviceType !== 'mobile' && (osname === "Windows" || osname === "Mac OS")) ) ?
														<div>
															{ !this.props.sessionStartTimeHigher15Min ?
																<div>
																	<div className="inline_joins">
																		<a href={this.props.urlPath} target="_blank"><div className="join_browser" onClick={this.state.showClose ? this.closePopup : this.triggerOpened}>Join from web browser</div></a>
																	</div>
																	<span className="inline_spans">or</span>
																	<div className="inline_joins">
																		<a href={this.props.isHost ? process.env.REACT_APP_PATH+"/dashboardLanding" : process.env.REACT_APP_PATH+"/participant-dashboard"}><div className="join_browser">Go to dashboard</div></a>
																	</div>
																</div>
																:
																<a href={this.props.isHost ? process.env.REACT_APP_PATH+"/dashboardLanding" : process.env.REACT_APP_PATH+"/participant-dashboard"}><div className="join_browser">Go to dashboard</div></a>
															}
														</div>
														:
														<a href={this.props.isHost ? process.env.REACT_APP_PATH+"/dashboardLanding" : process.env.REACT_APP_PATH+"/participant-dashboard"}><div className="join_browser">Go to dashboard</div></a>
													}
												</div>
												:
												<></>
											}
										</div>
									}
								</div>	
								{this.state.showQR &&
									<div className="qr_back">
										<div className="qr_container">
											<div className="scan_icons"></div>
											<div className="qr_title">Install our app and experience a better service</div>
											<div className="scan_container">
												<div className="scan_code" style={{backgroundImage: "url('" + this.state.qrImage + "')"}}></div>
											</div>
											<div className="qr_btns_container">
												<div className="qr_btn" onClick={() => this.sendInstructionQR(this.state.typeQR)}>INSTALL NOW</div>
												<div className="qr_later"><span onClick={this.closeQR}>Later</span></div>
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</div>
					{/* !this.props.state.isMarketplaceUser ?
						<div className="page_title">{this.state.hasAllApps ? 'Remember to use your Virdio Apps' : 'Level up your workout with '+this.props.sessionChannelName+'!' }</div>
						:
						<div className="page_title">{this.state.hasAllApps ? 'Remember to use your Virdio Apps' : 'Level up your workout with Virdio!' }</div>
					*/}
					{/*
					<div className="inside_page additional_padd">
						{ !this.props.isOnDemandSession && 
							<div className="page_subtitle">{this.props.isHost ? this.state.hostLivePopText : this.state.participantLivePopText}</div>
						}
						{ this.props.isOnDemandSession && 
							<div className="page_subtitle">{this.props.isHost ? this.state.hostDemandPopText : this.state.participantDemandPopText}</div>
						}
						
					</div>
					*/}
					{ this.state.additionalMobilePopup &&
						<div className="booked_pop_content2">
							<div className="mobilePopupInfo">
								<div className="mobPopTitle">Download Virdio for Mobile</div>
								<div className="mobPopSub mrgb16">Currently, Virtual Equipment is not available on the mobile app (coming soon).</div>
								<div className="mobPopSub mrgb32 paddlr30">You can download the desktop app to experience Virtual Equipment.</div>
								<div>
									<div className="mobPopLft" onClick={() => this.setState({additionalMobilePopup: false})}>Cancel</div>
									<div className="mobPopRght" onClick={this.downloadMob}>Download Mobile App</div>
								</div>
							</div>
						</div>
					}
				</div>
			}
			
		</div>
    );
  }
}

export default ParticipantSignedUp;