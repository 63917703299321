import React, { Component } from "react";
import classnames from "classnames";
import { Link } from "react-router";

class MemberPlans extends Component {
  
  constructor(props) {
	super(props);
	
	this.state={
		appUrl: process.env.REACT_APP_URL+'login',
		showCode: true,
    }
}
 
componentDidMount(){
	
}

openApp=()=>{
	let url = this.state.appUrl;
	window.open(url);
}

showAndHideCoupon=()=>{
	/*
	let showMod = true;
	if ( this.state.showCode )
		{
			showMod = false;
		}		
	this.setState({
		showCode: showMod,
	});
	*/
}

selectPlans=(ev, mod)=>{
	this.props.selectPlan(mod);
}

setPlan=()=>{
	let choosenPlan = this.props.state.defSelectedPlan;
	this.props.selectPlan(choosenPlan);
}

setPlanSelected=(mod)=>{
	
	let planTypeChange = false;
	
	if ( !this.props.state.isRegister )
		{
			if ( mod == 1 )
				{
					if ( this.props.state.singlePlanType != this.props.state.memberSelectedPlanType )
						{
							planTypeChange = true;
						}
				}
			else 
				{
					if ( this.props.state.familyPlanType != this.props.state.memberSelectedPlanType )
						{
							planTypeChange = true;
						}
				}
		}
	
	if ( mod == this.props.state.defSelectedPlan && !planTypeChange )
		{
			return false;
		}
	
	this.props.setPlanSelected(mod);
}

  render() {
	 return (
		<div className="memberRegisterHolder memberPlansHolder lessPaddingSpace">
			<div className="plansH1Holder">
				{this.props.state.isProfile &&
					<div className="closePaymentsHistory" onClick={(e)=>this.props.updateStateFromChild({showSelectPlan: false})}></div>
				}
				{!this.props.state.isProfile ?
					<div className="plansH1">Start Your 14-Day Free Trial</div>
					:
					<div className="plansH1">Select a Plan</div>
				}
				{!this.props.state.isProfile &&
					<div className="plansH2">Try Virdio for free and pay once your trial is over.</div>
				}
				<div className="typeSelectroHolder" >
						<div className={classnames("annualText " + (this.props.state.planTypeText == 'annual' ? "selectedTextColor" : "" ) )} onClick={e=>{this.props.setPlanType('annual')}}>Annual (30% off)</div>
						<div className={classnames("annualText monthlyText " + (this.props.state.planTypeText != 'annual' ? "selectedTextColor" : "" ) )} onClick={e=>{this.props.setPlanType('monthly')}}>Monthly</div>
						<div className={classnames("activeSlider " + (this.props.state.planTypeText != 'annual' ? "slideToRight" : "" ) )}></div>
				</div>
			</div>
			<div className="allPlansHolder" > 
				<div className="plans_holder_half">
					<div className={classnames("plans_inner_holder " + (this.props.state.defSelectedPlan == 1 && !this.props.state.isRegister && this.props.state.memberSelectedPlanType == this.props.state.singlePlanType ? "selected_plan_border" : "" ) + (this.props.state.defSelectedPlan == 1 && this.props.state.isRegister ? "selected_plan_border" : "" ) )} onClick={e=>{this.setPlanSelected(1)}} >
						<div className="plans_inner_h1">{this.props.state.singlePlanObj['planName']}</div>
						
						{!this.props.state.isRegister &&
							<div className="plansAbsButton">
								{this.props.state.defSelectedPlan == 1 && this.props.state.memberSelectedPlanType == this.props.state.singlePlanType ?
									<div className="selectedPlanBadge">Plan Selected</div>
									:
									<div className="selectPlanButton">Select Plan</div>
								}
							</div>
						}
					
						{this.props.state.isRegister &&
							<div className="plansAbsButton">
								{this.props.state.defSelectedPlan == 1  ?
									<div className="selectedPlanBadge">Plan Selected</div>
									:
									<div className="selectPlanButton">Select Plan</div>
								}
							</div>
						}
						
						{this.props.state.codeApplied ?
							<div className="plans_inner_h2">
								<span className="oldPriceDec" > Annual (save 30%) </span>
								<span className="newCouponValue">{this.props.state.subPercent}% off</span>
							</div>
							:
							<div className="plans_inner_h2">Annual (save 30%)</div>
						}
						{this.props.state.singlePlanType == 1 &&
							<div className="plans_inner_price">					
								<div>
									{this.props.state.codeApplied ?
										<span >${(this.props.state.singlePlanObj['monthlyFullPrice'] * this.props.state.promoDiscountMonth).toFixed(2)}</span>
											:
										<span >${this.props.state.singlePlanObj['monthlyFullPrice']}</span>
									}/month
								</div>
							</div>
						}
						{this.props.state.singlePlanType == 2 &&
							<div className="plans_inner_price">
								{ !this.props.state.calculateFullPrice ? 
									<div>	
										{this.props.state.codeApplied ?
											<span className="selectedPriceText"> ${(this.props.state.singlePlanObj['annualFullPrice'] * this.props.state.promoDiscountYear / 12).toFixed(2)}</span>
											:
											<span className="selectedPriceText"> $16.99 </span>
										}/month
									</div>
									:
									<div>
										{this.props.state.codeApplied ?
											<span className="selectedPriceText"> ${(this.props.state.singlePlanObj['annualFullPrice'] * this.props.state.promoDiscountYear / 12).toFixed(2)}</span>
											:
											<span className="selectedPriceText"> ${(this.props.state.singlePlanObj['annualFullPrice'] / 12).toFixed(2)} </span>
										}/month
									</div>
								}
							</div>
						}
						<div className="plans_inner_line singleInnerLineMargin"></div>
						<div className="plans_inner_textHolder">	
							<div className="plans_inner_text"> <div className="emojiDiv">🕺</div> Only you can join classes</div>
							<div className="plans_inner_text"> <div className="emojiDiv">💪</div> 35+ live classes weekly</div>
							<div className="plans_inner_text"> <div className="emojiDiv">📹</div> All Access to on-demand classes</div>
							<div className="plans_inner_text"> <div className="emojiDiv">👋</div> World-class instructors and access to training programs</div>
						</div>
						{this.props.state.isProfile &&
							<div className="plans_inner_buttonsHolder">
							{ /* <div className="plans_inner_startBtn" onClick={e=>{this.selectPlans(e, 1)}}>Start Your Free Trial</div> */}
								<div className="plans_inner_tryText">✌️Try it free, no credit card required</div>
							</div>	
						}
					</div>
				</div>
				<div className="plans_holder_half">
					<div className={classnames("plans_inner_holder " + (this.props.state.defSelectedPlan == 2 && !this.props.state.isRegister && this.props.state.memberSelectedPlanType == this.props.state.familyPlanType ? "selected_plan_border" : "" ) + (this.props.state.defSelectedPlan == 2 && this.props.state.isRegister ? "selected_plan_border" : "" ) )}  onClick={e=>{this.setPlanSelected(2)}} >
						<div className="plans_inner_h1">{this.props.state.familyPlanObj['planName']}</div>
						
						{!this.props.state.isRegister &&
							<div className="plansAbsButton">
								{this.props.state.defSelectedPlan == 2 && this.props.state.memberSelectedPlanType == this.props.state.familyPlanType ?
									<div className="selectedPlanBadge">Plan Selected</div>
									:
									<div className="selectPlanButton" 
									
									>Select Plan</div>
								}
							</div>
						}
						{this.props.state.isRegister &&
							<div className="plansAbsButton">
								{this.props.state.defSelectedPlan == 2 ?
									<div className="selectedPlanBadge">Plan Selected</div>
									:
									<div className="selectPlanButton" >Select Plan</div>
								}
							</div>
						}
						{this.props.state.codeApplied ?
							<div className="plans_inner_h2">
								<span className="oldPriceDec" > Annual (save 30%) </span>
								<span className="newCouponValue">{this.props.state.subPercent}% off</span>
							</div>
							:
							<div className="plans_inner_h2">Annual (save 30%)</div>
						}
						{this.props.state.familyPlanType == 1 && 
							<div className="plans_inner_price">
								{ !this.props.state.calculateFullPrice ? 
									<div>	
										{this.props.state.codeApplied ?
											<span>${(this.props.state.familyPlanObj['monthlyFullPrice'] * this.props.state.promoDiscountFamilyMonth).toFixed(2)}</span>
												:
											<span>${this.props.state.familyPlanObj['monthlyFullPrice']}</span>
										}/month
									</div>
									:
									<div>
										{this.props.state.codeApplied ?
											<span>${(this.props.state.familyPlanObj['monthlyFullPrice'] * this.props.state.promoDiscountFamilyMonth).toFixed(2)}</span>
												:
											<span>${this.props.state.familyPlanObj['monthlyFullPrice']}</span>
										}/month
									</div>
								}
							</div>
						}
						{this.props.state.familyPlanType == 2 && 
							<div className="plans_inner_price"> 
								{ !this.props.state.calculateFullPrice ? 
								<div>
									{this.props.state.codeApplied ?
										<span className="selectedPriceText"> ${(this.props.state.familyPlanObj['annualFullPrice'] * this.props.state.promoDiscountFamilyYear /12).toFixed(2)} </span>
										:
										<span className="selectedPriceText">$24.99</span>
									}/month
								</div>
								:
								<div>
									{this.props.state.codeApplied ?
										<span>${(this.props.state.familyPlanObj['annualFullPrice'] * this.props.state.promoDiscountFamilyYear /12).toFixed(2)}</span>
										:
										<span>${(this.props.state.familyPlanObj['annualFullPrice'] /12).toFixed(2)}</span>
									}/month
								</div>
								}
							</div>
						}
						<div className="plans_inner_line"></div>
						<div className="plans_inner_textHolder">	
							<div className="plans_inner_text"> <div className="emojiDiv">👪</div> Add up to 3 members to your account </div>
							<div className="plans_inner_text"> <div className="emojiDiv">💪</div> 35+ live classes weekly</div>
							<div className="plans_inner_text"> <div className="emojiDiv">📹</div> All Access to on-demand classes</div>
							<div className="plans_inner_text"> <div className="emojiDiv">👋</div> World-class instructors and access to training programs</div>
						</div>
						{this.props.state.isProfile &&
							<div className="plans_inner_buttonsHolder">
								{/* <div className="plans_inner_startBtn" onClick={e=>{this.selectPlans(e, 2)}} >Start Your Free Trial</div> */}
								<div className="plans_inner_tryText">✌️Try it free, no credit card required</div>
							</div>	
						}
					</div>
				</div>
			</div>	
			{!this.props.state.isProfile &&
				<div>	
					<div className="priceTodayHolder">
						<div className="priceTodayH1">Price Today $0 </div>
					</div>
					<div className="trialPeriodInfoHolder">
						<div className="trialHalfHolders">
							<div className="trialH1">14-day trial</div>
						</div>
						<div className="trialHalfHolders rightSideTrialHolder">
							<div className="trialH1">$ 0</div>
						</div>
						<div className="trialHalfHolders">
							<div className="trialH1">Price After Trial</div>
							<div className="trialH2">
								{this.props.state.codeApplied &&
									<div className="codeAppliedHolder">{this.props.state.subPercent}% off</div>
								}
							</div>
						</div>
						<div className="trialHalfHolders rightSideTrialHolder">
							{this.props.state.defSelectedPlan == 1 && this.props.state.singlePlanType == 1 && 
								<div className="trialH1">
									{this.props.state.codeApplied ?
										<span>
											<span className="spanOldPrice">${this.props.state.singlePlanObj['monthlyFullPrice']}</span>
											<span >${(this.props.state.singlePlanObj['monthlyFullPrice'] * this.props.state.promoDiscountMonth).toFixed(2)}</span>
										</span>
										:
										<span >${this.props.state.singlePlanObj['monthlyFullPrice']}</span>
									}
								</div>
							}
							{this.props.state.defSelectedPlan == 1 && this.props.state.singlePlanType == 2 && 
								<div className="trialH1">
									{this.props.state.codeApplied ?
										<span>
											<span className="spanOldPrice" >${this.props.state.singlePlanObj['annualFullPrice']}</span>
											<span>${(this.props.state.singlePlanObj['annualFullPrice'] * this.props.state.promoDiscountYear).toFixed(2)}</span>
										</span>
										:
										<span>${this.props.state.singlePlanObj['annualFullPrice']}</span>
									}
								</div>
							}
							{this.props.state.defSelectedPlan == 2 && this.props.state.familyPlanType == 1 && 
								<div className="trialH1">
									{this.props.state.codeApplied ?
										<span>
											<span className="spanOldPrice" >${this.props.state.familyPlanObj['monthlyFullPrice']}</span>
											<span>${(this.props.state.familyPlanObj['monthlyFullPrice'] * this.props.state.promoDiscountFamilyMonth).toFixed(2)}</span>
										</span>	
											:
										<span>${this.props.state.familyPlanObj['monthlyFullPrice']}</span>
									}
								</div>
							}
							{this.props.state.defSelectedPlan == 2 && this.props.state.familyPlanType == 2 && 
								<div className="trialH1">
									{this.props.state.codeApplied ?
										<span>	
											<span className="spanOldPrice" >${this.props.state.familyPlanObj['annualFullPrice']} </span>
											<span>${(this.props.state.familyPlanObj['annualFullPrice'] * this.props.state.promoDiscountFamilyYear).toFixed(2)} </span>
										</span>
										:
										<span>
											{!this.props.state.familyPlanObj['annualFullPrice'] ? 
												<span>$0</span>
												:												
												<span>${(this.props.state.familyPlanObj['annualFullPrice'] ).toFixed(2)}</span>	
											}												
										</span>
									}
								</div>
							}
							{!this.props.state.calculateFullPrice && this.props.state.familyPlanType == 2 && 
								<div className="trialH2">
									{this.props.state.defSelectedPlan == 1 ? 
										<span> Billed ${this.props.state.singlePlanObj['annualCalcPrice']} for first year, 
											{this.props.state.codeApplied ?
												<span>${(this.props.state.singlePlanObj['annualFullPrice'] * this.props.state.promoDiscountYear).toFixed(2)}/yr after.</span>
												:
												<span>${this.props.state.singlePlanObj['annualFullPrice']}/yr after.</span>
											}
										</span>
										:
										<span> Billed ${ this.props.state.familyPlanObj['annualCalcPrice'] ? (this.props.state.familyPlanObj['annualCalcPrice']).toFixed(2) : 0 } for first year, 
										
										{this.props.state.codeApplied ?
											<span> ${(this.props.state.familyPlanObj['annualFullPrice'] * this.props.state.promoDiscountFamilyYear ).toFixed(2)}/yr after. </span>
											:
											<span> ${ this.props.state.familyPlanObj['annualFullPrice'] ?  (this.props.state.familyPlanObj['annualFullPrice'] ).toFixed(2) : 0}/yr after. </span>
										}
										</span>
									}
								</div>
							}
						</div>
					</div>
					<div className="trialH2 paddingBottom32">*Billed {this.props.state.trialEndDateText} unless cancelled beforehand.</div>
					<div className="bottomCodeHolder">			
						<div className="codeHalfPart">	
							<div className="trialH1 promoTextHolder" onClick={(e)=>this.showAndHideCoupon()} >Have a promo code? 
								{/*<div className="arrowSpan"></div> */}
							</div>
							{this.state.showCode && 
							<div className="inputCodeMainHolder">
								<div className="relative_input_holder" >
									<input type="text" tabIndex="1" className={classnames({'gray_inputs': true, 'foundCouponActive': this.props.state.codeApplied, 'codeError': this.props.state.codeError})}  
										id="code" 
										value={this.props.state.code} 
										onChange={(e)=>this.props.updateStateFromChild({[e.target.id]:e.target.value, codeError: false})} 
										onFocus={() => this.props.updateStateFromChild({focusedElement: 'code'})} 
										onBlur={() => this.props.updateStateFromChild({focusedElement: ''})} 
									/>
									{ this.props.state.code !== '' &&
										<div className="inputImgIcon">
											{this.props.state.codeApplied ?
												<div className="codeApliedCorrect"></div>
												:
												<div className="empty_input_icon" onClick={()=>this.props.updateStateFromChild({code: "", codeApplied: false, codeError: false})} ></div>
											}
											{/* <div className="empty_input" onClick={()=>this.props.updateStateFromChild({code: "", codeApplied: false})}></div> */}
										</div>
									}
								</div>
								{this.props.state.codeApplied ?
									<div className="buttonForCode" onClick={(e)=>this.props.removeCoupon()}>Remove</div>
									:
									<div className="buttonForCode" onClick={(e)=>this.props.checkCoupon()} >Apply</div>
								}
								{this.props.state.codeError && 
									<div className="errorCodeTxt">We couldn’t find this promo </div>
								}
							</div>
							}
						</div>
						<div className="codeHalfPart rightPartCodeHolder">
							<div className="smallPlansButton" onClick={e=>{this.setPlan()}} >Continue</div>
							<div className="plans_inner_tryText blockTry">✌️Try it free, no credit card required</div>
						</div>				
					</div>
				</div>	
				}			
				{/*
				<div className={classnames("inner_plans_holder " + (this.props.state.planSelected == 1 ? "selectedPlanBorder" : "" ))}  >
					<div className="plan_h1">{this.props.state.singlePlanObj['planName']}</div>
					<div className="planDetails_holder">
						<div className="oneDetailHolder">
							35+ live classes weekly
							<div className="dotPartAbs"></div>
						</div>
						<div className="oneDetailHolder">
							All access to on-demand classes
							<div className="dotPartAbs"></div>
						</div>
						<div className="oneDetailHolder">
							World-class instructors and access to training programs
							<div className="dotPartAbs"></div>
						</div>
					</div>
					{!this.props.state.calculateFullPrice && 
					<div className="planPriceTypeHolder">
						<div className="innerPriceType">NORMAL PRICE</div>
						<div className="innerPriceType">FIRST YEAR</div>
					</div>
					}
					{ !this.props.state.calculateFullPrice ? 
						<div className={classnames("new_plan_type_holder " + (this.props.state.singlePlanType == 1 ? "activePlanType" : "" ))} onClick={e=>{this.props.updateStateFromChild({singlePlanType: 1})}} >
							<div className="planTypeInnerHolder">
								<div className="selectedPartHolder" >
										<div className="selectedDivPart">
											<div className="innerSelectedDiv"></div>
										</div>
									</div>
									<div className="innerPlanName">Monthly:</div>
							</div>
							<div className="planTypeInnerHolder monthMiddleHolder">
								<div className="fullPriceText">${this.props.state.singlePlanObj['monthlyFullPrice']}</div>
							</div>
							<div className="planTypeInnerHolder rightPriceHolder">
								<div className="calcPriceText">
									{this.props.state.codeApplied ?
										<span className="selectedPriceText">${(this.props.state.singlePlanObj['monthlyFullPrice'] * this.props.state.promoDiscountMonth).toFixed(2)}</span>
										:
										<span className="selectedPriceText">${this.props.state.singlePlanObj['monthlyCalcPrice']}</span>
									}	
									/mo
								</div>
							</div>
						</div>
						:
						<div className={classnames("new_plan_type_holder " + (this.props.state.singlePlanType == 1 ? "activePlanType" : "" ))} onClick={e=>{this.props.updateStateFromChild({singlePlanType: 1})}} >
							<div className="planTypeInnerHolder fullPriceWidth">
								<div className="selectedPartHolder" >
										<div className="selectedDivPart">
											<div className="innerSelectedDiv"></div>
										</div>
									</div>
									<div className="innerPlanName">Monthly:</div>
							</div>
							<div className="planTypeInnerHolder fullPriceWidth rightFullPrice">
								<div className="calcPriceText">
									{this.props.state.codeApplied ?
										<span className="selectedPriceText">${(this.props.state.singlePlanObj['monthlyFullPrice'] * this.props.state.promoDiscountMonth).toFixed(2)}</span>
										:
										<span className="selectedPriceText">${this.props.state.singlePlanObj['monthlyFullPrice']}</span>
									}	
									/mo
								</div>
							</div>
						</div>
					}
					{ !this.props.state.calculateFullPrice ? 
						<div className={classnames("new_plan_type_holder " + (this.props.state.singlePlanType == 2 ? "activePlanType" : "" ))} onClick={e=>{this.props.updateStateFromChild({singlePlanType: 2})}}>
							<div className="planTypeInnerHolder">
								<div className="selectedPartHolder" >
										<div className="selectedDivPart">
											<div className="innerSelectedDiv"></div>
										</div>
									</div>
									<div className="innerPlanName">Yearly:</div>
							</div>
							<div className="planTypeInnerHolder monthMiddleHolder">
								<div className="fullPriceText smallerText">$24.99</div>
								<div className="fullPriceText smallerText">${this.props.state.singlePlanObj['annualFullPrice']}</div>
							</div>
							<div className="planTypeInnerHolder rightPriceHolder">
								<div className="calcPriceText smallerText">
									{this.props.state.codeApplied ?
										<span className="selectedPriceText"> ${(this.props.state.singlePlanObj['annualFullPrice'] * this.props.state.promoDiscountYear / 12).toFixed(2)}</span>
										:
										<span className="selectedPriceText"> $16.99</span>
									}
									/mo
								</div>
								<div className="calcPriceText smallerText">
									{this.props.state.codeApplied ?
										<span> ${(this.props.state.singlePlanObj['annualFullPrice'] * this.props.state.promoDiscountYear).toFixed(2)} </span>
										:
										<span> ${this.props.state.singlePlanObj['annualCalcPrice']} </span>
									}
								</div>
							</div>
						</div>
					:
						<div className={classnames("new_plan_type_holder " + (this.props.state.singlePlanType == 2 ? "activePlanType" : "" ))} onClick={e=>{this.props.updateStateFromChild({singlePlanType: 2})}}>
								<div className="planTypeInnerHolder fullPriceWidth">
									<div className="selectedPartHolder" >
											<div className="selectedDivPart">
												<div className="innerSelectedDiv"></div>
											</div>
										</div>
										<div className="innerPlanName">Yearly:</div>
								</div>
								<div className="planTypeInnerHolder fullPriceWidth rightFullPrice">
									<div className="calcPriceText smallerText">
										{this.props.state.codeApplied ?
											<span className="selectedPriceText"> ${(this.props.state.singlePlanObj['annualFullPrice'] * this.props.state.promoDiscountYear / 12).toFixed(2)}</span>
											:
											<span className="selectedPriceText"> ${(this.props.state.singlePlanObj['annualFullPrice'] / 12).toFixed(2)}</span>
										}
										/mo
									</div>
									<div className="calcPriceText smallerText">
										{this.props.state.codeApplied ?
											<span> ${(this.props.state.singlePlanObj['annualFullPrice'] * this.props.state.promoDiscountYear).toFixed(2)} </span>
											:
											<span> ${this.props.state.singlePlanObj['annualFullPrice']} </span>
										}
									</div>
								</div>
							</div>
					}
					{ !this.props.state.calculateFullPrice &&
						<div className="saveTip">Save 30% by paying annually</div>	
					}					
					<div className="startTrialButtonHolder" >
						<div className="startTrialButton" onClick={e=>{this.props.selectPlan(1)}} >Start Your Free Trial</div>
						<div className="noCCText">(No Credit Card Required)</div>
					</div>
				</div>
				*/}
			{/*
			<div className="plans_holder_half right_half_plans">
				<div className={classnames("inner_plans_holder " + (this.props.state.planSelected == 2 ? "selectedPlanBorder" : "" )) } >
					<div className="plan_h1">{this.props.state.familyPlanObj['planName']}</div>
					<div className="planDetails_holder">
						<div className="oneDetailHolder">
							35+ live classes weekly
							<div className="dotPartAbs"></div>
						</div>
						<div className="oneDetailHolder">
							All access to on-demand classes
							<div className="dotPartAbs"></div>
						</div>
						<div className="oneDetailHolder">
							World-class instructors and access to training programs
							<div className="dotPartAbs"></div>
						</div>
					</div>
					{!this.props.state.calculateFullPrice && 
					<div className="planPriceTypeHolder">
						<div className="innerPriceType">NORMAL PRICE</div>
						<div className="innerPriceType">FIRST YEAR</div>
					</div>
					}
					{!this.props.state.calculateFullPrice ?
						<div className={classnames("new_plan_type_holder " + (this.props.state.familyPlanType == 1 ? "activePlanType" : "" ))} onClick={e=>{this.props.updateStateFromChild({familyPlanType: 1})}} >
							<div className="planTypeInnerHolder">
								<div className="selectedPartHolder" >
										<div className="selectedDivPart">
											<div className="innerSelectedDiv"></div>
										</div>
									</div>
									<div className="innerPlanName">Monthly:</div>
							</div>
							<div className="planTypeInnerHolder monthMiddleHolder">
								<div className="fullPriceText">${this.props.state.familyPlanObj['monthlyFullPrice']}</div>
							</div>
							<div className="planTypeInnerHolder rightPriceHolder">
								<div className="calcPriceText">
									{this.props.state.codeApplied ?
										<span className="selectedPriceText">${(this.props.state.familyPlanObj['monthlyFullPrice'] * this.props.state.promoDiscountFamilyMonth).toFixed(2)}</span>
										:
										<span className="selectedPriceText">${this.props.state.familyPlanObj['monthlyCalcPrice']}</span>
									}
									/mo
								</div>
							</div>
						</div>
						:
						<div className={classnames("new_plan_type_holder " + (this.props.state.familyPlanType == 1 ? "activePlanType" : "" ))} onClick={e=>{this.props.updateStateFromChild({familyPlanType: 1})}} >
							<div className="planTypeInnerHolder fullPriceWidth">
								<div className="selectedPartHolder" >
										<div className="selectedDivPart">
											<div className="innerSelectedDiv"></div>
										</div>
									</div>
									<div className="innerPlanName">Monthly:</div>
							</div>
							<div className="planTypeInnerHolder fullPriceWidth rightFullPrice">
								<div className="calcPriceText">
									{this.props.state.codeApplied ?
										<span className="selectedPriceText">${(this.props.state.familyPlanObj['monthlyFullPrice'] * this.props.state.promoDiscountFamilyMonth).toFixed(2)}</span>
										:
										<span className="selectedPriceText">${this.props.state.familyPlanObj['monthlyFullPrice']}</span>
									}
									/mo
								</div>
							</div>
						</div>
					}
					{!this.props.state.calculateFullPrice ?
						<div className={classnames("new_plan_type_holder " + (this.props.state.familyPlanType == 2 ? "activePlanType" : "" ))} onClick={e=>{this.props.updateStateFromChild({familyPlanType: 2})}}>
							<div className="planTypeInnerHolder">
								<div className="selectedPartHolder" >
										<div className="selectedDivPart">
											<div className="innerSelectedDiv"></div>
										</div>
									</div>
									<div className="innerPlanName">Yearly:</div>
							</div>
							<div className="planTypeInnerHolder monthMiddleHolder">
								<div className="fullPriceText smallerText">$34.99</div>
								<div className="fullPriceText smallerText">${this.props.state.familyPlanObj['annualFullPrice']}</div>
							</div>
							<div className="planTypeInnerHolder rightPriceHolder">
								<div className="calcPriceText smallerText">
									{this.props.state.codeApplied ?
										<span className="selectedPriceText"> ${(this.props.state.familyPlanObj['annualFullPrice'] * this.props.state.promoDiscountFamilyYear /12).toFixed(2)} </span>
										:
										<span className="selectedPriceText"> $24.99</span>
									}
									/mo
								</div>
								<div className="calcPriceText smallerText">
									{this.props.state.codeApplied ?
										<span>${(this.props.state.familyPlanObj['annualFullPrice'] * this.props.state.promoDiscountFamilyYear ).toFixed(2)}</span>
										:
										<span>${this.props.state.familyPlanObj['annualCalcPrice']}</span>
									}
								</div>
							</div>
						</div>
						:
						<div className={classnames("new_plan_type_holder " + (this.props.state.familyPlanType == 2 ? "activePlanType" : "" ))} onClick={e=>{this.props.updateStateFromChild({familyPlanType: 2})}}>
							<div className="planTypeInnerHolder fullPriceWidth">
								<div className="selectedPartHolder" >
										<div className="selectedDivPart">
											<div className="innerSelectedDiv"></div>
										</div>
									</div>
									<div className="innerPlanName">Yearly:</div>
							</div>
							<div className="planTypeInnerHolder fullPriceWidth rightFullPrice">
								<div className="calcPriceText smallerText">
									{this.props.state.codeApplied ?
										<span className="selectedPriceText"> ${(this.props.state.familyPlanObj['annualFullPrice'] * this.props.state.promoDiscountFamilyYear /12).toFixed(2)} </span>
										:
										<span className="selectedPriceText"> ${(this.props.state.familyPlanObj['annualFullPrice'] /12).toFixed(2)} </span>
									}
									/mo
								</div>
								<div className="calcPriceText smallerText">
									{this.props.state.codeApplied ?
										<span>${(this.props.state.familyPlanObj['annualFullPrice'] * this.props.state.promoDiscountFamilyYear ).toFixed(2)}</span>
										:
										<span>${this.props.state.familyPlanObj['annualFullPrice']}</span>
									}
								</div>
							</div>
						</div>
					}
					{ !this.props.state.calculateFullPrice &&
						<div className="saveTip">Save 30% by paying annually</div>
					}
					<div className="startTrialButtonHolder" >
						<div className="startTrialButton" onClick={e=>{this.props.selectPlan(2)}}>Start Your Free Trial</div>
						<div className="noCCText">(No Credit Card Required)</div>
					</div>
				</div>
			</div>
			*/}
			{/*
			<div className="trialEndHolder">
				Your free trial will end on 
					<span> {this.props.state.trialEndDateText}. </span>
				You can cancel your subscription at any time.</div>
			*/}
		</div>
    );
  }
}
export default MemberPlans;
