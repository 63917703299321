import React, { Component } from "react";
import { browserHistory } from 'react-router'
import ChargingSession from '../home/ChargingSession';
import Header from './Header';
import DatePicker from "react-datepicker";
import axios from "axios";
import $ from 'jquery';
import { getSessionDate } from '../../util/formatDateFunctions';
import { getSessionTime } from '../../util/formatDateFunctions';

class ReportsVirdioCommunity extends Component {
constructor(props) {
	super(props);
	this.state = 
				{ 
					searchStartDate: null,
					searchEndDate: null,
					searchBy: '',
					billing_info: [],
					page_id: 0,
					fullCount: 0,
					page_num_count: 10,
					maxPages: 10,
					page_numbers: [],
					ajaxLoaded: false,
					checkInfoOpen: false
				}
				
	this.getSessionDate = getSessionDate.bind(this);
	this.getSessionTime = getSessionTime.bind(this);
}

componentDidMount(){
	this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
	document.addEventListener('mousedown', this.checkInfoOpen);
}
 
componentWillUnmount() {
	document.removeEventListener('mousedown', this.checkInfoOpen);
}

checkInfoOpen = (e) => {
	if(e.target !== "info_btn" && $(e.target).closest('.info_btn').length === 0 )
		{
			this.setState({ checkInfoOpen: false });
		}
}

changePageCount = (e) => {
	let pg = 0;
	this.setState({ 
			page_num_count: e.target.value,
			page_id: pg
		});
	this.fetchSessionDetails(pg, e.target.value);
}

changePage = (e, mod) => {
	console.log(mod);
	if($(e.currentTarget).hasClass('active'))
		{
			return false;
		}
		
		
	if( mod > -1 && mod < this.state.maxPages)
		{
			this.setState({ page_id: mod });
			this.fetchSessionDetails(mod, this.state.page_num_count);
		}
}

openCloseSearchItem = (e, just_close) => {
	
	if( just_close )
		{
			this.setState({ showSearch: !this.state.showSearch, searchStartDate: null, searchEndDate: null, searchBy: '' });
			
			this.fetchSessionDetails(this.state.page_id, this.state.page_num_count);
		}
	else { this.setState({ showSearch: !this.state.showSearch }); }
	
}

searchByInput =() => {
	let pg = 0;
	this.setState({
		page_id: pg
	});
	this.fetchSessionDetails(pg, this.state.page_num_count); 
}

fetchSessionDetails = (page_id, countId, searchText = this.state.searchBy, startDate= this.state.searchStartDate, endDate= this.state.searchEndDate) => {
	
	
	
	let token = JSON.parse(localStorage.getItem('userData')).data.responseData.token;
	axios.defaults.headers.common['Authorization'] = token;
	
  $('.main_loader').attr({'style':'display:flex'});
  axios.post(process.env.REACT_APP_NAME+"/api/v1/user/getAllCommunityEmails", { page_id: page_id, countId: countId, searchText: searchText, startDate: startDate, endDate: endDate})          
  .then(res => {
	const maxPg = Math.ceil(res.data.responseData['maxPages'] / this.state.page_num_count);
	this.setState({billing_info: res.data.responseData['emails'], maxPages: maxPg, fullCount: res.data.responseData['maxPages'], ajaxLoaded: true});
	if( (this.state.page_id+6) <= maxPg )
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(this.state.page_id+1));
			this.setState({page_numbers: newArray});
		}
	else 
		{
			let howMuch = maxPg < 5 ? maxPg : 5;
			
			 
			let newState = maxPg < 5 ? 0 : maxPg - 5 ;
			const newArray = new Array(howMuch).fill().map((_, i) => i+(newState+1));
			this.setState({page_numbers: newArray});
		}
		
	$('.main_loader').hide();
   
  })
  .catch(err =>{
	  console.log('----------there is problem------------', err);
	 
  });

}


exportCsv=()=>{
	
	let csv = 'Email, Code, Instructor, Date'+"\r\n";
	
	for ( let i = 0; i < this.state.billing_info.length; i++ )
		{
			let date = this.getSessionDate(this.state.billing_info[i]['createdDate'])+' @ '+this.getSessionTime(this.state.billing_info[i]['createdDate']);
			let instructorName = this.state.billing_info[i]['firstName']+' '+this.state.billing_info[i]['lastName'];
			csv += [this.state.billing_info[i]['email'], this.state.billing_info[i]['instructorCode'], instructorName, date].join(',')+"\r\n";
		}
	
	$('#exportcsvcommunity').attr({'href': 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv), download: 'downloadVirdioCommunity.csv'});
}

  render() {
	
    return (
		<div>
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
			<div className="pay_main"> 
				<div className={ "container-fluid payinfo_main_hold " + (this.state.checkInfoOpen ? 'pad_b250' : '')}>
					<div className="main_part_pg">
						<div className="channel_topscroll">
							<div className="channel_subscroll">
								<div className="pay_head">
									<div className="pay_head_title">Emails</div>
								</div>
								<div className="show_pay_page">
									Show
									 <span className="select_span">
										<select onChange={this.changePageCount} className="input-field" id="sessionHour">
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="30">30</option>
											<option value="40">40</option>
											<option value="50">50</option>
											<option value="100000">All</option>
										</select>
									 </span>
									Emails
									{ this.state.billing_info.length > 0 &&
										<div className="exportDiv">
											<a href="#" id="exportcsvcommunity"><div className="rose_btn" onClick={()=> { this.exportCsv(); }}>Export CSV</div></a>
										</div>
									}
								</div>
								<div className="show_search_opt">
									
									{ this.state.showSearch ? 
											<div>
											<div className="flex-grow-1 input_field_container py-0 radius-8">
												<div className="row mx-0 frst-input-row shadow-1 new_search_padd align-items-center radius-8">
													<div className={"col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center" + (this.state.customCheck1 ? ' col-xl-4' : ' col-xl-6')}>
														<input type="text" className="form-control" placeholder="Search for email" onChange={(e) => this.setState({ searchBy: e.target.value })} name="" />
													</div>
													<div className={"col-lg-8 col-md-12 d-flex d-flex flex-wrap align-items-center" + (this.state.customCheck1 ? ' col-xl-8' : ' col-xl-6')}>
														<div className="parent-row mb-3 full_width">				
															<div className="row mx-0 row1 d-flex">
																<div className="col-md-12 d-flex flex-wrap align-items-center">	
																	<div className="filter_item">
																		<span className="searh_span">From</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" selected={this.state.searchStartDate} onChange={date => this.setState({searchStartDate: date})} />
																	</div>
																	<div className="filter_item">
																		<span className="searh_span">To</span>
																		<DatePicker className="form-control dt_input flex-grow-1" placeholderText="mm/dd/yy" selected={this.state.searchEndDate} onChange={date => this.setState({searchEndDate: date})} />
																	</div>
																</div>
															</div>
														</div>
												</div>
												</div>
												

												<div className="right-small-box" onClick={this.searchByInput}>
													<img src="/images/search_loop.png" alt="search" />
												</div>
											</div>
											<div className="closeSearchItem" onClick={ (e) => this.openCloseSearchItem(e, true) }></div>
										</div>
										: 
										
										<div className="seachInputStart" onClick={ this.openCloseSearchItem }></div>
									}
									
									
									
								</div>
								{ this.state.billing_info.length > 0 && 
									<table className="billing_table">
										<thead>
											<tr>
												<th>Virdio Id</th>
												<th>Email</th>
												<th>Code</th>
												<th>Instructor</th>
												<th>Date Sign up</th>
												<th>Medium</th>
												<th>Campaign</th>
												<th>UTM id</th>
												<th>Source</th>											
											</tr>
										</thead>
										<tbody> 
											{ this.state.billing_info.map((item, key) =>
												<tr key={key}>
													<td>
														{item['userId'] ?
															<span> {item['userId']}</span>
															:
															<span> - </span>
														}
													</td>
													<td>{item['email']}</td>
													<td>{item['instructorCode']}</td>
													<td>{item['firstName']} {item['lastName']}</td>
													<td>{ this.getSessionDate(item['createdDate'])} @ {this.getSessionTime(item['createdDate'])}</td>
													<td>
														{item['utm_medium'] ?
															<span> {item['utm_medium']}</span>
															:
															<span> - </span>
														}
													</td>
													<td>
														{item['utm_campaign'] ?
															<span> {item['utm_campaign']}</span>
															:
															<span> - </span>
														}
													</td>
													<td>
														{item['utm_id'] ?
															<span> {item['utm_id']}</span>
															:
															<span> - </span>
														}
													</td>
													<td>
														{item['utm_source'] ?
															<span> {item['utm_source']}</span>
															:
															<span> - </span>
														}
													</td>
												</tr>
											
											) }
										</tbody>
									</table>
								}
								{ this.state.billing_info.length > 0 && 
									<div>
										<div className="showPageNavigation">
											{ this.state.page_num_count != 100000 ?
												<div className="showpinfo">Showing { this.state.page_id+1 } to { this.state.page_num_count } of { this.state.fullCount } emails</div>
												:
												<div className="showpinfo">Showing { this.state.fullCount } emails</div>
											}
											<div className="shopnumbers">
												
												{ (this.state.page_id - 1) > -1 &&
													<span onClick={(e) => this.changePage(e, this.state.page_id - 1)}>Previous</span>
												}
												
												<span>
													{ this.state.page_numbers.map((nitem, nindex) => 
														<span key={nindex} onClick={(e) => this.changePage(e, nitem-1)} className={"pgNumbers " + (this.state.page_id+1 === nitem ? 'active' : '')}>{nitem}</span>	
													)}
												</span>
												
												 { (this.state.page_id + 1) < this.state.maxPages &&
													<span onClick={(e) => this.changePage(e, this.state.page_id + 1)}>Next</span>
		
												 }
												</div>
										</div>
										<div className={ this.state.checkInfoOpen ? 'mob_pad_b250' : ''}></div>
									</div>
								}
								{ this.state.billing_info.length == 0 && this.state.ajaxLoaded && <div className="noResults2">No Emails Found</div> }
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal pr-0" id="coming_soon_alert" aria-modal="true">
				<div className="modal-dialog mw-400 mx-auto modal-dialog-centered">
					<div className="modal-content bg-transparent">
						<div className="modal-body px-4 py-4 modl_bg_drk m-2 radius-8 shadow-none">
							<p className="mb-0 py-4 white text-center">Coming Soon</p>
						</div>
						<div className="text-center"><button type="button" className="custom_btn1 mt-3" data-dismiss="modal">ok</button></div>
					</div>
				</div>
			</div>
			
			<div className="main_loader">
				<div className="main_loader_inner"></div>
			</div>
		</div>
    );
  }
}

export default ReportsVirdioCommunity;